import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { translate } from "../../../translations/translations";

const AdditionalServices = ({ auth: { user } }) => {
  return (
    <li className="mb-1">
      <button
        className="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#AdditionalServices-collapse"
        aria-expanded="false"
      >
        {translate("cNavigation.exploreAddnlServices", false, null)}
      </button>
      <div className="collapse" id="AdditionalServices-collapse">
        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li data-bs-dismiss="offcanvas">
            <Link to="/domains" className="link-dark rounded">
              {translate("common.productNameDomains", false, null)}
            </Link>
          </li>
          <li data-bs-dismiss="offcanvas">
            <Link to="hosting" className="link-dark rounded">
              {translate("common.productNameHosting", false, null)}
            </Link>
          </li>
          <li data-bs-dismiss="offcanvas">
            <Link to="sitebuilder" className="link-dark rounded">
              {translate("common.productNameSb", false, null)}
            </Link>
          </li>
          <li data-bs-dismiss="offcanvas">
            <Link to="email-marketing" className="link-dark rounded">
              {translate("common.productNameEmailMarketing", false, null)}
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

AdditionalServices.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AdditionalServices);
