export const pWsReferrals = {
  sendingInvite: {
    en: "Sending invite..",
    nl: "Uitnodiging versturen..",
  },
  userHasBeenInvited: {
    en: "New prospective user has been invited! Thank you!",
    nl: "Uitnodiging is verzonden!",
  },
  userAlreadyExists: {
    en: "A user with the provided email address already exists.",
    nl: "De gebruiker die u wilde uitnodigen is al geregistreerd.",
  },
  referrals: {
    en: "Referrals",
    nl: "Verwijzingen",
  },
  referNewUser: {
    en: "Refer a new user",
    nl: "Verwijs ons aan een nieuwe gebruiker",
  },
  earnCredits: {
    en: "You can invite new users to Satonda and earn a credit when they sign up!",
    nl: "U kunt nieuwe gebruikers uitnodigen en daarmee krediet verdienen wanneer zij een product kopen van Satonda!",
  },
  shareRefCode: {
    en: "To do so, share your referral code or the below link with the person you're inviting and ask him/her to use this code during the check out process. Alternatively, you can also provide us his/her email address and we'll send the referral link automatically.",
    nl: "Deel uw verwijscode of de onderstaande link met de persoon die u wilt uitnodigen en vraag hem/haar deze code te gebruiken tijdens het afrekenen. U kunt ook de gebruiker's email adres doorgeven, dan zullen wij de verwijslink automatisch delen.",
  },
  yourRefCode: {
    en: "Your referral code is",
    nl: "Uw verwijscode is",
  },
  yourRefLink: {
    en: "and your referral link is",
    nl: "en uw verwijslink is",
  },
  inviteNewUser: {
    en: "Directly invite a new user",
    nl: "Nodig een nieuwe gebruiker uit",
  },
  recipientEmailAddress: {
    en: "Recipient's email address",
    nl: "Email adres van ontvanger",
  },
  sendInvite: {
    en: "Send invite!",
    nl: "Verstuur uitnodiging!",
  },
  provideValidEmail: {
    en: "Please provide a valid email address",
    nl: "Geef aub een geldig email adres op",
  },
  yourRefCredit: {
    en: "Your referral credit",
    nl: "Uw krediet van verwijzingen",
  },
  currentBalance: {
    en: "You currently have a credit balance of",
    nl: "Uw krediet is op dit moment",
  },
  willBeCredited: {
    en: "This amount will be automatically reduced from your invoice when you order new subscriptions that are paid upfront or when invoices for monthly subscriptions are collected.",
    nl: "Dit bedrag zal automatisch worden gebruikt om uw volgende rekening te verlagen.",
  },
};
