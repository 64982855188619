import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import RecordA from "../../../components/products/dns/dnsRecords/RecordA";
import RecordAAAA from "../../../components/products/dns/dnsRecords/RecordAAAA";
import RecordCAA from "../../../components/products/dns/dnsRecords/RecordCAA";
import RecordCNAME from "../../../components/products/dns/dnsRecords/RecordCNAME";
import RecordMX from "../../../components/products/dns/dnsRecords/RecordMX";
import RecordSRV from "../../../components/products/dns/dnsRecords/RecordSRV";
import RecordTXT from "../../../components/products/dns/dnsRecords/RecordTXT";

import { removeMainDomainFromRecordHostname } from "../../../components/products/dns/lib/dnsFunctions";

import { translate } from "../../../translations/translations";

const EditDnsRecord = ({ auth: { activeWorkspace } }) => {
  let { domainName } = useParams();
  let { recordId } = useParams();

  const [dnsRecord, setDnsRecord] = useState(null);

  useEffect(() => {
    getDnsRecordBeingEdited();
    // eslint-disable-next-line
  }, [activeWorkspace, domainName]);

  const getDnsRecordBeingEdited = () => {
    try {
      setDnsRecord(
        removeMainDomainFromRecordHostname(
          domainName,
          activeWorkspace.domains.filter((d) => d.domainName === domainName)[0].dnsRecords.filter((r) => r._id === recordId)[0]
        )
      );
    } catch (error) {
      setDnsRecord(null);
      console.error(error);
    }
  };

  return dnsRecord === null || typeof dnsRecord === "undefined" ? (
    <p className="text-italic m-0">{translate("cEditDnsRecord.dnsRecordNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">
        {translate("cEditDnsRecord.editDnsTypeForDomain", false, { type: dnsRecord.type })} <span className="text-italic">{domainName}</span>
      </h1>
      <div className="mt-4">
        {dnsRecord.type === "A" ? (
          <RecordA existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "AAAA" ? (
          <RecordAAAA existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "CAA" ? (
          <RecordCAA existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "CNAME" ? (
          <RecordCNAME existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "MX" ? (
          <RecordMX existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "SRV" ? (
          <RecordSRV existingData={dnsRecord} formDisabled={false} />
        ) : dnsRecord.type === "TXT" ? (
          <RecordTXT existingData={dnsRecord} formDisabled={false} />
        ) : (
          <p className="text-italic mt-3">{translate("cEditDnsRecord.dnsRecordNotFound", false, null)}</p>
        )}
      </div>
    </>
  );
};

EditDnsRecord.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(EditDnsRecord);
