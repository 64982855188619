import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import Spinner from "../../layout/Spinner";
import DomainCard from "./DomainCard";

import { isValidDomain, addPricesToDomainSuggestions } from "./lib/domainsFunctions";
import { setAlert } from "../../../actions/alert";
import { translate } from "../../../translations/translations";
import { API_URL, POST_CONFIG } from "../../../lib/GeneralVars";

// TODO: Add option to "transfer" domain if available=false

const DomainChecker = ({ auth: { activeWorkspace }, brandProducts: { TLDs }, setAlert }) => {
  let [searchParams] = useSearchParams();
  const PARAM = "check";
  const checkerDiv = useRef();

  const [domainInput, setDomainInput] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [suggestions, setSuggestions] = useState([]);
  const [lastSearch, setLastSearch] = useState("");

  useEffect(() => {
    if (searchParams.has(PARAM)) {
      searchFromParam();
    }
    // eslint-disable-next-line
  }, [searchParams, checkerDiv]);

  const searchFromParam = () => {
    const domainToCheck = searchParams.get(PARAM);
    setDomainInput(domainToCheck);
    onClickSearch(domainToCheck);
    scrollToCheckerDiv();
  };

  const scrollToCheckerDiv = async () => {
    try {
      await new Promise((r) => setTimeout(r, 200));
      window.scrollTo({ top: checkerDiv.current.offsetTop, behavior: "smooth" });
    } catch (error) {
      return;
    }
  };

  const onType = (text) => {
    setDomainInput(text);
    setBtnDisabled(!isValidDomain(text));
  };

  const clearField = () => {
    setDomainInput("");
    setBtnDisabled(true);
  };

  const onClickSearch = async (domainName) => {
    setLocalLoading({ isLoading: true, msg: "Checking availability.." });
    setShowResults(true);
    try {
      if (domainName !== lastSearch || suggestions.length === 0) {
        setLastSearch(domainName);
        const body = JSON.stringify({ domainName });
        const res = await axios.post(`${API_URL}/domains/checkAvailability`, body, POST_CONFIG);
        setSuggestions(addPricesToDomainSuggestions(res.data, TLDs));
      }
    } catch (error) {
      console.error(error);
      setAlert(translate("common.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  return activeWorkspace === null ? (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  ) : (
    <>
      <div className="" ref={checkerDiv}>
        <h1 className="text-primary mb-4">Already have a domain name in mind? Check it's availability!</h1>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className={`posRelative`}>
            <span className="posAbs d-flex align-items-center h-100 z-9 ms-2" style={{ width: "40px" }}>
              www.
            </span>
            {/* add ".input-callout-light" for light shadow */}
            <div className="input-group rounded trans-3">
              <input
                type="text"
                className="form-control"
                id="domainCheckerInputBox"
                // width of "www." = 40px + padding
                style={{ paddingLeft: "50px" }}
                placeholder={translate("cDomainChecker.exampleCom", false, null)}
                aria-label={translate("cDomainChecker.domainInputField", false, null)}
                value={domainInput}
                onChange={(e) => onType(e.target.value)}
              />
              <div className="input-group-append posRelative">
                <span
                  className={`posAbs d-flex align-items-center h-100 z-9 text-danger ${
                    domainInput === "" ? "opacity-0" : "opacity-1 cursorPointer"
                  }  trans-3`}
                  title={domainInput === "" ? undefined : translate("cDomainChecker.clear", false, null)}
                  onClick={() => clearField()}
                  style={{ left: "-20px" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </span>
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  disabled={btnDisabled || localLoading.isLoading}
                  onClick={() => onClickSearch(domainInput)}
                >
                  {translate("cDomainChecker.checkAvailability", false, null)}
                </button>
              </div>
            </div>
          </div>
          {showResults && (
            <>
              {localLoading.isLoading ? (
                <div className="my-5">
                  <Spinner msg={localLoading.msg} />
                </div>
              ) : (
                <>
                  {suggestions.length === 0 ? (
                    <p className="my-3 text-italic">An error occured. Please try again.</p>
                  ) : (
                    <div className="row mt-3">
                      {suggestions.map((suggestion, i) => (
                        <DomainCard data={suggestion} key={`sugg${i}`} />
                      ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

DomainChecker.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, { setAlert })(DomainChecker);
