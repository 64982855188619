export const cSitebuilder = {
  createNewProject: {
    en: "Create new project",
    nl: "Creer nieuw project",
  },
  projectName: {
    en: "Project name",
    nl: "Project naam",
  },
  pleaseUpgrade: {
    en: "Please upgrade to create additional projects",
    nl: "Waardeer uw abonnement op naar een hoger niveau om extra projecten te kunnen maken",
  },
  createProject: {
    en: "Create project!",
    nl: "Creer project!",
  },

  yourProjects: {
    en: "Your projects",
    nl: "Uw projecten",
  },
  noProjectsFound: {
    en: "No existing projects found.",
    nl: "Geen projecten gevonden.",
  },
  showingProjects: {
    en: "Showing projects",
    nl: "Projecten",
  },
  of: {
    en: "of",
    nl: "van",
  },
  openMenu: {
    en: "Open menu",
    nl: "Open menu",
  },
  status: {
    en: "Status",
    nl: "Status",
  },
  published: {
    en: "Published",
    nl: "Gepubliceerd",
  },
  development: {
    en: "Under development",
    nl: "Onder ontwikkeling",
  },
  created: {
    en: "Created",
    nl: "Gemaakt",
  },
  lastChange: {
    en: "Last change",
    nl: "Veranderd",
  },
  editInSb: {
    en: "Edit in Sitebuilder",
    nl: "Bewerk in Sitebuilder",
  },
  areYouSureDelete: {
    en: "Are you sure you want to delete project %projectName%? This cannot be undone.",
    nl: "Weet u zeker dat u project %projectName% wilt verwijderen? Dit kan niet ongedaan gemaakt worden.",
  },
  deleteProject: {
    en: "Delete project",
    nl: "Verwijder project",
  },
  projectNotFound: {
    en: "Project not found.",
    nl: "Project niet gevonden.",
  },
  duplicateProject: {
    en: "Duplicate project",
    nl: "Kloon project",
  },
  duplicatingIsEasyStart: {
    en: "Duplicating a project is an easy way to start a new project with the same styles/layouts as your other projects, to ensure consistency between pages.",
    nl: "Klonen van een project is de gemakkelijkste manier om een nieuw project te beginnen met dezelfde style/layout als uw andere projecten en daarmee consistentie tussen verschillende paginas te waarborgen.",
  },
  editProjectName: {
    en: "Edit project name",
    nl: "Verander projectnaam",
  },
  newProjectName: {
    en: "New project name",
    nl: "Nieuwe projectnaam",
  },
  editName: {
    en: "Edit name",
    nl: "Verander naam",
  },
  publishSite: {
    en: "Publish site",
    nl: "Publiceer site",
  },
  retractSite: {
    en: "Retract site",
    nl: "Site intrekken",
  },
  domain: {
    en: "Domain",
    nl: "Domein",
  },
  selectDomain: {
    en: "Select domain to publish site on",
    nl: "Selecteer domein waar de website op te publiceren",
  },
  directory: {
    en: "Directory",
    nl: "Folder locatie",
  },
  directoryExplainer: {
    en: `Set the directory of the domain you want to publish your site on. Use a forward slash ("/") to specify subdirectories. Set to "/" for the root directory of the domain. Only letters, numbers, "-" and "_" are allowed as characters.`,
    nl: `Specificeer de folder locatie van de domein waar u de site op wilt publiceren. Gebruik de schuine streep naar voren ("/") om subfolders aan te duiden. Gebruik "/" om de website te publiceren op de hoofdfolder van de domein. Alleen letters, getallen, "-" en "_" zijn toegestane karakters in de folder naam.`,
  },
  directoryToPublishOn: {
    en: "Directory to publish site on",
    nl: "Locatie om site te publiceren",
  },
  siteHasBeenPublishedOn: {
    en: "Your site has been published on",
    nl: "Uw site is gepubliceerd op",
  },
  seeMessagesSites: {
    en: "See messages from your sites contact forms",
    nl: "Bekijk berichten ontvangen op contact formulieren op uw sites",
  },
  noWsSelected: {
    en: "No workspace selected.",
    nl: "Geen workspace geselecteerd.",
  },
  noContactForms: {
    en: "You don't have any contact forms on published sites yet.",
    nl: "U heeft geen contactformulieren op gepubliceerde sites.",
  },
  clickToSeeMessages: {
    en: "Click here to see your messages",
    nl: "Klik hier om uw berichten te zien",
  },
};
