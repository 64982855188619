import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormatDate from "./layout/FormatDate";
import { translate } from "../translations/translations";

const Notifications = ({ auth: { user }, allNotifications, readNotifications, wsId = null }) => {
  const NOTIFICATION_ID = "notification_";
  const CLASSNAME_UNREAD = "unreadNotification";
  const PER_PAGE = 5;

  const [notificationsToRender, setNotificationsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  let maxPages = Math.ceil(allNotifications.length / PER_PAGE);

  useEffect(() => {
    renderNotifications();
    // eslint-disable-next-line
  }, [allNotifications, currPage]);

  const renderNotifications = () => {
    let start = currPage * PER_PAGE;
    let end = start + PER_PAGE;
    let notifications = allNotifications.slice(start, end);
    let unreadNotifications = notifications.filter((n) => !n.read);
    setNotificationsToRender(notifications);
    if (unreadNotifications.length > 0) {
      if (wsId === null) {
        // user notifications
        readNotifications(unreadNotifications);
      } else {
        // workspace notifications
        readNotifications(wsId, unreadNotifications);
      }
      setTimeout(() => {
        updateCssReadNotifications(unreadNotifications);
      }, 500);
    }
  };

  const updateCssReadNotifications = (notifications) => {
    notifications.forEach((n) => {
      if (document.getElementById(`${NOTIFICATION_ID}${n._id}`) !== null) {
        document.getElementById(`${NOTIFICATION_ID}${n._id}`).classList.remove(CLASSNAME_UNREAD);
      }
    });
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  return allNotifications.length === 0 ? (
    <p className="text-italic">{translate("cNotifications.noNotificationsFound", false, null)}</p>
  ) : (
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
        <div className="rounded-3 overflowHidden border border-primary mt-5">
          <div className="row mx-0 bg-primary text-light text-bold">
            <div className="col-4 p-2">{translate("cNotifications.date", false, null)}</div>
            <div className="col-8 p-2">{translate("cNotifications.message", false, null)}</div>
          </div>
          {notificationsToRender.map((notification, i) => (
            <div
              className={`row mx-0 trans-5 ${i > 0 ? "border-top border-midgray" : ""} ${notification.read ? "" : CLASSNAME_UNREAD}`}
              id={`${NOTIFICATION_ID}${notification._id}`}
              key={notification._id}
            >
              <div className="col-4 p-2 d-flex align-items-center">
                <FormatDate dateString={notification.date} lang={user.language} formatStr={"dmmyy hm"} />
              </div>
              <div className="col-8 p-2 d-flex align-items-center">
                <div dangerouslySetInnerHTML={{ __html: notification.html }} />
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(-1)}
            title={
              currPage === 0
                ? translate("cNotifications.latestNotifications", false, null)
                : translate("cNotifications.seeNewerNotifications", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("cNotifications.showingNotifications", false, null)}{" "}
            <span className="text-bold">
              {Math.min(currPage * PER_PAGE + 1, allNotifications.length)} - {Math.min((currPage + 1) * PER_PAGE, allNotifications.length)}
            </span>{" "}
            {translate("cNotifications.of", false, null)} <span className="text-bold">{allNotifications.length}</span>
          </span>
          <button
            className="btn btn-outline-primary trans-3"
            onClick={() => clickPageBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("cNotifications.oldestNotifications", false, null)
                : translate("cNotifications.seeOlderNotifications", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  auth: PropTypes.object.isRequired,
  allNotifications: PropTypes.array.isRequired,
  readNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Notifications);
