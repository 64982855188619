import { v4 as uuidv4 } from "uuid";

// Bring in action types
import { SET_ALERT, REMOVE_ALERT } from "./types";

export const setAlert =
  (msg, alertType, timeout = 3000) =>
  (dispatch) => {
    // Generate random, unique ID
    const id = uuidv4();
    // Dispatch action to the reducer
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });
    // Settimeout to make alert disappear after 3sec
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
