import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setGlobalLoading, removeGlobalLoading } from "../actions/globalLoading";
import { setAlert } from "../actions/alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { capitalizeFirstLetter } from "../lib/formattingFunctions";

import Spinner from "../components/layout/Spinner";
import FormatDate from "../components/layout/FormatDate";

import { translate } from "../translations/translations";

const Feedback = ({ auth: { user }, setGlobalLoading, removeGlobalLoading, setAlert }) => {
  const star05 = useRef();
  const star10 = useRef();
  const star15 = useRef();
  const star20 = useRef();
  const star25 = useRef();
  const star30 = useRef();
  const star35 = useRef();
  const star40 = useRef();
  const star45 = useRef();
  const star50 = useRef();

  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackStars, setFeedbackStars] = useState(-1);
  const [errorMsg_title, setErrorMsg_title] = useState("");
  const [errorMsg_text, setErrorMsg_text] = useState("");
  const [errorMsg_rating, setErrorMsg_rating] = useState("");
  const [prevFeedback, setPrevFeedback] = useState([]);
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  useEffect(() => {
    user !== null && getPrevFeedback();
    // eslint-disable-next-line
  }, [user]);

  const getPrevFeedback = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pFeedback.retrievingFeedback", false, null) });
    try {
      const res = await axios.get(`${API_URL}/users/prevFeedback`);
      setPrevFeedback(res.data);
    } catch (error) {
      console.error(error);
      setAlert(translate("common.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const clickSubmitFeedback = async () => {
    if (checkErrors() === 0) {
      await submitFeedback();
      resetStateVars();
    }
  };

  const submitFeedback = async () => {
    let loadingId = setGlobalLoading(translate("pFeedback.submittingFeedback", false, null));
    try {
      const body = JSON.stringify({ feedbackTitle: capitalizeFirstLetter(feedbackTitle), feedbackText, feedbackRating: stringifyFeedbackRating() });
      await axios.post(`${API_URL}/users/submitFeedback`, body, POST_CONFIG);
      setAlert(translate("pFeedback.feedbackSubmitted", false, null), "success");
    } catch (error) {
      console.error(error);
      setAlert(translate("common.serverError", false, null), "danger");
    }
    removeGlobalLoading(loadingId);
  };

  const stringifyFeedbackRating = () => {
    return ["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"][feedbackStars + 1];
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (feedbackTitle === "") {
      setErrorMsg_title(translate("pFeedback.provideTitle", false, null));
      errors++;
    }
    if (feedbackText === "") {
      setErrorMsg_text(translate("pFeedback.provideText", false, null));
      errors++;
    }
    if (feedbackStars < 0) {
      setErrorMsg_rating(translate("pFeedback.provideRating", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setFeedbackTitle("");
    setFeedbackText("");
    setFeedbackStars(-1);
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrorMsg_title("");
    setErrorMsg_text("");
    setErrorMsg_rating("");
  };

  const prevFeedbackGetStars = (feedbackId, rating) => {
    let stars = [];
    for (let i = 1; i <= parseInt(rating); i++) {
      stars.push(<i className="fa-solid fa-star" key={`${feedbackId}_rating_${i}`}></i>);
    }
    rating % 1 > 0 && stars.push(<i className="fa-solid fa-star-half" key={`${feedbackId}_rating_half`}></i>);
    return stars;
  };

  const hoverStar = (i) => {
    [star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].forEach(
      (star, j) => (star.current.style.fill = i >= j ? "#FFD700" : "#ddd")
    );
  };

  const starsMouseOut = () => {
    // Reset to status as per state
    [star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].forEach(
      (star, j) => (star.current.style.fill = feedbackStars >= j ? "#FFD700" : "#ddd")
    );
  };

  const sortByDate = (a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  };

  return (
    <>
      <h1 className="text-primary">{translate("pFeedback.loveToHear", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-6">
            <p className="my-3">{translate("pFeedback.whatDoingWell", false, null)}</p>
            <div className="mt-5 mb-3">
              <label htmlFor="feedback_title" className="form-label">
                {translate("pFeedback.title", false, null)}
              </label>
              <input
                type="text"
                className={`form-control${errorMsg_title === "" ? "" : " is-invalid"}`}
                id="feedback_title"
                placeholder={translate("pFeedback.feedbackTitle", false, null)}
                value={feedbackTitle}
                onChange={(e) => setFeedbackTitle(e.target.value)}
              />
              <div className="invalid-feedback">{errorMsg_title}</div>
            </div>
            <div className="mb-3">
              <label htmlFor="feedback_text" className="form-label">
                Feedback
              </label>
              <textarea
                className={`form-control${errorMsg_text === "" ? "" : " is-invalid"}`}
                id="feedback_text"
                rows="7"
                placeholder={translate("pFeedback.feedbackText", false, null)}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
              ></textarea>
              <div className="invalid-feedback">{errorMsg_text}</div>
            </div>
            <p className="mb-2">{translate("pFeedback.rating", false, null)}</p>
            <div className={`mb-3 d-inline-flex ${errorMsg_rating === "" ? "" : "is-invalid"}`} onMouseOut={() => starsMouseOut()}>
              {[star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].map((star, i) => (
                <svg
                  key={`rating_star_${i}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 288 512"
                  className="cursorPointer"
                  style={{ height: "2rem", fill: "#ddd", transform: i % 2 === 0 ? "" : "scaleX(-1)" }}
                  onMouseOver={() => hoverStar(i)}
                  onClick={() => setFeedbackStars(i)}
                  ref={star}
                >
                  <path d="M288 0c-11.4 0-22.8 5.9-28.7 17.8L194 150.2 47.9 171.4c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.1 23 46 46.4 33.7L288 439.6V0z" />
                </svg>
              ))}
            </div>
            {errorMsg_rating !== "" && <p className="mb-3 invalid-feedback">{errorMsg_rating}</p>}
            <button className="btn btn-primary w-100 mt-5" onClick={clickSubmitFeedback}>
              {translate("pFeedback.submitFeedback", false, null)}
            </button>

            <h2 className="text-secondary mt-5">{translate("pFeedback.prevFeedback", false, null)}</h2>
            {localLoading.isLoading ? (
              <div className="mt-5">
                <Spinner msg={localLoading.msg} />
              </div>
            ) : prevFeedback.length === 0 ? (
              <p className="text-italic">{translate("pFeedback.noPrevFeedback", false, null)}</p>
            ) : (
              <div className="accordion mt-3 mb-5" id="accordionPrevFeedback">
                {prevFeedback.sort(sortByDate).map((feedback, i) => (
                  <div className="accordion-item" key={feedback._id}>
                    <h6 className="accordion-header" id={`heading_${i}`}>
                      <button
                        className="accordion-button collapsed justify-content-between"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse_${i}`}
                        aria-expanded="false"
                        aria-controls={`collapse_${i}`}
                      >
                        <span className="" title={feedback.title}>
                          <span className="d-block d-md-none">
                            {feedback.title.slice(0, 10)}
                            {feedback.title.length > 10 && "..."}
                          </span>
                          <span className="d-none d-md-block">
                            {feedback.title.slice(0, 30)}
                            {feedback.title.length > 30 && "..."}
                          </span>
                        </span>
                        <span className="ms-3 toGrow fontSize08" style={{ color: "#FFD700" }}>
                          {prevFeedbackGetStars(feedback._id, feedback.rating)}
                        </span>
                        <span className="ms-auto me-2 fontSize08 text-italic">
                          (<FormatDate dateString={feedback.createdAt} lang={user.language} formatStr={"dmyy"} />)
                        </span>
                      </button>
                    </h6>
                    <div
                      id={`collapse_${i}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading_${i}`}
                      data-bs-parent="#accordionPrevFeedback"
                    >
                      <div className="accordion-body fontSize09">{feedback.text}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Feedback.propTypes = {
  auth: PropTypes.object.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setGlobalLoading, removeGlobalLoading, setAlert })(Feedback);
