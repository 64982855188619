export const cWsEditCustomerInfo = {
  provideValidVatNumber: {
    en: "Please provide a valid VAT number or select proceed as a consumer rather than a business customer",
    nl: "Geef aub een geldig btw nummer op of ga verder als consument",
  },
  confirmingVatNumber: {
    en: "Confirming VAT number..",
    nl: "Btw nummer bevestigen..",
  },
  euVatCheckerUnavailable: {
    en: "EU's VAT number checker is currently not available. Please continue as a consumer or try another time.",
    nl: "EU's btw checker is op dit moment niet beschikbaar. Ga aub verder als een consument of probeer het later opnieuw.",
  },
  errorWhileConfirmingVatNumber: {
    en: "An error occured while confirming your VAT number. Please try again.",
    nl: "Er is een fout opgetreden tijdens het bevestigen van uw btw nummer. Probeer het aub opnieuw.",
  },
  specifyCustomerType: {
    en: "Please specify whether you're a consumer or a business",
    nl: "Geef aub door of u een consument of bedrijf bent",
  },
  editCustomerInfo: {
    en: "Edit customer information",
    nl: "Klantinformatie bijwerken",
  },
  customerType: {
    en: "Customer type",
    nl: "Klanttype",
  },
  consumer: {
    en: "Consumer",
    nl: "Consument",
  },
  business: {
    en: "Business",
    nl: "Bedrijf",
  },
  companyName: {
    en: "Company name",
    nl: "Bedrijfsnaam",
  },
  euVatNumber: {
    en: "EU VAT number",
    nl: "EU btw nummer",
  },
  vatNumber: {
    en: "VAT number",
    nl: "Btw nummer",
  },
  confirm: {
    en: "Confirm",
    nl: "Bevestig",
  },
};
