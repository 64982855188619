import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setGlobalLoading, removeGlobalLoading } from "../../../actions/globalLoading";
import { setAlert } from "../../../actions/alert";

import { POST_CONFIG, API_URL } from "../../../lib/GeneralVars";
import { validateEmail, validatePhoneNumber, validatePostalcode, getExamplePostalcode } from "../../../lib/GeneralFunctions";
import { errorStatus_initial, errorMsg_initial, getServerSideErrors } from "./lib/profileFunctions";
import { countries } from "../../../lib/Countries";
import { updateUserContactDetails } from "../../../actions/auth";

import { translate } from "../../../translations/translations";

const UpdateContactDetails = ({ auth: { user }, updateUserContactDetails, setGlobalLoading, removeGlobalLoading, setAlert }) => {
  const [profileFields, setProfileFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    street: "",
    postal: "",
    city: "",
    country: "",
  });
  const [errorStatus, setErrorStatus] = useState(errorStatus_initial);
  const [errorMsg, setErrorMsg] = useState(errorMsg_initial);
  const { firstName, lastName, phoneNumber, companyName, street, postal, city, country, email } = profileFields;
  const { err_firstName, err_lastName, err_phoneNumber, err_companyName, err_street, err_postal, err_city, err_country, err_email } = errorStatus;
  const {
    errMsg_firstName,
    errMsg_lastName,
    errMsg_phoneNumber,
    errMsg_companyName,
    errMsg_street,
    errMsg_postal,
    errMsg_city,
    errMsg_country,
    errMsg_email,
  } = errorMsg;

  useEffect(() => {
    populateContactDetails();
    // eslint-disable-next-line
  }, [user]);

  const populateContactDetails = () => {
    if (user !== null) {
      let contactDetailsObj = {};
      Object.keys(profileFields).forEach((field) => {
        if (!field.includes("password")) {
          typeof user[field] !== "undefined" && (contactDetailsObj[field] = user[field]);
        }
      });
      setProfileFields({ ...profileFields, ...contactDetailsObj });
    }
  };

  const onChange = (e) => setProfileFields({ ...profileFields, [e.target.name]: e.target.value });

  const clickChangeContactInfo = async () => {
    // First simple client-side validation (all info provided, passwords match)
    let proceed = clientSideValidation_contactInfo();
    // If client-side all ok, check with backend
    if (proceed) {
      let loadingId = setGlobalLoading(translate("cUserProfile.updatingContactInfo", false, null));
      try {
        const body = JSON.stringify({ email, firstName, lastName, phoneNumber, companyName, street, postal, city, country });
        let res = await axios.post(`${API_URL}/users/update/contact`, body, POST_CONFIG);
        if (res.data.success) {
          updateUserContactDetails(email, firstName, lastName, phoneNumber, companyName, street, postal, city, country);
          setAlert(res.data.msg, "success");
        } else if (res.data.errors) {
          serverSideValidation(res.data.errors);
        } else {
          throw new Error("Error");
        }
        removeGlobalLoading(loadingId);
      } catch (error) {
        console.error(error);
        removeGlobalLoading(loadingId);
        setAlert(translate("cUserProfile.serverErrorContactInfo", false, null), "danger");
      }
    }
  };

  const clientSideValidation_contactInfo = () => {
    let errors = 0;
    let updErrorStatus = errorStatus_initial;
    let updErrorMsg = errorMsg_initial;
    // 1) Check for empty fields
    Object.keys(profileFields).forEach((field) => {
      if (!field.includes("password") && profileFields[field] === "") {
        errors++;
        updErrorStatus = { ...updErrorStatus, [`err_${field}`]: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, [`errMsg_${field}`]: translate("cUserProfile.cannotBeEmpty", false, null) };
      }
    });
    // 2) Field-specific checks
    // Email format
    if (!validateEmail(email)) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_email: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_email: translate("cUserProfile.provideValidEmail", false, null) };
    }
    // Phone number format
    if (!validatePhoneNumber(phoneNumber)) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_phoneNumber: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_phoneNumber: translate("cUserProfile.provideValidPhonenumber", false, null) };
    }
    // Only accepted country codes
    if (!countries.map((country) => country.code).includes(country)) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_country: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_country: translate("cUserProfile.provideValidCountryCode", false, null) };
    }
    // Postal code format
    if (!validatePostalcode(country, postal)) {
      const postalExample = getExamplePostalcode(country);
      errors++;
      updErrorStatus = { ...updErrorStatus, err_postal: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_postal: translate("cUserProfile.provideValidPostalcode", false, { postalExample }) };
    }
    setErrorStatus(updErrorStatus);
    setErrorMsg(updErrorMsg);
    return errors === 0;
  };

  const serverSideValidation = (errors) => {
    const { updErrorStatus, updErrorMsg } = getServerSideErrors(errors);
    setErrorStatus(updErrorStatus);
    setErrorMsg(updErrorMsg);
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-0">{translate("cUserProfile.updateContactDetails", false, null)}</h2>
      <div className="row mt-3">
        <div className="col-md-6 py-2 py-md-0">
          <label htmlFor="firstName">{translate("cUserProfile.firstName", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_firstName}`}
            name="firstName"
            value={firstName}
            onChange={onChange}
            placeholder={translate("cUserProfile.firstName", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_firstName}</div>
        </div>
        <div className="col-md-6 py-2 py-md-0">
          <label htmlFor="lastName">{translate("cUserProfile.lastName", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_lastName}`}
            name="lastName"
            value={lastName}
            onChange={onChange}
            placeholder={translate("cUserProfile.lastName", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_lastName}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="email">{translate("cUserProfile.emailAddress", false, null)}</label>
          <input
            type="email"
            className={`form-control ${err_email}`}
            name="email"
            value={email}
            onChange={onChange}
            placeholder={translate("cUserProfile.exampleEmail", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_email}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="companyName">{translate("cUserProfile.companyName", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_companyName}`}
            name="companyName"
            value={companyName}
            onChange={onChange}
            placeholder={translate("cUserProfile.companyName", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_companyName}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-8 py-2 py-md-0">
          <label htmlFor="street">{translate("cUserProfile.street", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_street}`}
            name="street"
            value={street}
            onChange={onChange}
            placeholder={translate("cUserProfile.street", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_street}</div>
        </div>
        <div className="col-md-4 py-2 py-md-0">
          <label htmlFor="postal">{translate("cUserProfile.postal", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_postal}`}
            name="postal"
            value={postal}
            onChange={onChange}
            placeholder={translate("cUserProfile.postal", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_postal}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 py-2 py-md-0">
          <label htmlFor="city">{translate("cUserProfile.city", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_city}`}
            name="city"
            value={city}
            onChange={onChange}
            placeholder={translate("cUserProfile.city", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_city}</div>
        </div>
        <div className="col-md-6 py-2 py-md-0">
          <label htmlFor="country">{translate("cUserProfile.country", false, null)}</label>
          <select className={`form-select ${err_country}`} name="country" value={country} onChange={onChange} required>
            <option value="">{translate("cUserProfile.selectCountry", false, null)}</option>
            {countries
              .filter((country) => country.active)
              .map((country) => (
                <option key={country.code} value={country.code}>
                  {country.enName}
                </option>
              ))}
          </select>
          <div className="invalid-feedback">{errMsg_country}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="phoneNumber">{translate("cUserProfile.phoneNumber", false, null)}</label>
          <input
            type="text"
            className={`form-control ${err_phoneNumber}`}
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            placeholder={translate("cUserProfile.phoneIntFormat", false, null)}
            required
          />
          <div className="invalid-feedback">{errMsg_phoneNumber}</div>
        </div>
      </div>
      {/* <div className="form-check mt-3">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="updateDomainData"
          checked={checkUpdateDomains}
          onChange={(e) => setCheckUpdateDomains(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="updateDomainData">
          {translate("cUserProfile.alsoUpdateDomainInfo", false, null)}
        </label>
      </div> */}
      <button className="btn btn-primary w-100 mt-3" onClick={clickChangeContactInfo}>
        {translate("cUserProfile.updateContactDetails", false, null)}
      </button>
    </>
  );
};

UpdateContactDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  updateUserContactDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateUserContactDetails, setGlobalLoading, removeGlobalLoading, setAlert })(UpdateContactDetails);
