import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FaChartLine, FaChartPie } from "react-icons/fa";
import { PiRocketLaunchBold } from "react-icons/pi";
import { MdCompare } from "react-icons/md";

import ProductTableEm from "../../../../components/products/productTables/ProductTableEm";

import { translate } from "../../../../translations/translations";

const EmailMarketingMain = ({ auth: { langCode } }) => {
  return (
    <>
      <section className="mt-5" id="header">
        <h1 className="text-primary mt-4 mb-4">{translate("pEmMain.whyUs", false, null)}</h1>
        <div className="row">
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaChartLine />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pEmMain.highRoi", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pEmMain.highRoiTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <PiRocketLaunchBold />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pEmMain.unlimitedContacts", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pEmMain.unlimitedContactsTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <MdCompare />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pEmMain.abVersions", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pEmMain.abVersionsTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaChartPie />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pEmMain.reportingAnalytics", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pEmMain.reportingAnalyticsTag", false, null)}</p>
          </div>
        </div>
        <div className="mt-4">
          <a
            href={`https://www.satonda.com/${langCode}/products/email-marketing`}
            className="btn btn-primary px-5 rounded-pill"
            target="_blank"
            rel="noreferrer"
          >
            {translate("pEmMain.readMore", false, null)}
          </a>
        </div>
      </section>
      <section className="mt-6 mb-6" id="pricing">
        <h1 className="text-primary">{translate("pEmMain.selectYourPlan", false, null)}</h1>
        <ProductTableEm />
      </section>
    </>
  );
};

EmailMarketingMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(EmailMarketingMain);
