export const cWsEditLocationInfo = {
  editLocationInformation: {
    en: "Edit location information",
    nl: "Locatie informatie bijwerken",
  },
  provideCountry: {
    en: "Please provide your country",
    nl: "Specificeer aub uw land",
  },
  provideAddress: {
    en: "Please provide your address",
    nl: "Specificeer aub uw adres",
  },
  providePostal: {
    en: "Please provide your postal or ZIP code",
    nl: "Specificeer aub uw postcode",
  },
  provideCity: {
    en: "Please provide your city",
    nl: "Specificeer aub uw stad",
  },
  street: {
    en: "Street and streetnumber",
    nl: "Straatnaam en -nummer",
  },
  postalOrZip: {
    en: "Postal or ZIP code",
    nl: "Postcode",
  },
  city: {
    en: "City",
    nl: "Stad",
  },
  country: {
    en: "Country",
    nl: "Land",
  },
  selectCountry: {
    en: "Select country",
    nl: "Selecteer land",
  },
};
