import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AudienceEntriesTable from "../../../../components/audiences/AudienceEntriesTable";
import { setAlert } from "../../../../actions/alert";
import { getEmailFromEntry } from "../../../../components/audiences/lib/audienceFunctions";
import { scheduleNewEmailCampaign } from "../../../../actions/emailMarketing";
import { capitalizeFirstLetter } from "../../../../lib/formattingFunctions";

import { translate } from "../../../../translations/translations";

// TODO: Add a check that user added contact info to their email as is required per T&Cs

// Google Analytics URL builder: https://ga-dev-tools.google/campaign-url-builder/ & https://support.google.com/analytics/answer/1033863?hl=en#zippy=

const NewEmailCampaign = ({
  auth: { activeWorkspace },
  emailMarketing: { ebProjects },
  audiences: { audiences },
  setAlert,
  scheduleNewEmailCampaign,
}) => {
  // Campaign name & sender
  const [campaignType, setCampaignType] = useState("proactive");
  const [campaignName, setCampaignName] = useState("");
  const [senderName, setSenderName] = useState("");
  // const [senderEmail, setSenderEmail] = useState("noreply@satonda.nl"); // TODO: Add functionality later
  const senderEmail = "noreply@satonda.nl";
  // Recipient list
  const [selectedAudienceId, setSelectedAudienceId] = useState("");
  const [allEntries, setAllEntries] = useState(null);
  const [filteredEntries, setFilteredEntries] = useState(null);
  // Email content
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState("");
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailDynamicContent, setEmailDynamicContent] = useState([]);
  const [audienceDataKeys, setAudienceDataKeys] = useState([]);
  // Scheduling
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleHour, setScheduleHour] = useState("0");
  const [scheduleMinute, setScheduleMinute] = useState("0");

  const IGNORE_DYNAMIC_VARS = ["EMAIL_SUBJECT", "UNSUBSCRIBE_LINK"];
  const IGNORE_AUDIENCE_KEYS = ["_id"];

  useEffect(() => {
    let audienceData = (audiences.filter((audience) => audience._id === selectedAudienceId)[0] || { data: [] }).data;
    setAllEntries(audienceData);
    setFilteredEntries(audienceData);
    // eslint-disable-next-line
  }, [selectedAudienceId]);

  useEffect(() => {
    setSelectedEmailTemplate(ebProjects.filter((ebProject) => ebProject._id === selectedEmailTemplateId)[0]);
    // eslint-disable-next-line
  }, [selectedEmailTemplateId]);

  useEffect(() => {
    if (selectedEmailTemplate === null || typeof selectedEmailTemplate === "undefined") {
      setEmailDynamicContent([]);
    } else {
      let emailHtml = selectedEmailTemplate.exportedMail.map((v) => v.html).join("");
      let dynamicVariables = [...new Set([...emailHtml.matchAll(/%([\w_]+?)%/g)].map((match) => match[1]))]
        .filter((dynVar) => !IGNORE_DYNAMIC_VARS.includes(dynVar))
        .map((dynVar) => ({ var: dynVar, audienceDataKey: "" }));
      setEmailDynamicContent(dynamicVariables);
      let audienceKeys = filteredEntries.length === 0 ? [] : Object.keys(filteredEntries[0]).filter((key) => !IGNORE_AUDIENCE_KEYS.includes(key));
      setAudienceDataKeys(audienceKeys);
    }
    // eslint-disable-next-line
  }, [selectedEmailTemplate]);

  const onTypeCampaignName = (val) => {
    setCampaignName(val.replace(/[^A-Za-z0-9_-\s]/g, ""));
  };

  const onTypeSenderName = (val) => {
    setSenderName(val.replace(/[^A-Za-z0-9_-\s]/g, ""));
  };

  const selectDynamicVar = (dynVar, key) => {
    setEmailDynamicContent((prev) => prev.map((dataKey) => (dataKey.var === dynVar ? { ...dataKey, audienceDataKey: key } : dataKey)));
  };

  const clickLaunchCampaign = () => {
    if (!errorExists()) {
      const selectedDateUTC = getSelectedDate();
      const recipients = prepareRecipientList(selectedEmailTemplate.exportedMail.map((v) => v.version));
      const dynamicContentData = getDynamicContentData();

      scheduleNewEmailCampaign(
        selectedEmailTemplateId,
        selectedAudienceId,
        campaignType,
        capitalizeFirstLetter(campaignName),
        senderName,
        senderEmail,
        emailSubject,
        selectedEmailTemplate.exportedMail,
        recipients,
        selectedDateUTC,
        dynamicContentData
      );
    }
  };

  const errorExists = () => {
    if (campaignType !== "reactive" && campaignType !== "proactive") {
      setAlert(translate("pEmNewCampaign.errorCampaignType", false, null), "danger");
      return true;
    }
    if (campaignName === "") {
      setAlert(translate("pEmNewCampaign.errorCampaignName", false, null), "danger");
      return true;
    }
    if (senderName === "") {
      setAlert(translate("pEmNewCampaign.errorCampaignSender", false, null), "danger");
      return true;
    }
    // if (senderEmail === "" || !validateEmail(senderEmail)) {
    //   setAlert(translate("pEmNewCampaign.errorCampaignEmail", false, null), "danger");
    //   return true;
    // }
    if (campaignType === "proactive" && filteredEntries.length === 0) {
      setAlert(translate("pEmNewCampaign.errorCampaignAudience", false, null), "danger");
      return true;
    }
    if (selectedEmailTemplateId === "") {
      setAlert(translate("pEmNewCampaign.errorCampaignTemplate", false, null), "danger");
      return true;
    }
    if (emailSubject === "") {
      setAlert(translate("pEmNewCampaign.errorCampaignSubject", false, null), "danger");
      return true;
    }
    if (emailDynamicContent.map((dynContent) => dynContent.audienceDataKey).filter((key) => key === "").length > 0) {
      setAlert(translate("pEmNewCampaign.errorCampaignDynVar", false, null), "danger");
      return true;
    }
    let selectedDate = new Date(scheduleDate);
    selectedDate.setHours(scheduleHour);
    selectedDate.setMinutes(scheduleMinute);
    if (campaignType === "proactive" && (selectedDate.toString() === "Invalid Date" || selectedDate < new Date(Date.now() + 20 * 60 * 1000))) {
      setAlert(translate("pEmNewCampaign.errorCampaignTime", false, null), "danger");
      return true;
    }
    return false;
  };

  const prepareRecipientList = (arrEmailVersions) => {
    if (campaignType === "reactive") {
      return [];
    } else {
      let uniqueEmails = [];
      return filteredEntries
        .map((entry) => {
          let { emailAddress } = getEmailFromEntry(entry);
          if (uniqueEmails.includes(emailAddress)) {
            // Remove duplicate email addresses
            return null;
          }
          uniqueEmails.push(emailAddress);
          let emailVersionToReceive = arrEmailVersions[Math.floor(Math.random() * arrEmailVersions.length)];
          let dynamicContentData = emailDynamicContent.map((dynVar) => ({
            dynamicVar: `%${dynVar.var}%`,
            value: entry[dynVar.audienceDataKey] || "",
          }));
          return {
            emailAddress,
            emailVersionToReceive,
            dynamicContentData,
            sent: false,
            received: false,
            opened: 0,
            unsubscribed: false,
            clickedOn: [],
          };
        })
        .filter((recipient) => recipient !== null);
    }
    // ===== Database model:
    // recipients: [
    //   {
    //     // Email address to send email to
    //     emailAddress: {
    //       type: String,
    //       required: true,
    //     },
    //     // Which version of the email from emailContent is to be sent
    //     emailVersionToReceive: {
    //       type: String,
    //       required: true,
    //     },
    //     // Map required dynamic data to prepare the email
    //     dynamicContentData: [
    //       {
    //         dynamicVar: {
    //           type: String,
    //           required: true,
    //         },
    //         value: {
    //           type: String,
    //           required: true,
    //         },
    //       },
    //     ],
    //     // Statistics
    //     sent: {
    //       type: Boolean,
    //       default: false,
    //     },
    //     received: {
    //       type: Boolean,
    //       default: false,
    //     },
    //     opened: {
    //       type: Number,
    //       default: 0,
    //     },
    //     unsubscribed: {
    //       type: Boolean,
    //       default: false,
    //     },
    //     clickedOn: [{ type: String }],
    //   },
    // ],
  };

  const getDynamicContentData = () => {
    if (campaignType === "proactive") {
      return [];
    } else {
      return emailDynamicContent.map((dynVar) => ({
        dynamicVar: `%${dynVar.var}%`,
        key: dynVar.audienceDataKey,
      }));
    }
  };

  const getSelectedDate = () => {
    if (campaignType === "reactive") {
      return new Date().toUTCString();
    } else {
      let selectedDate = new Date(scheduleDate);
      selectedDate.setHours(scheduleHour);
      selectedDate.setMinutes(scheduleMinute);
      return selectedDate.toUTCString();
    }
  };

  return (
    <>
      <h1 className="text-primary">{translate("pEmNewCampaign.launchNewCampaign", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          <div className="accordion mt-5" id="accordionNewEmailCampaign">
            {/* Campaign data */}
            <div className="accordion-item">
              <h5 className="accordion-header" id={`header_campaignName`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_campaignName`}
                  aria-expanded="false"
                  aria-controls={`collapse_campaignName`}
                >
                  {translate("pEmNewCampaign.campaignSetup", false, null)}
                </button>
              </h5>
              <div
                id={`collapse_campaignName`}
                className="accordion-collapse collapse"
                aria-labelledby={`header_campaignName`}
                data-bs-parent="#accordionNewEmailCampaign"
              >
                <div className="accordion-body fontSize09">
                  {/* Campaign type */}
                  <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.campaignType", false, null)}</h6>
                      <select className="form-select form-select-sm" value={campaignType} onChange={(e) => setCampaignType(e.target.value)}>
                        <option value="proactive">{translate("pEmNewCampaign.typeProactive", false, null)}</option>
                        <option value="reactive">{translate("pEmNewCampaign.typeReactive", false, null)}</option>
                      </select>
                    </div>
                  </div>
                  {/* Campaign name */}
                  <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.campaignName", false, null)}</h6>
                      <input
                        type="text"
                        className={`form-control form-control-sm`}
                        placeholder={translate("pEmNewCampaign.campaignNameDetail", false, null)}
                        value={campaignName}
                        onChange={(e) => onTypeCampaignName(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Sender name */}
                  <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.senderName", false, null)}</h6>
                      <input
                        type="text"
                        className={`form-control form-control-sm`}
                        placeholder={translate("pEmNewCampaign.senderNameDetail", false, null)}
                        value={senderName}
                        onChange={(e) => onTypeSenderName(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Sender email address */}
                  {/* <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.senderEmail", false, null)}</h6>
                      {activeWorkspace.emailMarketing.validatedEmails.length === 0 ? (
                        <p className="text-italic m-0">{translate("cListValidatedEmails.noValidatedEmailsYet", false, null)}</p>
                      ) : (
                        <select className="form-select form-select-sm" value={senderEmail} onChange={(e) => setSenderEmail(e.target.value)}>
                          <option value="">{translate("pEmNewCampaign.selectSenderEmailDetail", false, null)}</option>
                          {activeWorkspace.emailMarketing.validatedEmails.map((emails) => (
                            <option key={emails.emailAddress} value={emails.emailAddress}>
                              {emails.emailAddress}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* Recipients */}
            <div className="accordion-item">
              <h5 className="accordion-header" id={`header_campaignRecipients`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_campaignRecipients`}
                  aria-expanded="false"
                  aria-controls={`collapse_campaignRecipients`}
                >
                  {translate("pEmNewCampaign.recipients", false, null)}
                </button>
              </h5>
              <div
                id={`collapse_campaignRecipients`}
                className="accordion-collapse collapse"
                aria-labelledby={`header_campaignRecipients`}
                data-bs-parent="#accordionNewEmailCampaign"
              >
                <div className="accordion-body fontSize09">
                  {campaignType === "reactive" ? (
                    <p className="my-3 text-italic">{translate("pEmNewCampaign.recipientsReactive", false, null)}</p>
                  ) : (
                    <>
                      {/* Select audience */}
                      <div className="row mb-3">
                        <div className="col-12 col-md-9 col-lg-6">
                          <h6 className="text-secondary">{translate("pEmNewCampaign.selectAudience", false, null)}</h6>
                          <select
                            className="form-select form-select-sm mt-2"
                            value={selectedAudienceId}
                            onChange={(e) => setSelectedAudienceId(e.target.value)}
                          >
                            <option value="">{translate("pEmNewCampaign.selectAnAudience", false, null)}</option>
                            {audiences.map((audience) => (
                              <option key={audience._id} value={audience._id}>
                                {audience.audienceName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* Filter audience */}
                      <AudienceEntriesTable
                        allEntries={allEntries}
                        setAllEntries={setAllEntries}
                        filteredEntries={filteredEntries}
                        setFilteredEntries={setFilteredEntries}
                        boolEditable={false}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Email content */}
            <div className="accordion-item">
              <h5 className="accordion-header" id={`header_campaignSelectEmail`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_campaignSelectEmail`}
                  aria-expanded="false"
                  aria-controls={`collapse_campaignSelectEmail`}
                >
                  {translate("pEmNewCampaign.emailContent", false, null)}
                </button>
              </h5>
              <div
                id={`collapse_campaignSelectEmail`}
                className="accordion-collapse collapse"
                aria-labelledby={`header_campaignSelectEmail`}
                data-bs-parent="#accordionNewEmailCampaign"
              >
                <div className="accordion-body fontSize09">
                  {/* Select email */}
                  <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.selectTemplate", false, null)}</h6>
                      <select
                        className="form-select form-select-sm mt-2"
                        value={selectedEmailTemplateId}
                        onChange={(e) => setSelectedEmailTemplateId(e.target.value)}
                      >
                        <option value="">{translate("pEmNewCampaign.selectATemplate", false, null)}</option>
                        {ebProjects.map((ebProject) => (
                          <option key={ebProject._id} value={ebProject._id}>
                            {ebProject.projectName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Email subject */}
                  <div className="row mb-3">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">{translate("pEmNewCampaign.setSubjectLine", false, null)}</h6>
                      <input
                        type="text"
                        className={`form-control form-control-sm mt-2`}
                        placeholder={translate("pEmNewCampaign.emailSubjectLine", false, null)}
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Dynamic content */}
                  <div className="row">
                    <div className="col-12 col-md-9 col-lg-6">
                      <h6 className="text-secondary">
                        {campaignType === "proactive"
                          ? translate("pEmNewCampaign.assignAudienceKeys", false, null)
                          : translate("pEmNewCampaign.assignInputfieldNames", false, null)}
                      </h6>
                      {emailDynamicContent.length === 0 ? (
                        <p className="m-0 text-italic">{translate("pEmNewCampaign.noDynContentFound", false, null)}</p>
                      ) : (
                        emailDynamicContent.map((dynVar) => (
                          <div className="row align-items-center mb-2" key={dynVar.var}>
                            <div className="col-5">{dynVar.var}</div>
                            <div className="col-7">
                              {campaignType === "proactive" ? (
                                <select
                                  className="form-select form-select-sm"
                                  value={
                                    (emailDynamicContent.filter((dynContent) => dynContent.var === dynVar.var)[0] || { audienceDataKey: "" })
                                      .audienceDataKey
                                  }
                                  onChange={(e) => selectDynamicVar(dynVar.var, e.target.value)}
                                >
                                  <option value="">{translate("pEmNewCampaign.selectDynContentKey", false, null)}</option>
                                  {audienceDataKeys.map((audienceDataKey, i) => (
                                    <option key={i} value={audienceDataKey}>
                                      {audienceDataKey}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  className={`form-control form-control-sm mt-2`}
                                  placeholder={translate("pEmNewCampaign.selectDynContentInputfieldName", false, null)}
                                  value={
                                    (emailDynamicContent.filter((dynContent) => dynContent.var === dynVar.var)[0] || { audienceDataKey: "" })
                                      .audienceDataKey
                                  }
                                  onChange={(e) => selectDynamicVar(dynVar.var, e.target.value)}
                                />
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Scheduling */}
            <div className="accordion-item">
              <h5 className="accordion-header" id={`header_campaignSchedule`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_campaignSchedule`}
                  aria-expanded="false"
                  aria-controls={`collapse_campaignSchedule`}
                >
                  {translate("pEmNewCampaign.schedule", false, null)}
                </button>
              </h5>
              <div
                id={`collapse_campaignSchedule`}
                className="accordion-collapse collapse"
                aria-labelledby={`header_campaignSchedule`}
                data-bs-parent="#accordionNewEmailCampaign"
              >
                <div className="accordion-body fontSize09">
                  {campaignType === "reactive" ? (
                    <p className="my-3 text-italic">{translate("pEmNewCampaign.scheduleReactive", false, null)}</p>
                  ) : (
                    <>
                      <h6 className="text-secondary">{translate("pEmNewCampaign.scheduleWhen", false, null)}</h6>
                      <div className="m-0 row row-cols-lg-auto g-3 align-items-center">
                        <div className="col-12 ps-0">
                          <p className="m-0">{translate("pEmNewCampaign.selectDate", false, null)}</p>
                        </div>
                        <div className="col-12">
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            value={scheduleDate}
                            onChange={(e) => setScheduleDate(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <p className="m-0">{translate("pEmNewCampaign.selectTime", false, null)}</p>
                        </div>
                        <div className="col-12">
                          <div className="d-flex align-items-center">
                            <select className="form-select form-select-sm" value={scheduleHour} onChange={(e) => setScheduleHour(e.target.value)}>
                              <option value="00">00</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                            </select>
                            <span className="mx-2 text-bold">:</span>
                            <select className="form-select form-select-sm" value={scheduleMinute} onChange={(e) => setScheduleMinute(e.target.value)}>
                              <option value="0">00</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                              <option value="25">25</option>
                              <option value="30">30</option>
                              <option value="35">35</option>
                              <option value="40">40</option>
                              <option value="45">45</option>
                              <option value="50">50</option>
                              <option value="55">55</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-success mt-5 px-4" onClick={clickLaunchCampaign}>
            {translate("pEmNewCampaign.clickLaunchNewCampaign", false, null)}
          </button>
        </>
      )}
    </>
  );
};

NewEmailCampaign.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  scheduleNewEmailCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
  audiences: state.audiences,
});

export default connect(mapStateToProps, { setAlert, scheduleNewEmailCampaign })(NewEmailCampaign);
