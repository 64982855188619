export const aAuth = {
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  loadingUserData: {
    en: "Loading user data..",
    nl: "Ophalen gebruikersinformatie..",
  },
  creatingWs: {
    en: "Creating workspace..",
    nl: "Aanmaken workspace..",
  },
  createdNewWs: {
    en: "Successfully created new workspace",
    nl: "Nieuwe workspace aangemaakt",
  },
  userAddedToWs: {
    en: "#User has|Users have# been added to the workspace",
    nl: "#Gebruiker is|Gebruikers zijn# toegevoegd aan de workspace",
  },
  userRoleUpdated: {
    en: "User's role has been updated",
    nl: "Gebruiker's rol is bijgewerkt",
  },
  userRemovedFromWs: {
    en: "User has been removed from the workspace",
    nl: "Gebruiker is verwijderd van de workspace",
  },
  youHaveLeftWs: {
    en: "You have left the workspace",
    nl: "U hebt de workspace verlaten",
  },
  changedWsName: {
    en: "Successfully changed workspace name",
    nl: "Workspace naam bijgewerkt",
  },
  updatingWsLocationInfo: {
    en: "Updating location information..",
    nl: "Workspace locatie informatie bijwerken..",
  },
  changedWsLocationInfo: {
    en: "Successfully changed workspace location information",
    nl: "Workspace locatie informatie bijgewerkt",
  },
  updatingWsCustomerInfo: {
    en: "Updating customer information..",
    nl: "Workspace klantinformatie bijwerken..",
  },
  changedWsCustomerInfo: {
    en: "Successfully changed workspace customer information",
    nl: "Workspace klantinformatie bijgewerkt",
  },
  deletedWs: {
    en: "Successfully deleted workspace",
    nl: "Workspace verwijderd",
  },
  cancellingAccount: {
    en: "Cancelling account..",
    nl: "Account annuleren..",
  },
  updatingSub: {
    en: "Updating subscription..",
    nl: "Bijwerken abonnement..",
  },
  returningSub: {
    en: "Cancelling subscription..",
    nl: "Abonnement annuleren..",
  },
};
