import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";
import { validatePhoneNumber, validatePostalcode, getExamplePostalcode } from "../../lib/GeneralFunctions";
import { countries } from "../../lib/Countries";
import { translate } from "../../translations/translations";

import Spinner from "../../components/layout/Spinner";

const RegisterWsInvite = () => {
  let { wsInviteData } = useParams();

  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [validLink, setValidLink] = useState(false);
  const [done, setDone] = useState(false);
  // Form values
  const [wsId, setWsId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // Error messages
  const [errMsg_email, setErrMsg_email] = useState("");
  const [errMsg_password, setErrMsg_password] = useState("");
  const [errMsg_password2, setErrMsg_password2] = useState("");
  const [errMsg_firstName, setErrMsg_firstName] = useState("");
  const [errMsg_lastName, setErrMsg_lastName] = useState("");
  const [errMsg_street, setErrMsg_street] = useState("");
  const [errMsg_postal, setErrMsg_postal] = useState("");
  const [errMsg_city, setErrMsg_city] = useState("");
  const [errMsg_country, setErrMsg_country] = useState("");
  const [errMsg_phoneNumber, setErrMsg_phoneNumber] = useState("");
  // Server error
  const [errMsg_server, setErrMsg_server] = useState("");

  useEffect(() => {
    checkValidLink();
    // eslint-disable-next-line
  }, []);

  const checkValidLink = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pRegisterWsInvite.loading", false, null) });
    let valid = false;
    try {
      let decoded = window.atob(wsInviteData);
      let match = decoded.match(/^wsId=(.+?)&email=(.+?)$/) || ["", "", ""];
      let wsId = match[1];
      let email = match[2];
      setWsId(wsId);
      setEmail(email);
      if (wsId !== "" && email !== "") {
        const body = JSON.stringify({ wsId, email });
        const res = await axios.post(`${API_URL}/workspace/checkInviteLink`, body, POST_CONFIG);
        valid = res.data.valid;
      }
    } catch (error) {
      console.error(error);
      setErrMsg_server(translate("pRegisterWsInvite.serverError", false, null));
    }
    setValidLink(valid);
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const clickRegister = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pRegisterWsInvite.registeringYourAccount", false, null) });
    try {
      if (checkErrors() === 0) {
        const body = JSON.stringify({
          wsId,
          email,
          password,
          password2,
          firstName,
          lastName,
          street,
          postal,
          city,
          country,
          phoneNumber,
        });
        const res = await axios.post(`${API_URL}/users/registerWsInvite`, body, POST_CONFIG);
        setDone(res.data.success);
        resetStateVars();
      }
    } catch (error) {
      console.error(error);
      setErrMsg_server(translate("pRegisterWsInvite.serverError", false, null));
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    // Check empty fields
    if (email === "") {
      setErrMsg_email(translate("cDomainOwnership.provideEmail", false, null));
      errors++;
    }
    if (password === "") {
      setErrMsg_password(translate("cDomainOwnership.providePw", false, null));
      errors++;
    }
    if (password2 === "") {
      setErrMsg_password2(translate("cDomainOwnership.pleaseConfirmPw", false, null));
      errors++;
    }
    if (firstName === "") {
      setErrMsg_firstName(translate("cDomainOwnership.provideFirstName", false, null));
      errors++;
    }
    if (lastName === "") {
      setErrMsg_lastName(translate("cDomainOwnership.provideLastName", false, null));
      errors++;
    }
    if (street === "") {
      setErrMsg_street(translate("cDomainOwnership.provideAddress", false, null));
      errors++;
    }
    if (postal === "" || !validatePostalcode(country, postal)) {
      const postalExample = getExamplePostalcode(country);
      setErrMsg_postal(translate("cUserProfile.provideValidPostalcode", false, { postalExample }));
      errors++;
    }
    if (city === "") {
      setErrMsg_city(translate("cDomainOwnership.provideCity", false, null));
      errors++;
    }
    if (country === "" || !countries.map((country) => country.code).includes(country)) {
      setErrMsg_country(translate("cUserProfile.provideValidCountryCode", false, null));
      errors++;
    }
    if (phoneNumber === "" || !validatePhoneNumber(phoneNumber)) {
      setErrMsg_phoneNumber(translate("cUserProfile.provideValidPhonenumber", false, null));
      errors++;
    }

    // Password min length
    if (password.length < 8) {
      setErrMsg_password(translate("cUserProfile.pwMinChars", false, null));
      errors++;
    }
    if (password2.length < 8) {
      setErrMsg_password2(translate("cUserProfile.pwMinChars", false, null));
      errors++;
    }
    // Passwords match
    if (password !== "" && password2 !== "" && password.length > 8 && password2.length > 8 && password !== password2) {
      setErrMsg_password2(translate("cUserProfile.pwsDontMatch", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setPassword("");
    setPassword2("");
    setFirstName("");
    setLastName("");
    setStreet("");
    setPostal("");
    setCity("");
    setCountry("");
    setPhoneNumber("");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg_email("");
    setErrMsg_password("");
    setErrMsg_password2("");
    setErrMsg_firstName("");
    setErrMsg_lastName("");
    setErrMsg_street("");
    setErrMsg_postal("");
    setErrMsg_city("");
    setErrMsg_country("");
    setErrMsg_phoneNumber("");
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-10 col-lg-8">
          <h1 className="text-primary mb-3">{translate("pRegisterWsInvite.registerAccount", false, null)}</h1>
          {errMsg_server !== "" && <div className="my-4 alert alert-danger">{errMsg_server}</div>}
          {localLoading.isLoading ? (
            <Spinner msg={localLoading.msg} />
          ) : done ? (
            <p className="mt-5">
              {translate("pRegisterWsInvite.accountCreated", false, null)}{" "}
              <Link to="/">{translate("pRegisterWsInvite.clickHereToLogin", false, null)}</Link>
            </p>
          ) : validLink ? (
            <>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  {translate("cUserProfile.emailAddress", false, null)}
                </label>
                <input
                  type="email"
                  className={`form-control ${errMsg_email === "" ? "" : "is-invalid"}`}
                  name="email"
                  value={email}
                  placeholder={translate("cUserProfile.exampleEmail", false, null)}
                  disabled
                />
                <div className="invalid-feedback">{errMsg_email}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="password" className="form-label">
                    {translate("cUserProfile.password", false, null)}
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errMsg_password === "" ? "" : "is-invalid"}`}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={translate("cUserProfile.password", false, null)}
                    required
                    minLength="8"
                  />
                  <div className="invalid-feedback">{errMsg_password}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="password2" className="form-label">
                    {translate("cUserProfile.confirmPw", false, null)}
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errMsg_password2 === "" ? "" : "is-invalid"}`}
                    name="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    placeholder={translate("cUserProfile.confirmPw", false, null)}
                    required
                    minLength="8"
                  />
                  <div className="invalid-feedback">{errMsg_password2}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="firstName" className="form-label">
                    {translate("cUserProfile.firstName", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_firstName === "" ? "" : "is-invalid"}`}
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder={translate("cUserProfile.firstName", false, null)}
                    required
                  />
                  <div className="invalid-feedback">{errMsg_firstName}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="form-label">
                    {translate("cUserProfile.lastName", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_lastName === "" ? "" : "is-invalid"}`}
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder={translate("cUserProfile.lastName", false, null)}
                    required
                  />
                  <div className="invalid-feedback">{errMsg_lastName}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-8">
                  <label htmlFor="street" className="form-label">
                    {translate("cUserProfile.street", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_street === "" ? "" : "is-invalid"}`}
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    placeholder={translate("cUserProfile.street", false, null)}
                    required
                  />
                  <div className="invalid-feedback">{errMsg_street}</div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="postal" className="form-label">
                    {translate("cUserProfile.postal", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_postal === "" ? "" : "is-invalid"}`}
                    name="postal"
                    value={postal}
                    onChange={(e) => setPostal(e.target.value)}
                    placeholder={translate("cUserProfile.postal", false, null)}
                    required
                  />
                  <div className="invalid-feedback">{errMsg_postal}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="city" className="form-label">
                    {translate("cUserProfile.city", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_city === "" ? "" : "is-invalid"}`}
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder={translate("cUserProfile.city", false, null)}
                    required
                  />
                  <div className="invalid-feedback">{errMsg_city}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="form-label">
                    {translate("cUserProfile.country", false, null)}
                  </label>
                  <select
                    className={`form-select ${errMsg_country === "" ? "" : "is-invalid"}`}
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    <option value="">{translate("cUserProfile.selectCountry", false, null)}</option>
                    {countries
                      .filter((country) => country.active)
                      .map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.enName}
                        </option>
                      ))}
                  </select>
                  <div className="invalid-feedback">{errMsg_country}</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  {translate("cUserProfile.phoneNumber", false, null)}
                </label>
                <input
                  type="text"
                  className={`form-control ${errMsg_phoneNumber === "" ? "" : "is-invalid"}`}
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder={translate("cUserProfile.phoneIntFormat", false, null)}
                  required
                />
                <div className="invalid-feedback">{errMsg_phoneNumber}</div>
              </div>
              <div className="mt-4 mb-6">
                <button className="btn btn-success trans-3 w-100" onClick={clickRegister}>
                  {translate("pRegisterWsInvite.registerAccount", false, null)}
                </button>
              </div>
            </>
          ) : (
            <p className="mt-5">{translate("pRegisterWsInvite.invalidInviteLink", false, null)}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default RegisterWsInvite;
