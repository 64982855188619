import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getDomainActionPrice } from "../../../../components/products/domains/lib/domainsFunctions";
import { capitalizeFirstLetter } from "../../../../lib/formattingFunctions";
import FormatNumber from "../../../../components/layout/FormatNumber";
import { getCurrency } from "../../../../lib/Currencies";

import { translate } from "../../../../translations/translations";

const AllTLDs = ({ auth: { langCode, activeWorkspace, fxRates }, brandProducts: { TLDs, tldCategories } }) => {
  const CAT_ALL = "all";
  const CAT_POPULAR = "popular";
  const PER_PAGE = 30;

  const [selectedCategory, setSelectedCategory] = useState(CAT_POPULAR);
  const [TLDsMatchingCategory, setTLDsMatchingCategory] = useState([]);
  const [TLDsToRender, setTLDsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  useEffect(() => {
    if (selectedCategory === CAT_ALL) {
      setTLDsMatchingCategory(TLDs.sort(sortAlpha));
    } else {
      setTLDsMatchingCategory(TLDs.filter((row) => row.categories.includes(selectedCategory)).sort(sortAlpha));
    }
    setCurrPage(0);
    // eslint-disable-next-line
  }, [TLDs, selectedCategory]);

  useEffect(() => {
    renderTLDs();
    // eslint-disable-next-line
  }, [currPage, TLDsMatchingCategory]);

  const renderTLDs = () => {
    let start = currPage * PER_PAGE;
    let end = start + PER_PAGE;
    setTLDsToRender(TLDsMatchingCategory.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const selectCat = (target, category) => {
    setSelectedCategory(category);
    [...document.getElementsByClassName("selected")].forEach((el) => el.classList.remove("selected"));
    target.classList.add("selected");
  };

  let maxPages = Math.ceil(TLDsMatchingCategory.length / PER_PAGE);

  const sortAlpha = (a, b) => {
    if (a.tld < b.tld) {
      return -1;
    }
    if (a.tld > b.tld) {
      return 1;
    }
    return 0;
  };

  const categoryTranslation = (cat) => {
    const category = tldCategories[cat];
    return capitalizeFirstLetter(category[langCode] || category.en);
  };

  const sortAlphaCategories = (a, b) => {
    if (categoryTranslation(a) < categoryTranslation(b)) {
      return -1;
    }
    if (categoryTranslation(a) > categoryTranslation(b)) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <h1 className="text-primary">{translate("pDomainsAllTlds.allTlds", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : TLDs.length === 0 ? (
        <p className="text-italic m-0">{translate("pDomainsAllTlds.serverError", false, null)}</p>
      ) : (
        <div className="row mt-4">
          {/* Categories */}
          <div className="col-12 col-md-3 p-2">
            <div className="shadow-light noHover">
              <p className={`m-0 py-2 px-3 allDomainsCat`} onClick={(e) => selectCat(e.target, CAT_ALL)}>
                {translate("pDomainsAllTlds.all", false, null)}
              </p>
              <p className={`m-0 py-2 px-3 allDomainsCat selected`} onClick={(e) => selectCat(e.target, CAT_POPULAR)}>
                {categoryTranslation(CAT_POPULAR)}
                <i className="fa-solid fa-star ms-2 text-secondary"></i>
              </p>
              {Object.keys(tldCategories)
                .filter((cat) => cat !== CAT_POPULAR)
                .sort(sortAlphaCategories)
                .map((cat) => (
                  <p className={`m-0 py-2 px-3 allDomainsCat`} key={cat} onClick={(e) => selectCat(e.target, cat)}>
                    {categoryTranslation(cat)}
                  </p>
                ))}
            </div>
          </div>
          {/* TLDs */}
          <div className="col-12 col-md-9 p-2">
            <div className="shadow-light noHover">
              <div className="row mx-0">
                {TLDsToRender.map((tld) => {
                  const register = getDomainActionPrice(tld, "register", getCurrency(activeWorkspace.location.priceCountry).currencyCode, fxRates);
                  const renew = getDomainActionPrice(tld, "renew", getCurrency(activeWorkspace.location.priceCountry).currencyCode, fxRates);
                  const transfer = getDomainActionPrice(tld, "transfer", getCurrency(activeWorkspace.location.priceCountry).currencyCode, fxRates);
                  return (
                    <div className="col-6 col-xl-4 p-2 allTldsItem" key={tld.tld}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span className="h3 m-0">.{tld.tld}</span>
                        {register < renew && <span className="bg-success-light rounded-3 px-2 py-1 ms-2 fontSize08">Promo!</span>}
                      </div>
                      <div className="fontSize08 text-center px-1">
                        <p className="my-1">
                          {translate("pDomainsAllTlds.register", false, null)}: {getCurrency(activeWorkspace.location.priceCountry).currencySign}{" "}
                          <FormatNumber number={register} formatStr={"0.00"} />{" "}
                          {register < renew && (
                            <span className="fontSize07 text-strike">
                              {getCurrency(activeWorkspace.location.priceCountry).currencyCode} <FormatNumber number={renew} formatStr={"0.00"} />
                            </span>
                          )}
                        </p>
                        <p className="m-0">
                          {translate("pDomainsAllTlds.transfer", false, null)}: {getCurrency(activeWorkspace.location.priceCountry).currencySign}{" "}
                          <FormatNumber number={transfer} formatStr={"0.00"} />{" "}
                          {transfer < renew && (
                            <span className="fontSize07 text-strike">
                              {getCurrency(activeWorkspace.location.priceCountry).currencyCode} <FormatNumber number={renew} formatStr={"0.00"} />
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button className="btn btn-outline-primary trans-3 me-4" onClick={() => clickPageBtn(-1)} disabled={currPage === 0}>
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <span className="fontSize08 text-dark">
                {translate("pDomainsAllTlds.showingDomains", false, null)}{" "}
                <span className="text-bold">
                  {Math.min(currPage * PER_PAGE + 1, TLDsMatchingCategory.length)} -{" "}
                  {Math.min((currPage + 1) * PER_PAGE, TLDsMatchingCategory.length)}
                </span>{" "}
                {translate("pDomainsAllTlds.of", false, null)} <span className="text-bold">{TLDsMatchingCategory.length}</span>
              </span>
              <button className="btn btn-outline-primary trans-3 ms-4" onClick={() => clickPageBtn(1)} disabled={currPage + 1 === maxPages}>
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AllTLDs.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(AllTLDs);
