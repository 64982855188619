export const pSupport = {
  creatingNewTicket: {
    en: "Creating new ticket..",
    nl: "Creeren van nieuw ticket",
  },
  support: {
    en: "Support",
    nl: "Helpdesk",
  },
  openTickets: {
    en: "Open tickets",
    nl: "Open tickets",
  },
  closedTickets: {
    en: "Closed tickets",
    nl: "Gesloten tickets",
  },
  openTicketsShort: {
    en: "Open",
    nl: "Open",
  },
  closedTicketsShort: {
    en: "Closed",
    nl: "Gesloten",
  },
  newTicket: {
    en: "Create new ticket",
    nl: "Nieuw ticket",
  },
  newTicketShort: {
    en: "Create new",
    nl: "Nieuw",
  },
};
