import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import { validateEmail } from "../../lib/GeneralFunctions";

import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";

const ForgotPw = ({ auth: { isAuthenticated } }) => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [linkSent, setLinkSent] = useState(false);

  // Redirect if already logged in
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const clickSendLink = async () => {
    if (email === "") {
      setErrorMsg("Please enter your email");
    }
    if (!validateEmail(email)) {
      setErrorMsg("Please enter a valid email format");
    } else {
      setLocalLoading({ isLoading: true, msg: "Sending email with link to reset password.." });
      let success = await sendPwResetLink();
      success && setLinkSent(true);
      !success && setErrorMsg("An error occured or your link might not be valid (anymore). Please try again.");
      setLocalLoading({ isLoading: false, msg: "" });
    }
  };

  const sendPwResetLink = async () => {
    let success = false;
    try {
      const body = JSON.stringify({ email });
      let res = await axios.post(`${API_URL}/users/resetPw`, body, POST_CONFIG);
      success = res.data.success;
    } catch (error) {
      console.error(error);
    }
    return success;
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#DFDBE5",
        backgroundImage: `linear-gradient(225deg, rgba(246, 129, 0, 0.6) 0%, rgba(229, 42, 5, 0.6) 75%), url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-12 col-sm-9 col-md-6 col-lg-4 py-5 px-4 rounded text-center"
            style={{ backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0px 0px 5px 3px rgba(0, 0, 0, 0.2)" }}
          >
            <img src="/assets/img/satonda_gradient.png" alt="Satonda" className="mb-5" style={{ width: "200px" }} />
            {localLoading.isLoading ? (
              <div className="text-center my-5">
                <div className="spinner-border spinner-border-sm text-primary"></div>
                <p className="mb-0 mt-2 text-primary fontSize08">{localLoading.msg}</p>
              </div>
            ) : linkSent ? (
              <p className="m-0 fontSize08">
                An email with a link to reset your password has been sent to {email}! It might take a few minutes before it reaches your inbox. If you
                don't see it, please check your spam folder.
              </p>
            ) : (
              <>
                <p className="mb-3 fontSize08">
                  Please provide the email address you registered with. We'll send an email to this address with a link to reset your password.
                </p>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa-solid fa-user"></i>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {errorMsg !== "" && <p className="mb-3 text-center text-danger">{errorMsg}</p>}
                <button className="btn btn-primary w-100" onClick={clickSendLink}>
                  Reset password
                </button>
                <p className="mt-4 mb-0 fontSize08">
                  Remembered your password again?{" "}
                  <Link to="/login" className="link-primary">
                    Go to login page.
                  </Link>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPw.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ForgotPw);
