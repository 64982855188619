import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { isValidIPv4 } from "../../domains/lib/domainsFunctions";
import { isValidFqdn, getDnsDomainIdFromDomainName } from "../lib/dnsFunctions";

import { updateNameservers } from "../../../../actions/dns";

import { translate } from "../../../../translations/translations";

const RecordNameservers = ({ auth: { activeWorkspace }, dnsRecords, updateNameservers }) => {
  let { domainName } = useParams();
  let navigate = useNavigate();

  const DEFAULT_NS = { ns1: "ns1.yoursrs.com", ns2: "ns2.yoursrs.com" };

  const [useDefaultNs, setUseDefaultNs] = useState(true);
  const [customNs, setCustomNs] = useState([
    { ns: "", ip: "" },
    { ns: "", ip: "" },
  ]);
  const [customNsErrMsg, setCustomNsErrMsg] = useState([
    { ns: "", ip: "" },
    { ns: "", ip: "" },
  ]);

  useEffect(() => {
    dnsRecords !== null && dnsRecords.nameservers !== null && populateFields();
    // eslint-disable-next-line
  }, [dnsRecords]);

  const onType = (i, field, value) => {
    setCustomNs((prev) => prev.map((row, j) => (j === i ? { ...row, [field]: value } : row)));
  };

  const addAdditionalNs = () => {
    setCustomNs((prev) => [...prev, { ns: "", ip: "" }]);
    setCustomNsErrMsg((prev) => [...prev, { ns: "", ip: "" }]);
  };

  const removeNs = (i) => {
    setCustomNs((prev) => prev.filter((prev, j) => j !== i));
    setCustomNsErrMsg((prev) => prev.filter((prev, j) => j !== i));
  };

  const populateFields = () => {
    const { useDefault, ns1, ip1, ns2, ip2, ns3, ip3, ns4, ip4, ns5, ip5, ns6, ip6 } = dnsRecords.nameservers;
    setUseDefaultNs(useDefault);
    if (!useDefault) {
      let arrNs = [];
      if (ns1 !== "") {
        arrNs = [...arrNs, { ns: ns1, ip: ip1 }];
      }
      if (ns2 !== "") {
        arrNs = [...arrNs, { ns: ns2, ip: ip2 }];
      }
      if (ns3 !== "") {
        arrNs = [...arrNs, { ns: ns3, ip: ip3 }];
      }
      if (ns4 !== "") {
        arrNs = [...arrNs, { ns: ns4, ip: ip4 }];
      }
      if (ns5 !== "") {
        arrNs = [...arrNs, { ns: ns5, ip: ip5 }];
      }
      if (ns6 !== "") {
        arrNs = [...arrNs, { ns: ns6, ip: ip6 }];
      }
      let errMsgs = arrNs.map((ns) => ({ ns: "", ip: "" }));
      setCustomNs(arrNs);
      setCustomNsErrMsg(errMsgs);
    }
  };

  const clickUpdate = () => {
    if (useDefaultNs || checkErrors() === 0) {
      let ns1 = DEFAULT_NS.ns1;
      let ns2 = DEFAULT_NS.ns2;
      let ns3 = "";
      let ns4 = "";
      let ns5 = "";
      let ns6 = "";
      let ip1 = "";
      let ip2 = "";
      let ip3 = "";
      let ip4 = "";
      let ip5 = "";
      let ip6 = "";
      if (!useDefaultNs) {
        ns1 = typeof customNs[0] !== "undefined" ? customNs[0].ns : "";
        ns2 = typeof customNs[1] !== "undefined" ? customNs[1].ns : "";
        ns3 = typeof customNs[2] !== "undefined" ? customNs[2].ns : "";
        ns4 = typeof customNs[3] !== "undefined" ? customNs[3].ns : "";
        ns5 = typeof customNs[4] !== "undefined" ? customNs[4].ns : "";
        ns6 = typeof customNs[5] !== "undefined" ? customNs[5].ns : "";

        ip1 = typeof customNs[0] !== "undefined" ? customNs[0].ip : "";
        ip2 = typeof customNs[1] !== "undefined" ? customNs[1].ip : "";
        ip3 = typeof customNs[2] !== "undefined" ? customNs[2].ip : "";
        ip4 = typeof customNs[3] !== "undefined" ? customNs[3].ip : "";
        ip5 = typeof customNs[4] !== "undefined" ? customNs[4].ip : "";
        ip6 = typeof customNs[5] !== "undefined" ? customNs[5].ip : "";
      }
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      updateNameservers(activeWorkspace._id, domainId, useDefaultNs, ns1, ns2, ns3, ns4, ns5, ns6, ip1, ip2, ip3, ip4, ip5, ip6);
      resetStateVars();
      navigate(`/dns/${domainName}`);
    }
  };

  const checkErrors = () => {
    let arrErrors = [];
    let errors = 0;
    customNs.forEach((ns, i) => {
      let errObj = { ns: "", ip: "" };
      if (ns.ns === "" || !isValidFqdn(ns.ns)) {
        errObj.ns = translate("cDnsNameservers.provideValidNs", false, null);
        errors++;
      }
      if (ns.ip !== "" && !isValidIPv4(ns.ip)) {
        errObj.ip = translate("cDnsNameservers.provideValidIp", false, null);
        errors++;
      }
      arrErrors = [...arrErrors, errObj];
    });
    setCustomNsErrMsg(arrErrors);
    return errors;
  };

  const resetStateVars = () => {
    setCustomNs([
      { ns: "", ip: "" },
      { ns: "", ip: "" },
    ]);
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setCustomNsErrMsg([
      { ns: "", ip: "" },
      { ns: "", ip: "" },
    ]);
  };

  return (
    <>
      <p className="mb-3">
        {translate("cDnsNameservers.selectNameserversFor", false, null)} <span className="text-italic">{domainName}</span>
      </p>

      <div className="row align-items-top">
        <div className="col-12 col-md-4 p-2">
          {/* Default nameservers */}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="radio_nameservers"
              id="radio_nameservers_default"
              checked={useDefaultNs}
              onChange={(e) => setUseDefaultNs(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="radio_nameservers_default">
              {translate("cDnsNameservers.defaultNs", false, null)}
            </label>
          </div>
          <div className="mt-1 px-4">
            <p className="m-0">{DEFAULT_NS.ns1}</p>
            <p className="m-0">{DEFAULT_NS.ns2}</p>
          </div>
        </div>
        <div className="col-12 col-md-8 p-2">
          {/* Custom nameserver - Checkbox */}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="radio_nameservers"
              id="radio_nameservers_custom"
              checked={!useDefaultNs}
              onChange={(e) => setUseDefaultNs(!e.target.checked)}
            />
            <label className="form-check-label" htmlFor="radio_nameservers_custom">
              {translate("cDnsNameservers.customNs", false, null)}
            </label>
          </div>
          {/* Custom nameserver - Form */}
          <div className="mt-1 px-4">
            {customNs.map((ns, i) => (
              <div key={`customNs_${i}`} className="row align-items-center mt-2">
                <div className="col-4 px-2">
                  <input
                    type="text"
                    className={`form-control form-control-sm${customNsErrMsg[i].ns === "" ? "" : " is-invalid"}`}
                    placeholder={`${translate("cDnsNameservers.nameserver", false, null)} ${i + 1}`}
                    value={ns.ns}
                    onChange={(e) => onType(i, "ns", e.target.value)}
                    disabled={useDefaultNs}
                  />
                  <div className="invalid-feedback">{customNsErrMsg[i].ns}</div>
                </div>
                {/* <div className="col-4 px-2">
                  <input
                    type="text"
                    className={`form-control form-control-sm${customNsErrMsg[i].ip === "" ? "" : " is-invalid"}`}
                    placeholder={translate("cDnsNameservers.ipAddress", false, null)}
                    value={ns.ip}
                    onChange={(e) => onType(i, "ip", e.target.value)}
                    disabled={useDefaultNs}
                  />
                  <div className="invalid-feedback">{customNsErrMsg[i].ip}</div>
                </div> */}
                {i > 1 && (
                  <div className="col-4">
                    <span
                      className="text-danger cursorPointer"
                      title={translate("cDnsNameservers.removeNs", false, null)}
                      onClick={() => removeNs(i)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="px-4 mx-n1">
            <button className="btn btn-sm btn-outline-success mt-2" onClick={addAdditionalNs} disabled={useDefaultNs || customNs.length > 5}>
              <i className="fa-solid fa-plus me-2"></i>
              {translate("cDnsNameservers.AddAddnlNs", false, null)}
            </button>
            <p className="mt-2 text-italic fontSize08">{translate("cDnsNameservers.externalNsNoDnsViaUs", false, null)}</p>
          </div>
        </div>
      </div>

      <div className="row mx-0">
        <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
          <button className="btn btn-sm btn-gray w-100" onClick={() => navigate(-1)}>
            {translate("cCreateDnsRecords.cancelUpdateRecord", false, null)}
          </button>
        </div>
        <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
          <button className="btn btn-sm btn-success w-100" onClick={clickUpdate}>
            {translate("cDnsNameservers.saveChanges", false, null)}
          </button>
        </div>
      </div>
    </>
  );
};

RecordNameservers.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object.isRequired,
  updateNameservers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateNameservers })(RecordNameservers);
