import React, { useEffect, useRef } from "react";

import { translate } from "../../translations/translations";

const BackToTop = () => {
  const btnBackToTop = useRef();

  useEffect(() => {
    const onScroll = (e) => {
      if (window.scrollY > 100) {
        btnBackToTop.current.classList.add("shown");
      }
      if (window.scrollY <= 100) {
        btnBackToTop.current.classList.remove("shown");
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const scrollToTop = function () {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div ref={btnBackToTop} onClick={scrollToTop} className="backToTop" title={translate("cNavigation.backToTop", false, null)}>
      <i className="fa-solid fa-chevron-up"></i>
    </div>
  );
};

export default BackToTop;
