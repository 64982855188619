import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CreateNewAudience from "../../../components/audiences/CreateNewAudience";
import ListWsAudiences from "../../../components/audiences/ListWsAudiences";

import { translate } from "../../../translations/translations";

const AudiencesMain = ({ auth: { activeWorkspace } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pAudienceMain.manageAudiences", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("pAudienceMain.noWsSelected", false, null)}</p>
      ) : (
        <>
          <CreateNewAudience />
          <ListWsAudiences />
        </>
      )}
    </>
  );
};

AudiencesMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AudiencesMain);
