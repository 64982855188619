import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

import { translate } from "../../../translations/translations";
import DashboardErrorItem from "../DashboardErrorItem";
import { formatDate } from "../../../lib/formattingFunctions";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// backgroundColor: ["rgba(229, 42, 5, 0.2)", "rgba(246, 129, 0, 0.2)", "rgba(13, 202, 240, 0.2)", "rgba(206, 212, 218, 0.2)"],
//         borderColor: ["rgba(229, 42, 5, 1)", "rgba(246, 129, 0, 1)", "rgba(13, 202, 240, 1)", "rgba(206, 212, 218, 1)"],

const Bandwidth = ({ auth: { activeWorkspace, user } }) => {
  const [bandwidthData, setBandwidthData] = useState({ bandwidthMb: [], dates: [] });

  useEffect(() => {
    getBandwidthData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getBandwidthData = () => {
    try {
      if (activeWorkspace !== null) {
        const bandwidthMb = activeWorkspace.hosting.quotaUsage.bandwidthMb;
        const today = new Date();
        const dates = bandwidthMb.map((x) => formatDate(new Date(today.setDate(today.getDate() - 1)), user.language, "dm")).reverse();
        setBandwidthData({ bandwidthMb, dates });
      }
    } catch (error) {
      setBandwidthData({ bandwidthMb: [], dates: [] });
    }
  };

  const data = {
    labels: bandwidthData.dates,
    datasets: [
      {
        label: translate("cUserDashboard.totalBandwidthUsed", false, null),
        data: bandwidthData.bandwidthMb,
        backgroundColor: "rgba(229, 42, 5, 0.2)",
        borderColor: "rgba(229, 42, 5, 1)",
        borderWidth: 1,
        // lineTension: 0.4, // smoothed line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     label: cbLabel,
      //     // label: (context) => {
      //     //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
      //     // },
      //     title: (context) => {
      //       return "";
      //     },
      //   },
      // },
    },
  };

  return bandwidthData.bandwidthMb.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noBandwidthDataFound", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

Bandwidth.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Bandwidth);
