export const cWsSubs = {
  // Metric names
  storage: {
    en: "Storage",
    nl: "Data",
  },
  mailboxes: {
    en: "Mailboxes",
    nl: "Mailboxes",
  },
  domains: {
    en: "(Sub)domains",
    nl: "(Sub)domeinen",
  },
  databases: {
    en: "Databases",
    nl: "Databases",
  },
  wpToolkit: {
    en: "WordPress Toolkit",
    nl: "WordPress Toolkit",
  },
  traffic: {
    en: "Monthly bandwidth",
    nl: "Maandelijkse bandbreedte",
  },
  sbPages: {
    en: "Pages",
    nl: "Paginas",
  },
  sbProjects: {
    en: "Projects",
    nl: "Projecten",
  },
  ebProjects: {
    en: "Projects",
    nl: "Projecten",
  },
  emMailsSent: {
    en: "Monthly emails",
    nl: "Maandelijkse emails",
  },
  emAbTesting: {
    en: "A/B tests",
    nl: "A/B tests",
  },
  // Metric values
  included: {
    en: "Included",
    nl: "Inbegrepen",
  },
  excluded: {
    en: "Excluded",
    nl: "Niet inbegrepen",
  },
  unlimited: {
    en: "Unlimited",
    nl: "Ongelimiteerd",
  },
  // Other
  subsOverview: {
    en: "Subscriptions overview",
    nl: "Abonnementen overzicht",
  },
  noActiveSubs: {
    en: "No active subscriptions found.",
    nl: "Geen actieve abonnementen gevonden.",
  },
  subscription: {
    en: "Subscription",
    nl: "Abonnement",
  },
  quotas: {
    en: "Quotas",
    nl: "Quotas",
  },
  billingDetails: {
    en: "Billing details",
    nl: "Facturering",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  noQuotas: {
    en: "No quotas",
    nl: "Geen quotas",
  },
  subPrepaid: {
    en: "Subscription prepaid",
    nl: "Abonnement vooruitbetaald",
  },
  invoicedMonthly: {
    en: "invoiced monthly",
    nl: "maandelijks gefactureerd",
  },
  subValidUntil: {
    en: "Subscription valid until",
    nl: "Abonnement geldig tot",
  },
  toBeCancelledMonth: {
    en: "Cancel at end of month",
    nl: "Annuleer op einde van de maand",
  },
  setSubToBeCancelledMonth: {
    en: "Order the subscription to be cancelled at the end of this month",
    nl: "Wijs abonnement aan om geannuleerd te worden aan het eind van deze maand",
  },
  toBeCancelled: {
    en: "To be cancelled",
    nl: "Wordt geannuleerd",
  },
  reverseCancellation: {
    en: "Reverse order to cancel",
    nl: "Draai annuleringsopdracht terug",
  },
  cancelAtEndPeriod: {
    en: "Cancel at end of period",
    nl: "Annuleer aan eind van het abonnement",
  },
  setSubToBeCancelledPeriod: {
    en: "Order the subscription to be cancelled at the end of its period",
    nl: "Wijs abonnement aan om geannuleerd te worden aan het eind van de geldigheid",
  },
  returnSub: {
    en: "Return",
    nl: "Retourneer",
  },
  returnSubTitle: {
    en: "Return the subscription and get a refund",
    nl: "Retourneer abonnement en krijg uw geld terug",
  },
  confirmReturnSub: {
    en: `Are you sure you want to return subscription "%subDesc%"? This cannot be reversed.`,
    nl: `Weet u zeker dat u het abonnement "%subDesc%" wil retourneren? Dit kan niet ongedaan gemaakt worden.`,
  },
  upgradeDowngradePrepay: {
    en: "Upgrade/downgrade# |/prepay #",
    nl: "Upgrade/downgrade# |/betaal vooruit#",
  },
};
