export const cEditDnsRecord = {
  dnsRecordNotFound: {
    en: "DNS record not found",
    nl: "DNS record niet gevonden",
  },
  editDnsTypeForDomain: {
    en: "Edit DNS %type% record for",
    nl: "Pas DNS %type% record aan voor",
  },
  editNameserversForDomain: {
    en: "Edit nameservers forr",
    nl: "Pas nameservers aan voor",
  },
  dnsRecordsFor: {
    en: "DNS records for",
    nl: "DNS records voor",
  },
};
