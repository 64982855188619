import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { translate } from "../../../translations/translations";

import { getSummaryLineDomainDnsRecords } from "./lib/dnsFunctions";

const ListDnsDomains = ({ auth: { activeWorkspace } }) => {
  // Domains data is in activeWorkspace.domains = [ { _id, domainName, managed, redirectMainHost, nameservers, dnsRecords }, ... ]
  // Each element in activeWorkspace.domains is a main domain name
  // Managed = true/false. True = for domains that are registered with / transferred to Satonda/RTR.
  //    Managed = false is for domains that are hosted on Plesk server and DNS records are shown as a help to user
  // DNS records can only be set for domains that are 1) managed and 2) have nameservers set to nameservers.useDefault = true
  // const managedDomains = activeWorkspace === null ? [] : activeWorkspace.domains.filter((d) => d.managed);
  // const externalDomains = activeWorkspace === null ? [] : activeWorkspace.domains.filter((d) => !d.managed);

  const [managedDomains, setManagedDomains] = useState([]);
  const [externalDomains, setExternalDomains] = useState([]);

  useEffect(() => {
    getDnsData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getDnsData = () => {
    try {
      setManagedDomains(activeWorkspace.domains.filter((d) => d.managed));
      setExternalDomains(activeWorkspace.domains.filter((d) => !d.managed));
    } catch (error) {
      setManagedDomains([]);
      setExternalDomains([]);
    }
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="bg-primary text-white border-none">
          <th scope="col">{translate("cListDnsDomains.domain", false, null)}</th>
          <th scope="col">{translate("cListDnsDomains.records", false, null)}</th>
          <th scope="col">{translate("cListDnsDomains.actions", false, null)}</th>
        </tr>
      </thead>
    );
  };

  const TableRow = ({ domain }) => {
    return (
      <tr>
        <td>
          <Link to={domain.domainName} className="cursorPointer text-dark">
            {domain.domainName}
          </Link>
        </td>
        <td>
          <Link to={domain.domainName} className="cursorPointer text-dark">
            {getSummaryLineDomainDnsRecords(domain)}
          </Link>
        </td>
        <td>
          <Link to={domain.domainName} className="cursorPointer text-success me-3">
            <i className="fa-solid fa-pencil" title={translate("cListDnsDomains.editDomainDnsRecords", false, null)}></i>
          </Link>
        </td>
      </tr>
    );
  };

  return activeWorkspace === null ? (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  ) : (
    <>
      {/* Managed domains */}
      <h2 className="text-secondary mt-5">{translate("cListDnsDomains.managedDomains", false, null)}</h2>
      {managedDomains.length === 0 ? (
        <p className="text-italic m-0">{translate("cListDnsDomains.noDomainsOnDnsYet", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <TableHead />
            <tbody>
              {managedDomains.map((domain) => (
                <TableRow key={domain._id} domain={domain} />
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* External domains */}
      <h2 className="text-secondary mt-5">{translate("cListDnsDomains.externalDomains", false, null)}</h2>
      <p className="fontSize08 text-italic mt-1 mb-3">{translate("cListDnsDomains.externalDomainsExplain", false, null)}</p>
      {externalDomains.length === 0 ? (
        <p className="text-italic m-0">{translate("cListDnsDomains.noExternalDomains", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <TableHead />
            <tbody>
              {externalDomains.map((domain) => (
                <TableRow key={domain._id} domain={domain} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

ListDnsDomains.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ListDnsDomains);
