import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import RecordNameservers from "../../../components/products/dns/dnsRecords/RecordNameservers";

import { translate } from "../../../translations/translations";

const EditNsRecord = ({ auth: { activeWorkspace } }) => {
  let { domainName } = useParams();

  const [nsData, setNsData] = useState(null);

  useEffect(() => {
    getNsData();
    // eslint-disable-next-line
  }, [activeWorkspace, domainName]);

  const getNsData = () => {
    try {
      setNsData(activeWorkspace.domains.filter((domain) => domain.domainName === domainName)[0]);
    } catch (error) {
      setNsData(null);
      console.error(error);
    }
  };

  return nsData === null ? (
    <p className="text-italic m-0">{translate("cEditDnsRecord.dnsRecordNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">
        {translate("cEditDnsRecord.editNameserversForDomain", false, null)} <span className="text-italic">{domainName}</span>
      </h1>
      <div className="mt-4">
        <RecordNameservers dnsRecords={nsData} />
      </div>
    </>
  );
};

EditNsRecord.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(EditNsRecord);
