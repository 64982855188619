export const removeMainDomainFromRecordHostname = (domainName, dnsRecord) => {
  // dnsRecord = { _id, recordFields: { hostname, pointsTo, TTL }, type }
  // recordFields field names depend on DNS record type

  // Take out the domainName from the recordFields.hostname
  const re = new RegExp(`[.]?${domainName}$`);
  let hostname = dnsRecord.recordFields.hostname.replace(re, "");
  if (hostname === "") {
    hostname = "@";
  }
  return { ...dnsRecord, recordFields: { ...dnsRecord.recordFields, hostname } };
};

export const getSummaryLineDomainDnsRecords = (domainObj) => {
  // Version 12/11/2023

  // domainObj = { domainName: "example.com", nameservers: { ns1, ..., ns6 }, dnsRecords: [ { type: "A", recordFields: [] }, ... ] }
  let ns = Object.keys(domainObj.nameservers).filter((key) => key.match(/ns\d/) !== null && domainObj.nameservers[key] !== "").length;
  let dnsRecordTypes = domainObj.dnsRecords.map((record) => record.type);
  let uniqueRecordTypes = [...new Set(dnsRecordTypes)].sort(sortAlpha);
  let summary = `${ns} NS`;
  uniqueRecordTypes.forEach((recordType) => {
    summary += ` / ${dnsRecordTypes.filter((type) => type === recordType).length} ${recordType}`;
  });
  return summary;
};

const sortAlpha = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const getDnsDomainIdFromDomainName = (wsDomains, domainName) => {
  // Version 12/11/2023
  return wsDomains.filter((wsDomain) => wsDomain.domainName === domainName)[0]._id;
};

export const takeoutTrailingDotInHostname = (str) => {
  // Version 12/11/2023
  return str.replace(/\.+?$/, "");
};

export const isValidFqdn = (str, options) => {
  // Version 12/11/2023
  assertString(str);
  options = merge(options, default_fqdn_options);

  /* Remove the optional trailing dot before checking validity */
  if (options.allow_trailing_dot && str[str.length - 1] === ".") {
    str = str.substring(0, str.length - 1);
  }

  /* Remove the optional wildcard before checking validity */
  if (options.allow_wildcard === true && str.indexOf("*.") === 0) {
    str = str.substring(2);
  }

  const parts = str.split(".");
  const tld = parts[parts.length - 1];

  if (options.require_tld) {
    // disallow fqdns without tld
    if (parts.length < 2) {
      return false;
    }

    if (!options.allow_numeric_tld && !/^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
      return false;
    }

    // disallow spaces
    if (/\s/.test(tld)) {
      return false;
    }
  }

  // reject numeric TLDs
  if (!options.allow_numeric_tld && /^\d+$/.test(tld)) {
    return false;
  }

  return parts.every((part) => {
    if (part.length > 63 && !options.ignore_max_length) {
      return false;
    }

    if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
      return false;
    }

    // disallow full-width chars
    if (/[\uff01-\uff5e]/.test(part)) {
      return false;
    }

    // disallow parts starting or ending with hyphen
    if (/^-|-$/.test(part)) {
      return false;
    }

    if (!options.allow_underscores && /_/.test(part)) {
      return false;
    }

    return true;
  });
};

export const hostnameTypeCombinationAlreadyExists = (hostnameToUse, recordType, dnsRecords) => {
  // Version 12/11/2023

  // dnsRecords = { _id, domainName, nameservers, records }
  return dnsRecords.records.filter((record) => record.type === recordType && record.recordFields.hostname === hostnameToUse).length > 0;
};

export const parseIntTtl = (ttl) => {
  // Version 12/11/2023
  return Math.min(3600, parseInt(ttl));
};

// =============
// == Helpers ==
// =============

const assertString = (input) => {
  const isString = typeof input === "string" || input instanceof String;

  if (!isString) {
    let invalidType = typeof input;
    if (input === null) invalidType = "null";
    else if (invalidType === "object") invalidType = input.constructor.name;

    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
};

const default_fqdn_options = {
  require_tld: true,
  allow_underscores: true,
  allow_trailing_dot: false,
  allow_numeric_tld: true,
  allow_wildcard: false,
  ignore_max_length: false,
};

const merge = (obj = {}, defaults) => {
  for (const key in defaults) {
    if (typeof obj[key] === "undefined") {
      obj[key] = defaults[key];
    }
  }
  return obj;
};
