import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import EbMenuEditName from "../../../../components/products/email-marketing/manage/EbMenuEditName";
import EbMenuDelete from "../../../../components/products/email-marketing/manage/EbMenuDelete";
import EbMenuDuplicateProject from "../../../../components/products/email-marketing/manage/EbMenuDuplicateProject";

import { translate } from "../../../../translations/translations";

const EmailbuilderProjectMenu = ({ auth: { user }, emailMarketing: { ebProjects } }) => {
  let { projectId } = useParams();

  const [ebProject, setEbProject] = useState(null);

  useEffect(() => {
    if (user !== null && projectId !== "") {
      setEbProject(ebProjects.filter((project) => project._id === projectId)[0] || null);
    } else {
      setEbProject(null);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  return ebProject === null ? (
    <p className="text-italic m-0">{translate("pEmEbProject.projectNotFound", false, null)}</p>
  ) : (
    <div className="row">
      <div className="col-12 col-lg-9 col-xl-6">
        <h1 className="text-primary mb-3">{ebProject.projectName}</h1>
        <EbMenuEditName />
        <EbMenuDuplicateProject />
        <EbMenuDelete />
      </div>
    </div>
  );
};

EmailbuilderProjectMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, null)(EmailbuilderProjectMenu);
