import store from "../../../store";
import { addToCart } from "../../../actions/cart";
import {
  PRODUCT_NAMES,
  PACKAGE_NAMES,
  PACKAGE_NAMES_HOSTING,
  PACKAGE_NAMES_SITEBUILDER,
  PACKAGE_NAMES_EMAIL_MARKETING,
  PERIOD_ANNUALLY,
  PERIOD_MONTHLY,
} from "./ProductVars";
import { ORIGIN_OVERDUE } from "../../workspace/billing/lib/invoiceFunctions";
import { subIsSuspendedOrCancelled } from "../../workspace/subscriptions/lib/subscriptionFunctions";
import { userIsWsAdmin } from "../../workspace/lib/wsFunctions";

export const formListPrice = (price, mode, deduct1Cent) => {
  // Modes:
  // 1 = Round to 1 euro
  // 2 = Round to 50 cents
  // 3 = Round to 10 cents
  // 4 = No rounding
  let listPrice;
  if (mode === 1) {
    listPrice = Math.ceil(price);
  }
  if (mode === 2) {
    let wholeDigit = Math.trunc(price);
    let digits = price - wholeDigit;
    digits < 0.5 ? (listPrice = wholeDigit + 0.5) : (listPrice = wholeDigit + 1);
  }
  if (mode === 3) {
    listPrice = Math.ceil(price * 10) / 10;
  }
  if (mode === 4) {
    listPrice = price;
  }
  if (deduct1Cent && listPrice > 0.01) {
    listPrice -= 0.01;
  }
  return listPrice;
};

export const getFxRate = (toCurrCode, fxRates) => {
  // Version: 19/10/2023

  // Usage:
  // If from EUR to other currency: priceNewCurr = priceEur * fxRate
  // If from other currency to EUR: priceEur = priceOtherCurr / fxRate

  // Don't use a try/catch and don't return standard values. Let it fail if fxRates isn't loaded from backend
  if (toCurrCode === "EUR") {
    return 1;
  }
  return fxRates.filter((fxRate) => fxRate.curr === toCurrCode)[0].fxRate;
};

export const clickAddToCart = (ws, user, sessionId, productPackage, selectedPeriod, payableAtCheckout, cartItemActionType) => {
  if (userIsWsAdmin(ws, user)) {
    // Beyond package information, key data points relate to price and timing

    // 1) "price" and "payableAtCheckout" variables:
    //                          Annual sub                                     Monthly sub
    // With trial period        price = payableAtCheckout                      price = trialPeriodPriceMonthly
    //                          payableAtCheckout = payableAtCheckout          payableAtCheckout = 0
    //                          renewalMonthlyPrice = null                     renewalMonthlyPrice = fullPriceMonthly
    // Without trial period     price = payableAtCheckout                      price = discountedPriceMonthly
    //                          payableAtCheckout = payableAtCheckout          payableAtCheckout = 0
    //                          renewalMonthlyPrice = null                     renewalMonthlyPrice = null
    //
    // price will be turned into monthlyPrice on the backend by dividing by the sub's number of months for annual subs and the monthly price as provided for monthly subs
    // payableAtCheckout is used for the current invoice for annual subs and is provided from the ProductTable component, calculated as:
    // Action                                   With trial period
    // New annual sub                           trialPeriodPriceAnnually
    // Upgrade existing annual sub              Existing sub monthly price based on trialPeriodPriceAnnually. New sub monthly price based on discountedPriceAnnually
    // Prepay existing monthly sub              Trial period monthly price * months remaining in trial + renewalMonthlyPrice * months remaining outside of trial
    // Upgrade + prepay existing monthly sub    New sub monthly price * months remaining
    //
    // Action                                   Without trial period
    // New annual sub                           discountedPriceAnnually
    // Upgrade existing annual sub              (new sub monthly price - existing sub monthly price) * months remaining
    // Prepay existing monthly sub              Existing sub monthly price * months remaining
    // Upgrade + prepay existing monthly sub    New sub monthly price * months remaining

    // 2) "renewDate", "renewalMonthlyPrice" and "nextRenewDate" variables:
    // Only relevant for monthly subs with a trial period:
    //    - renewDate = new Date() + x months
    //    - nextRenewDate = new Date() + 1 year
    //    - renewalMonthlyPrice = fullPriceMonthly
    // For all other subs:
    //    - renewDate = new Date() + 1 year
    //    - nextRenewDate = new Date() + 2 years
    //    - renewalMonthlyPrice = null

    // Simplify price data and add selectedPeriod, cartItemActionType, additionalData and renewal data required to process cart checkout on backend
    let {
      fullPriceAnnually,
      fullPriceMonthly,
      discountedPriceMonthly,
      discountedPriceAnnually,
      trialPeriodInMonths,
      trialPeriodPriceAnnually,
      trialPeriodPriceMonthly,
      additionalData,
      payUpfrontDuringTrialPeriod,
      ...rest
    } = productPackage;

    // console.log(`clickAddToCart // payableAtCheckout: ${payableAtCheckout}`);

    let price;
    let renewalMonthlyPrice = null;
    if (trialPeriodInMonths > 0) {
      if (selectedPeriod === PERIOD_ANNUALLY) {
        price = payableAtCheckout; // Don't divide by number of months in subscription here already; gets done when loaded into subscription object in DB
        if (!payUpfrontDuringTrialPeriod) {
          // If user doesn't have to pre-pay the non-trial period portion of the sub, then set a renewalMonthlyPrice
          renewalMonthlyPrice = discountedPriceAnnually / 12;
        }
      } else {
        price = trialPeriodPriceMonthly;
        renewalMonthlyPrice = fullPriceMonthly;
      }
    } else {
      if (selectedPeriod === PERIOD_ANNUALLY) {
        price = payableAtCheckout; // Don't divide by number of months in subscription here already; gets done when loaded into subscription object in DB
      } else {
        price = discountedPriceMonthly;
      }
    }
    // console.log(`clickAddToCart // price: ${price} // renewalMonthlyPrice: ${renewalMonthlyPrice}`);

    // For sub upgrades/prepayments, renewDate/nextRenewDate don't matter as they will be taken from the sub to be updated in backend
    // For new subs, they do need to be set
    const year = new Date().getFullYear();
    let renewDate = new Date(new Date().setFullYear(year + 1));
    let nextRenewDate = new Date(new Date().setFullYear(year + 2));
    if (trialPeriodInMonths > 0 && (selectedPeriod === PERIOD_MONTHLY || (selectedPeriod === PERIOD_ANNUALLY && !payUpfrontDuringTrialPeriod))) {
      // If a monthly sub with a trial period or an annual sub with a trial period for which user doesn't need to pre-pay non-trial portion
      // reflect that sub needs to be renewed after the trial period ends at normal prices for a 1 year period
      let month = new Date().getMonth();
      renewDate = new Date(new Date().setMonth(month + trialPeriodInMonths));
      nextRenewDate = new Date(new Date().setMonth(month + trialPeriodInMonths + 12)); // 1 year after the renewDate
    }
    // console.log(`clickAddToCart // renewDate: ${renewDate} // nextRenewDate: ${nextRenewDate}`);

    productPackage = {
      ...rest,
      selectedPeriod,
      price,
      payableAtCheckout,
      cartItemActionType,
      additionalData: { ...additionalData, ...productPackage },
      renewDate: renewDate.toISOString(),
      nextRenewDate: nextRenewDate.toISOString(),
      renewalMonthlyPrice,
    };
    // console.log("final productPackage:", productPackage);
    store.dispatch(addToCart(sessionId, productPackage));
  }
};

export const getTotalAmountAtCheckoutExcVat = (origin, items) => {
  if (origin === ORIGIN_OVERDUE) {
    return items.map((item) => item.invoiceAmount).reduce((a, b) => a + b, 0);
  } else {
    return items
      .filter((item) => item.selectedPeriod === PERIOD_ANNUALLY)
      .map((item) => item.payableAtCheckout)
      .reduce((a, b) => a + b, 0);
  }
};

export const getTotalAmountAtCheckoutIncVat = (origin, items, vatRate) => {
  return getTotalAmountAtCheckoutExcVat(origin, items) * (1 + parseFloat(vatRate));
};

export const getProductPackageNames = (productName) => {
  if (productName === PRODUCT_NAMES.domains) {
    // TODO: Package name for domain reg should be the domain name (xyz.tld)
    return "Domain";
  }
  if (productName === PRODUCT_NAMES.hosting) {
    return PACKAGE_NAMES_HOSTING;
  }
  if (productName === PRODUCT_NAMES.sitebuilder) {
    return PACKAGE_NAMES_SITEBUILDER;
  }
  if (productName === PRODUCT_NAMES.emailMarketing) {
    return PACKAGE_NAMES_EMAIL_MARKETING;
  }
  return PACKAGE_NAMES;
};

export const getPackageName = (productName, i) => {
  if (productName === PRODUCT_NAMES.domains) {
    // TODO: Package name for a domain name should be its TLD
    return "Domain";
  }
  if (productName === PRODUCT_NAMES.hosting) {
    return PACKAGE_NAMES_HOSTING[i];
  }
  if (productName === PRODUCT_NAMES.sitebuilder) {
    return PACKAGE_NAMES_SITEBUILDER[i];
  }
  if (productName === PRODUCT_NAMES.emailMarketing) {
    return PACKAGE_NAMES_EMAIL_MARKETING[i];
  }
  return PACKAGE_NAMES[i];
};

export const getPayableAtCheckout = (productPackage, selectedPeriod, activeProductSub = null) => {
  // payableAtCheckout equals:
  // Action                                   With trial period
  // New annual sub                           trialPeriodPriceAnnually
  // Upgrade existing annual sub              Existing sub monthly price based on trialPeriodPriceAnnually. New sub monthly price based on discountedPriceAnnually
  // Prepay existing monthly sub              Trial period monthly price * months remaining in trial + renewalMonthlyPrice * months remaining outside of trial
  // Upgrade + prepay existing monthly sub    New sub monthly price * months remaining
  //
  // Action                                   Without trial period
  // New annual sub                           discountedPriceAnnually
  // Upgrade existing annual sub              (new sub monthly price - existing sub monthly price) * months remaining
  // Prepay existing monthly sub              (discountedPriceAnnually / 12) * months remaining
  // Upgrade + prepay existing monthly sub    (discountedPriceAnnually / 12) * months remaining

  const { fullPriceAnnually, discountedPriceAnnually, trialPeriodInMonths, trialPeriodPriceAnnually, payUpfrontDuringTrialPeriod } = productPackage;
  // console.log("=========================");
  // console.log(`getPayableAtCheckout // selectedPeriod: ${selectedPeriod}`);
  // console.log(
  //   `getPayableAtCheckout // fullPriceAnnually: ${fullPriceAnnually} // discountedPriceAnnually: ${discountedPriceAnnually} // trialPeriodInMonths: ${trialPeriodInMonths} // trialPeriodPriceAnnually: ${trialPeriodPriceAnnually}`
  // );
  // console.log("getPayableAtCheckout // activeProductSub", activeProductSub);

  if (selectedPeriod === PERIOD_MONTHLY) {
    // If selectedPeriod is monthly, payableAtCheckout is always 0
    return 0;
  }

  // Case: New annual sub
  if (activeProductSub === null) {
    if (trialPeriodInMonths > 0) {
      if (payUpfrontDuringTrialPeriod) {
        // Case: annual sub with trial period having to pre-pay the non-trial period amount => payableAtCheckout = # months trial period * trial period price/month + (12-trial period) * normal price/month
        return Math.max(0, trialPeriodInMonths * (trialPeriodPriceAnnually / 12) + (12 - trialPeriodInMonths) * (fullPriceAnnually / 12));
      } else {
        // Case annual sub with trial period, but not having to pre-pay the non-trial period amount of the sub
        return Math.max(0.01, trialPeriodPriceAnnually / 12);
      }
    } else {
      // payableAtCheckout = discountedPriceAnnually
      return discountedPriceAnnually;
    }
  }

  const { prepaid, renewalMonthlyPrice, renewDate, nextRenewDate, monthlyPrice, metadata } = activeProductSub;
  // console.log(
  //   `prepaid: ${prepaid} // renewalMonthlyPrice: ${renewalMonthlyPrice} // renewDate: ${renewDate} // nextRenewDate: ${nextRenewDate} // monthlyPrice: ${monthlyPrice}`
  // );
  // Get months remaining in the active sub
  const YEAR_MS = 365 * 24 * 60 * 60 * 1000;
  let existingSubIsAnnual = prepaid;
  let existingSubIsInTrialPeriod = renewalMonthlyPrice !== null && typeof renewalMonthlyPrice !== "undefined" && !isNaN(renewalMonthlyPrice);
  // console.log(`existingSubIsAnnual: ${existingSubIsAnnual} // existingSubIsInTrialPeriod: ${existingSubIsInTrialPeriod} `);
  let today = new Date(Date.now());
  let monthsRemainingInTrial = existingSubIsInTrialPeriod ? ((Date.parse(renewDate) - today) / YEAR_MS) * 12 : 0;
  let monthsRemainingInSub = existingSubIsInTrialPeriod
    ? ((Date.parse(nextRenewDate) - Date.parse(renewDate)) / YEAR_MS) * 12
    : ((Date.parse(renewDate) - today) / YEAR_MS) * 12;
  monthsRemainingInTrial = parseFloat(monthsRemainingInTrial.toFixed(2));
  monthsRemainingInSub = parseFloat(monthsRemainingInSub.toFixed(2));
  // console.log(`monthsRemainingInTrial: ${monthsRemainingInTrial} // monthsRemainingInSub: ${monthsRemainingInSub} `);

  // Case: Upgrade an existing annual sub
  if (existingSubIsAnnual) {
    // Check whether existing annual sub is in its trial period
    if (
      metadata !== null &&
      typeof metadata !== "undefined" &&
      metadata.annualSubTrialData !== null &&
      typeof metadata.annualSubTrialData !== "undefined"
    ) {
      const { trialPeriodEndDate, normalPeriodMonthlyPrice } = metadata.annualSubTrialData;
      const trialPeriodMonthsRemaining = parseFloat((((Date.parse(trialPeriodEndDate) - today) / YEAR_MS) * 12).toFixed(2));
      if (trialPeriodMonthsRemaining > 0) {
        // If it is still in trial period, recalculate price
        // payableAtCheckout = (new sub monthly price - old sub non-trial price) * months remaining outside of trial period
        // console.log(
        //   `Price (upgrade, annual sub in trial period):`,
        //   Math.max(0, (discountedPriceAnnually / 12 - normalPeriodMonthlyPrice) * (monthsRemainingInSub - trialPeriodMonthsRemaining))
        // );
        return Math.max(0, (discountedPriceAnnually / 12 - normalPeriodMonthlyPrice) * (monthsRemainingInSub - trialPeriodMonthsRemaining));
      }
      // If annual sub is not in trial period
      // payableAtCheckout = (new sub monthly price - old sub monthly price) * months remaining
      // console.log(`Price (upgrade):`, Math.max(0, (discountedPriceAnnually / 12 - monthlyPrice) * monthsRemainingInSub));
      return Math.max(0, (discountedPriceAnnually / 12 - monthlyPrice) * monthsRemainingInSub);
    }
  }
  // Case: Prepay an existing monthly sub
  if (activeProductSub.packageName === productPackage.packageName && existingSubIsInTrialPeriod && monthsRemainingInTrial > 0) {
    // payableAtCheckout = trial period monthly price * remaining trial period + renewal monthly price * remaining sub period
    // console.log(`Price (prepay):`, Math.max(0, monthlyPrice * monthsRemainingInTrial + renewalMonthlyPrice * monthsRemainingInSub));
    return Math.max(0, monthlyPrice * monthsRemainingInTrial + renewalMonthlyPrice * monthsRemainingInSub);
  }
  // Case Prepay or prepay + down/upgrade an existing monthly sub
  // payableAtCheckout = new sub monthly price * months remaining
  // console.log(`Price (other):`, Math.max(0, (discountedPriceAnnually / 12) * monthsRemainingInSub));
  return Math.max(0, (discountedPriceAnnually / 12) * monthsRemainingInSub);
};

export const workspaceHasProduct = (workspaceSubs, productName) => {
  try {
    return workspaceSubs.filter((sub) => !subIsSuspendedOrCancelled(sub) && sub.productName === productName).length > 0;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getWorkspaceProductPackageDetails = (workspaceSubs, productName) => {
  try {
    let product = workspaceSubs.filter((sub) => !subIsSuspendedOrCancelled(sub) && sub.productName === productName)[0];
    return typeof product === "undefined" ? null : product.packageDetails;
  } catch (error) {
    return null;
  }
};
