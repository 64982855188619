import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../../translations/translations";
import { getDataMsgStats } from "../../../pages/products/email-marketing/manage/lib";
import DashboardErrorItem from "../DashboardErrorItem";
import MsgStats from "../../products/email-marketing/manage/StatsCharts/MsgStats";

const EmEmailsSummary = ({ auth: { activeWorkspace }, emailMarketing: { executedEmailCampaigns } }) => {
  const [emailData, setEmailData] = useState([]);

  useEffect(() => {
    getEmailStats();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getEmailStats = () => {
    try {
      const proactiveCampaigns = executedEmailCampaigns.filter((campaign) => campaign.campaignType === "proactive");
      let consolidatedRecipients = [];
      proactiveCampaigns.forEach((campaign) => {
        consolidatedRecipients = [...consolidatedRecipients, ...campaign.recipients];
      });
      setEmailData(getDataMsgStats(consolidatedRecipients));
    } catch (error) {
      setEmailData([]);
    }
  };

  return emailData.length === 0 || emailData.reduce((val, add) => val + add, 0) === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noEmStatsFound", false, null)} />
  ) : (
    <MsgStats heading={translate("pEmCampaignStats.deliveryInteraction", false, null)} arrData={emailData} height={"300px"} />
  );
};

EmEmailsSummary.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, null)(EmEmailsSummary);
