import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import StoredPaymentMethod from "../../components/user/profile/StoredPaymentMethod";
import UpdatePassword from "../../components/user/profile/UpdatePassword";
import UpdateContactDetails from "../../components/user/profile/UpdateContactDetails";
import UpdateEmailPreferences from "../../components/user/profile/UpdateEmailPreferences";
import CancelAccount from "../../components/user/profile/CancelAccount";

import { translate } from "../../translations/translations";

const Profile = ({ auth: { user } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pUserPages.profile", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-6">
            <StoredPaymentMethod />
            <UpdatePassword />
            <UpdateContactDetails />
            <UpdateEmailPreferences />
            <CancelAccount />
          </div>
        </div>
      )}
    </>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Profile);
