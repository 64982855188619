export const pFeedback = {
  loveToHear: {
    en: "We would love to hear from you!",
    nl: "We krijgen graag feedback!",
  },
  whatDoingWell: {
    en: "What are we doing well? What should we improve? Would you recommend us to your friends and colleagues? We would love to hear your feedback, so we can continue to improve our services!",
    nl: "Wat doen we goed? Waar moeten we verbeteren? Zou je ons aanraden aan je kennissen en collegas? We krijgen graag jouw feedback, zodat we ons aanbod verder kunnen verbeteren!",
  },
  title: {
    en: "Title",
    nl: "Titel",
  },
  feedbackTitle: {
    en: "Feedback title",
    nl: "Feedback titel",
  },
  feedbackText: {
    en: "Feedback text",
    nl: "Feedback tekst",
  },
  rating: {
    en: "Rating",
    nl: "Beoordeling",
  },
  submitFeedback: {
    en: "Submit feedback",
    nl: "Verzend feedback",
  },
  prevFeedback: {
    en: "Your previously submitted feedback",
    nl: "Eerder gegeven feedback",
  },
  noPrevFeedback: {
    en: "You have not provided feedback yet.",
    nl: "Je hebt nog geen eerdere feedback gegeven.",
  },
  retrievingFeedback: {
    en: "Retrieving previously submitted feedback..",
    nl: "Ophalen van eerder gegeven feedback..",
  },
  submittingFeedback: {
    en: "Submitting feedback..",
    nl: "Verzenden van feedback..",
  },
  feedbackSubmitted: {
    en: "Feedback submitted! Thank you!",
    nl: "Feedback is verzonden! Dank je!",
  },
  provideTitle: {
    en: "Please provide a title",
    nl: "Voeg aub een titel toe",
  },
  provideText: {
    en: "Please provide the feedback you'd like to leave",
    nl: "Voeg aub de feedback die je wil geven toe",
  },
  provideRating: {
    en: "Please provide a rating",
    nl: "Voeg aub een beoordeling toe",
  },
};
