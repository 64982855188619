import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Spinner from "../../components/layout/Spinner";

import { setAlert } from "../../actions/alert";
import { addNewContactformToWorkspace } from "../../actions/auth";

import FormatDate from "../../components/layout/FormatDate";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";
import { validateEmail, makeAndDownloadFile } from "../../lib/GeneralFunctions";

import { translate } from "../../translations/translations";

const Messages = ({ auth: { user, activeWorkspace }, setAlert, addNewContactformToWorkspace }) => {
  const PER_PAGE = 10;

  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [workspaceMessages, setWorkspaceMessages] = useState([]);
  const [formViewing, setFormViewing] = useState(null);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [msgsToRender, setMsgsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  useEffect(() => {
    activeWorkspace !== null && loadMessages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeWorkspace !== null && formViewing !== null && workspaceMessages.length > 0) {
      setMaxPages(Math.ceil(workspaceMessages.filter((msg) => msg.formId === formViewing)[0].msgs.length / PER_PAGE));
      setMsgsToRender(
        workspaceMessages.filter((msg) => msg.formId === formViewing)[0].msgs.slice(currPage * PER_PAGE, currPage * PER_PAGE + PER_PAGE)
      );
    }
    // eslint-disable-next-line
  }, [workspaceMessages, currPage, formViewing]);

  const loadMessages = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pContactFormMessages.retrievingYourMsgs", false, null) });
    try {
      const res = await axios.get(`${API_URL}/contactForm/getWorkspaceMessages`);
      setFormViewing(activeWorkspace.contactForms[0].formId);
      setWorkspaceMessages(
        activeWorkspace.contactForms.map((cf) => ({ formId: cf.formId, msgs: res.data.sort(sortByDate).filter((msg) => msg.formId === cf.formId) }))
      );
    } catch (error) {
      console.error(error);
      setWorkspaceMessages([]);
      setAlert(translate("pContactFormMessages.serverErrorRetrievingMsgs", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const sortByDate = (a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  };

  const clickMsgReadStatus = async (msg) => {
    try {
      // Update read status in local state
      setWorkspaceMessages((prev) =>
        prev.map((wsForm) =>
          wsForm.formId === formViewing
            ? { ...wsForm, msgs: wsForm.msgs.map((prevMsg) => (prevMsg._id === msg._id ? { ...prevMsg, read: !msg.read } : prevMsg)) }
            : wsForm
        )
      );
      // Update read status on backend
      const body = JSON.stringify({ boolRead: !msg.read });
      await axios.put(`${API_URL}/contactForm/read/${msg._id}`, body, POST_CONFIG);
    } catch (error) {
      console.error(error);
    }
  };

  const clickSelectMsg = (msgId) => {
    if (selectedMessages.includes(msgId)) {
      setSelectedMessages((prev) => prev.filter((prevMsgId) => prevMsgId !== msgId));
    } else {
      setSelectedMessages((prev) => [...prev, msgId]);
    }
  };

  const clickDeleteMsg = async (msg) => {
    try {
      // Change page if no msgs are left after deletion
      if (msgsToRender.length === 1) {
        setCurrPage((prev) => prev - 1);
      }
      // Remove from local state
      setWorkspaceMessages((prev) =>
        prev.map((wsForm) => (wsForm.formId === formViewing ? { ...wsForm, msgs: wsForm.msgs.filter((prevMsg) => prevMsg._id !== msg._id) } : wsForm))
      );
      // Remove from backend
      const body = JSON.stringify({ messages: [msg._id] });
      await axios.post(`${API_URL}/contactForm/delete`, body, POST_CONFIG);
    } catch (error) {
      console.error(error);
    }
  };

  const clickSelectAll = () => {
    setSelectedMessages(msgsToRender.map((msg) => msg._id));
  };

  const clickDeselectAll = () => {
    setSelectedMessages([]);
  };

  const exportEmailAddresses = () => {
    // Export as csv: https://stackoverflow.com/a/14966131
    let exportData = [["Name", "Email address"]];
    exportData = [
      ...exportData,
      ...workspaceMessages
        .filter((msg) => msg.formId === formViewing)[0]
        .msgs.map((msg) => [getNameFromMsgData(msg.data), getEmailFromMsgData(msg.data)]),
    ]
      .map((row) => row.join(","))
      .join("\n");
    makeAndDownloadFile(
      `export_${activeWorkspace.contactForms
        .filter((cf) => cf.formId === formViewing)[0]
        .formName.replace(/\s/g, "_")
        .toLowerCase()}`,
      exportData,
      "data:text/csv",
      "csv"
    );
  };

  const clickDeleteSelected = async () => {
    try {
      // Change page if no msgs are left after deletion
      if (msgsToRender.length === selectedMessages.length) {
        setCurrPage((prev) => prev - 1);
      }
      // Remove from local state
      setWorkspaceMessages((prev) =>
        prev.map((wsForm) =>
          wsForm.formId === formViewing
            ? { ...wsForm, msgs: wsForm.msgs.filter((prevMsg) => !selectedMessages.some((selMsg) => selMsg === prevMsg._id)) }
            : wsForm
        )
      );
      // Remove from backend
      const body = JSON.stringify({ messages: selectedMessages });
      await axios.post(`${API_URL}/contactForm/delete`, body, POST_CONFIG);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmailFromMsgData = (msgData) => {
    let emailAddress = "";
    Object.keys(msgData).forEach((data) => validateEmail(msgData[data]) && (emailAddress = msgData[data]));
    return emailAddress;
  };

  const getNameFromMsgData = (msgData) => {
    return typeof msgData.name === "undefined" ? "" : msgData.name;
  };

  const clickNavBtn = (formId) => {
    setFormViewing(formId);
    setCurrPage(0);
    setSelectedMessages([]);
  };

  const clickPagBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
    setSelectedMessages([]);
  };

  const ContactFormMenu = () => {
    return (
      <div className="d-flex justify-content-end mb-3">
        <button className="btn btn-sm btn-outline-success me-3" onClick={exportEmailAddresses}>
          <i className="fa-solid fa-download" />
          <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.exportAll", false, null)}</span>
        </button>
        <button className="btn btn-sm btn-outline-dark me-3" onClick={clickSelectAll}>
          <i className="fa-regular fa-square-check" />
          <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.selectAll", false, null)}</span>
        </button>
        <button className="btn btn-sm btn-outline-dark me-3" onClick={clickDeselectAll}>
          <i className="fa-regular fa-square" />
          <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.deselectAll", false, null)}</span>
        </button>
        <button className="btn btn-sm btn-outline-danger" onClick={clickDeleteSelected}>
          <i className="fa-regular fa-trash-can" />
          <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.deleteSelected", false, null)}</span>
        </button>
      </div>
    );
  };

  const ContactFormButton = ({ contactForm }) => {
    return (
      <button
        className={`nav-link${contactForm.formId === formViewing ? " active" : ""}`}
        id={`contactForm_${contactForm.formId}_tab`}
        data-bs-toggle="pill"
        data-bs-target={`#contactForm_${contactForm.formId}_content`}
        type="button"
        role="tab"
        aria-controls={`contactForm_${contactForm.formId}_content`}
        aria-selected={contactForm.formId === formViewing ? "true" : "false"}
        onClick={() => clickNavBtn(contactForm.formId)}
      >
        {contactForm.formName}
      </button>
    );
  };

  const ContactFormContent = () => {
    let cf = activeWorkspace.contactForms.filter((cf) => cf.formId === formViewing)[0];
    return (
      <div>
        {msgsToRender.length === 0 ? (
          <h4 className="text-primary mb-3">
            {translate("pContactFormMessages.noMsgsYetForCf", false, null)} {cf.formName}
          </h4>
        ) : (
          <>
            <h4 className="text-primary mb-3">
              {translate("pContactFormMessages.msgsForCf", false, null)} <span className="text-italic">{cf.formName}</span>{" "}
              {translate("pContactFormMessages.from", false, null)}{" "}
              <span className="text-italic">{msgsToRender[0].siteOrigin.replace(/https?:\/\//, "")}</span>
            </h4>
            {msgsToRender.map((msg, j) => (
              <ContactFormMessage key={`msg_${msg._id}`} msg={msg} j={j} />
            ))}
          </>
        )}
      </div>
    );
  };

  const ContactFormMessage = ({ msg, j }) => {
    let isSelected = selectedMessages.includes(msg._id);
    return (
      <div
        className={`d-flex align-items-stretch contactFormMessageWrapper trans-3 p-0 ${j === 0 ? "border-top " : ""}border-bottom border-midgray ${
          isSelected ? "bg-light " : ""
        }bgHover-light cursorPointer`}
        title={isSelected ? translate("pContactFormMessages.deselectMsg", false, null) : translate("pContactFormMessages.selectMsg", false, null)}
        onClick={() => clickSelectMsg(msg._id)}
      >
        {/* Unread bar */}
        <div
          className={`${msg.read ? "bgHover-primary" : "bg-primary"} trans-3 cursorPointer`}
          style={{ width: "10px" }}
          title={msg.read ? translate("pContactFormMessages.markAsUnread", false, null) : translate("pContactFormMessages.markAsRead", false, null)}
          onClick={() => clickMsgReadStatus(msg)}
        ></div>
        {/* Content */}
        <div className="toGrow fontSize08 px-2 py-2">
          <p className="mb-0">
            <span className="text-bold">{translate("pContactFormMessages.date", false, null)}: </span>
            <FormatDate dateString={msg.createdAt} lang={user.language} formatStr={"dmmyy"} />
          </p>
          {Object.keys(msg.data).map((msgData, k) => (
            <p className="mt-1 mb-0" key={`msg_${j}_${k}`}>
              <span className="text-bold">{capitalizeFirstLetter(msgData)}: </span>
              {msg.data[msgData]}
            </p>
          ))}
        </div>
        {/* Respond / delete */}
        <div className="w-25 d-flex align-items-center justify-content-end">
          <a
            className="btn btn-sm btn-outline-dark me-3"
            href={`mailto:${getEmailFromMsgData(msg.data)}?subject=${`${translate("pContactFormMessages.yourMsgOnSite", false, null)} `.replaceAll(
              " ",
              "%20"
            )}${msg.siteOrigin}`}
          >
            <i className="fa-solid fa-reply" />
            <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.reply", false, null)}</span>
          </a>
          <button className="btn btn-sm btn-outline-danger me-3" onClick={() => clickDeleteMsg(msg)}>
            <i className="fa-regular fa-trash-can" />
            <span className="d-none d-lg-inline ms-2">{translate("pContactFormMessages.delete", false, null)}</span>
          </button>
        </div>
      </div>
    );
  };

  const ContactFormPagination = () => {
    return (
      msgsToRender.length > 0 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3 me-4"
            onClick={() => clickPagBtn(-1)}
            title={
              currPage === 0 ? translate("pContactFormMessages.latestMsgs", false, null) : translate("pContactFormMessages.newerMsg", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("pContactFormMessages.showingMsgs", false, null)}{" "}
            <span className="text-bold">
              {currPage * PER_PAGE + 1} -{" "}
              {Math.min(
                (currPage + 1) * PER_PAGE,
                workspaceMessages.length === 0 ? 0 : workspaceMessages.filter((msg) => msg.formId === formViewing)[0].msgs.length
              )}
            </span>{" "}
            {translate("pContactFormMessages.showingMsgsOf", false, null)}{" "}
            <span className="text-bold">
              {workspaceMessages.length === 0 ? 0 : workspaceMessages.filter((msg) => msg.formId === formViewing)[0].msgs.length}
            </span>
          </span>
          <button
            className="btn btn-outline-primary trans-3 ms-4"
            onClick={() => clickPagBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("pContactFormMessages.oldestMsgs", false, null)
                : translate("pContactFormMessages.olderMsgs", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      )
    );
  };

  const CreateNewContactform = () => {
    const [contactFormName, setContactFormName] = useState("");

    const onType = (val) => {
      setContactFormName(val.replace(/[^A-Za-z0-9_-\s]/g, ""));
    };

    const clickCreateNewContactform = async () => {
      setLocalLoading({ isLoading: true, msg: translate("pContactFormMessages.creatingNewCf", false, null) });
      try {
        const body = JSON.stringify({
          formId: uuidv4(),
          formName: capitalizeFirstLetter(contactFormName.replace(/^\s+/, "").replace(/\s+$/, "").replace(/\s+/g, " ")),
        });
        const res = await axios.post(`${API_URL}/contactForm/createNew`, body, POST_CONFIG);
        addNewContactformToWorkspace(activeWorkspace._id, res.data.wsForms);
        setWorkspaceMessages(
          res.data.wsForms.map((cf) => ({ formId: cf.formId, msgs: res.data.wsMsgs.sort(sortByDate).filter((msg) => msg.formId === cf.formId) }))
        );
      } catch (error) {
        console.error(error);
        setWorkspaceMessages([]);
        setAlert(translate("pContactFormMessages.serverErrorCreatingCf", false, null), "danger");
      }
      setLocalLoading({ isLoading: false, msg: "" });
    };

    return (
      <div className="row mt-5">
        <div className="col-12 col-lg-9 col-xl-6">
          <h2 className="text-secondary mb-3">{translate("pContactFormMessages.createNewCf", false, null)}</h2>
          <div className="input-group">
            <input
              type="text"
              className={`form-control`}
              placeholder={translate("pContactFormMessages.cfName", false, null)}
              value={contactFormName}
              onChange={(e) => onType(e.target.value)}
            />
            <button className="btn btn-outline-primary" disabled={contactFormName === ""} onClick={clickCreateNewContactform}>
              {translate("pContactFormMessages.create", false, null)}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <h1 className="text-primary">{translate("pContactFormMessages.yourContactforms", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          {localLoading.isLoading ? (
            <div className="mt-5">
              <Spinner msg={localLoading.msg} />
            </div>
          ) : (
            <>
              <CreateNewContactform />
              <h2 className="text-secondary mt-5 mb-3">{translate("pContactFormMessages.yourMessages", false, null)}</h2>
              {activeWorkspace.contactForms.length === 0 ? (
                <p className="text-italic m-0">{translate("pContactFormMessages.noFormsYet", false, null)}</p>
              ) : workspaceMessages.length === 0 ? (
                <p className="text-italic m-0">{translate("pContactFormMessages.noMsgsYet", false, null)}</p>
              ) : (
                <div className="">
                  <div className="d-flex align-items-start">
                    <div className="nav flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      {activeWorkspace.contactForms.map((cf, i) => (
                        <ContactFormButton key={`cf_btn_${cf.formId}`} contactForm={cf} i={i} />
                      ))}
                    </div>
                    <div className="toGrow">
                      <ContactFormMenu />
                      <ContactFormContent />
                      <ContactFormPagination />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Messages.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  addNewContactformToWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, addNewContactformToWorkspace })(Messages);
