export const pResetPleskPw = {
  resetPleskPw: {
    en: "Reset password of your Plesk admin user",
    nl: "Plesk admin account wachtwoord herstellen",
  },
  yourAdminUsername: {
    en: "Your admin username is",
    nl: "Uw admin gebruikersnaam is",
  },
  newPw: {
    en: "New admin password",
    nl: "Nieuw admin wachtwoord",
  },
  confirmNewPw: {
    en: "Confirm new admin password",
    nl: "Bevestig nieuw admin wachtwoord",
  },
  password: {
    en: "password",
    nl: "wachtwoord",
  },
  generateNewPw: {
    en: "Generate random password",
    nl: "Genereer willekeurig wachtwoord",
  },
  pwRequirements: {
    en: "Passwords should be at least 8 characters, containing at least 1 capital letter, lower case letter, 1 digit and 1 special character (!@#%^*-_)",
    nl: "Wachtwoord moet minimaal 8 karakters lang zijn en 1 hoofdletter, 1 kleine letter, 1 getal en 1 speciaal karakter (!@#%^*-_) bevatten",
  },
  changePw: {
    en: "Change password",
    nl: "Verander wachtwoord",
  },
};
