import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { API_URL } from "../../lib/GeneralVars";
import { translate } from "../../translations/translations";

import Spinner from "../../components/layout/Spinner";

const ConfirmEmail = () => {
  let { token } = useParams();
  const navigate = useNavigate();

  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [errMsg_server, setErrMsg_server] = useState("");

  useEffect(() => {
    checkValidLink();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (emailConfirmed) {
      redirectToLogin();
    }
    // eslint-disable-next-line
  }, [emailConfirmed]);

  const checkValidLink = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pConfirmEmail.confirmingEmail", false, null) });
    try {
      //   let decoded = window.atob(token);
      const res = await axios.get(`${API_URL}/users/confirmEmail/${token}`);
      if (res.data.success) {
        setEmailConfirmed(true);
      }
    } catch (error) {
      console.error(error);
      setErrMsg_server(translate("pConfirmEmail.errorOccured", false, null));
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const redirectToLogin = async () => {
    await new Promise((r) => setTimeout(r, 1000));
    navigate(`/`, { replace: true });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-10 col-lg-8">
          <h1 className="text-primary mb-3">{translate("pConfirmEmail.confirmEmail", false, null)}</h1>
          {errMsg_server !== "" && <div className="my-4 alert alert-danger">{errMsg_server}</div>}
          {localLoading.isLoading ? (
            <Spinner msg={localLoading.msg} />
          ) : emailConfirmed ? (
            <>
              <p className="mt-5 mb-0">{translate("pConfirmEmail.emailConfirmed", false, null)}</p>
              <p className="mt-2">{translate("pConfirmEmail.willBeRedirected", false, null)}</p>
            </>
          ) : (
            <p className="mt-5">{translate("pConfirmEmail.invalidLink", false, null)}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConfirmEmail;
