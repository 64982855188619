import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Outlet } from "react-router-dom";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Alerts from "./Alerts";
import GlobalLoading from "./GlobalLoading";
import BackToTop from "./BackToTop";
import BtnToggleSidebar from "./BtnToggleSidebar";
import SidebarOffCanvas from "./SidebarOffCanvas";

const SvgIconGradient = () => {
  // Use via .icon-satonda on the icon wrapper
  return (
    <svg style={{ width: "0", height: "0", position: "absolute" }} aria-hidden="true" focusable="false">
      <linearGradient id="icon-gradient-satonda" x1="1" y1="0" x2="0" y2="1">
        <stop offset="25%" stopColor="#f68100" />
        <stop offset="100%" stopColor="#e52a05" />
      </linearGradient>
    </svg>
  );
};

const Layout = ({ globalLoading: { globalLoading } }) => {
  return (
    <div className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh", overflowX: "hidden", overflowY: "auto" }}>
      <Navbar />
      <div className="row flex-fill mx-0">
        <div className="d-none d-lg-block col-lg-3 col-xl-2 p-3 bg-light sidebar-shadow" style={{ zIndex: "100" }}>
          <Sidebar />
        </div>
        <div className="col-12 col-lg-9 col-xl-10 p-3 posRelative">
          <div className="container">
            <SidebarOffCanvas />
            {globalLoading.length > 0 ? (
              <GlobalLoading />
            ) : (
              <>
                <BtnToggleSidebar />
                <Header />
                <Alerts />
                <div className="mt-4">
                  <Outlet />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <BackToTop />
      <SvgIconGradient />
    </div>
  );
};

Layout.propTypes = {
  globalLoading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  globalLoading: state.globalLoading,
});

export default connect(mapStateToProps, null)(Layout);
