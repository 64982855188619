export const pEmEbProject = {
  projectNotFound: {
    en: "Project not found.",
    nl: "Project niet gevonden.",
  },
  manageProjects: {
    en: "Manage your email builder projects",
    nl: "Beheer uw email builder projecten",
  },
};
