export const cEmailCampaignStatsTop = {
  subject: {
    en: "Email subject",
    nl: "Onderwerp",
  },
  audience: {
    en: "Audience",
    nl: "Audience",
  },
  template: {
    en: "Email template",
    nl: "Email template",
  },
};
