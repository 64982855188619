export const cAudienceMenuEditName = {
  editAudienceName: {
    en: "Edit audience name",
    nl: "Verander audience naam",
  },
  newName: {
    en: "New audience name",
    nl: "Nieuwe audience naam",
  },
  editName: {
    en: "Edit name",
    nl: "Verander naam",
  },
  audienceNotFound: {
    en: "Audience not found.",
    nl: "Audience niet gevonden.",
  },
};
