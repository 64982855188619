import { fromEvent } from "file-selector";
import { validateEmail } from "../../../lib/GeneralFunctions";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import { translate } from "../../../translations/translations";

export const getEmailFromEntry = (entry) => {
  let emailKey = "";
  let emailAddress = "";
  Object.keys(entry).forEach((key) => {
    if (validateEmail(entry[key])) {
      emailKey = key;
      emailAddress = entry[key];
    }
  });
  return { emailKey, emailAddress };
};

export const CSV_MIME_TYPES = [
  "text/csv",
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/csv",
];

const parseCsv = (fileContent, setAlert, setCsvData, clearFiles) => {
  try {
    let lines = fileContent.split("\n").map((line) => line.replace(/[\r|\t|\n]/g, ""));
    let headers = lines[0].split(",").map((val) =>
      val
        .replace(/^"/, "")
        .replace(/"$/, "")
        .split(/\s+/)
        .map((val, i) => (i === 0 ? val : capitalizeFirstLetter(val)))
        .join("")
    );
    let res = [];
    for (let i = 1; i < lines.length; i++) {
      let lineValues = lines[i].split(",").map((val) => val.replace(/^"/, "").replace(/"$/, ""));
      let lineObj = {};
      headers.forEach((header, j) => {
        let lineVal = typeof lineValues[j] === "undefined" ? "" : lineValues[j];
        if (new Date(lineVal).toString() !== "Invalid Date") {
          lineVal = new Date(lineVal);
        }
        if (!Number.isNaN(parseFloat(lineVal)) && Number.isFinite(parseFloat(lineVal))) {
          lineVal = parseFloat(lineVal);
        }
        lineObj = { ...lineObj, [header]: lineVal };
      });
      if (Object.values(lineObj).filter((val) => val !== "").length > 0) {
        // Skip lines that have only empty values
        res.push(lineObj);
      }
    }
    if (res.length === 0) {
      setAlert(translate("cAudienceFunctions.noCsvDataFound", false, null), "danger");
    }
    setCsvData(res);
  } catch (error) {
    console.error(error);
    setAlert(translate("cAudienceFunctions.couldntParse", false, null), "danger");
    clearFiles();
  }
};

export const filesSelected = async (e, setAlert, setCsvData, clearFiles) => {
  const files = await fromEvent(e);
  if (files.length > 0 && CSV_MIME_TYPES.includes(files[0].type) && (files[0].name.match(/.(\w{3})$/) || ["", ""])[1] === "csv") {
    const reader = new FileReader();
    reader.addEventListener("load", () => parseCsv(reader.result, setAlert, setCsvData, clearFiles), false);
    if (files[0]) {
      reader.readAsText(files[0]);
    }
  } else {
    setAlert(translate("cAudienceFunctions.onlyCsvAllowed", false, null), "danger");
    clearFiles();
  }
};
