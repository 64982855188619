export const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (error) {
    return "";
  }
};

export const decapitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toLowerCase() + string.slice(1);
  } catch (error) {
    return "";
  }
};

export const formatDate = (dateString, lang, formatStr) => {
  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat:
  // weekday: "short" || "long"
  // year: "numeric" || "2-digit"
  // month: "numeric" || "long" || "short"
  // day: "numeric"
  // hour/minute/second: "numeric" || "2-digit" (probably always use numeric)
  // timeZoneName: "short" || "shortOffset"
  // hour12: true for 0-12, false for 0-23

  // formatStr = "[dateFormat] [timeFormat]"
  // dateFormat =
  //    dmyy       => 3/1/2022
  //    dmmyy      => 3 Jan 2022
  //    dmmmyy     => 3 January 2022
  //    ddmmmyy    => Thu 3 January 2022
  //    dddmmmyy   => Thursday 3 January 2022
  // timeFormat =
  //    hm
  //    hms
  const DATE_FORMATS = {
    dm: {
      day: "numeric",
      month: "numeric",
    },
    dmyy: {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    },
    dmmyy: {
      day: "numeric",
      month: "short",
      year: "numeric",
    },
    dmmmyy: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    ddmmmyy: {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    dddmmmyy: {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    mmyy: {
      month: "short",
      year: "numeric",
    },
    mmmyy: {
      month: "long",
      year: "numeric",
    },
  };
  const TIME_FORMATS = {
    hm: {
      hour: "numeric",
      minute: "numeric",
    },
    hms: {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  };

  formatStr = formatStr.split(" ");
  let format = { ...DATE_FORMATS[formatStr[0]], ...TIME_FORMATS[formatStr[1]] };
  const dateObj = new Date(dateString);
  return new Intl.DateTimeFormat(lang, format).format(dateObj);
};

export const formatDateFromTimestamp = (timestamp, lang, formatStr) => {
  let dateObj = new Date();
  dateObj.setTime(timestamp);
  return formatDate(dateObj.toString(), lang, formatStr);
};

export const formatNumber = (number, formatStr) => {
  let lang = "en";
  let formatOptions = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };

  // Percent - 0 digit
  if (formatStr === "0%") {
    formatOptions = { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 0 };
  }
  // Percent - 1 digit
  if (formatStr === "0.0%") {
    formatOptions = { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 1 };
  }
  // Percent - 2 digit
  if (formatStr === "0.00%") {
    formatOptions = { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  // Decimal - 0 digit
  if (formatStr === "0") {
    formatOptions = { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 0 };
  }
  // Decimal - 1 digit
  if (formatStr === "0.0") {
    formatOptions = { style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1 };
  }
  // Decimal - 2 digit
  if (formatStr === "0.00") {
    formatOptions = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  return new Intl.NumberFormat(lang, formatOptions).format(number);
};

export const simpleRound = (number, precision) => {
  try {
    return parseFloat(number.toFixed(precision));
  } catch (error) {
    return 0;
  }
};
