export const cUserDashboard = {
  dashboard: {
    en: "Dashboard",
    nl: "Dashboard",
  },
  hostingStorageUsage: {
    en: "Storage usage (MB)",
    nl: "Opslag gebruik (MB)",
  },
  storageUsed: {
    en: "Used",
    nl: "Gebruikt",
  },
  storageAvailable: {
    en: "Available",
    nl: "Beschikbaar",
  },
  noStorageDataFound: {
    en: "No hosting storage data found",
    nl: "Er zijn geen opslaggebruikscijfers gevonden",
  },
  hostingOverusingPlan: {
    en: "You're using more data than your plan allows. Please remove files from your server or upgrade your plan.",
    nl: "U gebruikt meer data dan uw pakket inhoudt. Verwijder aub bestanden van uw server of kies een pakket met een hogere capaciteit.",
  },
  noDomainsYet: {
    en: "You have not yet registered any domains through Satonda",
    nl: "U heeft nog geen domeinnamen geregistreerd via Satonda",
  },
  noVisitorDataFound: {
    en: "No website visitor data found",
    nl: "Er zijn geen websitebezoekergegevens gevonden",
  },
  noBandwidthDataFound: {
    en: "No bandwidth data found",
    nl: "Er zijn geen dataverkeergegevens gevonden",
  },
  totalBandwidthUsed: {
    en: "Total bandwidth used (MB)",
    nl: "Totaal gebruikte bandbreedte (MB)",
  },
  noSbProjectDataFound: {
    en: "No Sitebuilder projects data found",
    nl: "Er zijn geen Sitebuilder projectgegevens gevonden",
  },
  sbProjectsLaunched: {
    en: "Launched",
    nl: "Gepubliceerd",
  },
  sbProjectsUnderDev: {
    en: "Under development",
    nl: "Onder ontwikkeling",
  },
  sbProjectsUnused: {
    en: "Unused",
    nl: "Ongebruikt",
  },
  noEmStatsFound: {
    en: "No email marketing statistics found",
    nl: "Er zijn geen email marketing statistieken gevonden",
  },
  emStatsLabel: {
    en: "Emails sent",
    nl: "Verzonden emails",
  },
  emStatsSent: {
    en: "Sent",
    nl: "Verstuurd",
  },
  emStatsUnused: {
    en: "Unused",
    nl: "Ongebruikt",
  },
  noAudienceDataFound: {
    en: "No audience data found",
    nl: "Er zijn geen audiencegegevens gevonden",
  },
  noWorkspacesFound: {
    en: "You don't have any workspaces yet",
    nl: "U heeft nog geen workspaces",
  },
  wsName: {
    en: "Workspace name",
    nl: "Workspace naam",
  },
  wsYourRole: {
    en: "Your role",
    nl: "Uw rol",
  },
  wsNumMembers: {
    en: "# members",
    nl: "# leden",
  },
  wsRoleOwner: {
    en: "Owner",
    nl: "Eigenaar",
  },
  wsRoleAdmin: {
    en: "Admin",
    nl: "Beheerder",
  },
  wsRoleMember: {
    en: "Member",
    nl: "Lid",
  },
  showingWorkspaces: {
    en: "Showing workspaces",
    nl: "Workspaces",
  },
  registerNewDomain: {
    en: "Register a domain name",
    nl: "Registreer een domeinnaam",
  },
  siteVisitors: {
    en: "Site visitors",
    nl: "Website bezoekers",
  },
  bandwidthUsed: {
    en: "Bandwidth used",
    nl: "Gebruikte bandbreedte",
  },
  domainOverview: {
    en: "Domain overview",
    nl: "Domeinoverzicht",
  },
  sbProjects: {
    en: "Sitebuilder projects",
    nl: "Sitebuilder projecten",
  },
  emInteraction: {
    en: "Email marketing interaction",
    nl: "Email marketing interactie",
  },
  emMailsSent: {
    en: "Email marketing emails sent",
    nl: "Email marketing verstuurde emails",
  },
  audiences: {
    en: "Audiences",
    nl: "Audiences",
  },
  yourWorkspaces: {
    en: "Your workspaces",
    nl: "Uw workspaces",
  },
};
