import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { submitPulse } from "../../actions/auth";
import { translate } from "../../translations/translations";

// TODO:
// - Add a few minute (3-5?) delay before actually showing the pop up, so that user doesn't see it as the first thing when logging in again..
// - Change to only thumbs up or thumbs down to simplify and force decision
// - Make it as a component within the navbar, not a popup on top

const PulseCheck = ({ auth: { user }, submitPulse }) => {
  // show: 0 = don't show, 1 = ask for rating, 2 = thanks msg, 3 = fade out box
  const [show, setShow] = useState(0);

  const DAYS_TO_REASK = 3;

  useEffect(() => {
    if (user !== null) {
      let latest = user.pulse[0];
      if (typeof latest !== "undefined") {
        let date = new Date(latest.date);
        date.setDate(date.getDate() + DAYS_TO_REASK);
        if (date < new Date()) {
          // Last pulse was more than DAYS_TO_REASK ago -> ask for new rating
          setShow(1);
        } else {
          setShow(0);
        }
      } else {
        // No rating yet
        setShow(1);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const clickSubmitPulse = (score) => {
    if (score > 0) {
      setShow(2);
      setTimeout(() => fadeOutBox(score), 3000);
    } else {
      fadeOutBox(score);
    }
  };

  const fadeOutBox = (score) => {
    setShow(3);
    // Submit the pulse a little after box has fade out. setShow(0) will then be picked up in the useEffect through updated user var
    setTimeout(() => submitPulse(score), 1000);
  };

  return (
    <div className={`pulseCheck ${show === 0 ? "d-none" : "d-block"} ${show === 3 ? "invisible opacity-0" : "visible opacity-1"}`}>
      {/* Ask for a rating */}
      <p className={`text-gray text-bold trans-3 ${show === 2 ? "invisible opacity-0" : "visible opacity-1"}`}>
        {translate("cPulseCheck.howFeeling", false, null)}
      </p>
      <div className={`text-center trans-3 ${show === 2 ? "invisible opacity-0" : "visible opacity-1"}`}>
        <span className="pulseCheck-score" onClick={() => clickSubmitPulse(1)}>
          <i className="far fa-frown" />
        </span>
        <span className="pulseCheck-score" onClick={() => clickSubmitPulse(2)}>
          <i className="far fa-meh" />
        </span>
        <span className="pulseCheck-score" onClick={() => clickSubmitPulse(3)}>
          <i className="far fa-smile" />
        </span>
        <span className="pulseCheck-score" onClick={() => clickSubmitPulse(4)}>
          <i className="far fa-grin-hearts" />
        </span>
      </div>
      {/* Dismiss button: only show when asking for a rating */}
      <span
        className={`pulseCheck-dismiss trans-3 ${show === 1 ? "visible opacity-1" : "invisible opacity-0"}`}
        title={translate("cPulseCheck.dismiss", false, null)}
        onClick={() => clickSubmitPulse(0)}
      >
        <i className="fa fa-times" />
      </span>
      {/* Thanks message after having submitted a rating */}
      <div className={`pulseCheck-thanks trans-3 px-2 ${show === 2 ? "visible opacity-1" : "invisible opacity-0"}`}>
        <span className="text-success me-2">
          <i className="fa fa-check" />
        </span>
        <p className="m-0 text-primary">
          <span className="text-bold">{translate("cPulseCheck.thankYou", false, null)}</span> {translate("cPulseCheck.pleaseClick", false, null)}{" "}
          <Link className="text-underline trans-3" to="/feedback">
            {translate("cPulseCheck.here", false, null)}
          </Link>{" "}
          {translate("cPulseCheck.provideMoreFeedback", false, null)}
        </p>
      </div>
    </div>
  );
};

PulseCheck.propTypes = {
  auth: PropTypes.object.isRequired,
  submitPulse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { submitPulse })(PulseCheck);
