import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import SbMenuEditName from "../../../../components/products/sitebuilder/manage/SbMenuEditName";
import SbMenuPublish from "../../../../components/products/sitebuilder/manage/SbMenuPublish";
import SbMenuDelete from "../../../../components/products/sitebuilder/manage/SbMenuDelete";
import SbMenuDuplicate from "../../../../components/products/sitebuilder/manage/SbMenuDuplicate";

import { translate } from "../../../../translations/translations";

const SitebuilderProjectMenu = ({ auth: { user }, sitebuilder: { sbProjects } }) => {
  let { projectId } = useParams();

  const [sbProject, setSbProject] = useState(null);

  useEffect(() => {
    if (user !== null && projectId !== "") {
      setSbProject(sbProjects.filter((project) => project._id === projectId)[0] || null);
    } else {
      setSbProject(null);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  return sbProject === null ? (
    <p className="text-italic m-0">{translate("pSbOthers.projectNotFound", false, null)}</p>
  ) : (
    <div className="row">
      <div className="col-12 col-lg-9 col-xl-6">
        <h1 className="text-primary mb-3">{sbProject.projectName}</h1>
        <SbMenuEditName />
        <SbMenuDuplicate />
        <SbMenuPublish />
        <SbMenuDelete />
      </div>
    </div>
  );
};

SitebuilderProjectMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, null)(SitebuilderProjectMenu);
