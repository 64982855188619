import React from "react";

const BoxDesc = ({ desc, descId }) => {
  return (
    <div className="border border-light rounded p-2">
      {desc.split("\n").map((line, j) => (
        <p className={line === "" ? "my-2" : "m-0"} key={`desc_${descId}_${j}`}>
          {line}
        </p>
      ))}
    </div>
  );
};

export default BoxDesc;
