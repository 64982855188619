export const cEmailBuilder = {
  createNewProject: {
    en: "Create new project",
    nl: "Creer nieuw project",
  },
  projectName: {
    en: "Project name",
    nl: "Project naam",
  },
  pleaseUpgrade: {
    en: "Please upgrade to create additional projects",
    nl: "Waardeer uw abonnement op naar een hoger niveau om extra projecten te kunnen maken",
  },
  createProject: {
    en: "Create project!",
    nl: "Creer project!",
  },
  yourProjects: {
    en: "Your projects",
    nl: "Uw projecten",
  },
  noProjectsFound: {
    en: "No existing projects found.",
    nl: "Geen projecten gevonden.",
  },
  openMenu: {
    en: "Open menu",
    nl: "Open menu",
  },
  created: {
    en: "Created",
    nl: "Gemaakt",
  },
  lastChange: {
    en: "Last change",
    nl: "Veranderd",
  },
  editInEb: {
    en: "Edit in Emailbuilder",
    nl: "Bewerk in Emailbuilder",
  },
  areYouSureDelete: {
    en: "Are you sure you want to delete project %projectName%? This cannot be undone.",
    nl: "Weet u zeker dat u project %projectName% wilt verwijderen? Dit kan niet ongedaan gemaakt worden.",
  },
  deleteProject: {
    en: "Delete project",
    nl: "Verwijder project",
  },
  projectNotFound: {
    en: "Project not found.",
    nl: "Project niet gevonden.",
  },
  duplicateProject: {
    en: "Duplicate project",
    nl: "Kloon project",
  },
  duplicatingIsEasyStart: {
    en: "Duplicating a project is an easy way to start a new project with the same styles/layouts as your other projects, to ensure consistency between emails.",
    nl: "Klonen van een project is de gemakkelijkste manier om een nieuw project te beginnen met dezelfde style/layout als uw andere projecten en daarmee consistentie tussen emails te waarborgen.",
  },
  editProjectName: {
    en: "Edit project name",
    nl: "Verander projectnaam",
  },
  newProjectName: {
    en: "New project name",
    nl: "Nieuwe projectnaam",
  },
  editName: {
    en: "Edit name",
    nl: "Verander naam",
  },
};
