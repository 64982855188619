export const cEmailHostingComponents = {
  provideEmailAddress: {
    en: "Please provide a valid email address",
    nl: "Geef aub een geldig email adres op",
  },
  emailAddressAlreadyExists: {
    en: "Email address already exists",
    nl: "Email adres bestaat al",
  },
  pwStrength: {
    en: "Please provide a password of at least 8 characters, including at least 1 lower case, 1 upper case and 1 digit",
    nl: "Wachtwoord moet ten minste 8 tekens bevatten, waarvan minimaal 1 kleine letter, 1 hoofdletter en 1 getal",
  },
  addNewEmailTo: {
    en: "Add new email account to",
    nl: "Maak nieuw email adres voor",
  },
  emailAddress: {
    en: "Email address",
    nl: "Email adres",
  },
  example: {
    en: "example",
    nl: "voorbeeld",
  },
  firstName: {
    en: "First name",
    nl: "Voornaam",
  },
  userFirstName: {
    en: "User's first name",
    nl: "Gebruiker's voornaam",
  },
  lastName: {
    en: "Last name",
    nl: "Achternaam",
  },
  userLastName: {
    en: "User's last name",
    nl: "Gebruiker's achternaam",
  },
  password: {
    en: "Password",
    nl: "Wachtwoord",
  },
  genSecurePw: {
    en: "Generate secure password",
    nl: "Genereer veilig wachtwoord",
  },
  hidePw: {
    en: "Hide password",
    nl: "Verberg wachtwoord",
  },
  showPw: {
    en: "Show password",
    nl: "Bekijk wachtwoord",
  },
  createEmailAccount: {
    en: "Create email account",
    nl: "Maak email account",
  },
  isNotValidEmailAddress: {
    en: "is not a valid email address",
    nl: "is geen geldig email adres",
  },
  isIncludedMultipleTimes: {
    en: "is included multiple times",
    nl: "wordt meerdere keren gebruikt",
  },
  provideRecipients: {
    en: "Please provide one or more recipients",
    nl: "Geeb aub een of meerdere ontvangers op",
  },
  addNewAlias: {
    en: "Add new email alias to",
    nl: "Maak nieuw email alias aan voor",
  },
  recipients: {
    en: "Recipients",
    nl: "Ontvangers",
  },
  emailAddresses: {
    en: "Email address(es)",
    nl: "Email adres(sen)",
  },
  separateMultipleRecipients: {
    en: `Separate multiple recipients with a ","`,
    nl: `Scheid meerdere ontvangers af met een ","`,
  },
  createAlias: {
    en: "Create email alias",
    nl: "Maak email alias",
  },
  addNewEmailDomain: {
    en: "Add a new email domain",
    nl: "Voeg een nieuw email domein toe",
  },
  exampleCom: {
    en: "example.com",
    nl: "voorbeeld.nl",
  },
  addDomain: {
    en: "Add domain",
    nl: "Voeg domein toe",
  },
  areYouSureRemoveEmailAddress: {
    en: "Are you sure you want to remove email address %emailAddress%? This permanently removes all its emails.",
    nl: "Weet u zeker dat u email adres %emailAddress% wilt verwijderen? Dit verwijdert alle emails van het account en kan niet ongedaan worden.",
  },
  emailAccountsFor: {
    en: "Email accounts for",
    nl: "Email accounts voor",
  },
  fullNameUser: {
    en: "Full name user",
    nl: "Naam gebruiker",
  },
  emailAliases: {
    en: "Email aliases",
    nl: "Email aliases",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  changePw: {
    en: "Change password",
    nl: "Verander wachtwoord",
  },
  removeEmailAccount: {
    en: "Remove email account and its emails",
    nl: "Verwijder email account en emails",
  },
  removeAccount: {
    en: "Remove account",
    nl: "Verwijder account",
  },
  areYouSureRemoveEmailAlias: {
    en: "Are you sure you want to remove alias %emailAddress%?",
    nl: "Weet u zeker dat u alias %emailAddress% wilt verwijderen?",
  },
  aliasesFor: {
    en: "Aliases for",
    nl: "Aliases voor",
  },
  emailAlias: {
    en: "Email alias",
    nl: "Email alias",
  },
  updateRecipients: {
    en: "Update recipients",
    nl: "Verander ontvangers",
  },
  removeEmailAlias: {
    en: "Remove email alias",
    nl: "Verwijder email alias",
  },
  removeAlias: {
    en: "Remove alias",
    nl: "Verwijder alias",
  },
  areYouSureRemoveEmailHostingDomain: {
    en: "Are you sure you want to stop email hosting for %domainName%? This permanently removes all emails of all domain email accounts.",
    nl: "Weet u zeker dat u email hosting voor %domainName% wilt stoppen? Dit verwijdert alle emails van alle accounts van het domein en kan niet ongedaan worden.",
  },
  noDomainsHosted: {
    en: "You don't currently have any hosted email domains.",
    nl: "U host nog geen email domeinen bij ons.",
  },
  domainsHosted: {
    en: "Domains hosted on your server",
    nl: "Domeinen gehost op uw server",
  },
  domain: {
    en: "Domain",
    nl: "Domein",
  },
  manageAccounts: {
    en: "Manage accounts",
    nl: "Beheer accounts",
  },
  removeDomainFromServer: {
    en: "Remove domain from server",
    nl: "Verwijder domein van server",
  },
  remove: {
    en: "Remove",
    nl: "Verwijder",
  },
};
