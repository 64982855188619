export const aAudiences = {
  retrievingAudiences: {
    en: "Retrieving audiences..",
    nl: "Ophalen audiences..",
  },
  creatingAudience: {
    en: "Creating new audience..",
    nl: "Nieuwe audience aanmaken..",
  },
  audienceCreated: {
    en: "Audience has been created!",
    nl: "Nieuwe audience aangemaakt!",
  },
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  updatingName: {
    en: "Updating audience name..",
    nl: "Bijwerken audience name",
  },
  nameUpdated: {
    en: "Audience name updated!",
    nl: "Audience naam bijgewerkt!",
  },
  loadingData: {
    en: "Loading audience data..",
    nl: "Laden audience data..",
  },
  dataLoaded: {
    en: "Audience data loaded!",
    nl: "Audience gegevens geladen!",
  },
  deletingAudience: {
    en: "Deleting audience..",
    nl: "Verwijderen audience..",
  },
  audienceDeleted: {
    en: "Audience deleted!",
    nl: "Audience verwijderd!",
  },
  entryRemoved: {
    en: "Entry removed!",
    nl: "Item verwijderd!",
  },
};
