import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { duplicateEbProject } from "../../../../actions/emailMarketing";
import { translate } from "../../../../translations/translations";

const EbMenuDuplidateProject = ({ auth: { user, activeWorkspace }, emailMarketing: { ebProjects }, duplicateEbProject }) => {
  let { projectId } = useParams();

  const clickDuplicateProject = async () => {
    await duplicateEbProject(projectId);
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cEmailBuilder.duplicateProject", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cEmailBuilder.projectNotFound", false, null)}</p>
      ) : (
        <>
          <p className="mb-2">{translate("cEmailBuilder.duplicatingIsEasyStart", false, null)}</p>
          <button className="btn btn-success" onClick={clickDuplicateProject}>
            {translate("cEmailBuilder.duplicateProject", false, null)}
          </button>
        </>
      )}
    </>
  );
};

EbMenuDuplidateProject.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  duplicateEbProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, { duplicateEbProject })(EbMenuDuplidateProject);
