export const cAddNewDomainToDns = {
  provideValidDomainName: {
    en: "Please provide a valid domain name",
    nl: "Geef een geldige domeinnaam op",
  },
  addADomain: {
    en: "Add a domain",
    nl: "Voeg een domeinnaam toe",
  },
  addDomain: {
    en: "Add domain",
    nl: "Voeg domein toe",
  },
};
