export const cDomainOwnership = {
  provideFullName: {
    en: "Please provide your full name or company name",
    nl: "Specificeer aub uw volledige naam of bedrijfsnaam",
  },
  provideEmail: {
    en: "Please provide a valid email address",
    nl: "Specificeer aub uw email adres",
  },
  provideCountry: {
    en: "Please provide your country",
    nl: "Specificeer aub uw land",
  },
  provideAddress: {
    en: "Please provide your address",
    nl: "Specificeer aub uw adres",
  },
  providePostal: {
    en: "Please provide your postal or ZIP code",
    nl: "Specificeer aub uw postcode",
  },
  provideCity: {
    en: "Please provide your city",
    nl: "Specificeer aub uw stad",
  },
  providePhone: {
    en: "Please provide your phone number in international format (e.g.: +31.611111111)",
    nl: "Specificeer aub uw telefoonnummer in internationaal formaat (bijv.: +31.611111111)",
  },
  provideCompanyName: {
    en: "Please provide your company name",
    nl: "Specificeer aub uw bedrijfsnaam",
  },
  name: {
    en: "Name",
    nl: "Naam",
  },
  fullName: {
    en: "Full name",
    nl: "Volledige naam",
  },
  companyName: {
    en: "Company name",
    nl: "Bedrijfsnaam",
  },
  email: {
    en: "Email",
    nl: "Email",
  },
  emailAddress: {
    en: "Email address",
    nl: "Email adres",
  },
  address: {
    en: "Address",
    nl: "Adres",
  },
  street: {
    en: "Street and streetnumber",
    nl: "Straatnaam en -nummer",
  },
  postalOrZip: {
    en: "Postal or ZIP code",
    nl: "Postcode",
  },
  city: {
    en: "City",
    nl: "Stad",
  },
  country: {
    en: "Country",
    nl: "Land",
  },
  phone: {
    en: "Phone number",
    nl: "Telefoonnummer",
  },
  phoneIntFormat: {
    en: "Phone number in international format (e.g.: +31.611111111)",
    nl: "Telefoonnummer in internationaal formaat (bijv.: +31.611111111)",
  },
  update: {
    en: "Update",
    nl: "Update",
  },
};
