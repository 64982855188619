import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FaMagic } from "react-icons/fa";
import { PiDevicesBold, PiFunnelBold } from "react-icons/pi";
import { MdCompare } from "react-icons/md";

import ProductTableSb from "../../../../components/products/productTables/ProductTableSb";

import { translate } from "../../../../translations/translations";

const SbMain = ({ auth: { langCode } }) => {
  return (
    <>
      <section className="mt-5" id="header">
        <h1 className="text-primary mt-4 mb-4">{translate("pSbMain.whyUs", false, null)}</h1>
        <div className="row">
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaMagic />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pSbMain.noCode", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pSbMain.noCodeTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <PiDevicesBold />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pSbMain.allDevices", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pSbMain.allDevicesTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <PiFunnelBold />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pSbMain.conversions", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pSbMain.conversionsTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <MdCompare />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pSbMain.abVersions", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pSbMain.abVersionsTag", false, null)}</p>
          </div>
        </div>
        <div className="mt-4">
          <a
            href={`https://www.satonda.com/${langCode}/products/sitebuilder`}
            className="btn btn-primary px-5 rounded-pill"
            target="_blank"
            rel="noreferrer"
          >
            {translate("pSbMain.readMore", false, null)}
          </a>
        </div>
      </section>
      <section className="mt-6 mb-6" id="pricing">
        <h1 className="text-primary">{translate("pSbMain.selectYourPlan", false, null)}</h1>
        <ProductTableSb />
      </section>
    </>
  );
};

SbMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(SbMain);
