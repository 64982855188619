export const cPendingPayments = {
  youHavePendingPayments: {
    en: "You have payments that are pending authorisation",
    nl: "U heeft betalingen die nog in behandeling zijn",
  },
  date: {
    en: "Date",
    nl: "Datum",
  },
  items: {
    en: "Items",
    nl: "Artikelen",
  },
  amount: {
    en: "Amount (inc VAT)",
    nl: "Bedrag (inc BTW)",
  },
};
