export const isUnpaid = (invoice) => {
  return invoice.paymentStatus !== "paid" && !isCancelled(invoice) && isOverdue(invoice.invoiceDate);
};

export const isOverdue = (invoiceDate) => {
  // overdue = not paid within 5 days of invoice date (automatic collection happens on day 3)
  return Date.now() - new Date(invoiceDate) > 5 * 24 * 60 * 60 * 1000;
};

export const isCancelled = (invoice) => {
  return typeof invoice.cancelDate !== "undefined" && !invoice.isSuspended;
};

export const ORIGIN_OVERDUE = "overdueInvoice";
