export const cTldCard = {
  highlightTxt: { en: "Popular", nl: "Populair" },
  firstYearTxt: { en: "First year", nl: "Eerste jaar" },
  renewsTxt: { en: "Renews at", nl: "Daarna" },
  highlightedExtensions: { en: "Highlighted extensions", nl: "Uitgelichte extensies" },
  clickToSeeAllTlds: {
    en: "Click here to see all available extensions and prices",
    nl: "Klik hier om alle Beschikbare extensies en prijzen te zien",
  },
};
