import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import AudienceMenuEditName from "../../../components/audiences/AudienceMenuEditName";
import AudienceMenuDelete from "../../../components/audiences/AudienceMenuDelete";
import AudienceMenuLoadDatafile from "../../../components/audiences/AudienceMenuLoadDatafile";

import { translate } from "../../../translations/translations";

const AudienceMenu = ({ auth: { user }, audiences: { audiences } }) => {
  let { audienceId } = useParams();

  const [audience, setAudience] = useState(null);

  useEffect(() => {
    if (user !== null && audienceId !== "") {
      setAudience(audiences.filter((audience) => audience._id === audienceId)[0] || null);
    } else {
      setAudience(null);
    }
    // eslint-disable-next-line
  }, [user, audienceId]);

  return audience === null ? (
    <p className="text-italic m-0">{translate("pAudienceEntries.audienceNotFound", false, null)}</p>
  ) : (
    <div className="row">
      <div className="col-12 col-lg-9 col-xl-6">
        <h1 className="text-primary mb-3">{audience.audienceName}</h1>
        <AudienceMenuEditName />
        <AudienceMenuLoadDatafile />
        <AudienceMenuDelete />
      </div>
    </div>
  );
};

AudienceMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(AudienceMenu);
