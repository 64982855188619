export const pWsPages = {
  billingOverview: {
    en: "Billing overview",
    nl: "Facturering",
  },
  provideWsName: {
    en: "Please provide a name for your workspace.",
    nl: "Geef aub een naam op voor uw workspace.",
  },
  wsNameAlreadyExists: {
    en: "You already are a member of a workspace with the same name. Please pick another name.",
    nl: "U bent al een lid van een workspace met dezelfde naam. Kies aub een andere naam.",
  },
  changeWsName: {
    en: "Change workspace name",
    nl: "Verander naam van workspace",
  },
  wsName: {
    en: "Workspace name",
    nl: "Workspace naam",
  },
  changeName: {
    en: "Change name",
    nl: "Verander naam",
  },
  wsNotifications: {
    en: "Workspace notifications",
    nl: "Workspace berichten",
  },
  wsAdminInfo: {
    en: "Administrative information",
    nl: "Administratieve informatie",
  },
};
