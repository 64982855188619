import { translate } from "../../../translations/translations";

export const TICKET_TOPICS = {
  domains: translate("cSupportComponents.domains", false, null),
  hosting: translate("cSupportComponents.hosting", false, null),
  sitebuilder: translate("cSupportComponents.sitebuilder", false, null),
  emailMarketing: translate("cSupportComponents.emailMarketing", false, null),
  billing: translate("cSupportComponents.billing", false, null),
  other: translate("cSupportComponents.other", false, null),
};
export const TICKET_ATTACHMENT_BASE_LINK = "https://cdn.satonda.com/support/";
export const ACCEPTED_MIME_TYPES = [
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];
