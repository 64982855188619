import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { deleteEbProject } from "../../../../actions/emailMarketing";

import { translate } from "../../../../translations/translations";

const EbMenuDelete = ({ auth: { user, activeWorkspace }, emailMarketing: { ebProjects }, deleteEbProject }) => {
  let { projectId } = useParams();
  const navigate = useNavigate();

  const [ebProject, setEbProject] = useState(null);

  useEffect(() => {
    if (activeWorkspace !== null && user !== null && projectId !== "") {
      setEbProject(ebProjects.filter((project) => project._id === projectId)[0] || null);
    } else {
      setEbProject(null);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, user, projectId]);

  const clickDeleteProject = async () => {
    if (window.confirm(translate("cEmailBuilder.areYouSureDelete", false, { projectName: ebProject.projectName }))) {
      await deleteEbProject(projectId);
      navigate(`/email-marketing/manage/builder`, { replace: true });
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cEmailBuilder.deleteProject", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cEmailBuilder.projectNotFound", false, null)}</p>
      ) : (
        <button className="btn btn-danger" onClick={clickDeleteProject}>
          {translate("cEmailBuilder.deleteProject", false, null)}
        </button>
      )}
    </>
  );
};

EbMenuDelete.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  deleteEbProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, { deleteEbProject })(EbMenuDelete);
