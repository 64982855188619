import axios from "axios";

import { GET_SB_PROJECTS } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { translate } from "../translations/translations";

export const getSbProjects = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.retrievingProjects", false, null)));
  try {
    const res = await axios.get(`${API_URL}/sitebuilder/manage/all`);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const createNewSbProject = (projectName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.creatingProject", false, null)));
  try {
    const body = JSON.stringify({ projectName });
    const res = await axios.post(`${API_URL}/sitebuilder/manage/create`, body, POST_CONFIG);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aSitebuilder.projectCreated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aSitebuilder.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const editSbProjectName = (projectId, projectName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.updatingProjectName", false, null)));
  try {
    const body = JSON.stringify({ projectId, projectName });
    const res = await axios.put(`${API_URL}/sitebuilder/manage/edit/name`, body, POST_CONFIG);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aSitebuilder.updatedProjectName", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aSitebuilder.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const deleteSbProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.deletingProject", false, null)));
  try {
    const res = await axios.delete(`${API_URL}/sitebuilder/manage/delete/${projectId}`);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aSitebuilder.projectDeleted", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aSitebuilder.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const duplicateSbProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.duplicatingProject", false, null)));
  try {
    const res = await axios.get(`${API_URL}/sitebuilder/manage/duplicate/${projectId}`);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aSitebuilder.projectDuplicated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aSitebuilder.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const sbRetractSite = (sbProject) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aSitebuilder.retractingSite", false, null)));
  try {
    const res = await axios.put(`${API_URL}/sitebuilder/manage/retract`, JSON.stringify({ projectId: sbProject._id }), POST_CONFIG);
    dispatch({
      type: GET_SB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aSitebuilder.siteRetracted", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aSitebuilder.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};
