import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { formatNumber } from "../../lib/formattingFunctions";
import { translate } from "../../translations/translations";
import { MY_SATONDA_URL } from "../../lib/GeneralVars";

const StripeCheckout = ({ options, curr, amount, checkoutLocation, invoiceId }) => {
  // checkoutLocation = "payment" || "overdue"

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const BTN_TEXT = `${translate("cStripeCheckout.pay", false, null)} ${curr}${formatNumber(amount, "0.00")}`;

  // This useEffect gets called to find whether a payment had been made already (this is the return_url)
  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent.status === "succeeded" || paymentIntent.status === "processing") {
        navigate(`/checkout/status/${checkoutLocation}/${paymentIntent.status}`, { replace: true });
      } else if (paymentIntent.status === "requires_payment_method") {
        navigate(`/checkout/status/${checkoutLocation}/failed`, { replace: true });
      } else {
        navigate(`/checkout/status/${checkoutLocation}/error`, { replace: true });
      }
    });
    // eslint-disable-next-line
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // confirmPayment API: https://docs.stripe.com/js/payment_intents/confirm_payment
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          checkoutLocation === "payment"
            ? `${MY_SATONDA_URL}/checkout/${checkoutLocation}`
            : `${MY_SATONDA_URL}/checkout/${checkoutLocation}/${invoiceId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    // Redirect to status/${checkoutLocation}/error page
    if (error.type === "card_error" || error.type === "validation_error") {
      navigate(`/checkout/status/${checkoutLocation}/error?errMsg=${error.message}`, { replace: true });
    } else {
      navigate(`/checkout/status/${checkoutLocation}/error`, { replace: true });
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={options} />
      <button
        disabled={isLoading || !stripe || !elements}
        className="btn btn-primary rounded-pill trans-3 w-100"
        // id="stripe-pay-button"
      >
        <span id="button-text">{isLoading ? <div className="spinner" id="stripe-spinner"></div> : BTN_TEXT}</span>
      </button>
    </form>
  );
};

export default StripeCheckout;
