export const pNotfound = {
  pageNotFound: {
    en: "Page not found",
    nl: "Pagina niet gevonden",
  },
  pageNotExist: {
    en: "Sorry, this page does not exist.",
    nl: "Sorry, deze pagina bestaat niet.",
  },
  goBack: {
    en: "Click here to go to our homepage.",
    nl: "Klik hier om terug te gaan naar onze startpagina.",
  },
};
