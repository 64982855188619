export const pDomainsManageDns = {
  domainNotFound: {
    en: "Error: Domain not found",
    nl: "Fout: Domein niet gevonden",
  },
  manageDns: {
    en: "Manage your DNS records",
    nl: "Beheer uw DNS records",
  },
  redirect: {
    en: "Redirect domain",
    nl: "Domein omleiden",
  },
  setDns: {
    en: "Set DNS records",
    nl: "Zet DNS records",
  },
  updateNs: {
    en: "Update nameservers",
    nl: "Nameservers bijwerken",
  },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
  // xxxxxxxxxxx: {
  //   en: "yyyyyyyyyyyy",
  //   nl: "zzzzzzzzzzzz",
  // },
};
