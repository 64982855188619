import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RecordNameservers from "./dnsRecords/RecordNameservers";
import RecordA from "./dnsRecords/RecordA";
import RecordAAAA from "./dnsRecords/RecordAAAA";
import RecordCAA from "./dnsRecords/RecordCAA";
import RecordCNAME from "./dnsRecords/RecordCNAME";
import RecordMX from "./dnsRecords/RecordMX";
import RecordSRV from "./dnsRecords/RecordSRV";
import RecordTXT from "./dnsRecords/RecordTXT";

import { translate } from "../../../translations/translations";

// On redirect
// - CNAME is not a 'redirect' as such: it only resolves a domain to an alias so the visitor sees the website from the alias domain while
//   still seeing the provided domain the address bar.
// - CNAME is a pure DNS solution
// - URL redirect is not a DNS solution. It actually needs a server behind the scenes and hidden A record.
//   Idea is that a user hits the provided domain, goes to the location provided on the A record, which has a script to redirect the user to
//   the provided target URL (HTTP redirect 301 or 302 code)
// https://www.namecheap.com/support/knowledgebase/article.aspx/9604/2237/types-of-domain-redirects-301-302-url-redirects-url-frame-and-cname/
//
// => TODO: RTR offers redirect via "URL" DNS record. Add later

const CreateNewDnsRecord = ({ auth: { activeWorkspace }, dnsRecords }) => {
  // dnsRecords = { _id, domainName, nameservers, records }

  return (
    <>
      <h2 className="text-secondary mt-5 mb-4">Create DNS record</h2>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_NS">
                NS
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#createRecord_A">
                A
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_AAAA">
                AAAA
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_CNAME">
                CNAME
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_MX">
                MX
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_TXT">
                TXT
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_SRV">
                SRV
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#createRecord_CAA">
                CAA
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div id="createRecord_NS" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordNameservers dnsRecords={dnsRecords} />
            </div>
            <div id="createRecord_A" className="tab-pane px-0 pt-3 pb-0 active">
              <RecordA dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_AAAA" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordAAAA dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_CNAME" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordCNAME dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_MX" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordMX dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_TXT" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordTXT dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_SRV" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordSRV dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
            <div id="createRecord_CAA" className="tab-pane px-0 pt-3 pb-0 fade">
              <RecordCAA dnsRecords={dnsRecords} formDisabled={!dnsRecords.nameservers.useDefault} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

CreateNewDnsRecord.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(CreateNewDnsRecord);
