export const pMain = {
  landingPage: {
    en: "Landing page",
    nl: "Startpagina",
  },
  welcome: {
    en: "Welcome, %name%!",
    nl: "Welkom, %name%!",
  },
};
