import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { checkout } from "../actions/cart";
import { setGlobalLoading, removeGlobalLoading } from "../actions/globalLoading";

import CartSubscription from "../components/cart/CartSubscription";
import PendingPayments from "../components/checkout/PendingPayments";
import FormatNumber from "../components/layout/FormatNumber";

import { getTotalAmountAtCheckoutExcVat } from "../components/products/lib/ProductFunctions";
import { getCurrency } from "../lib/Currencies";
import { getVatRate, isWsVatNumberConfirmed } from "../lib/VatRates";

import { translate } from "../translations/translations";

const Cart = ({ auth: { activeWorkspace }, cart: { sessionId, cartItems, cartType }, checkout }) => {
  const navigate = useNavigate();

  const [totalBilledAtCheckout, setTotalBilledAtCheckout] = useState(0);
  const [vatRate, setVatRate] = useState(0.21);

  useEffect(() => {
    activeWorkspace !== null &&
      setVatRate(getVatRate(activeWorkspace.location.countryCode, activeWorkspace.customerType, isWsVatNumberConfirmed(activeWorkspace)));
  }, [activeWorkspace]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setTotalBilledAtCheckout(getTotalAmountAtCheckoutExcVat(cartType, cartItems));
    }
    // eslint-disable-next-line
  }, [cartItems]);

  const clickCheckoutNoPayment = async () => {
    if (totalBilledAtCheckout === 0) {
      let success = await checkout(sessionId);
      if (success) {
        navigate(`/checkout/status/payment/succeeded`, { replace: true });
      } else {
        navigate(`/checkout/status/payment/error`, { replace: true });
      }
    }
  };

  return (
    <>
      <PendingPayments />
      <h1 className="text-primary">{translate("pCart.shoppingCart", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : cartItems.length === 0 ? (
        <p className="text-italic">{translate("pCart.noItemsInCart", false, null)}</p>
      ) : (
        <>
          {/* Wrapper for rounded corner */}
          <div className="rounded-3 overflowHidden border border-primary mt-5">
            {/* Table header + cartItem rows */}
            <CartSubscription cartItems={cartItems} />
            {/* Total amounts */}
            <div className="row mx-0 bg-primary-light text-bold py-2">
              <div className="col-7 d-flex align-items-center">{translate("pCart.totalExcVat", false, null)}</div>
              <div className="col-5 d-flex align-items-center text-left">
                {getCurrency(activeWorkspace.location.priceCountry).currencySign} <FormatNumber number={totalBilledAtCheckout} formatStr={"0.00"} />
              </div>
            </div>
            <div className="row mx-0 text-dark py-2">
              <div className="col-7 d-flex align-items-center">
                {translate("pCart.vat", false, null)} (<FormatNumber number={vatRate} formatStr={"0.00%"} />)
              </div>
              <div className="col-5 d-flex align-items-center text-left">
                {getCurrency(activeWorkspace.location.priceCountry).currencySign}{" "}
                <FormatNumber number={totalBilledAtCheckout * vatRate} formatStr={"0.00"} />
              </div>
            </div>
            <div className="row mx-0 bg-primary text-white text-bold py-2">
              <div className="col-7 d-flex align-items-center">{translate("pCart.totalPayable", false, null)}</div>
              <div className="col-5 d-flex align-items-center text-left">
                {getCurrency(activeWorkspace.location.priceCountry).currencySign}{" "}
                <FormatNumber number={totalBilledAtCheckout * (1 + vatRate)} formatStr={"0.00"} />
              </div>
            </div>
          </div>
          {/* Click checkout */}
          <div className="text-end">
            {totalBilledAtCheckout > 0 ? (
              <Link to="/checkout/payment" className="btn btn-primary rounded-pill trans-3 mt-4 px-5">
                {translate("pCart.proceedCheckout", false, null)}
              </Link>
            ) : (
              <button className="btn btn-success trans-3 mt-4 px-4" onClick={() => clickCheckoutNoPayment()}>
                {translate("pCart.addSubsToAccount", cartItems.length === 1, null)}
                {/* Add subscription{cartItems.length > 1 && "s"} to your account! */}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

Cart.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  checkout: PropTypes.func.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, { checkout, setGlobalLoading, removeGlobalLoading })(Cart);
