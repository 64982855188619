export const cAudienceFunctions = {
  noCsvDataFound: {
    en: "No CSV data found in file.",
    nl: "Er is geen CSV data gevonden in het bestand.",
  },
  couldntParse: {
    en: "Couldn't parse CSV. Please check the file has no errors.",
    nl: "Kon CSV data niet lezen. Check of het bestand fouten bevat.",
  },
  onlyCsvAllowed: {
    en: "Only CSV files are allowed.",
    nl: "Alleen CSV bestanden zijn toegestaan.",
  },
};
