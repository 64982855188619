import { setAlert } from "../../../../actions/alert";
import { translate } from "../../../../translations/translations";

export const errorStatus_initial = {
  err_curr_password: "",
  err_password: "",
  err_password2: "",
  err_firstName: "",
  err_lastName: "",
  err_email: "",
  err_phoneNumber: "",
  err_companyName: "",
  err_street: "",
  err_postal: "",
  err_city: "",
  err_country: "",
};

export const errorMsg_initial = {
  errMsg_curr_password: "",
  errMsg_password: "",
  errMsg_password2: "",
  errMsg_firstName: "",
  errMsg_lastName: "",
  errMsg_email: "",
  errMsg_phoneNumber: "",
  errMsg_companyName: "",
  errMsg_street: "",
  errMsg_postal: "",
  errMsg_city: "",
  errMsg_country: "",
};

export const getServerSideErrors = (errors) => {
  let updErrorStatus = errorStatus_initial;
  let updErrorMsg = errorMsg_initial;
  errors.forEach((error) => {
    switch (error.msg) {
      case "Old password is required":
        updErrorStatus = { ...updErrorStatus, err_curr_password: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_curr_password: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Wrong password":
        updErrorStatus = { ...updErrorStatus, err_curr_password: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_curr_password: translate("cUserProfile.wrongPw", false, null) };
        break;
      case "New password is required":
        updErrorStatus = { ...updErrorStatus, err_password: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_password: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "New password should be at least 8 characters":
        updErrorStatus = { ...updErrorStatus, err_password: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_password: translate("cUserProfile.pwMinChars", false, null) };
        break;
      case "Email is already registered":
        updErrorStatus = { ...updErrorStatus, err_email: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_email: translate("cUserProfile.emailAlreadyExists", false, null) };
        break;
      case "Please submit a valid email":
        updErrorStatus = { ...updErrorStatus, err_email: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_email: translate("cUserProfile.provideValidEmail", false, null) };
        break;
      case "Please submit a valid phone number":
        updErrorStatus = { ...updErrorStatus, err_email: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_email: translate("cUserProfile.provideValidPhonenumber", false, null) };
        break;
      case "Please submit a valid postal code":
        updErrorStatus = { ...updErrorStatus, err_email: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_email: translate("cUserProfile.provideValidPostalcode", false, { postalExample: "" }) };
        break;
      case "First name is required":
        updErrorStatus = { ...updErrorStatus, err_firstName: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_firstName: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Last name is required":
        updErrorStatus = { ...updErrorStatus, err_lastName: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_lastName: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Phone number is required":
        updErrorStatus = { ...updErrorStatus, err_phoneNumber: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_phoneNumber: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Company name is required":
        updErrorStatus = { ...updErrorStatus, err_companyName: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_companyName: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Street address is required":
        updErrorStatus = { ...updErrorStatus, err_street: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_street: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Postal / ZIP code is required":
        updErrorStatus = { ...updErrorStatus, err_postal: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_postal: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "City is required":
        updErrorStatus = { ...updErrorStatus, err_city: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_city: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      case "Country name is required":
        updErrorStatus = { ...updErrorStatus, err_country: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, errMsg_country: translate("cUserProfile.cannotBeEmpty", false, null) };
        break;
      default:
        setAlert(translate("cUserProfile.serverErrorGeneral", false, null), "danger");
        break;
    }
  });
  return { updErrorStatus, updErrorMsg };
};
