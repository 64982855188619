export const cDnsNameservers = {
  provideValidNs: {
    en: "Please provide a valid nameserver",
    nl: "Specificeer aub een valide nameserver",
  },
  provideValidIp: {
    en: "Please provide a valid IP address",
    nl: "Specificeer aub een valide IP adres",
  },
  selectNameserversFor: {
    en: "Select the nameservers you want to use for",
    nl: "Selecteer de nameservers die u wilt gebruiken voor",
  },
  defaultNs: {
    en: "Satonda nameservers (default)",
    nl: "Satonda nameservers (standaard)",
  },
  customNs: {
    en: "Set custom nameservers",
    nl: "Specificeer andere nameservers",
  },
  nameserver: {
    en: "Nameserver",
    nl: "Nameserver",
  },
  ipAddress: {
    en: "IP address (optional)",
    nl: "IP adres (optioneel)",
  },
  removeNs: {
    en: "Remove nameserver",
    nl: "Verwijder nameserver",
  },
  AddAddnlNs: {
    en: "Add additional nameserver",
    nl: "Voeg extra nameserver toe",
  },
  externalNsNoDnsViaUs: {
    en: "Please note that by moving to external nameservers, all other DNS records will be removed as Satonda will not be managing those anymore. You'll have to recreate those with your external DNS service.",
    nl: "Houd er rekening mee dat, door te veranderen naar externe nameservers, al uw andere DNS records verwijderd zullen worden aangezien Satonda deze niet meer beheert. U zal deze opnieuw moeten aanmaken bij uw externe DNS service.",
  },
  saveChanges: {
    en: "Save changes to nameservers",
    nl: "Bewaar nameserver veranderingen",
  },
};
