import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UnpaidInvoice from "../../components/workspace/billing/UnpaidInvoice";
import ShowInvoice from "../../components/workspace/billing/ShowInvoice";
import PendingPayments from "../../components/checkout/PendingPayments";

import { translate } from "../../translations/translations";

const Billing = ({ auth: { activeWorkspace } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pWsPages.billingOverview", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          <PendingPayments />
          <UnpaidInvoice />
          <ShowInvoice />
        </>
      )}
    </>
  );
};

Billing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Billing);
