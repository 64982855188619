import React from "react";

const DashboardItem = ({ colClass, title, titleClass, content }) => {
  return (
    <div className={`${colClass} p-2`}>
      <div className="flexSameHeight justify-content-center align-items-center shadow-light noHover rounded-3 p-3 trans-3">
        <h3 className={titleClass}>{title}</h3>
        <div className="toGrow w-100">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardItem;
