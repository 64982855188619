export const cCartOverdueInvoice = {
  overdueInvoice: {
    en: "Overdue invoice",
    nl: "Achterstallige rekening",
  },
  payableAtCheckout: {
    en: "Payable at checkout",
    nl: "Te betalen",
  },
  annualExtensionOfProduct: {
    en: "Annual extension of %productName% subscription",
    nl: "Jaarlijkse verlenging van %productName%",
  },
  removeItemFromCart: {
    en: "Remove item from cart",
    nl: "Verwijder uit winkelwagentje",
  },
};
