export const aEmailMarketing = {
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  retrievingProjects: {
    en: "Retrieving emailbuilder projects..",
    nl: "Ophalen emailbuilder projecten..",
  },
  creatingProject: {
    en: "Creating emailbuilder project..",
    nl: "Aanmaken emailbuilder project..",
  },
  projectCreated: {
    en: "Emailbuilder project has been created!",
    nl: "Emailbuilder project aangemaakt!",
  },
  updatingProjectName: {
    en: "Updating project name..",
    nl: "Bijwerken project naam..",
  },
  updatedProjectName: {
    en: "Project name updated!",
    nl: "Project naam bijgewerkt!",
  },
  deletingProject: {
    en: "Deleting project..",
    nl: "Verwijderen project..",
  },
  projectDeleted: {
    en: "Project deleted!",
    nl: "Project verwijderd!",
  },
  duplicatingProject: {
    en: "Duplicating project..",
    nl: "Dupliceren project..",
  },
  projectDuplicated: {
    en: "Project duplicated!",
    nl: "Project gedupliceerd!",
  },
  retrievingPendingCampaigns: {
    en: "Retrieving pending email campaigns..",
    nl: "Ophalen geplande email campagnes..",
  },
  retrievingCampaigns: {
    en: "Retrieving email campaigns..",
    nl: "Ophalen email campagnes..",
  },
  creatingCampaign: {
    en: "Creating and scheduling email marketing campaign..",
    nl: "Opstellen email marketing campagne",
  },
  campaignCreated: {
    en: "Email marketing campaign has been created!",
    nl: "Email marketing campagne opgesteld!",
  },
  cancellingCampaign: {
    en: "Cancelling email campaign..",
    nl: "Annuleren email campagne..",
  },
  campaignCancelled: {
    en: "Email campaign cancelled!",
    nl: "Email campagne geannuleerd!",
  },
};
