import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Transfer from "../../../../components/products/domains/Transfer";

import { translate } from "../../../../translations/translations";

const DomainTransfer = ({ auth: { user } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pDomainsTransfer.transferExistingDomain", false, null)}</h1>
      {user === null ? <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p> : <Transfer />}
    </>
  );
};

DomainTransfer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(DomainTransfer);
