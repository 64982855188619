import React from "react";
import { Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";

import { translate } from "../../../translations/translations";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import FormatDate from "../../layout/FormatDate";
import FormatNumber from "../../layout/FormatNumber";
import { PRODUCT_NAME_CREDIT } from "../../products/lib/ProductVars";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "rgb(255, 255, 255)",
    padding: 25,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  col: {
    flex: "0 0 auto",
    // flexDirection: "column",
  },
  col2: {
    width: "50%",
  },
  col3: {
    width: "33.3%",
  },
  col4: {
    width: "25%",
  },
  col5: {
    width: "20%",
  },
  space: {
    marginTop: 20,
  },
  logo: {
    width: 100,
  },
  invoicePeriod: {
    fontSize: 12,
  },
  normalText: {
    fontFamily: "Roboto",
    fontSize: 9,
    fontWeight: "light",
  },
  textMargin: {
    marginBottom: 3,
  },
  bold: {
    fontWeight: "bold",
  },
  tableWrapper: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgb(229, 42, 5)",
    width: "100%",
    borderRadius: "4pt",
    overflow: "hidden",
  },
  tableRowPadding: {
    paddingHorizontal: 3,
    paddingVertical: 4,
  },
  tableHeader: {
    backgroundColor: "rgb(229, 42, 5)",
    color: "#fff",
  },
  subtotalRow: {
    backgroundColor: "rgba(229, 42, 5, 0.5)",
    color: "#fff",
  },
});

// Google Font files: https://gist.github.com/karimnaaji/b6c9c9e819204113e9cabf290d580551
Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://fonts.gstatic.com/s/roboto/v15/dtpHsbgPEm2lVWciJZ0P-A.ttf", fontStyle: "normal", fontWeight: 300 },
    { src: "https://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf", fontStyle: "normal", fontWeight: 700 },
  ],
});

const InvoicePDF = ({ data }) => {
  return (
    <Page size="A4" style={styles.page}>
      {/* Logo */}
      <View style={styles.row}>
        <Image src="/assets/img/satonda_gradient.png" style={styles.logo} />
      </View>
      {/* Invoice title */}
      <View style={{ ...styles.row, ...styles.space }}>
        <Text style={styles.invoicePeriod}>{data.invoiceHeader}</Text>
      </View>
      {/* From & invoice details */}
      <View style={{ ...styles.row, ...styles.space }}>
        <View style={{ ...styles.col, ...styles.col2 }}>
          <Text style={{ ...styles.normalText, ...styles.textMargin, ...styles.bold }}>{data.from.from}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.from.satondaName}</Text>
          {/* <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.from.satondaStreet}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.from.satondaPostalCityCountry}</Text> */}
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.from.satondaVatNumber}</Text>
        </View>
        <View style={{ ...styles.col, ...styles.col2 }}>
          <Text style={{ ...styles.normalText, ...styles.textMargin, ...styles.bold }}>{data.invoiceDetails.invoiceDetails}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.invoiceDetails.invoiceRef}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.invoiceDetails.invoiceDate}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.invoiceDetails.dueDate}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.invoiceDetails.paymentStatus}</Text>
        </View>
      </View>
      {/* Billing details */}
      <View style={{ ...styles.row, ...styles.space }}>
        <View style={styles.col}>
          <Text style={{ ...styles.normalText, ...styles.textMargin, ...styles.bold }}>{data.billingDetails.billingDetails}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>
            {data.billingDetails.wsName} (ID: {data.billingDetails.wsId})
          </Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.billingDetails.customerName}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.billingDetails.email}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.billingDetails.street}</Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>
            {data.billingDetails.postal}, {data.billingDetails.city}
          </Text>
          <Text style={{ ...styles.normalText, ...styles.textMargin }}>{data.billingDetails.country}</Text>
        </View>
      </View>
      {/* Invoice table */}
      <View style={{ ...styles.row, ...styles.space }}>
        {/* Wrapper for border */}
        <View style={{ ...styles.row, ...styles.tableWrapper }}>
          {/* Header */}
          <View style={{ ...styles.row, ...styles.tableRowPadding, ...styles.tableHeader }}>
            <View style={{ ...styles.col, ...styles.col2 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>{translate("cWsBilling.item", false, null)}</Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>{translate("cWsBilling.period", false, null)}</Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>{translate("cWsBilling.invoiceAmount", false, null)}</Text>
            </View>
          </View>
          {/* Invoice items */}
          {data.invoiceItemsToRender.map((invoice, i) => (
            <View style={{ ...styles.row, ...styles.tableRowPadding }} key={`invoiceItemsToRender_${i}`}>
              <View style={{ ...styles.col, ...styles.col2 }}>
                <Text style={{ ...styles.normalText }}>
                  {capitalizeFirstLetter(invoice.productName)} - {capitalizeFirstLetter(invoice.packageName)}
                </Text>
              </View>
              <View style={{ ...styles.col, ...styles.col4 }}>
                <Text style={{ ...styles.normalText }}>
                  {invoice.productName !== PRODUCT_NAME_CREDIT && (
                    <>
                      <FormatDate dateString={invoice.fromDate} lang={data.language} formatStr={"dmmyy"} /> -{" "}
                      <FormatDate dateString={invoice.toDate} lang={data.language} formatStr={"dmmyy"} />
                    </>
                  )}
                </Text>
              </View>
              <View style={{ ...styles.col, ...styles.col4 }}>
                <Text style={{ ...styles.normalText }}>
                  {data.invoiceCurrency} <FormatNumber number={invoice.invoiceAmount} formatStr={"0.00"} />
                </Text>
              </View>
            </View>
          ))}
          {/* Subtotal row */}
          <View style={{ ...styles.row, ...styles.tableRowPadding, ...styles.subtotalRow }}>
            <View style={{ ...styles.col, ...styles.col2 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>{translate("cWsBilling.totalExcVat", false, { tax: "VAT" })}</Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}></Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>
                {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat} formatStr={"0.00"} />
              </Text>
            </View>
          </View>
          {/* VAT row */}
          <View style={{ ...styles.row, ...styles.tableRowPadding }}>
            <View style={{ ...styles.col, ...styles.col2 }}>
              <Text style={{ ...styles.normalText }}>
                {translate("cWsBilling.vat", false, { tax: "VAT" })} (<FormatNumber number={data.vatRate} formatStr={"0.00%"} />)
              </Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText }}></Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText }}>
                {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat * data.vatRate} formatStr={"0.00"} />
              </Text>
            </View>
          </View>
          {/* Total invoice amound */}
          <View style={{ ...styles.row, ...styles.tableRowPadding, ...styles.tableHeader }}>
            <View style={{ ...styles.col, ...styles.col2 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>{translate("cWsBilling.totalInvoicedAmount", false, null)}</Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}></Text>
            </View>
            <View style={{ ...styles.col, ...styles.col4 }}>
              <Text style={{ ...styles.normalText, ...styles.bold }}>
                {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat * (1 + data.vatRate)} formatStr={"0.00"} />
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default InvoicePDF;
