import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AllWorkspaces from "../../components/user/workspaces/AllWorkspaces";
import CreateWorkspace from "../../components/user/workspaces/CreateWorkspace";

import { translate } from "../../translations/translations";

const UserWorkspaces = ({ auth: { user } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pUserPages.manageyourWorkspaces", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : (
        <>
          <AllWorkspaces />
          <CreateWorkspace />
        </>
      )}
    </>
  );
};

UserWorkspaces.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UserWorkspaces);
