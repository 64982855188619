import store from "../store";

import { getBrandData } from "../actions/brandProducts";
import { getSbProjects } from "../actions/sitebuilder";
import { getEbProjects, getPendingEmailCampaigns, getExecutedEmailCampaigns } from "../actions/emailMarketing";
import { getWsAudiences } from "../actions/audiences";
import { getFxRates } from "../actions/auth";

import { PRODUCT_NAMES } from "../components/products/lib/ProductVars";
import { workspaceHasProduct } from "../components/products/lib/ProductFunctions";
import { countries } from "./Countries";

export const loadAllData = (activeWorkspace) => {
  store.dispatch(getBrandData(activeWorkspace.brandId));
  // store.dispatch(getWorkspacePendingPayments()); // TODO: Rewrite to workspace API route
  store.dispatch(getFxRates());
  if (workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder)) {
    store.dispatch(getSbProjects());
  }
  if (workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.emailMarketing)) {
    store.dispatch(getEbProjects());
    store.dispatch(getPendingEmailCampaigns());
    store.dispatch(getExecutedEmailCampaigns());
  }
  if (
    workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder) ||
    workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.emailMarketing)
  ) {
    store.dispatch(getWsAudiences());
  }
};

export const validateEmail = (email) => {
  if (typeof email !== "string") {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  let regex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;
  let emailParts = email.split("@");
  if (emailParts.length !== 2) return false;
  let account = emailParts[0];
  let address = emailParts[1];
  if (account.length > 64) return false;
  else if (address.length > 255) return false;
  let domainParts = address.split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;
  if (!regex.test(email)) return false;
  return true;
};

export const validatePhoneNumber = (str) => {
  return str.match(/^\+[1-9]\d{0,2}[.]\d{4,14}$/);
};

export const validatePostalcode = (countryCode, postalCode) => {
  try {
    const postalRegex = countries.filter((c) => c.code === countryCode)[0].postalRegex;
    const re = new RegExp(postalRegex);
    return postalCode.match(re);
  } catch (error) {
    return false;
  }
};

export const getExamplePostalcode = (countryCode) => {
  try {
    const postalExample = countries.filter((c) => c.code === countryCode)[0].postalExample;
    if (postalExample === null || typeof postalExample === "undefined") {
      return "No postal code example found";
    }
    return postalExample;
  } catch (error) {
    return "No postal code example found";
  }
};

export const makeAndDownloadFile = (fileName, stringContent, mimeType, fileExt) => {
  const blob = new Blob([stringContent], { type: mimeType });
  const link = document.createElement("a");
  document.body.append(link);
  link.href = URL.createObjectURL(blob);
  link.target = "_blank";
  link.download = `${fileName}.${fileExt}`;
  link.click();
  link.remove();
};

export const dateAddZeroBefore = (number, length = 2) => {
  number = number.toString();
  if (number.length < length) {
    let zeros = "";
    for (let i = 0; i < length - number.length; i++) {
      zeros += "0";
    }
    return `${zeros}${number}`;
  }
  return number;
};

export const arrayEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, i) => val === b[i]);
};

export const roundNumber = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
