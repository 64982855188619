import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setAlert } from "../../actions/alert";
import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";

import { translate } from "../../translations/translations";

const TicketCloseTicket = ({ showCloseTicket, setShowCloseTicket, ticketId, setLocalLoading, getUserTickets, setAlert }) => {
  const star05 = useRef();
  const star10 = useRef();
  const star15 = useRef();
  const star20 = useRef();
  const star25 = useRef();
  const star30 = useRef();
  const star35 = useRef();
  const star40 = useRef();
  const star45 = useRef();
  const star50 = useRef();

  const [feedbackStars, setFeedbackStars] = useState(-1);
  const [errorMsg_rating, setErrorMsg_rating] = useState("");

  const clickCloseTicket = async () => {
    if (checkErrors_closeTicket() === 0) {
      await closeTicket();
      setFeedbackStars(-1);
      setErrorMsg_rating("");
    }
  };

  const closeTicket = async () => {
    setLocalLoading({ isLoading: true, msg: translate("cSupportComponents.closingTicket", false, null) });
    try {
      const body = JSON.stringify({ ticketId, rating: stringifyFeedbackRating() });
      await axios.put(`${API_URL}/support/close`, body, POST_CONFIG);
      await getUserTickets();
      setAlert(translate("cSupportComponents.ticketHasBeenClosed", false, null), "success");
    } catch (error) {
      console.error(error);
      setAlert(translate("cSupportComponents.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const checkErrors_closeTicket = () => {
    setErrorMsg_rating("");
    let errors = 0;
    if (feedbackStars < 0) {
      setErrorMsg_rating(translate("cSupportComponents.provideARating", false, null));
      errors++;
    }
    return errors;
  };

  const stringifyFeedbackRating = () => {
    return ["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"][feedbackStars + 1];
  };

  const hoverStar = (i) => {
    [star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].forEach(
      (star, j) => (star.current.style.fill = i >= j ? "#FFD700" : "#ddd")
    );
  };

  const starsMouseOut = () => {
    // Reset to status as per state
    [star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].forEach(
      (star, j) => (star.current.style.fill = feedbackStars >= j ? "#FFD700" : "#ddd")
    );
  };

  return (
    showCloseTicket && (
      <div className="mt-5 mb-3 posRelative d-flex flex-column align-items-center">
        <p className="mb-2">{translate("cSupportComponents.howWouldYouRate", false, null)}</p>
        <div className="mb-3 d-inline-flex" onMouseOut={() => starsMouseOut()}>
          {[star05, star10, star15, star20, star25, star30, star35, star40, star45, star50].map((star, i) => (
            <svg
              key={`rating_star_${i}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 288 512"
              className="cursorPointer"
              style={{ height: "2rem", fill: "#ddd", transform: i % 2 === 0 ? "" : "scaleX(-1)" }}
              onMouseOver={() => hoverStar(i)}
              onClick={() => setFeedbackStars(i)}
              ref={star}
            >
              <path d="M288 0c-11.4 0-22.8 5.9-28.7 17.8L194 150.2 47.9 171.4c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.1 23 46 46.4 33.7L288 439.6V0z" />
            </svg>
          ))}
        </div>
        {errorMsg_rating !== "" && (
          <div className="invalid-feedback d-block text-center" style={{ position: "absolute", top: "80px" }}>
            {errorMsg_rating}
          </div>
        )}
        <div className={`d-flex w-100 ${errorMsg_rating === "" ? "mt-3" : "mt-4"}`}>
          <button className="btn btn-sm btn-gray px-3" onClick={() => setShowCloseTicket(false)}>
            {translate("cSupportComponents.cancel", false, null)}
          </button>
          <button className="btn btn-sm btn-success px-3 ms-auto" onClick={() => clickCloseTicket(ticketId)}>
            <i className="fa-solid fa-check me-2" />
            {translate("cSupportComponents.closeTicket", false, null)}
          </button>
        </div>
      </div>
    )
  );
};

TicketCloseTicket.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

//   const mapStateToProps = (state) => ({
//     auth: state.auth,
//   });

export default connect(null, { setAlert })(TicketCloseTicket);
