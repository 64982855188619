export const pEmExecutedCampaigns = {
  reviewStats: {
    en: "Review email campaign statistics",
    nl: "Bekijk email campagne statistieken",
  },
  noCampaignsFound: {
    en: "No executed email campaigns found.",
    nl: "Geen uitgevoerde email campagnes gevonden",
  },
  campaignName: {
    en: "Campaign name",
    nl: "Campagne naam",
  },
  subjectLine: {
    en: "Email subject line",
    nl: "Email onderwerp",
  },
  dateTime: {
    en: "Date and time",
    nl: "Datum en tijd",
  },
  numRecipients: {
    en: "# recipients",
    nl: "# ontvangers",
  },
  reactive: {
    en: "Reactive",
    nl: "Reactief",
  },
  seeStats: {
    en: "See statistics",
    nl: "Bekijk statistieken",
  },
  seeAudit: {
    en: "See audit details",
    nl: "Bekijk audit informatie",
  },
};
