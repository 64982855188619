export const pSbOthers = {
  manageSbProjects: {
    en: "Manage your sitebuilder projects",
    nl: "Beheer uw sitebuilder projecten",
  },
  projectNotFound: {
    en: "Project not found.",
    nl: "Project niet gevonden.",
  },
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
};
