import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { subIsCancelled, sortByRenewDate } from "./lib/subscriptionFunctions";

import SubRow from "./SubRow";
import { translate } from "../../../translations/translations";

const AllSubs = ({ auth: { activeWorkspace } }) => {
  const [activeSubs, setActiveSubs] = useState([]);

  useEffect(() => {
    if (activeWorkspace !== null) {
      setActiveSubs(activeWorkspace.subscriptions.filter((sub) => !subIsCancelled(sub)).sort(sortByRenewDate));
    }
  }, [activeWorkspace]);

  return (
    <>
      <h1 className="text-primary">{translate("cWsSubs.subsOverview", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : activeSubs.length === 0 ? (
        <p className="text-italic m-0">{translate("cWsSubs.noActiveSubs", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col">{translate("cWsSubs.subscription", false, null)}</th>
                <th scope="col">{translate("cWsSubs.quotas", false, null)}</th>
                <th scope="col">{translate("cWsSubs.billingDetails", false, null)}</th>
                <th scope="col">{translate("cWsSubs.actions", false, null)}</th>
              </tr>
            </thead>
            <tbody>
              {activeSubs.map((sub) => (
                <SubRow key={sub._id} sub={sub} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

AllSubs.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AllSubs);
