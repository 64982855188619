export const pDomainsMain = {
  whyUs: {
    en: "Why register your domain name with Satonda",
    nl: "Waarom uw domeinnamen via Satonda registreren",
  },
  broadChoice: {
    en: "Broad choice",
    nl: "Brede keuze",
  },
  broadChoiceTag: {
    en: "We offer more than 500 domain extensions, so you can find the perfect domain name that fits your needs!",
    nl: "Wij bieden meer dan 500 domein extensies aan, zodat u de domeinnaam kan vinden die perfect bij u past!",
  },
  price: {
    en: "Best price",
    nl: "Beste prijs",
  },
  priceTag: {
    en: "We believe securing your spot on the internet should be affordable and pricing be transparent. Therefore, we offer low prices and no price surprises after renewal!",
    nl: "Wij geloven dat vastleggen van uw plek op het internet betaalbaar en transparant moet zijn. Daarom bieden wij lage prijzen zonder verassingen na verlenging!",
  },
  easeOfUse: {
    en: "Ease of use",
    nl: "Eenvoudig",
  },
  easeOfUseTag: {
    en: "The registration process is simple and fast. Afterwards, we take care of connecting your domain to the internet.",
    nl: "Het registratieproces is eenvoudig en snel. Na registratie zorgen wij ervoor dat uw domein verbonden wordt met het internet.",
  },
  privacy: {
    en: "Privacy ensured",
    nl: "Privacy gewaarborgd",
  },
  privacyTag: {
    en: "We always shield your personal information from the public's eye, at no additional cost!",
    nl: "Wij schermen altijd uw persoonlijke informatie af, zonder extra kosten!",
  },
  readMore: {
    en: "Read more",
    nl: "Lees meer",
  },
};
