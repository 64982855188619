export const cDomainChecker = {
  checkingAvailability: {
    en: "Checking availability..",
    nl: "Beschikbaarheid aan het checken..",
  },
  isInCart: {
    en: "Domain name is in your cart!",
    nl: "Domeinnaam staat in uw winkelwagentje!",
  },
  clickAddToCart: {
    en: "Click to add to cart!",
    nl: "Voeg toe aan winkelwagentje!",
  },
  domainTaken: {
    en: "Domain already taken",
    nl: "Domeinnaam is bezet",
  },
  prices1year: {
    en: "Prices are for 1 year periods.",
    nl: "Prijzen zijn per jaar",
  },
  errorOccured: {
    en: "An error occured. Please try again later.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  exampleCom: {
    en: "example.com",
    nl: "voorbeeld.nl",
  },
  domainInputField: {
    en: "Domain name input field",
    nl: "Domeinnaam invoerveld",
  },
  checkAvailability: {
    en: "Check availability",
    nl: "Check beschikbaarheid",
  },
  findDomain: {
    en: "Find domain",
    nl: "Vind domein",
  },
  closeSuggestions: {
    en: "Close suggestions",
    nl: "Verberg suggesties",
  },
  clear: {
    en: "Clear",
    nl: "Wis",
  },
};
