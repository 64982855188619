import React from "react";

import { formatNumber } from "../../../lib/formattingFunctions";

const TldCardMini = ({ tld, boolHighlight, priceFirstYear, priceRenew, currency, highlightTxt, firstYearTxt, renewsTxt }) => {
  return (
    <div className="col-6 col-md-4 col-lg-6 col-xl-4 d-flex p-2 h-100">
      <div className={`h-100 w-100 overflowHidden shadow-light trans-3 rounded-3 bg-light-light ${boolHighlight ? "border border-primary" : ""}`}>
        <div
          className={`w-100 ${
            boolHighlight ? "bg-satonda" : "bg-light-light"
          } px-2 py-2 d-flex align-items-center justify-content-center text-bold text-white`}
          style={{ height: "1.5rem", fontSize: "0.7rem" }}
        >
          {boolHighlight ? highlightTxt : ""}
        </div>
        <div className="px-2 py-2 flexSameHeight align-items-center">
          <h1 className="h5 text-secondary text-bold mb-0">{tld}</h1>
          <p className="mt-2 mb-0 fontSize09">{firstYearTxt}:</p>
          <p className="mt-2 mb-0 text-satonda h4 text-bold toGrow">
            {currency.currencySign}
            {formatNumber(priceFirstYear, "0.00")}
          </p>
          <p className="mt-2 mb-0 fontSize09">
            {renewsTxt} {currency.currencySign}
            {formatNumber(priceRenew, "0.00")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TldCardMini;
