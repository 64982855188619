export const pUserPages = {
  profile: {
    en: "Profile",
    nl: "Profiel",
  },
  yourNotifications: {
    en: "Your notifications",
    nl: "Uw berichten",
  },
  manageyourWorkspaces: {
    en: "Manage your workspaces",
    nl: "Beheer uw workspaces",
  },
};
