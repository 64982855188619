import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setGlobalLoading, removeGlobalLoading } from "../../../actions/globalLoading";
import { setAlert } from "../../../actions/alert";
import { loadUser } from "../../../actions/auth";

import { POST_CONFIG, API_URL } from "../../../lib/GeneralVars";

import { translate } from "../../../translations/translations";

const UpdateEmailPreferences = ({ auth: { user }, setGlobalLoading, removeGlobalLoading, setAlert, loadUser }) => {
  const [checkProductUpdates, setCheckProductUpdates] = useState(true);
  const [checkPromotions, setCheckPromotions] = useState(true);

  useEffect(() => {
    populateCheckboxes();
    // eslint-disable-next-line
  }, [user]);

  const populateCheckboxes = () => {
    if (user !== null && typeof user.emailPreferences !== "undefined") {
      setCheckProductUpdates(user.emailPreferences.productUpdates);
      setCheckPromotions(user.emailPreferences.promotions);
    }
  };

  const clickUpdateEmailPrefs = async () => {
    let loadingId = setGlobalLoading(translate("cUserProfile.updatingEmailPrefs", false, null));
    try {
      const body = JSON.stringify({ checkProductUpdates, checkPromotions });
      await axios.post(`${API_URL}/users/update/emailPreferences`, body, POST_CONFIG);
      await loadUser();
      setAlert(translate("cUserProfile.emailPrefsUpdated", false, null), "success");
    } catch (error) {
      console.error(error);
      setAlert(translate("cUserProfile.serverErrorUpdatingEmailPrefs", false, null), "danger");
    }
    removeGlobalLoading(loadingId);
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-0">{translate("cUserProfile.updateEmailPreferences", false, null)}</h2>
      {/* Product updates */}
      <div className="form-check mt-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="checkProductUpdates"
          checked={checkProductUpdates}
          onChange={(e) => setCheckProductUpdates(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="checkProductUpdates">
          {translate("cUserProfile.receiveEmailsProducts", false, null)}
        </label>
      </div>
      {/* Promotions */}
      <div className="form-check mt-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="checkPromotions"
          checked={checkPromotions}
          onChange={(e) => setCheckPromotions(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="checkPromotions">
          {translate("cUserProfile.receiveEmailsPromos", false, null)}
        </label>
      </div>
      {/* Submit button */}
      <button className="btn btn-primary w-100 mt-3" onClick={clickUpdateEmailPrefs}>
        {translate("cUserProfile.updateEmailPreferences", false, null)}
      </button>
    </>
  );
};

UpdateEmailPreferences.propTypes = {
  auth: PropTypes.object.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setGlobalLoading, removeGlobalLoading, setAlert, loadUser })(UpdateEmailPreferences);
