import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { translate } from "../../../../translations/translations";
import { SB_URL } from "../../../../lib/GeneralVars";

const SitebuilderProjectMenu = ({ auth: { token, activeWorkspace } }) => {
  let { projectId } = useParams();
  let { domainName } = useParams();
  let { dir } = useParams();

  return (
    <div className="row">
      <div className="col-12 col-lg-9 col-xl-6">
        {token !== null && projectId !== "" && domainName !== "" ? (
          <div style={{ position: "relative", width: "100%" }}>
            <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9 }}></div>
            <iframe
              src={`${SB_URL}/publish/${token}/${activeWorkspace._id}/${projectId}/${domainName}/${dir}`}
              style={{ width: "100%", minHeight: "50vh", overflow: "hidden" }}
              id="iframepublishsite"
              title="Publish site status"
            ></iframe>
          </div>
        ) : (
          <p className="text-italic">{translate("pSbOthers.errorOccured", false, null)}</p>
        )}
      </div>
    </div>
  );
};

SitebuilderProjectMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, null)(SitebuilderProjectMenu);
