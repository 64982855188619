import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { translate } from "../../../translations/translations";
import { getTldFromDomain } from "../../products/domains/lib/domainsFunctions";
import { getChartColors } from "../lib/dashboardFunctions";
import DashboardErrorItem from "../DashboardErrorItem";

ChartJS.register(ArcElement, Tooltip, Legend);

const StorageUsage = ({ auth: { activeWorkspace } }) => {
  const [domainData, setDomainData] = useState([]);

  useEffect(() => {
    getDomainData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getDomainData = () => {
    try {
      const allTlds = activeWorkspace.domains.filter((d) => d.managed).map((d) => `.${getTldFromDomain(d.domainName)}`);
      const uniqueTlds = [...new Set(allTlds)];
      setDomainData(
        uniqueTlds
          .map((uniqueTld) => ({ tld: uniqueTld, count: allTlds.filter((tld) => tld === uniqueTld).length }))
          .sort((a, b) => b.count - a.count)
      );
    } catch (error) {
      setDomainData([]);
    }
  };

  const data = {
    labels: domainData.map((d) => d.tld),
    datasets: [
      {
        label: "Domains",
        data: domainData.map((d) => d.count),
        backgroundColor: getChartColors(domainData.length, "0.2"),
        borderColor: getChartColors(domainData.length, "1"),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    plugins: {
      legend: {
        display: true,
      },
      // tooltip: {
      //   callbacks: {
      //     label: cbLabel,
      //     // label: (context) => {
      //     //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
      //     // },
      //     title: (context) => {
      //       return "";
      //     },
      //   },
      // },
    },
  };

  return domainData.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noDomainsYet", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

StorageUsage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(StorageUsage);
