export const cModalRecipientEmail = {
  recipientNotFound: {
    en: "Recipient not found",
    nl: "Ontvanger niet gevonden",
  },
  from: {
    en: "From",
    nl: "Van",
  },
  to: {
    en: "To",
    nl: "Aan",
  },
  sent: {
    en: "Sent",
    nl: "Verzonden",
  },
  subject: {
    en: "Subject",
    nl: "Onderwerp",
  },
  emailSentTo: {
    en: "Email sent to",
    nl: "Email verzonden naar",
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
  printToPdf: {
    en: "Print to PDF",
    nl: "Print naar PDF",
  },
};
