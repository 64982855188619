import React, { useState } from "react";

import TicketHeader from "./TicketHeader";
import TicketReference from "./TicketReference";
import TicketDesc from "./TicketDesc";
import TicketResponses from "./TicketResponses";
import TicketActions from "./TicketActions";
import TicketCloseTicket from "./TicketCloseTicket";
import TicketAddResponse from "./TicketAddResponse";

import { translate } from "../../translations/translations";

const ListTickets = ({ tickets, status, setLocalLoading, getUserTickets, lang }) => {
  const [showCloseTicket, setShowCloseTicket] = useState(false);
  const [showRespondTicket, setShowRespondTicket] = useState(false);

  return tickets.length === 0 ? (
    <p className="m-0 text-italic">{translate("cSupportComponents.noTicketsToShow", false, null)}</p>
  ) : (
    <div className="accordion" id={`accordion_${status}`}>
      {tickets.map((ticket, i) => (
        <div className="accordion-item" key={ticket._id}>
          <TicketHeader ticket={ticket} status={status} i={i} lang={lang} />
          <div
            id={`collapse_${status}_${i}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading_${status}_${i}`}
            data-bs-parent={`#accordion_${status}`}
          >
            <div className="accordion-body fontSize09">
              <TicketReference ticketId={ticket._id} />
              <TicketDesc ticket={ticket} />
              <TicketResponses responses={ticket.responses} ticketId={ticket._id} lang={lang} />
              <TicketActions
                ticket={ticket}
                showCloseTicket={showCloseTicket}
                showRespondTicket={showRespondTicket}
                setShowCloseTicket={setShowCloseTicket}
                setShowRespondTicket={setShowRespondTicket}
                lang={lang}
              />
              <TicketCloseTicket
                showCloseTicket={showCloseTicket}
                setShowCloseTicket={setShowCloseTicket}
                ticketId={ticket._id}
                setLocalLoading={setLocalLoading}
                getUserTickets={getUserTickets}
              />
              <TicketAddResponse
                showRespondTicket={showRespondTicket}
                setShowRespondTicket={setShowRespondTicket}
                ticketId={ticket._id}
                setLocalLoading={setLocalLoading}
                getUserTickets={getUserTickets}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListTickets;
