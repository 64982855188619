import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import TldCardMini from "./TldCardMini";

import { isValidDomain } from "../../products/domains/lib/domainsFunctions";
import { translate } from "../../../translations/translations";
import { getDomainActionPrice } from "../../products/domains/lib/domainsFunctions";
import { getCurrency } from "../../../lib/Currencies";

const RegisterNewDomain = ({ auth: { activeWorkspace, fxRates }, brandProducts: { TLDs } }) => {
  const navigate = useNavigate();

  const HIGHLIGHTED_TLDS = [
    { tld: "nl", boolHighlight: true },
    { tld: "nu", boolHighlight: false },
    { tld: "be", boolHighlight: false },
    { tld: "com", boolHighlight: true },
    { tld: "shop", boolHighlight: false },
    { tld: "blog", boolHighlight: false },
  ];

  const [domainInput, setDomainInput] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [tldsToRender, setTldsToRender] = useState([]);
  const [currencyObj, setCurrencyObj] = useState({ currencyCode: "EUR", currencySign: "€" });

  useEffect(() => {
    try {
      const currency = getCurrency(activeWorkspace.location.priceCountry);
      setCurrencyObj(currency);

      const higlightedTlds = HIGHLIGHTED_TLDS.map((tld) => tld.tld);
      const tldData = TLDs.filter((tld) => higlightedTlds.includes(tld.tld)).map((tld) => ({
        ...tld,
        boolHighlight: HIGHLIGHTED_TLDS.filter((hiTld) => hiTld.tld === tld.tld)[0].boolHighlight,
        register: getDomainActionPrice(tld, "register", currency.currencyCode, fxRates),
        renew: getDomainActionPrice(tld, "renew", currency.currencyCode, fxRates),
      }));
      // Sort in same sequence as HIGHLIGHTED_TLDS
      setTldsToRender(HIGHLIGHTED_TLDS.map((tld) => ({ ...tld, ...tldData.filter((tldDataItem) => tldDataItem.tld === tld.tld)[0] })));
    } catch (error) {
      setTldsToRender([]);
    }
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const onType = (text) => {
    setDomainInput(text);
    setBtnDisabled(!isValidDomain(text));
  };

  const clearField = () => {
    setDomainInput("");
    setBtnDisabled(true);
  };

  const onClickSearch = () => {
    navigate(`/domains?check=${domainInput}`, { replace: true });
  };

  return (
    <div className="p-2 w-100 h-100">
      {/* Check availability */}
      <div className={`posRelative mt-2`}>
        <span className="posAbs d-flex align-items-center h-100 z-9 ms-2" style={{ width: "40px" }}>
          www.
        </span>
        <div className="input-group rounded trans-3">
          <input
            type="text"
            className="form-control"
            id="domainCheckerInputBox"
            // width of "www." = 40px + padding
            style={{ paddingLeft: "50px" }}
            placeholder={translate("cDomainChecker.exampleCom", false, null)}
            aria-label={translate("cDomainChecker.domainInputField", false, null)}
            value={domainInput}
            onChange={(e) => onType(e.target.value)}
          />
          <div className="input-group-append posRelative">
            <span
              className={`posAbs d-flex align-items-center h-100 z-9 text-danger ${
                domainInput === "" ? "opacity-0" : "opacity-1 cursorPointer"
              }  trans-3`}
              title={domainInput === "" ? undefined : translate("cDomainChecker.clear", false, null)}
              onClick={() => clearField()}
              style={{ left: "-20px" }}
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
            <button className="btn btn-outline-primary" type="button" disabled={btnDisabled} onClick={onClickSearch}>
              {translate("cDomainChecker.checkAvailability", false, null)}
            </button>
          </div>
        </div>
      </div>

      {/* TLD cards */}
      <div className="row mt-4">
        {tldsToRender.length > 0 &&
          tldsToRender.map((tld) => (
            <TldCardMini
              key={tld._id}
              tld={tld.tld}
              boolHighlight={tld.boolHighlight}
              priceFirstYear={tld.register}
              priceRenew={tld.renew}
              currency={currencyObj}
              highlightTxt={translate("cTldCard.highlightTxt", false, null)}
              firstYearTxt={translate("cTldCard.firstYearTxt", false, null)}
              renewsTxt={translate("cTldCard.renewsTxt", false, null)}
            />
          ))}
      </div>
    </div>
  );
};

RegisterNewDomain.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(RegisterNewDomain);
