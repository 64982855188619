export const pEmCampaignStats = {
  campaignNotFound: {
    en: "Campaign not found.",
    nl: "Campagne niet gevonden.",
  },
  notFound: {
    en: "Not found",
    nl: "Niet gevonden",
  },
  statsFor: {
    en: "Statistics for",
    nl: "Statistieken voor",
  },
  selectEmailVersion: {
    en: "Select email version",
    nl: "Selecteer email versie",
  },
  version: {
    en: "Version",
    nl: "Versie",
  },
  noResultsFound: {
    en: "No results found",
    nl: "Geen resultaten gevonden",
  },
  deliveryInteraction: {
    en: "Delivery & interaction",
    nl: "Verzending & interactie",
  },
  versionsReceived: {
    en: "Versions received",
    nl: "Versies ontvangen",
  },
  timesOpened: {
    en: "Times received email has been opened",
    nl: "Keren een ontvangen email is geopend",
  },
  numRecipientsOpenedTimes: {
    en: "%numRecipients% recipients have opened the email %numOpened% times",
    nl: "%numRecipients% ontvangers hebben de email %numOpened% keer geopend",
  },
  timesClickedLink: {
    en: "Times recipient opened and clicked on a link",
    nl: "Keren de ontvanger de email heeft geopend en op een link heeft geklikt",
  },
  numRecipientsClickedLinks: {
    en: "%numRecipients% recipients have clicked a link %numOpened% times",
    nl: "%numRecipients% ontvangers hebben %numOpened% keer op een link geklikt",
  },
  highestClickedLinks: {
    en: "Links with highest click rate",
    nl: "Links met de hoogste aantal kliks",
  },
  linkIdClickedTimes: {
    en: "Link ID %linkId% has been clicked %numClicked% times",
    nl: "Link ID %linkId% is %numClicked% keer geklikt",
  },
};
