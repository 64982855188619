import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckout from "../../components/checkout/StripeCheckout";

import { isUnpaid, ORIGIN_OVERDUE } from "../../components/workspace/billing/lib/invoiceFunctions";

import FormatNumber from "../../components/layout/FormatNumber";

import { getTotalAmountAtCheckoutIncVat } from "../../components/products/lib/ProductFunctions";
import { getCurrencySign } from "../../lib/Currencies";
import { translate } from "../../translations/translations";
import { getStripeElementsOptions } from "../../lib/StripeLib";
import { API_URL } from "../../lib/GeneralVars";

// const stripePromise = loadStripe(
//   process.env.NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_PUBLISH_KEY_PROD : process.env.REACT_APP_STRIPE_PUBLISH_KEY_TEST
// );
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY_PROD);

// Looks a bit awkward, but reason is:
// - Need to check invoice is unpaid to prevent user seeing an already paid invoice and being able to pay twice
// - However, after payment of the invoice and return to the page via the return_url, the invoice will have shown to be paid
// - The <Elements /> Stripe component needs to render, for StripeCheckout to render and catch the redirect after the payment attempt

const Overdue = ({ auth: { activeWorkspace, user } }) => {
  let { invoiceId } = useParams();

  const [clientSecret, setClientSecret] = useState("");
  const [overdueInvoice, setOverdueInvoice] = useState(null);

  useEffect(() => {
    if (activeWorkspace !== null && invoiceId !== "") {
      setOverdueInvoice(
        activeWorkspace.invoices
          .filter((inv) => inv._id === invoiceId && isUnpaid(inv))
          .map((inv) => ({ ...inv, amountIncVat: getTotalAmountAtCheckoutIncVat(ORIGIN_OVERDUE, inv.items, inv.rateVAT) }))[0] || null
      );
    } else {
      setOverdueInvoice(null);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, invoiceId]);

  useEffect(() => {
    if (activeWorkspace !== null && invoiceId !== "") {
      // If payment page is loaded as a return after a payment submission, don't create a new payment intent
      // Rather, use returned payment intent and then redirect user to /status/:status page in StripeCheckout component
      // If payment page is being loaded from scratch, create new payment intent
      const existingClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
      if (existingClientSecret) {
        setClientSecret(existingClientSecret);
      } else {
        if (overdueInvoice !== null) {
          createPaymentIntent();
        }
      }
    }
    // eslint-disable-next-line
  }, [overdueInvoice]);

  const createPaymentIntent = async () => {
    const res = await fetch(`${API_URL}/stripe/createPaymentIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        wsId: activeWorkspace._id,
        invoiceId,
        name: `${user.firstName} ${user.lastName}`,
        email: activeWorkspace.email,
        customerId: typeof activeWorkspace.stripeCustomerId === "undefined" ? null : activeWorkspace.stripeCustomerId,
        vatRate: overdueInvoice.rateVAT,
        origin: ORIGIN_OVERDUE,
      }),
    });
    const data = await res.json();
    setClientSecret(data.clientSecret);
  };

  const options = getStripeElementsOptions(clientSecret, activeWorkspace, user);

  return (
    <>
      <h1 className="text-primary">{translate("pCheckoutPayment.checkout", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          {/* Stripe payment element */}
          {clientSecret && (
            <>
              {overdueInvoice === null || overdueInvoice.amountIncVat === 0 ? (
                <p className="text-italic">{translate("pCheckoutPayment.invoiceNotFound", false, null)}</p>
              ) : (
                <p className="text-secondary text-bold">
                  {translate("pCheckoutPayment.selectPaymentMethod", false, null)} {getCurrencySign(overdueInvoice.currency)}
                  <FormatNumber number={overdueInvoice.amountIncVat} formatStr={"0.00"} />:
                </p>
              )}

              <Elements options={options} stripe={stripePromise}>
                <StripeCheckout
                  options={options}
                  curr={overdueInvoice === null ? "" : getCurrencySign(overdueInvoice.currency)}
                  amount={overdueInvoice === null ? 0 : overdueInvoice.amountIncVat}
                  checkoutLocation={"overdue"}
                  invoiceId={invoiceId}
                />
              </Elements>
            </>
          )}
        </>
      )}
    </>
  );
};

Overdue.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Overdue);
