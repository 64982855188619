import React from "react";

import FormatDate from "../layout/FormatDate";
import BoxDesc from "./BoxDesc";
import BoxAttachments from "./BoxAttachments";

import { translate } from "../../translations/translations";

const TicketResponses = ({ responses, ticketId, lang }) => {
  return responses.length === 0
    ? null
    : responses.map((response) => (
        <div key={response._id}>
          <hr className="bg-secondary" />
          <div className="d-flex mt-3 mb-2">
            <p className="text-bold m-0">{translate("cSupportComponents.response", false, null)}:</p>
            <p className="ms-auto m-0">
              <span className="text-bold">{translate("cSupportComponents.by", false, null)}: </span>
              {response.by === "user" ? translate("cSupportComponents.you", false, null) : translate("cSupportComponents.helpdesk", false, null)}{" "}
              <span className="mx-2">-</span> <span className="text-bold">{translate("cSupportComponents.date", false, null)}: </span>
              <FormatDate dateString={response.date} lang={lang} formatStr={"dmmmyy"} />
            </p>
          </div>
          <BoxDesc desc={response.description} descId={response._id} />
          {response.attachments.length > 0 && (
            <>
              <p className="mt-3 mb-2 text-bold">{translate("cSupportComponents.responseAttachments", false, null)}:</p>
              <BoxAttachments attachments={response.attachments} ticketId={ticketId} />
            </>
          )}
        </div>
      ));
};

export default TicketResponses;
