import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../../translations/translations";
import { getPackageData } from "../lib/productTableFunctions";
import { clickAddToCart } from "../lib/ProductFunctions";
import { userIsWsAdmin } from "../../workspace/lib/wsFunctions";

const ProductTable = ({
  auth: { user, activeWorkspace },
  cart: { sessionId, cartItems },
  period,
  col,
  boolHighlight,
  productData,
  specs,
  tagLine,
}) => {
  const { packageName } = productData;

  const { btnText, disabled, price, payableAtCheckout, actionType } = getPackageData(productData, activeWorkspace, cartItems, period);

  const Spec = ({ text }) => {
    return (
      <p className="mt-1 mb-0 fontSize09">
        <span className="text-primary me-2">
          <i className="fa-solid fa-check" />
        </span>
        {text}
      </p>
    );
  };

  return (
    <div className={`col-12 col-lg-${col} px-3 py-3 py-lg-0`}>
      <div className={`h-100 w-100 overflowHidden shadow-light trans-3 rounded-3 bg-light-light ${boolHighlight ? "border border-primary" : ""}`}>
        <div
          className={`w-100 ${
            boolHighlight ? "bg-satonda" : "bg-light-light"
          } px-2 py-2 d-flex align-items-center justify-content-center text-bold text-white`}
          style={{ height: "1.5rem", fontSize: "0.7rem" }}
        >
          {boolHighlight ? translate("cProductTable.highlightText", false, null) : ""}
        </div>
        <div className="px-3 py-4 flexSameHeight align-items-center">
          <h1 className="h3 text-secondary text-bold mb-0">{packageName}</h1>
          {typeof tagLine !== "undefined" && tagLine !== "" && (
            <p className="text-center fontSize08 text-bold text-italic text-gray mt-4 mb-0">{tagLine}</p>
          )}
          {price}
          <div className="d-flex flex-column w-100 mt-4 toGrow">
            {specs.map((spec, i) => (
              <Spec text={spec} key={`${packageName}_spec_${i}`} />
            ))}
          </div>
          <button
            className="btn btn-outline-primary w-100 rounded-pill mt-4 mb-4"
            onClick={() => clickAddToCart(activeWorkspace, user, sessionId, productData, period, payableAtCheckout, actionType)}
            disabled={disabled || !userIsWsAdmin(activeWorkspace, user)}
          >
            <i className="fa-solid fa-shopping-cart me-2" />
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

ProductTable.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, null)(ProductTable);
