export const cAudienceMenuDelete = {
  confirmDeleteAudience: {
    en: "Are you sure you want to delete audience %audienceName%? This cannot be undone.",
    nl: "Weet u zeker dat u audience %audienceName% wilt verwijderen? Dit kan niet teruggedraaid worden.",
  },
  deleteAudience: {
    en: "Delete audience",
    nl: "Verwijder audience",
  },
  audienceNotFound: {
    en: "Audience not found.",
    nl: "Audience niet gevonden.",
  },
};
