export const aHosting = {
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  addingFirstHostingDomain: {
    en: "Adding your first domain to your server..",
    nl: "Uw eerste domein op uw server zetten..",
  },
  hostingDomainAdded: {
    en: "Domain has been added. The username and password to login to your server's admin panel has been sent to your email.",
    nl: "Domein is gecreerd op uw server. De gebruikersnaam en wachtwoord om in te loggen op uw server's admin paneel is naar uw emailadres verstuurd.",
  },
  updatingAdminAccountPw: {
    en: "Updating your admin account's password..",
    nl: "Bijwerken admin account wachtwoord..",
  },
  adminAccountPwUpdated: {
    en: "Your admin account's password has been updated!",
    nl: "Wachtwoord van uw admin account is bijgewerkt!",
  },
  retrievingEmailAccounts: {
    en: "Retrieving email accounts..",
    nl: "Ophalen email accounts..",
  },
  creatingEmailDomain: {
    en: "Creating email domain..",
    nl: "Email domein aanmaken..",
  },
  emailDomainCreated: {
    en: "Email domain has been created!",
    nl: "Email domein aangemaakt!",
  },
  removingEmailDomain: {
    en: "Removing email domain..",
    nl: "Verwijderen email domein..",
  },
  emailDomainRemoved: {
    en: "Email domain has been removed",
    nl: "Email domein is verwijderd",
  },
  creatingEmailAddress: {
    en: "Creating email address..",
    nl: "Aanmaken email adres..",
  },
  emailAddressCreated: {
    en: "Email address has been created!",
    nl: "Email adres aangemaakt!",
  },
  creatingEmailAlias: {
    en: "Creating email alias..",
    nl: "Aanmaken email alias..",
  },
  createdEmailAlias: {
    en: "Email alias has been created!",
    nl: "Email alias aangemaakt!",
  },
  updatingEmailPw: {
    en: "Updating email password..",
    nl: "Bijwerken email wachtwoord..",
  },
  emailPwUpdated: {
    en: "Email password has been updated!",
    nl: "Email wachtwoord bijgewerkt!",
  },
  updatingEmailAlias: {
    en: "Updating email alias..",
    nl: "Bijwerken email alias..",
  },
  updatedEmailAlias: {
    en: "Email alias has been updated!",
    nl: "Email alias bijgewerkt!",
  },
  removingEmailAcc: {
    en: "Removing email account..",
    nl: "Email account verwijderen..",
  },
  emailAccRemoved: {
    en: "Email account has been removed!",
    nl: "Email account verwijderd!",
  },
  removingAlias: {
    en: "Removing email alias..",
    nl: "Email alias verwijderen..",
  },
  removedEmailAlias: {
    en: "Email alias has been removed!",
    nl: "Email alias verwijderd!",
  },
};
