import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { setActiveWorkspace } from "../../actions/auth";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";

import { translate } from "../../translations/translations";

const Header = ({ auth: { workspaces, activeWorkspace }, setActiveWorkspace }) => {
  let navigate = useNavigate();

  const clickSelectWorkspace = (ws) => {
    ws._id !== activeWorkspace._id && setActiveWorkspace(ws);
  };

  return (
    <div className="d-flex justify-content-between">
      <span
        className="text-primary textHover-primary cursorPointer trans-3 fontSize12"
        title={translate("cNavigation.goBack", false, null)}
        onClick={() => navigate(-1)}
      >
        <i className="fa-solid fa-long-arrow-left"></i>
      </span>
      <div
        className="m-0 text-bold text-primary textHover-primary cursorPointer trans-3"
        title={translate("cNavigation.selectWs", false, null)}
        data-bs-toggle="dropdown"
      >
        {activeWorkspace === null ? (
          <span>{translate("cNavigation.selectWs", false, null)}</span>
        ) : (
          <span>{capitalizeFirstLetter(activeWorkspace.name)}</span>
        )}
        {workspaces.length > 0 && <i className="fa-solid fa-chevron-down ms-2"></i>}
      </div>
      {workspaces.length > 0 && (
        <div className="dropdown-menu rounded-3 p-0 border-0">
          {workspaces.map((ws) => (
            <span
              key={ws._id}
              className="d-block px-0 py-1 fontSize09 cursorPointer textHover-primary trans-3"
              onClick={() => clickSelectWorkspace(ws)}
            >
              {capitalizeFirstLetter(ws.name)}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  setActiveWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setActiveWorkspace })(Header);
