import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams, Navigate } from "react-router-dom";
import axios from "axios";

import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";

const ChangePw = ({ auth: { isAuthenticated } }) => {
  let { reqId } = useParams();

  const [validLink, setValidLink] = useState(false);
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [pwHasBeenReset, setPwHasBeenReset] = useState(false);

  useEffect(() => {
    checkValidLink();
    // eslint-disable-next-line
  }, []);

  const checkValidLink = async () => {
    setLocalLoading({ isLoading: true, msg: "Loading.." });
    let valid = false;
    try {
      let res = await axios.get(`${API_URL}/users/resetPw/${reqId}`);
      valid = res.data.success;
    } catch (error) {
      console.error(error);
    }
    setValidLink(valid);
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const clickChangePw = async () => {
    if (validatePw()) {
      setLocalLoading({ isLoading: true, msg: "Updating your password.." });
      let success = await changePw();
      success && setPwHasBeenReset(true);
      !success && setErrorMsg("An error occured. Please try again.");
      setLocalLoading({ isLoading: false, msg: "" });
    }
  };

  const validatePw = () => {
    let errors = 0;
    if (pw1 === "") {
      errors++;
      setErrorMsg("Please enter a password");
    }
    if (pw2 === "") {
      errors++;
      setErrorMsg("Please confirm your password");
    }
    if (pw1 !== "" && pw1.length < 8) {
      errors++;
      setErrorMsg("Password needs to be at least 8 characters");
    }
    if (pw2 !== "" && pw2.length < 8) {
      errors++;
      setErrorMsg("Password needs to be at least 8 characters");
    }
    if (pw1 !== "" && pw2 !== "" && pw1.length > 8 && pw2.length > 8 && pw1 !== pw2) {
      errors++;
      setErrorMsg("Passwords don't match");
    }
    return errors === 0;
  };

  const changePw = async () => {
    let success = false;
    try {
      const body = JSON.stringify({ pw1, pw2, reqId });
      let res = await axios.post(`${API_URL}/users/updatePw`, body, POST_CONFIG);
      success = res.data.success;
    } catch (error) {
      console.error(error);
    }
    return success;
  };

  // Redirect if already logged in
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#DFDBE5",
        backgroundImage: `linear-gradient(225deg, rgba(246, 129, 0, 0.6) 0%, rgba(229, 42, 5, 0.6) 75%), url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-12 col-sm-9 col-md-6 col-lg-4 py-5 px-4 rounded text-center"
            style={{ backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0px 0px 5px 3px rgba(0, 0, 0, 0.2)" }}
          >
            <img src="/assets/img/satonda_gradient.png" alt="Satonda" className="mb-5" style={{ width: "200px" }} />
            {localLoading.isLoading ? (
              <div className="text-center my-5">
                <div className="spinner-border spinner-border-sm text-primary"></div>
                <p className="mb-0 mt-2 text-primary fontSize08">{localLoading.msg}</p>
              </div>
            ) : !validLink ? (
              <p className="mb-0">
                Link is not valid or has expired. <Link to="/forgot-password">Please request a new link.</Link>
              </p>
            ) : pwHasBeenReset ? (
              <p className="m-0">
                Your password has been reset! <Link to="/login">Go to the login page.</Link>
              </p>
            ) : (
              <>
                <p className="mb-3">Reset your password:</p>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa-solid fa-key"></i>
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New password"
                    value={pw1}
                    onChange={(e) => setPw1(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa-solid fa-key"></i>
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    value={pw2}
                    onChange={(e) => setPw2(e.target.value)}
                    required
                  />
                </div>
                {errorMsg !== "" && <p className="mb-3 text-center text-danger">{errorMsg}</p>}
                <button className="btn btn-primary w-100" onClick={clickChangePw}>
                  Reset password
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChangePw.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ChangePw);
