export const cNotifications = {
  noNotificationsFound: {
    en: "No notifications found.",
    nl: "Geen berichten gevonden.",
  },
  date: {
    en: "Date",
    nl: "Datum",
  },
  message: {
    en: "Message",
    nl: "Bericht",
  },
  latestNotifications: {
    en: "These are your latest notifications",
    nl: "Dit zijn uw meest recente berichten",
  },
  seeNewerNotifications: {
    en: "See newer notifications",
    nl: "Bekijk recentere berichten",
  },
  showingNotifications: {
    en: "Showing notifications",
    nl: "Berichten",
  },
  of: {
    en: "of",
    nl: "van",
  },
  oldestNotifications: {
    en: "These are your oldest notifications",
    nl: "Dit zijn uw oudste berichten",
  },
  seeOlderNotifications: {
    en: "See older notifications",
    nl: "Bekijk oudere berichten",
  },
};
