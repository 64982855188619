import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import ListDomainDnsRecords from "../../../components/products/dns/ListDomainDnsRecords";
import CreateNewDnsRecord from "../../../components/products/dns/CreateNewDnsRecord";

import { translate } from "../../../translations/translations";

const ManageDomainDnsRecords = ({ auth: { activeWorkspace } }) => {
  let { domainName } = useParams();

  const [domainObj, setDomainObj] = useState(null);
  // domainObj = { domainName: "example.com", nameservers: { ns1, ..., ns6 }, dnsRecords: [ { type: "A", recordFields: [] }, ... ] }

  useEffect(() => {
    getDomainObj();
    // eslint-disable-next-line
  }, [activeWorkspace, domainName]);

  const getDomainObj = () => {
    try {
      const domain = activeWorkspace.domains.filter((d) => d.domainName === domainName)[0];
      if (typeof domain === "undefined") {
        setDomainObj(null);
      }
      setDomainObj(domain);
    } catch (error) {
      setDomainObj(null);
      console.error(error);
    }
  };

  return domainObj === null || typeof domainObj === "undefined" ? (
    <p className="text-italic m-0">{translate("cEditDnsRecord.dnsRecordNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">
        {translate("cEditDnsRecord.dnsRecordsFor", false, null)} <span className="text-italic">{domainName}</span>
      </h1>
      <CreateNewDnsRecord
        dnsRecords={{ _id: domainObj._id, domainName: domainObj.domainName, nameservers: domainObj.nameservers, records: domainObj.dnsRecords }}
      />
      <ListDomainDnsRecords
        dnsRecords={{ _id: domainObj._id, domainName: domainObj.domainName, nameservers: domainObj.nameservers, records: domainObj.dnsRecords }}
      />
    </>
  );
};

ManageDomainDnsRecords.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ManageDomainDnsRecords);
