export const cAudienceMenuLoadDatafile = {
  loadDataFromFile: {
    en: "Load data from file",
    nl: "Laad data vanuit bestand",
  },
  audienceNotFound: {
    en: "Audience not found.",
    nl: "Audience niet gevonden.",
  },
  clearFile: {
    en: "Clear file",
    nl: "Verwijder bestand",
  },
  loadData: {
    en: "Load data",
    nl: "Laad data",
  },
};
