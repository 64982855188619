export const cPendingEmailCampaigns = {
  pendingEmails: {
    en: "Pending emails to be sent",
    nl: "Geplande emails",
  },
  campaignName: {
    en: "Campaign name",
    nl: "Campagne naam",
  },
  subject: {
    en: "Email subject line",
    nl: "Email onderwerp",
  },
  scheduledFor: {
    en: "Scheduled for",
    nl: "Gepland voor",
  },
  reactive: {
    en: "Reactive",
    nl: "Reactief",
  },
  cancel: {
    en: "Cancel email campaign",
    nl: "Annuleer email campagne",
  },
};
