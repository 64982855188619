import React from "react";

import FormatDate from "../layout/FormatDate";

import { translate } from "../../translations/translations";

const TicketActions = ({ ticket, showCloseTicket, showRespondTicket, setShowCloseTicket, setShowRespondTicket, lang }) => {
  const ratingGetStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= parseInt(rating); i++) {
      stars.push(<i className="fa-solid fa-star" key={`${ticket.id}_rating_${i}`}></i>);
    }
    rating % 1 > 0 && stars.push(<i className="fa-solid fa-star-half" key={`${ticket.id}_rating_half`}></i>);
    return stars;
  };

  return (
    !showCloseTicket &&
    !showRespondTicket && (
      <div className="d-flex mt-3">
        {ticket.isOpen ? (
          <button className="btn btn-sm btn-success px-3" onClick={() => setShowCloseTicket(true)}>
            <i className="fa-solid fa-check me-2" />
            {translate("cSupportComponents.closeTicket", false, null)}
          </button>
        ) : (
          <div>
            <p className="mb-2">
              {translate("cSupportComponents.ticketWasClosedOn", false, null)}{" "}
              <FormatDate dateString={ticket.updatedAt} lang={lang} formatStr={"dmmmyy"} />.
            </p>
            <p className="m-0">
              {translate("cSupportComponents.yourRating", false, null)}:{" "}
              <span className="ms-3 fontSize09" style={{ color: "#FFD700" }}>
                {ratingGetStars(ticket.rating)}
              </span>
            </p>
          </div>
        )}
        <div className="ms-auto">
          <button className="btn btn-sm btn-primary px-3" onClick={() => setShowRespondTicket(true)}>
            <i className="fa-solid fa-reply me-2" />
            {ticket.isOpen
              ? translate("cSupportComponents.addResponse", false, null)
              : translate("cSupportComponents.reopenAddResponse", false, null)}
          </button>
        </div>
      </div>
    )
  );
};

export default TicketActions;
