export const WS_ROLE_OWNER = "owner";
export const WS_ROLE_ADMIN = "admin";
export const WS_ROLE_MEMBER = "member";
export const WS_MEMBER_TYPE_ACTIVATED = "activatedUser";
export const WS_MEMBER_TYPE_PENDING = "pendingInvite";

export const userIsWsMember = (ws, user) => {
  try {
    return ws.members.filter((member) => member.userId === user._id).length > 0;
  } catch (error) {
    // console.error(error);
    return false;
  }
};

export const userIsWsAdmin = (ws, user) => {
  try {
    return [WS_ROLE_OWNER, WS_ROLE_ADMIN].includes(ws.members.filter((member) => member.userId === user._id)[0].role);
  } catch (error) {
    // console.error(error);
    return false;
  }
};

export const userIsWsOwner = (ws, user) => {
  try {
    return [WS_ROLE_OWNER].includes(ws.members.filter((member) => member.userId === user._id)[0].role);
  } catch (error) {
    // console.error(error);
    return false;
  }
};
