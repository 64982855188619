import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { loadAudienceData } from "../../actions/audiences";
import { setAlert } from "../../actions/alert";
import { filesSelected } from "./lib/audienceFunctions";

import { translate } from "../../translations/translations";

const AudienceMenuLoadDatafile = ({ loadAudienceData, setAlert }) => {
  const fileSelectInput = useRef();

  let { audienceId } = useParams();

  const [csvData, setCsvData] = useState([]);

  const clickLoadData = () => {
    if (csvData.length > 0) {
      loadAudienceData(audienceId, csvData);
      clearFiles();
    }
  };

  const clearFiles = () => {
    setCsvData([]);
    fileSelectInput.current.value = "";
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cAudienceMenuLoadDatafile.loadDataFromFile", false, null)}</h2>
      {audienceId === "" ? (
        <p className="text-italic m-0">{translate("cAudienceMenuLoadDatafile.audienceNotFound", false, null)}</p>
      ) : (
        <div className="input-group mb-3">
          <div className="posRelative toGrow">
            <input type="file" className="form-control" onChange={(e) => filesSelected(e, setAlert, setCsvData, clearFiles)} ref={fileSelectInput} />
            {csvData.length > 0 && (
              <i
                className="fa-solid fa-xmark cursorPointer text-danger"
                title={translate("cAudienceMenuLoadDatafile.clearFile", false, null)}
                style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: "1rem" }}
                onClick={clearFiles}
              />
            )}
          </div>
          <button className="btn btn-outline-primary" disabled={csvData.length === 0} onClick={clickLoadData}>
            {translate("cAudienceMenuLoadDatafile.loadData", false, null)}
          </button>
        </div>
      )}
    </>
  );
};

AudienceMenuLoadDatafile.propTypes = {
  loadAudienceData: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { loadAudienceData, setAlert })(AudienceMenuLoadDatafile);
