import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { PRODUCT_NAMES } from "../../products/lib/ProductVars";
import { workspaceHasProduct } from "../../products/lib/ProductFunctions";
import { subIsSuspendedOrCancelled } from "../../workspace/subscriptions/lib/subscriptionFunctions";

import { translate } from "../../../translations/translations";

const YourServices = ({ auth: { activeWorkspace } }) => {
  let wsHasActiveSubs = activeWorkspace !== null && activeWorkspace.subscriptions.filter((sub) => !subIsSuspendedOrCancelled(sub)).length > 0;
  return (
    wsHasActiveSubs && (
      <li className="mb-1">
        <button
          className="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#YourServices-collapse"
          aria-expanded="false"
        >
          {translate("cNavigation.wsServices", false, null)}
        </button>
        <div className="collapse" id="YourServices-collapse">
          <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            {workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.domains) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/domains/manage" className="link-dark rounded">
                  {translate("common.productNameDomains", false, null)}
                </Link>
              </li>
            )}
            {(workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.domains) ||
              workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.hosting)) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/dns" className="link-dark rounded">
                  DNS
                </Link>
              </li>
            )}
            {workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.hosting) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/hosting/manage" className="link-dark rounded">
                  {translate("common.productNameHosting", false, null)}
                </Link>
              </li>
            )}
            {workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/sitebuilder/manage" className="link-dark rounded">
                  {translate("common.productNameSb", false, null)}
                </Link>
              </li>
            )}
            {workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.emailMarketing) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/email-marketing/manage" className="link-dark rounded">
                  {translate("common.productNameEmailMarketing", false, null)}
                </Link>
              </li>
            )}
            {(workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder) ||
              workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.emailMarketing)) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/audiences" className="link-dark rounded">
                  {translate("common.productNameAudiences", false, null)}
                </Link>
              </li>
            )}
            {workspaceHasProduct(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder) && (
              <li data-bs-dismiss="offcanvas">
                <Link to="/contact-form/messages" className="link-dark rounded">
                  {translate("common.productNameContactForms", false, null)}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </li>
    )
  );
};

YourServices.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(YourServices);
