import React from "react";

import { translate } from "../../../../translations/translations";

const EmailCampaignStatsAuditTop = ({ emailSubject, usedAudience, usedEbProject }) => {
  return (
    <div className="row mt-5">
      <div className="col-12 col-md-4 p-2">
        <div className="flexSameHeight bg-primary-light text-primary px-3 py-2 border border-primary rounded-xl">
          <p className="m-0 toGrow">
            <span className="text-bold">{translate("cEmailCampaignStatsTop.subject", false, null)}:</span> {emailSubject}
          </p>
        </div>
      </div>
      <div className="col-12 col-md-4 p-2">
        <div className="flexSameHeight bg-primary-light text-primary px-3 py-2 border border-primary rounded-xl">
          <p className="m-0 toGrow">
            <span className="text-bold">{translate("cEmailCampaignStatsTop.audience", false, null)}:</span> {usedAudience}
          </p>
        </div>
      </div>
      <div className="col-12 col-md-4 p-2">
        <div className="flexSameHeight bg-primary-light text-primary px-3 py-2 border border-primary rounded-xl">
          <p className="m-0 toGrow">
            <span className="text-bold">{translate("cEmailCampaignStatsTop.template", false, null)}:</span> {usedEbProject}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaignStatsAuditTop;
