import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isValidDomain } from "../../../../components/products/domains/lib/domainsFunctions";

import { createPleskCustomerSubscription } from "../../../../actions/hosting";

import { translate } from "../../../../translations/translations";

const FirstDomain = ({ auth: { activeWorkspace }, createPleskCustomerSubscription }) => {
  const [domainInput, setDomainInput] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const onType = (text) => {
    setDomainInput(text);
    if (isValidDomain(text)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const clickAddDomain = () => {
    createPleskCustomerSubscription(activeWorkspace._id, domainInput);
    setDomainInput("");
    setBtnDisabled(true);
  };

  return (
    <>
      <h1 className="text-primary">{translate("pFirstDomain.addFirstDomain", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : activeWorkspace.hosting.hostedDomains.length > 0 ? (
        <p className="text-italic m-0">{translate("pFirstDomain.alreadyHasDomain", false, null)}</p>
      ) : (
        <div className="row mt-5">
          <div className="col-12 col-sm-9 col-md-8 col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder={translate("pFirstDomain.exampleCom", false, null)}
              value={domainInput}
              onChange={(e) => onType(e.target.value)}
            />
            <button className="btn btn-primary trans-3 w-100 mt-3" type="button" disabled={btnDisabled} onClick={clickAddDomain}>
              {translate("pFirstDomain.addDomain", false, null)}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

FirstDomain.propTypes = {
  auth: PropTypes.object.isRequired,
  createPleskCustomerSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createPleskCustomerSubscription })(FirstDomain);
