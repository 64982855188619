import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { changeWorkspaceName } from "../../actions/auth";
import { userIsWsAdmin } from "../../components/workspace/lib/wsFunctions";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";

import { translate } from "../../translations/translations";

const ChangeWsName = ({ auth: { user, workspaces }, changeWorkspaceName }) => {
  let { wsId } = useParams();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [newWsName, setNewWsName] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    try {
      let ws = workspaces.filter((ws) => ws._id === wsId)[0] || null;
      if (userIsWsAdmin(ws, user)) {
        setSelectedWorkspace(ws);
        setNewWsName(ws.name);
      }
    } catch (error) {
      setSelectedWorkspace(null);
    }
  }, [user, workspaces, wsId]);

  const clickChangeWorkspace = async () => {
    if (checkErrors() === 0) {
      await changeWorkspaceName(wsId, capitalizeFirstLetter(newWsName));
      resetStateVars();
    }
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (newWsName === "") {
      setErrMsg(translate("pWsPages.provideWsName", false, null));
      errors++;
    }
    if (workspaces.filter((ws) => ws.name === newWsName).length > 0) {
      setErrMsg(translate("pWsPages.wsNameAlreadyExists", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    // setNewWsName("");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg("");
  };

  return (
    <>
      <h1 className="text-primary mt-5 mb-3">{translate("pWsPages.changeWsName", false, null)}</h1>
      {selectedWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <div className="row mt-4">
          <div className="col-12 col-lg-9 col-xl-6">
            <div className="input-group mb-3">
              <input
                type="email"
                className={`form-control${errMsg === "" ? "" : " is-invalid"}`}
                placeholder={translate("pWsPages.wsName", false, null)}
                value={newWsName}
                onChange={(e) => setNewWsName(e.target.value)}
              />
              <button className="btn btn-outline-primary" disabled={newWsName === ""} onClick={clickChangeWorkspace}>
                {translate("pWsPages.changeName", false, null)}
              </button>
              {errMsg !== "" && <div className="invalid-feedback">{errMsg}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ChangeWsName.propTypes = {
  auth: PropTypes.object.isRequired,
  changeWorkspaceName: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { changeWorkspaceName })(ChangeWsName);
