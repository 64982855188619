export const cDomainsSeeAll = {
  seeAllExtensions: {
    en: "See all available extensions and pricing",
    nl: "Bekijk hier alle beschikbare extensies en prijzen",
  },
  alreadyOwnDomain: {
    en: "Already own a domain?",
    nl: "Bezit u al een domein?",
  },
  clickToTransfer: {
    en: "Click here to transfer your domain to Satonda.",
    nl: "Klik hier om uw domein over te zetten naar Satonda",
  },
};
