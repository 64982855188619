import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../../translations/translations";
import { getSummaryLineDomainDnsRecords } from "../../products/dns/lib/dnsFunctions";
import DashboardErrorItem from "../DashboardErrorItem";

const DnsSummary = ({ auth: { activeWorkspace } }) => {
  const PER_PAGE = 10;

  const [managedDomains, setManagedDomains] = useState([]);
  const [domainsToRender, setDomainsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  let maxPages = Math.ceil(managedDomains.length / PER_PAGE);
  useEffect(() => {
    try {
      setManagedDomains(activeWorkspace.domains.filter((d) => d.managed));
    } catch (error) {
      setManagedDomains([]);
    }
    // eslint-disable-next-line
  }, [activeWorkspace]);

  useEffect(() => {
    renderDomainRecords();
    // eslint-disable-next-line
  }, [managedDomains, currPage]);

  const renderDomainRecords = () => {
    const start = currPage * PER_PAGE;
    const end = start + PER_PAGE;
    setDomainsToRender(managedDomains.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  return managedDomains.length === 0 ? (
    <DashboardErrorItem msg={translate("cListDnsDomains.noDomainsOnDnsYet", false, null)} />
  ) : (
    <div className="w-100">
      <div className="table-responsive">
        <table className="table table-sm table-hover rounded-3 overflowHidden align-middle mt-3 fontSize09">
          <thead>
            <tr className="bg-primary text-white border-none">
              <th scope="col">{translate("cListDnsDomains.domain", false, null)}</th>
              <th scope="col">{translate("cListDnsDomains.records", false, null)}</th>
            </tr>
          </thead>
          <tbody>
            {domainsToRender.map((domain) => (
              <tr key={domain._id}>
                <td>{domain.domainName}</td>
                <td>{getSummaryLineDomainDnsRecords(domain)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(-1)} disabled={currPage === 0}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate("cListDnsDomains.showingManagedDomains", false, null)}{" "}
          <span className="text-bold">
            {Math.min(currPage * PER_PAGE + 1, managedDomains.length)} - {Math.min((currPage + 1) * PER_PAGE, managedDomains.length)}
          </span>{" "}
          {translate("cListDnsDomains.of", false, null)} <span className="text-bold">{managedDomains.length}</span>
        </span>
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(1)} disabled={currPage + 1 === maxPages}>
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

DnsSummary.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(DnsSummary);
