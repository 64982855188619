import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

// import { useStripe } from "@stripe/react-stripe-js";

import { emptyShoppingCart } from "../../actions/cart";
import { translate } from "../../translations/translations";

const Status = ({ emptyShoppingCart }) => {
  let { paymentIntentStatus, checkoutLocation } = useParams();
  const PAYMENT = "payment";
  // checkoutLocation = "payment" || "overdue"

  useEffect(() => {
    if (paymentIntentStatus === "succeeded" || paymentIntentStatus === "processing") {
      emptyShoppingCart();
    }
    // eslint-disable-next-line
  }, [paymentIntentStatus]);

  let query = new URLSearchParams(useLocation().search);
  let errMsg = query ? query.get("errMsg") : "";

  let msg1 = {
    processing:
      checkoutLocation === PAYMENT
        ? translate("pCheckoutStatus.msg1Pending_payment", false, null)
        : translate("pCheckoutStatus.msg1Pending_overdue", false, null),
    failed: translate("pCheckoutStatus.msg1Failed", false, null),
    error: translate("pCheckoutStatus.msg1Err", false, null),
    succeeded:
      checkoutLocation === PAYMENT
        ? translate("pCheckoutStatus.msg1Success_payment", false, null)
        : translate("pCheckoutStatus.msg1Success_overdue", false, null),
  };

  let msg2 = {
    processing:
      checkoutLocation === PAYMENT
        ? translate("pCheckoutStatus.msg2Pending_payment", false, null)
        : translate("pCheckoutStatus.msg2Pending_overdue", false, null),
    failed: "",
    error: errMsg === "" || errMsg === null ? `An unexpected error occured. Please try again.` : `Error: ${errMsg}`,
    succeeded: checkoutLocation === PAYMENT ? translate("pCheckoutStatus.msg2Success_payment", false, null) : "",
  };

  let icon = {
    processing: "fa-solid fa-check",
    failed: "fa-solid fa-exclamation",
    error: "fa-solid fa-exclamation",
    succeeded: "fa-solid fa-check",
  };

  let colorClass = {
    processing: "success",
    failed: "danger",
    error: "danger",
    succeeded: "success",
  };

  return (
    <div className="px-3 py-4 bg-light rounded-3 shadow d-flex flex-column align-items-center">
      <span
        className={`mt-3 bg-light shadow-sm border border-${colorClass[paymentIntentStatus]} text-${colorClass[paymentIntentStatus]} rounded-circle d-flex align-items-center justify-content-center fontSize25`}
        style={{ height: "75px", width: "75px" }}
      >
        <i className={icon[paymentIntentStatus]} />
      </span>
      <p className="mt-4 mb-3 text-center">{msg1[paymentIntentStatus]}</p>
      {msg2[paymentIntentStatus] !== "" && <p className="mb-3 text-center">{msg2[paymentIntentStatus]}</p>}
      <Link to="/" className="btn btn-primary trans-3 mt-4 px-4">
        {translate("pCheckoutStatus.returnHome", false, null)}
      </Link>
    </div>
  );
};

Status.propTypes = {
  emptyShoppingCart: PropTypes.func.isRequired,
};

export default connect(null, { emptyShoppingCart })(Status);
