import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createNewWorkspace } from "../../../actions/auth";
import { translate } from "../../../translations/translations";

const CreateWorkspace = ({ auth: { user, workspaces }, createNewWorkspace }) => {
  const [newWsName, setNewWsName] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const clickCreateNewWorkspace = async () => {
    if (checkErrors() === 0) {
      await createNewWorkspace(newWsName);
      resetStateVars();
    }
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (newWsName === "") {
      setErrMsg(translate("cUserWorkspaces.provideWsName", false, null));
      errors++;
    }
    if (workspaces.filter((ws) => ws.name === newWsName).length > 0) {
      setErrMsg(translate("cUserWorkspaces.nameAlreadyExists", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setNewWsName("");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg("");
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cUserWorkspaces.createNewWs", false, null)}</h2>
      <div className="row mt-4 mb-5">
        <div className="col-12 col-lg-9 col-xl-6">
          <div className="input-group mb-3">
            <input
              type="email"
              className={`form-control${errMsg === "" ? "" : " is-invalid"}`}
              placeholder={translate("cUserWorkspaces.wsName", false, null)}
              value={newWsName}
              onChange={(e) => setNewWsName(e.target.value)}
            />
            <button
              className="btn btn-outline-primary"
              disabled={newWsName === ""}
              onClick={clickCreateNewWorkspace}
              title={translate("cUserWorkspaces.createNewWs", false, null)}
            >
              <span className="d-md-none">
                <i className="fa-solid fa-arrow-right-long" />
              </span>{" "}
              <span className="d-none d-md-block">{translate("cUserWorkspaces.createNewWs", false, null)}</span>
            </button>
            {errMsg !== "" && <div className="invalid-feedback">{errMsg}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

CreateWorkspace.propTypes = {
  auth: PropTypes.object.isRequired,
  createNewWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createNewWorkspace })(CreateWorkspace);
