import React from "react";

const Spinner = ({ msg, direction = "ver" }) => {
  // <div className="text-center">
  //     <div className="spinner-border spinner-border-sm text-primary"></div>
  //     <p className="mb-0 mt-2 text-primary fontSize08">{msg}</p>
  //   </div>

  return (
    <div className={`d-flex ${direction === "ver" ? "flex-column" : ""} justify-content-center align-items-center`}>
      <div className="spinner-border spinner-border-sm text-primary"></div>
      <p className={`${direction === "ver" ? "mt-2" : "ms-2"} mb-0 text-primary fontSize08`}>{msg}</p>
    </div>
  );
};

export default Spinner;
