import React from "react";

import { TICKET_ATTACHMENT_BASE_LINK } from "./lib/supportVars";

import { translate } from "../../translations/translations";

const BoxAttachments = ({ attachments, ticketId }) => {
  return (
    <div className="row border border-light rounded m-0 p-2">
      {attachments.map((attachment, k) => (
        <div className="col-6 p-0" key={`attachment_${ticketId}_${k}`}>
          <a
            className="my-2"
            href={`${TICKET_ATTACHMENT_BASE_LINK}${ticketId}_${attachment}`}
            target="_blank"
            rel="noreferrer"
            title={translate("cSupportComponents.openAttachment", false, null)}
          >
            <span className="me-2">
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </span>
            {attachment}
          </a>
        </div>
      ))}
    </div>
  );
};

export default BoxAttachments;
