import { getWorkspaceProductPackageDetails } from "../../products/lib/ProductFunctions";
import { PRODUCT_NAMES } from "../../products/lib/ProductVars";
import { getPackageDetailUsageMetric } from "../../workspace/subscriptions/lib/subscriptionFunctions";
import { simpleRound } from "../../../lib/formattingFunctions";

export const getStorageUsageData = (workspace) => {
  // Returns array of storage usage data in MBs: [files, dbs, emails, unused]
  let productPackageDetails = getWorkspaceProductPackageDetails(workspace.subscriptions, PRODUCT_NAMES.hosting);
  if (workspace === null || productPackageDetails === null) {
    return [0, 0];
  } else {
    const used = parseInt(workspace.hosting.quotaUsage.storageMb || 0);
    const allowedInSub =
      getPackageDetailUsageMetric(productPackageDetails, "storage") === null
        ? 0
        : parseInt(getPackageDetailUsageMetric(productPackageDetails, "storage").value);
    const available = allowedInSub - used;
    return [simpleRound(used, 1), simpleRound(available, 1)];
  }
};

export const workspaceExceedsStoragePlan = (workspace) => {
  return getStorageUsageData(workspace)[1] < 0;
};

export const getChartColors = (numItems, alpha) => {
  // numItems = length of chart data array
  // alpha = 0.2 for background, 1 for border
  const colors = [
    "229, 42, 5",
    "246, 129, 0",
    "13, 202, 240",
    "50, 168, 82",
    "2, 65, 237",
    "168, 0, 186",
    "60, 60, 60",
    "227, 155, 0",
    "24, 143, 131",
    "46, 68, 128",
    "130, 36, 82",
    "155, 191, 169",
    "230, 106, 39",
    "206, 212, 218",
    "13, 35, 61",
    "222, 138, 230",
  ];
  return colors.slice(0, numItems).map((color) => `rgba(${color}, ${alpha})`);
};
