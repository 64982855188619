export const cListValidatedEmails = {
  validatedEmails: {
    en: "Validated email addresses",
    nl: "Gevalideerde emailadressen",
  },
  noValidatedEmailsYet: {
    en: "You don't have any validated email addresses yet.",
    nl: "U heeft nog geen gevalideerde emailadressen.",
  },
  defaultFromName: {
    en: `Default "from" name`,
    nl: `Standaard "van" naam`,
  },
  emailAddress: {
    en: "Email address",
    nl: "Emailadres",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  editFromName: {
    en: `Edit "from" name`,
    nl: `Verander "van" naam`,
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
  editDefaultFromName: {
    en: `Edit default "from" name`,
    nl: `Verander standaard "van" naam`,
  },
  removeEmail: {
    en: "Remove email address",
    nl: "Verwijder emailadres",
  },
  removingEmailAddress: {
    en: "Removing email address..",
    nl: "Verwijderen emailadres..",
  },
  emailAddressRemoved: {
    en: "Email address has been removed.",
    nl: "Emailadres is verwijderd.",
  },
  provideNewName: {
    en: "Please provide a new name",
    nl: "Geef aub een nieuwe naam op",
  },
  editingDefaultName: {
    en: `Editing default "from" name..`,
    nl: `Aanpassen standaard "van" naam..`,
  },
  defaultFromNameUpdated: {
    en: `Default "from" name has been updated!`,
    nl: `Standaard "van" naam aangepast!`,
  },
};
