import React from "react";

import { capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { TICKET_TOPICS } from "./lib/supportVars";
import FormatDate from "../layout/FormatDate";

import { translate } from "../../translations/translations";

const TicketHeader = ({ ticket, status, i, lang }) => {
  return (
    <h5 className="accordion-header" id={`heading_${status}_${i}`}>
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse_${status}_${i}`}
        aria-expanded="false"
        aria-controls={`collapse_${status}_${i}`}
      >
        <span className={`badge bg-${status === "open" ? "primary" : "success"} fontSize07 me-2`}>{capitalizeFirstLetter(status)}</span>
        <span className="badge bg-midgray text-gray fontSize07 me-2">{TICKET_TOPICS[ticket.topic]}</span>
        <span>{ticket.subject}</span>
        <span className="ms-3 fontSize08 text-italic">
          ({translate("cSupportComponents.lastUpdate", false, null)}: <FormatDate dateString={ticket.updatedAt} lang={lang} formatStr={"dmmmyy"} />)
        </span>
      </button>
    </h5>
  );
};

export default TicketHeader;
