export const pAudienceMain = {
  manageAudiences: {
    en: "Manage your audiences",
    nl: "Beheer uw audiences",
  },
  noWsSelected: {
    en: "No workspace selected.",
    nl: "Geen werkruimte geselecteerd.",
  },
};
