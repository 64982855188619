import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProductCard from "../../../../components/layout/ProductCard";

import { translate } from "../../../../translations/translations";

const ManageHosting = ({ auth: { activeWorkspace } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pManageHosting.manageHostingServices", false, null)}</h1>
      <div className="row mt-5">
        {activeWorkspace !== null && activeWorkspace.hosting.hostedDomains.length === 0 ? (
          <ProductCard
            productTitle={translate("pManageHosting.addFirstDomain", false, null)}
            iconClassname={"fa-solid fa-globe"}
            productLinkTo={"/hosting/first-domain"}
            productLinkText={translate("pManageHosting.hostFIrstDomain", false, null)}
          />
        ) : (
          <>
            <ProductCard
              productTitle={"Plesk"}
              iconClassname={"fa-solid fa-server"}
              productLinkTo={activeWorkspace !== null && `https://${activeWorkspace.hosting.pleskPanelUrl}`}
              productLinkText={translate("pManageHosting.openPlesk", false, null)}
            />
            <ProductCard
              productTitle={translate("pManageHosting.pleskPw", false, null)}
              iconClassname={"fa-solid fa-key"}
              productLinkTo={"/hosting/reset-plesk-pw"}
              productLinkText={translate("pManageHosting.resetPleskPw", false, null)}
            />
          </>
        )}
      </div>
    </>
  );
};

ManageHosting.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ManageHosting);
