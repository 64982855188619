import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import AudienceEntriesTable from "../../../components/audiences/AudienceEntriesTable";

import { translate } from "../../../translations/translations";

const AudienceEntries = ({ auth: { user }, audiences: { audiences } }) => {
  let { audienceId } = useParams();

  const [audience, setAudience] = useState(null);
  const [allEntries, setAllEntries] = useState(null);
  const [filteredEntries, setFilteredEntries] = useState(null);

  useEffect(() => {
    if (user !== null && audienceId !== "") {
      let activeAudience = audiences.filter((audience) => audience._id === audienceId)[0] || null;
      setAudience(activeAudience);
      setAllEntries(activeAudience.data);
      setFilteredEntries(activeAudience.data);
    } else {
      setAudience(null);
    }
    // eslint-disable-next-line
  }, [user, audienceId]);

  return audience === null ? (
    <p className="text-italic m-0">{translate("pAudienceEntries.audienceNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary mb-4">
        {translate("pAudienceEntries.audienceEntries", false, null)} "<span className="text-italic">{audience.audienceName}</span>"
      </h1>
      <AudienceEntriesTable
        allEntries={allEntries}
        setAllEntries={setAllEntries}
        filteredEntries={filteredEntries}
        setFilteredEntries={setFilteredEntries}
        boolEditable={true}
      />
    </>
  );
};

AudienceEntries.propTypes = {
  auth: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(AudienceEntries);
