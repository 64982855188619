import React, { useEffect } from "react";
import { Document, usePDF } from "@react-pdf/renderer";

import InvoicePDF from "./InvoicePDF";
import Spinner from "../../layout/Spinner";

import { translate } from "../../../translations/translations";
import { dateAddZeroBefore } from "../../../lib/GeneralFunctions";

const DownloadInvoicePDF = ({ data }) => {
  const Invoice = (
    <Document title="Invoice" subject="Invoice" author="Satonda" creator="Satonda" producer="Satonda">
      <InvoicePDF data={data} />
    </Document>
  );
  const [instance, updateInstance] = usePDF({
    document: Invoice,
  });

  useEffect(() => {
    updateInstance(Invoice);
    // eslint-disable-next-line
  }, [data]);

  const clickDownload = () => {
    if (!data.currMonth) {
      makeAndDownloadFile(instance.blob, `${getInvoiceFileName(data.invoiceDate, data.invoiceId)}.pdf`);
    }
  };

  const makeAndDownloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    document.body.append(link);
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    link.download = fileName;
    link.click();
    link.remove();
  };

  const getInvoiceFileName = (invoiceDate, invoiceId) => {
    let date = new Date(invoiceDate);
    return `invoice_${date.getFullYear()}${dateAddZeroBefore(date.getMonth() + 1)}${dateAddZeroBefore(date.getDate())}_${invoiceId}`;
  };

  return instance.loading ? (
    <div className="ms-auto">
      <Spinner msg={translate("cWsBilling.loadingPDF", false, null)} direction={"hor"} />
    </div>
  ) : (
    <span
      className={`ms-auto ${data.currMonth ? "text-gray" : "text-primary cursorPointer"}`}
      title={data.currMonth ? translate("cWsBilling.noInvoiceSelected", false, null) : translate("cWsBilling.downloadInvoice", false, null)}
      onClick={clickDownload}
    >
      <i className="fa-solid fa-download"></i>
    </span>
  );
};

export default DownloadInvoicePDF;
