export const cEmAllSubs = {
  designPerfectEmails: {
    en: "Design perfect emails that convert with our easy-to-use drag & drop email builder and reach your stakeholders at scale!",
    nl: "Maak perfecte emails die werken met onze gemakkelijk te gebruiken drag & drop email builder en bereik uw stakeholders!",
  },
  readMore: {
    en: "Read more.",
    nl: "Lees meer.",
  },
};
