import React from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NotSubscribed from "../layout/NotSubscribed";
import { workspaceHasProduct } from "../products/lib/ProductFunctions";

const WsHasProduct = ({ auth: { activeWorkspace }, arrProductNames }) => {
  return activeWorkspace !== null && arrProductNames.some((productName) => workspaceHasProduct(activeWorkspace.subscriptions, productName)) ? (
    <Outlet />
  ) : (
    <NotSubscribed />
  );
};

WsHasProduct.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(WsHasProduct);
