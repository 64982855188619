import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FormatDate from "../../../../components/layout/FormatDate";
import FormatNumber from "../../../../components/layout/FormatNumber";

import { translate } from "../../../../translations/translations";

const ExecutedEmailCampaigns = ({ auth: { user }, emailMarketing: { executedEmailCampaigns } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pEmExecutedCampaigns.reviewStats", false, null)}</h1>
      {executedEmailCampaigns.length === 0 ? (
        <p className="m-0 text-italic">{translate("pEmExecutedCampaigns.noCampaignsFound", false, null)}</p>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
              <thead>
                <tr className="bg-primary text-white border-none">
                  <th scope="col">{translate("pEmExecutedCampaigns.campaignName", false, null)}</th>
                  <th scope="col">{translate("pEmExecutedCampaigns.subjectLine", false, null)}</th>
                  <th scope="col">{translate("pEmExecutedCampaigns.dateTime", false, null)}</th>
                  <th scope="col">{translate("pEmExecutedCampaigns.numRecipients", false, null)}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {executedEmailCampaigns.map((campaign) => (
                  <tr key={campaign._id}>
                    <td>{campaign.campaignName}</td>
                    <td>{campaign.emailSubject}</td>
                    <td>
                      {campaign.campaignType === "reactive" ? (
                        <span className="py-1 px-2 bg-primary-light text-primary text-bold rounded-3 border border-primary fontSize08 text-italic">
                          {translate("pEmExecutedCampaigns.reactive", false, null)}
                        </span>
                      ) : (
                        <FormatDate dateString={campaign.scheduledAt} lang={user.language} formatStr={"dmmyy hm"} />
                      )}
                    </td>
                    <td>
                      <FormatNumber number={campaign.recipients.length} formatStr={"0"} />
                    </td>
                    <td className="text-end">
                      <Link className="btn btn-outline-dark btn-sm trans-3 px-4 me-2" to={`${campaign._id}`}>
                        {translate("pEmExecutedCampaigns.seeStats", false, null)}
                      </Link>
                      <Link className="btn btn-outline-dark btn-sm trans-3 px-4" to={`/email-marketing/manage/campaigns/audit/${campaign._id}`}>
                        {translate("pEmExecutedCampaigns.seeAudit", false, null)}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

ExecutedEmailCampaigns.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, null)(ExecutedEmailCampaigns);
