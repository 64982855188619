export const cSbAllSubs = {
  buildYourOwnSite: {
    en: "Build your own beatiful website with our easy to use sitebuilder!",
    nl: "Bouw zelf een prachtige website met onze gemakkelijk te gebruiken sitebuilder!",
  },
  readMore: {
    en: "Read more.",
    nl: "Lees meer.",
  },
};
