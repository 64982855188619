import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { userIsWsAdmin, WS_ROLE_OWNER } from "../../workspace/lib/wsFunctions";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import { leaveWorkspace, deleteWorkspace } from "../../../actions/auth";
import { setAlert } from "../../../actions/alert";
import Spinner from "../../layout/Spinner";
import { translate } from "../../../translations/translations";

const AllWorkspaces = ({ auth: { user, workspaces }, setAlert, leaveWorkspace, deleteWorkspace }) => {
  const WsRow = ({ ws }) => {
    const [loadingWsRow, setLoadingWsRow] = useState({ isLoading: false, msg: "" });
    // const [userIsAdmin, setUserIsAdmin] = useState(userIsWsAdmin(ws, user));
    const userIsAdmin = userIsWsAdmin(ws, user);

    const clickLeaveWs = async () => {
      if (ws.members.filter((member) => member.userId === user._id)[0].role === WS_ROLE_OWNER) {
        setAlert(translate("cUserWorkspaces.youreWsOwner", false, null), "danger");
      } else {
        if (window.confirm(translate("cUserWorkspaces.areYouSureLeave", false, { wsName: capitalizeFirstLetter(ws.name) }))) {
          setLoadingWsRow({ isLoading: true, msg: translate("cUserWorkspaces.leavingWs", false, null) });
          await leaveWorkspace(ws._id);
          setLoadingWsRow({ isLoading: false, msg: "" });
        }
      }
    };

    const clickDeleteWs = async () => {
      if (window.confirm(translate("cUserWorkspaces.areYouSureDelete", false, { wsName: capitalizeFirstLetter(ws.name) }))) {
        setLoadingWsRow({ isLoading: true, msg: translate("cUserWorkspaces.deletingWs", false, null) });
        await deleteWorkspace(ws._id);
        setLoadingWsRow({ isLoading: false, msg: "" });
      }
    };

    return loadingWsRow.isLoading ? (
      <tr>
        <td colSpan="2">
          <Spinner msg={loadingWsRow.msg} direction={"hor"} />
        </td>
      </tr>
    ) : (
      <tr>
        <td>
          <span className="d-inline-block me-3" style={{ width: "25px" }}>
            {userIsAdmin ? (
              <i className="fa-solid fa-user-pen" title={translate("cUserWorkspaces.youreAdmin", false, null)} />
            ) : (
              <i className="fa-solid fa-user" title={translate("cUserWorkspaces.youreMember", false, null)} />
            )}
          </span>
          {capitalizeFirstLetter(ws.name)}
        </td>
        <td>
          {userIsAdmin && (
            <Link className="btn btn-sm btn-outline-dark me-3 my-1" to={`/workspace/change-name/${ws._id}`}>
              <i className="fa-solid fa-pencil me-2" />
              {translate("cUserWorkspaces.changeName", false, null)}
            </Link>
          )}
          <button className="btn btn-sm btn-outline-warning me-3 my-1" onClick={clickLeaveWs}>
            <i className="fa-solid fa-arrow-right-from-bracket me-2" />
            {translate("cUserWorkspaces.leaveWs", false, null)}
          </button>
          {userIsAdmin && (
            <button className="btn btn-sm btn-outline-danger me-3 my-1" onClick={clickDeleteWs}>
              <i className="fa-solid fa-trash-can me-2" />
              {translate("cUserWorkspaces.deleteWs", false, null)}
            </button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cUserWorkspaces.yourWs", false, null)}</h2>
      {workspaces.length === 0 ? (
        <p className="text-italic m-0">{translate("cUserWorkspaces.notAMemberOfAnyWs", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-3">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col">{translate("cUserWorkspaces.workspace", false, null)}</th>
                <th scope="col">{translate("cUserWorkspaces.actions", false, null)}</th>
              </tr>
            </thead>
            <tbody>
              {workspaces.map((ws) => (
                <WsRow key={`allworkspaces_${ws._id}`} ws={ws} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

AllWorkspaces.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  leaveWorkspace: PropTypes.func.isRequired,
  deleteWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, leaveWorkspace, deleteWorkspace })(AllWorkspaces);
