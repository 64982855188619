import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DashboardItem from "./DashboardItem";

// Hosting
import StorageUsage from "./hosting/StorageUsage";
// import Visitors from "./hosting/Visitors";
import Bandwidth from "./hosting/Bandwidth";

// Domains
import DomainsByTld from "./domains/DomainsByTld";
import DnsSummary from "./domains/DnsSummary";
import RegisterNewDomain from "./domains/RegisterNewDomain";

// Sitebuilder
import SbProjectsSummary from "./sb/SbProjectsSummary";

// Email marketing
import EmEmailsSummary from "./em/EmEmailsSummary";
import EmEmailsSent from "./em/EmEmailsSent";
import Audiences from "./em/Audiences";

// Workspaces
import YourWorkspaces from "./workspaces/YourWorkspaces";

import { translate } from "../../translations/translations";

import { WS_ROLE_OWNER, WS_ROLE_ADMIN, WS_ROLE_MEMBER } from "../workspace/lib/wsFunctions";
import { PRODUCT_NAMES } from "../products/lib/ProductVars";
import { workspaceHasProduct } from "../products/lib/ProductFunctions";

// Items to add:
// - Suggest other TLDs for an existing domain name (eg: ws owns domain "example.com", show DomainCards to buy domains "example.nl", "example.eu", etc.)
// - Promo for hosting, SB, EM if ws doesn't yet own these products

const UserDashboard = ({ auth: { user, activeWorkspace } }) => {
  const WS_ROLES = [WS_ROLE_MEMBER, WS_ROLE_ADMIN, WS_ROLE_OWNER];

  // Promos: user needs to be at least admin so it can make purchases
  const PRODUCT_PROMOS = [
    {
      name: "Domains - Register new domain",
      title: translate("cUserDashboard.registerNewDomain", false, null),
      component: <RegisterNewDomain />,
      wsRoleRequired: WS_ROLE_ADMIN,
      productsOwned: [],
    },
  ];

  // Dashboard items: activeWorkspace needs to be subscribed to the particular product
  const DASHBOARD_ITEMS = [
    {
      name: "Hosting - Storage usage",
      title: translate("cUserDashboard.hostingStorageUsage", false, null),
      component: <StorageUsage />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.hosting],
    },
    // {
    //   name: "Hosting - Site visitors",
    //   title: translate("cUserDashboard.siteVisitors", false, null),
    //   component: <Visitors />,
    //   wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
    //   productsOwned: [PRODUCT_NAMES.hosting],
    // },
    {
      name: "Hosting - Bandwidth used",
      title: translate("cUserDashboard.bandwidthUsed", false, null),
      component: <Bandwidth />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.hosting],
    },
    {
      name: "Hosting - Domain overview",
      title: translate("cUserDashboard.domainOverview", false, null),
      component: <DomainsByTld />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.hosting],
    },
    {
      name: "Domains/Hosting - DNS overview",
      title: translate("cListDnsDomains.summaryDns", false, null),
      component: <DnsSummary />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.domains, PRODUCT_NAMES.hosting],
    },
    {
      name: "Sitebuilder - Projects",
      title: translate("cUserDashboard.sbProjects", false, null),
      component: <SbProjectsSummary />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.sitebuilder],
    },
    {
      name: "Email marketing - Interaction",
      title: translate("cUserDashboard.emInteraction", false, null),
      component: <EmEmailsSummary />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.emailMarketing],
    },
    {
      name: "Email marketing - Sent mails",
      title: translate("cUserDashboard.emMailsSent", false, null),
      component: <EmEmailsSent />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.emailMarketing],
    },
    {
      name: "Audiences - # entries",
      title: translate("cUserDashboard.audiences", false, null),
      component: <Audiences />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [PRODUCT_NAMES.emailMarketing, PRODUCT_NAMES.sitebuilder],
    },
    {
      name: "Your workspaces",
      title: translate("cUserDashboard.yourWorkspaces", false, null),
      component: <YourWorkspaces />,
      wsRoleRequired: WS_ROLES.indexOf(WS_ROLE_MEMBER),
      productsOwned: [],
    },
  ];

  const [dashboardItemsToShow, setDashboardItemsToShow] = useState([]);

  useEffect(() => {
    getDashboardItemsToShow();
    // eslint-disable-next-line
  }, [user, activeWorkspace]);

  const getDashboardItemsToShow = () => {
    try {
      const wsSubs = activeWorkspace.subscriptions;
      const userRole = WS_ROLES.indexOf(activeWorkspace.members.filter((member) => member.userId === user._id)[0].role);

      let dashboardItems = [];

      // If user is at least admin, then show 1 product promo
      if (userRole > 0) {
        dashboardItems.push(PRODUCT_PROMOS[0]);
      }

      // Fill remaining slots with informative dashboard items
      // Filter list of available dashboard items
      let eligibleDashboardItems = DASHBOARD_ITEMS.filter((dbItem) => {
        const roleIsOk = userRole >= dbItem.wsRoleRequired;
        const isSubscribedToProduct = wsIsSubscribedToProduct(dbItem.productsOwned, wsSubs);
        return roleIsOk && isSubscribedToProduct;
      });

      // Randomly get 3 items to show
      for (let i = 0; i < 3; i++) {
        if (eligibleDashboardItems.length === 0) {
          break;
        }
        const rnd = Math.floor(Math.random() * eligibleDashboardItems.length);
        dashboardItems.push(eligibleDashboardItems[rnd]);
        eligibleDashboardItems.splice(rnd, 1);
      }

      setDashboardItemsToShow(dashboardItems);
    } catch (error) {
      console.error(error);
      setDashboardItemsToShow([]);
    }
  };

  const wsIsSubscribedToProduct = (requiredProducts, wsSubs) => {
    if (requiredProducts.length === 0) {
      return true;
    }
    if (requiredProducts.some((productName) => workspaceHasProduct(wsSubs, productName))) {
      return true;
    }
    return false;
  };

  return (
    <div className="row mt-5">
      {dashboardItemsToShow.length > 0 &&
        dashboardItemsToShow.map((dbItem) => (
          <DashboardItem
            key={dbItem.name}
            colClass={"col-12 col-lg-6"}
            title={dbItem.title}
            titleClass={"text-primary text-center"}
            content={dbItem.component}
          />
        ))}
    </div>
  );
};

UserDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UserDashboard);
