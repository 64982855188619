import React, { useState, useRef, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { checkEmailPassword, checkVerificationCode } from "../actions/auth";

const Login = ({ auth: { isAuthenticated }, checkEmailPassword, checkVerificationCode }) => {
  const refDigit1 = useRef();
  const refDigit2 = useRef();
  const refDigit3 = useRef();
  const refDigit4 = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const [stage, setStage] = useState("login");
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  useEffect(() => {
    setStage("login");
    // eslint-disable-next-line
  }, []);

  const clickLogin = async () => {
    setErrorMsg("");
    if (email !== "" && password !== "") {
      setLocalLoading({ isLoading: true, msg: "Checking email and password combination.." });
      let success = await checkEmailPassword(email, password);
      setLocalLoading({ isLoading: false, msg: "" });
      if (success) {
        setStage("verification");
      } else {
        setErrorMsg("Login failed");
      }
    }
  };

  const clickVerify = async () => {
    if (digit1 !== "" && digit2 !== "" && digit3 !== "" && digit4 !== "") {
      setLocalLoading({ isLoading: true, msg: "Checking verification code.." });
      let success = await checkVerificationCode(email, `${digit1}${digit2}${digit3}${digit4}`);
      setLocalLoading({ isLoading: false, msg: "" });
      if (!success) {
        setStage("login");
        setErrorMsg("Login failed");
      }
    }
  };

  const typeDigit = (digit, value) => {
    if (digit === 1) {
      setDigit1(value);
      refDigit2.current.focus();
    }
    if (digit === 2) {
      setDigit2(value);
      refDigit3.current.focus();
    }
    if (digit === 3) {
      setDigit3(value);
      refDigit4.current.focus();
    }
    if (digit === 4) {
      setDigit4(value);
    }
  };

  // Redirect if already logged in
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#DFDBE5",
        backgroundImage: `linear-gradient(225deg, rgba(246, 129, 0, 0.6) 0%, rgba(229, 42, 5, 0.6) 75%), url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-12 col-sm-9 col-md-6 col-lg-4 py-5 px-4 rounded text-center"
            style={{ backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0px 0px 5px 3px rgba(0, 0, 0, 0.2)" }}
          >
            <img src="/assets/img/satonda_gradient.png" alt="Satonda" className="mb-5" style={{ width: "200px" }} />
            {localLoading.isLoading ? (
              <div className="text-center my-5">
                <div className="spinner-border spinner-border-sm text-primary"></div>
                <p className="mb-0 mt-2 text-primary fontSize08">{localLoading.msg}</p>
              </div>
            ) : (
              <>
                {stage === "login" ? (
                  <>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-user"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="fa-solid fa-key"></i>
                      </span>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {errorMsg !== "" && <p className="mb-3 text-center text-danger">{errorMsg}</p>}
                    <button className="btn btn-primary w-100" onClick={clickLogin}>
                      Log in
                    </button>
                    <p className="d-block mt-4 mb-0 fontSize08">
                      Forgot password?{" "}
                      <Link to="/forgot-password" className="link-primary">
                        Click here to reset.
                      </Link>
                    </p>
                  </>
                ) : (
                  <>
                    <div className="row mb-3">
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control text-center"
                          maxLength={1}
                          value={digit1}
                          onChange={(e) => typeDigit(1, e.target.value)}
                          ref={refDigit1}
                          required
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control text-center"
                          maxLength={1}
                          value={digit2}
                          onChange={(e) => typeDigit(2, e.target.value)}
                          ref={refDigit2}
                          required
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control text-center"
                          maxLength={1}
                          value={digit3}
                          onChange={(e) => typeDigit(3, e.target.value)}
                          ref={refDigit3}
                          required
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control text-center"
                          maxLength={1}
                          value={digit4}
                          onChange={(e) => typeDigit(4, e.target.value)}
                          ref={refDigit4}
                          required
                        />
                      </div>
                    </div>
                    <button className="btn btn-primary w-100" onClick={clickVerify}>
                      Verify code
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  checkEmailPassword: PropTypes.func.isRequired,
  checkVerificationCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { checkEmailPassword, checkVerificationCode })(Login);
