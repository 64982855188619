import { PERIOD_ANNUALLY, PRODUCT_NAMES } from "../../lib/ProductVars";
import { CART_ITEM_ACTION_TYPES } from "../../../../actions/lib/cartFunctionsVars";
import { getCurrency } from "../../../../lib/Currencies";
import { formListPrice, getFxRate } from "../../lib/ProductFunctions";

export const isValidDomain = (str) => {
  // eslint-disable-next-line no-useless-escape
  return str.match(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{2,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/) !== null;
};

export const isValidIPv4 = (str) => {
  return str.match(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/) !== null;
};

export const isValidIPv6 = (str) => {
  const regexExp =
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi;
  return regexExp.test(str);
};

export const getTldFromDomain = (domain) => {
  // Version: 18/11/2023

  // return (domain.match(/(^www\.)?([a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1})\.(.+)/) || ["", "", "", ""])[3];
  // Return everything from first "." (e.g., in case you want to return "co.uk" iso "uk"):
  // return (domain.replace(/^www./, "").match(/^\w+?\.(.+?)$/) || ["", ""])[1];
  // Return only what comes after the last "." (e.g., in case you want to return "uk" iso "co.uk"):
  return (domain.replace(/^www./, "").match(/^.+?\.(\w+?)$/) || ["", ""])[1];
};

export const addPricesToDomainSuggestions = (data, offeredTlds) => {
  // Version: 19/11/2023

  // data = [{ domainName, available, premium, error }, ... ]
  return data
    .map((item) => {
      const { domainName } = item;
      const tld = getTldFromDomain(domainName);
      // Add price/currency data
      const tldData = offeredTlds.filter((t) => t.tld === tld)[0];
      if (typeof tldData === "undefined") {
        return { ...item, error: true };
      }
      return { ...item, ...tldData };
    })
    .filter((item) => !item.error);
};

export const getDomainProductPackage = (workspace, products, allTlds, domainData, priceWsCurr, domainPriceAction) => {
  // Version 18/11/2023

  // domainData = { domainName, tld, available, premium, error, currency, prices: { register, registerDiscount, renew, transfer, restore } }
  // priceWsCurr = list price for domainPriceAction quoted in ws currency (getCurrency(workspace.location.priceCountry))
  // domainPriceAction = "register", "registerDiscount", "renew", "transfer" or "restore"
  let product = products.filter((product) => product.name === PRODUCT_NAMES.domains)[0];
  return {
    brandId: workspace.brandId,
    productId: product._id,
    productName: product.name,
    packageId: allTlds.filter((tld) => tld.tld === domainData.tld)[0]._id,
    packageName: domainData.domainName,
    packageDetails: [],
    currency: getCurrency(workspace.location.priceCountry).currencyCode,
    countryCode: workspace.location.countryCode,
    selectedPeriod: PERIOD_ANNUALLY,
    payableAtCheckout: priceWsCurr,
    cartItemActionType: CART_ITEM_ACTION_TYPES.new,
    additionalData: { domainPriceAction },
    // For clickAddToCart functionality
    trialPeriodInMonths: 0,
  };
};

export const getDomainActionPrice = (domainData, domainPriceAction, wsCurrencyCode, fxRates) => {
  // domainData = { domainName, tld, available, premium, error, currency, prices: { register, registerDiscount, renew, transfer, restore } }
  // domainPriceAction = "register", "registerDiscount", "renew", "transfer" or "restore"
  // wsCurrencyCode = getCurrency(activeWorkspace.location.priceCountry).currencyCode

  // Price calculation:
  // - data has the currency RTR charges in and the price set on admin panel
  // - Price to user needs to be quoted in the currency of the workspace
  // - Need to get the FX rate from the workspace currency to the RTR currency:
  //   1. Translate from RTR's currency to EUR
  //   2. Translate from EUR to workspace currency
  //   => Net FX rate from RTR to ws: 1 / getFxRate(data.currency, fxRates) * getFxRate(wsCurrency, fxRates)

  const price = (domainData.prices[domainPriceAction] / getFxRate(domainData.currency, fxRates)) * getFxRate(wsCurrencyCode, fxRates);

  if (domainData.prices[domainPriceAction] < 0.5) {
    return price;
  }

  return formListPrice(price, 1, true);
};
