import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../../translations/translations";
import { STRIPE_PAYMENT_METHODS } from "../../../lib/StripeLib";

const StoredPaymentMethod = ({ auth: { activeWorkspace } }) => {
  const formatPaymentMethod = (savedMethod) => {
    const formattedPaymentMethod = STRIPE_PAYMENT_METHODS[savedMethod];
    if (typeof formattedPaymentMethod === "undefined") {
      return translate("cUserProfile.noStoredPaymentMethodFound", false, null);
    }
    return formattedPaymentMethod;
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cUserProfile.storedPaymentMethod", false, null)}</h2>
      {activeWorkspace === null ||
      typeof activeWorkspace.stripeStoredPaymentMethod === "undefined" ||
      activeWorkspace.stripeStoredPaymentMethod === "" ? (
        <p className="text-italic">{translate("cUserProfile.noStoredPaymentMethodFound", false, null)}</p>
      ) : (
        <p>
          <span className="text-bold">{translate("cUserProfile.storedPaymentMethod", false, null)}:</span>{" "}
          {formatPaymentMethod(activeWorkspace.stripeStoredPaymentMethod)}
        </p>
      )}
    </>
  );
};

StoredPaymentMethod.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(StoredPaymentMethod);
