import React from "react";

const DashboardErrorItem = ({ msg }) => {
  return (
    <div className="px-4 w-100">
      <div className="w-100 py-3 m-0 alert alert-warning rounded-3 border border-warning fontSize09 text-center">
        <p className="m-0">{msg}</p>
      </div>
    </div>
  );
};

export default DashboardErrorItem;
