export const cPulseCheck = {
  howFeeling: {
    en: "How are you feeling today about Satonda?",
    nl: "Wat vindt u van Satonda?",
  },
  dismiss: {
    en: "Dismiss",
    nl: "Negeer",
  },
  thankYou: {
    en: "Thank you!",
    nl: "Dank u!",
  },
  pleaseClick: {
    en: "Please click",
    nl: "Klik",
  },
  here: {
    en: "here",
    nl: "hier",
  },
  provideMoreFeedback: {
    en: "if you want to give us more feedback.",
    nl: "als u ons meer gedetailleerde feedback wilt geven.",
  },
};
