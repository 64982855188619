import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckout from "../../components/checkout/StripeCheckout";
import Spinner from "../../components/layout/Spinner";

import { getTotalAmountAtCheckoutIncVat } from "../../components/products/lib/ProductFunctions";
import { ORIGIN_CART } from "../../components/cart/lib/CartVars";
import FormatNumber from "../../components/layout/FormatNumber";
import { translate } from "../../translations/translations";
import { getCurrency } from "../../lib/Currencies";
import { getVatRate, isWsVatNumberConfirmed } from "../../lib/VatRates";
import { API_URL } from "../../lib/GeneralVars";
import { getStripeElementsOptions } from "../../lib/StripeLib";

// const stripePromise = loadStripe(
//   process.env.NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_PUBLISH_KEY_PROD : process.env.REACT_APP_STRIPE_PUBLISH_KEY_TEST
// );
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY_PROD);

const Payment = ({ auth: { user, activeWorkspace }, cart: { sessionId, cartItems, cartType } }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [totalAmountIncVAT, setTotalAmountIncVAT] = useState(0);

  useEffect(() => {
    if (activeWorkspace !== null && cartItems.length > 0) {
      setTotalAmountIncVAT(
        getTotalAmountAtCheckoutIncVat(
          cartType,
          cartItems,
          getVatRate(activeWorkspace.location.countryCode, activeWorkspace.customerType, isWsVatNumberConfirmed(activeWorkspace))
        )
      );

      // If payment page is loaded as a return after a payment submission, don't create a new payment intent
      // Rather, use returned payment intent and then redirect user to /status/:status page in StripeCheckout component
      // If payment page is being loaded from scratch, create new payment intent
      const existingClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
      if (existingClientSecret) {
        setClientSecret(existingClientSecret);
      } else {
        createPaymentIntent();
      }
    }
    // eslint-disable-next-line
  }, []);

  const createPaymentIntent = async () => {
    const res = await fetch(`${API_URL}/stripe/createPaymentIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        wsId: activeWorkspace._id,
        cartId: sessionId,
        name: `${user.firstName} ${user.lastName}`,
        email: activeWorkspace.email,
        customerId: typeof activeWorkspace.stripeCustomerId === "undefined" ? null : activeWorkspace.stripeCustomerId,
        vatRate: getVatRate(activeWorkspace.location.countryCode, activeWorkspace.customerType, isWsVatNumberConfirmed(activeWorkspace)),
        origin: ORIGIN_CART,
      }),
    });
    const data = await res.json();
    setClientSecret(data.clientSecret);
  };

  const options = getStripeElementsOptions(clientSecret, activeWorkspace, user);

  // {clientSecret && (
  //   <Elements options={options} stripe={stripePromise}>
  //     <StripeCheckout
  //       options={options}
  //       curr={getCurrency(activeWorkspace.location.priceCountry).currencySign}
  //       amount={totalAmountIncVAT}
  //       checkoutLocation={"payment"}
  //       invoiceId={""}
  //     />
  //   </Elements>
  // )}

  return (
    <>
      <h1 className="text-primary">{translate("pCheckoutPayment.checkout", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : sessionId === "" || cartItems.length === 0 || totalAmountIncVAT === 0 ? (
        <p className="text-italic">{translate("pCart.noItemsInCart", false, null)}</p>
      ) : clientSecret ? (
        <>
          <p className="text-secondary text-bold">
            {translate("pCheckoutPayment.selectPaymentMethod", false, null)} {getCurrency(activeWorkspace.location.priceCountry).currencySign}
            <FormatNumber number={totalAmountIncVAT} formatStr={"0.00"} />:
          </p>
          {/* Stripe payment element */}
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckout
              options={options}
              curr={getCurrency(activeWorkspace.location.priceCountry).currencySign}
              amount={totalAmountIncVAT}
              checkoutLocation={"payment"}
              invoiceId={""}
            />
          </Elements>
        </>
      ) : (
        <div className="mt-5">
          <Spinner msg={translate("pCheckoutPayment.loading", false, null)} />
        </div>
      )}
    </>
  );
};

Payment.propTypes = {
  cart: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Payment);
