import React from "react";

import { translate } from "../../translations/translations";

const NoTickets = ({ clickCreateNewTicket, boolOpenTickets }) => {
  return (
    <div className="text-center">
      <span className="text-secondary fontSize25">
        <i className="fa-solid fa-ticket" />
      </span>
      <h4 className="text-secondary mt-3">
        {boolOpenTickets ? translate("cSupportComponents.noOpenTickets", false, null) : translate("cSupportComponents.noClosedTickets", false, null)}
      </h4>
      {boolOpenTickets && (
        <button className="btn btn-primary mt-4" onClick={clickCreateNewTicket}>
          {translate("cSupportComponents.createNewTicket", false, null)}
        </button>
      )}
    </div>
  );
};

export default NoTickets;
