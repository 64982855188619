import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setAlert } from "../../actions/alert";
import { createNewAudience } from "../../actions/audiences";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { filesSelected } from "./lib/audienceFunctions";

import { translate } from "../../translations/translations";

const CreateNewAudience = ({ auth: { user, activeWorkspace }, setAlert, createNewAudience }) => {
  const fileSelectInput = useRef();

  const [audienceName, setAudienceName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [canCreateAdditional, setCanCreateAdditional] = useState(true);
  const [csvData, setCsvData] = useState([]);

  const onType = (val) => {
    setAudienceName(val.replace(/[^A-Za-z0-9_-\s]/g, ""));
  };

  const clickCreateNewAudience = () => {
    if (audienceName !== "") {
      createNewAudience(capitalizeFirstLetter(audienceName.replace(/^\s+/, "").replace(/\s+$/, "").replace(/\s+/g, " ")), csvData);
      setAudienceName("");
      clearFiles();
    }
  };

  // const parseCsv = (fileContent) => {
  //   try {
  //     let lines = fileContent.split("\n").map((line) => line.replace(/[\r|\t|\n]/g, ""));
  //     let headers = lines[0].split(",").map((val) =>
  //       val
  //         .replace(/^"/, "")
  //         .replace(/"$/, "")
  //         .split(/\s+/)
  //         .map((val, i) => (i === 0 ? val : capitalizeFirstLetter(val)))
  //         .join("")
  //     );
  //     console.log("parseCsv headers:", headers);
  //     let res = [];
  //     for (let i = 1; i < lines.length; i++) {
  //       let lineValues = lines[i].split(",").map((val) => val.replace(/^"/, "").replace(/"$/, ""));
  //       let lineObj = {};
  //       headers.forEach((header, j) => {
  //         let lineVal = typeof lineValues[j] === "undefined" ? "" : lineValues[j];
  //         if (new Date(lineVal).toString() !== "Invalid Date") {
  //           lineVal = new Date(lineVal);
  //         }
  //         if (!Number.isNaN(parseFloat(lineVal)) && Number.isFinite(parseFloat(lineVal))) {
  //           lineVal = parseFloat(lineVal);
  //         }
  //         lineObj = { ...lineObj, [header]: lineVal };
  //       });
  //       if (Object.values(lineObj).filter((val) => val !== "").length > 0) {
  //         // Skip lines that have only empty values
  //         res.push(lineObj);
  //       }
  //     }
  //     if (res.length === 0) {
  //       setAlert("No CSV data found in file.", "danger");
  //     }
  //     setCsvData(res);
  //   } catch (error) {
  //     console.error(error);
  //     setAlert("Couldn't parse CSV. Please check the file has no errors.", "danger");
  //     setCsvData([]);
  //   }
  // };

  // const filesSelected = async (e) => {
  //   const files = await fromEvent(e);
  //   if (files.length > 0 && CSV_MIME_TYPES.includes(files[0].type) && (files[0].name.match(/.(\w{3})$/) || ["", ""])[1] === "csv") {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => parseCsv(reader.result), false);
  //     if (files[0]) {
  //       reader.readAsText(files[0]);
  //     }
  //   } else {
  //     setAlert("Only CSV files are allowed", "danger");
  //   }
  // };

  const clearFiles = () => {
    setCsvData([]);
    fileSelectInput.current.value = "";
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-9 col-xl-6">
          <h2 className="text-secondary mt-5 mb-3">{translate("cCreateNewAudience.createNewAudienceFromFile", false, null)}</h2>

          <input
            type="text"
            className={`form-control mb-3`}
            placeholder={
              canCreateAdditional
                ? translate("cCreateNewAudience.audienceName", false, null)
                : translate("cCreateNewAudience.pleaseUpgrade", false, null)
            }
            value={audienceName}
            onChange={(e) => onType(e.target.value)}
            disabled={!canCreateAdditional}
          />

          <div className="posRelative">
            <input type="file" className="form-control" onChange={(e) => filesSelected(e, setAlert, setCsvData, clearFiles)} ref={fileSelectInput} />
            {csvData.length > 0 && (
              <i
                className="fa-solid fa-xmark cursorPointer text-danger"
                title={translate("cCreateNewAudience.clearFile", false, null)}
                style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: "1rem" }}
                onClick={clearFiles}
              />
            )}
          </div>
          <p className="mt-1 mb-3 fontSize08 text-gray text-italic">{translate("cCreateNewAudience.optional", false, null)}</p>

          <button className="btn btn-success px-4" disabled={audienceName === ""} onClick={clickCreateNewAudience}>
            {translate("cCreateNewAudience.createAudience", false, null)}
          </button>
        </div>
      </div>
    </>
  );
};

CreateNewAudience.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  createNewAudience: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, createNewAudience })(CreateNewAudience);
