import React from "react";

import ListDnsDomains from "../../../components/products/dns/ListDnsDomains";

import { translate } from "../../../translations/translations";

const ManageDNS = () => {
  return (
    <>
      <h1 className="text-primary">{translate("pDomainsManageDns.manageDns", false, null)}</h1>
      <ListDnsDomains />
    </>
  );
};

export default ManageDNS;
