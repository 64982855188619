import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const GlobalLoading = ({ globalLoading: { globalLoading } }) => {
  return globalLoading.length > 0 ? (
    <div className="text-center mt-7">
      <div className="spinner-border spinner-border-sm text-primary"></div>
      <p className="mb-0 mt-2 text-primary fontSize08">{globalLoading[0].msg}</p>
    </div>
  ) : null;
};

// Specify what type a property should be
GlobalLoading.propTypes = {
  globalLoading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  globalLoading: state.globalLoading,
});

// Connect requires to specify the state you want to use as well as the methods you want to use
// You also need to add the methods you want to use as props in the function declaration on top
export default connect(
  // The state from mapStateToProps (post). If none, it set to null
  mapStateToProps,
  // An object of the methods you want to use
  null
)(GlobalLoading);
