// Bring in action types
import { SET_ALERT, REMOVE_ALERT } from "../actions/types";

// Set initialState to an empty array
const initialState = { activeAlerts: [] };

// Reducer functionality
export default function alert(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      // Return the existing alert state as well as the newly added alert (passed through via the action.payload)
      return {
        ...state,
        // Payload is { msg, alertType, id }
        activeAlerts: [...state.activeAlerts, payload],
      };
    case REMOVE_ALERT:
      // Remove a specific alert by its ID (filter through all alerts in the state and keep those whose id are not equal to the id passed through the payload)
      return {
        ...state,
        // Payload is the id of the alert to be deleted
        activeAlerts: state.activeAlerts.filter((alert) => alert.id !== payload),
      };
    default:
      return state;
  }
}
