import React from "react";
import Sidebar from "./Sidebar";

const SidebarOffCanvas = () => {
  return (
    <div className="offcanvas offcanvas-start bg-light sidebar-shadow" tabIndex="-1" id="SidebarOffCanvas" aria-labelledby="SidebarOffCanvasLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title text-dark text-bold" id="SidebarOffCanvasLabel">
          Menu
        </h5>
        <span className="cursorPointer fontSize15 trans-3 text-dark" data-bs-dismiss="offcanvas" aria-label="Close">
          <i className="fa-solid fa-xmark" />
        </span>
      </div>
      <div className="offcanvas-body">
        <Sidebar />
      </div>
    </div>
  );
};

export default SidebarOffCanvas;
