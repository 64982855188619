import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { translate } from "../../../translations/translations";
import { getWorkspaceProductPackageDetails } from "../../products/lib/ProductFunctions";
import { PRODUCT_NAMES } from "../../products/lib/ProductVars";
import DashboardErrorItem from "../DashboardErrorItem";

ChartJS.register(ArcElement, Tooltip, Legend);

const SbProjectsSummary = ({ auth: { activeWorkspace }, sitebuilder: { sbProjects } }) => {
  const [projectdata, setProjectData] = useState([]);

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getProjectData = () => {
    try {
      const sbQuota = parseInt(
        getWorkspaceProductPackageDetails(activeWorkspace.subscriptions, PRODUCT_NAMES.sitebuilder).filter((quota) => quota.name === "sbProjects")[0]
          .value
      );
      const devProjects = sbProjects.filter((sbProject) => sbProject.status === "Development").length;
      const launchedProjects = sbProjects.length - devProjects;
      setProjectData([launchedProjects, devProjects, sbQuota - launchedProjects - devProjects]);
    } catch (error) {
      setProjectData([0, 0, 0]);
    }
  };

  const data = {
    labels: [
      translate("cUserDashboard.sbProjectsLaunched", false, null),
      translate("cUserDashboard.sbProjectsUnderDev", false, null),
      translate("cUserDashboard.sbProjectsUnused", false, null),
    ],
    datasets: [
      {
        label: translate("cUserDashboard.sbProjects", false, null),
        data: projectdata,
        backgroundColor: ["rgba(32, 158, 78, 0.2)", "rgba(246, 129, 0, 0.2)", "rgba(206, 212, 218, 0.2)"],
        borderColor: ["rgba(32, 158, 78, 1)", "rgba(246, 129, 0, 1)", "rgba(206, 212, 218, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    plugins: {
      legend: {
        display: true,
      },
      // tooltip: {
      //   callbacks: {
      //     label: cbLabel,
      //     // label: (context) => {
      //     //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
      //     // },
      //     title: (context) => {
      //       return "";
      //     },
      //   },
      // },
    },
  };

  return projectdata.length === 0 || projectdata.reduce((val, add) => val + add, 0) === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noSbProjectDataFound", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

SbProjectsSummary.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, null)(SbProjectsSummary);
