// Bring in action types
import { GET_SB_PROJECTS } from "../actions/types";

// Set initialState to an empty array
const initialState = {
  sbProjects: [],
};

// Reducer functionality
export default function sitebuilder(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;
  switch (type) {
    case GET_SB_PROJECTS:
      // Payload is [ { sbProject }, ... ]
      return { ...state, sbProjects: payload };
    default:
      return state;
  }
}
