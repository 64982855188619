import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const VersionsSent = ({ auth: { activeWorkspace }, heading, arrData, arrLabels, bgColors, borderColors }) => {
  const data = {
    labels: arrLabels,
    datasets: [
      {
        label: heading,
        data: arrData,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || "";
            return `${label}: ${context.formattedValue} (${new Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(context.raw / context.dataset.data.reduce((val, add) => val + add, 0))})`;
          },
        },
      },
    },
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "250px" }}>
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

VersionsSent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(VersionsSent);
