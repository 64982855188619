import axios from "axios";
// import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import setWorkspace from "../utils/setWorkspace";
import setBrandId from "../utils/setBrandId";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  READ_NOTIFICATIONS_USER,
  READ_NOTIFICATIONS_WORKSPACE,
  UPDATE_USER_CONTACT_DETAILS,
  // SUBMIT_PULSE,
  SET_LANG_CODE,
  // Workspace
  SET_ACTIVE_WORKSPACE,
  CREATE_NEW_WORKSPACE,
  WORKSPACE_ADD_NEW_USER,
  CHANGE_USER_ROLE_WORKSPACE,
  REMOVE_USER_FROM_WORKSPACE,
  LEAVE_WORKSPACE,
  CHANGE_WORKSPACE_NAME,
  DELETE_WORKSPACE,
  ADD_NEW_CONTACTFORM_TO_WORKSPACE,
  UPDATE_WS_VALIDATED_EMAILS,
  EDIT_WS_LOCATION_DATA,
  EDIT_WS_CUSTOMER_DATA,
  UPDATE_WS_SUBS,
  // Fx rates
  SET_FX_RATES,
} from "./types";
import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";
import { loadCart, emptyShoppingCart } from "./cart";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";

import { loadAllData } from "../lib/GeneralFunctions";
import { translate } from "../translations/translations";

// ========================
// === Auth functions
// ========================

export const loadUser = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAuth.loadingUserData", false, null)));
  localStorage.token && setAuthToken(localStorage.token);
  try {
    const res = await axios.get(`${API_URL}/auth`);
    // console.log("loadUser");
    // console.log(res.data);
    if (localStorage.workspace) {
      setWorkspace(localStorage.workspace);
      dispatch({
        type: SET_ACTIVE_WORKSPACE,
        payload: res.data.workspaces.filter((ws) => ws._id === localStorage.workspace)[0],
      });
    } else {
      if (res.data.workspaces.length === 0) {
        dispatch({
          type: SET_ACTIVE_WORKSPACE,
          payload: null,
        });
        localStorage.setItem("workspace", "");
        setWorkspace("");
      } else {
        dispatch({
          type: SET_ACTIVE_WORKSPACE,
          payload: res.data.workspaces[0],
        });
        localStorage.setItem("workspace", res.data.workspaces[0]._id);
        setWorkspace(res.data.workspaces[0]._id);
      }
    }
    setBrandId(res.data.user.brandId);
    dispatch({
      type: USER_LOADED,
      payload: {
        user: res.data.user,
        langCode: res.data.user.language || "nl",
        countryCodeVat: res.data.user.country || "nl",
        countryCodePrice: res.data.user.priceCountry || "nl",
        brandId: res.data.user.brandId,
        workspaces: res.data.workspaces,
      },
    });
    localStorage.cartSessionId && dispatch(loadCart(localStorage.cartSessionId));
    dispatch(removeGlobalLoading(loadingId));
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    dispatch(removeGlobalLoading(loadingId));
  }
};

export const checkEmailPassword = (email, password) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  try {
    await axios.post(`${API_URL}/auth/checkEmailPw`, body, POST_CONFIG);
    return true;
  } catch (error) {
    console.error(error);
    dispatch({ type: LOGIN_FAIL });
    return false;
  }
};

export const checkVerificationCode = (email, code) => async (dispatch) => {
  const body = JSON.stringify({ email, code });
  try {
    const res = await axios.post(`${API_URL}/auth/verifyCode`, body, POST_CONFIG);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return true;
  } catch (error) {
    console.error(error);
    dispatch({ type: LOGIN_FAIL });
    return false;
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const setLangCode = (language) => async (dispatch) => {
  // language = 2-letter, lower-case language code (eg en, nl)
  try {
    dispatch({
      type: SET_LANG_CODE,
      payload: language,
    });
    const body = JSON.stringify({ language });
    axios.post(`${API_URL}/users/setLanguage`, body, POST_CONFIG);
  } catch (error) {
    console.error(error);
  }
};

// ==========================
// === Workspace functions ==
// ==========================

export const setActiveWorkspace = (workspace) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_WORKSPACE,
    payload: workspace,
  });
  localStorage.setItem("workspace", workspace._id);
  setWorkspace(workspace._id);
  // Empty shopping cart
  dispatch(emptyShoppingCart());
  // Load ws data (if this doesn't work, revert back to activeWOrkspace useEffect, but change to activeWorkspace._id)
  loadAllData(workspace);
};

export const createNewWorkspace = (wsName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAuth.creatingWs", false, null)));
  try {
    const body = JSON.stringify({ wsName });
    const res = await axios.post(`${API_URL}/workspace/create`, body, POST_CONFIG);
    dispatch({
      type: CREATE_NEW_WORKSPACE,
      payload: res.data,
    });
    localStorage.setItem("workspace", res.data.activeWsId);
    setWorkspace(res.data.activeWsId);
    dispatch(setAlert(translate("aAuth.createdNewWs", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const addNewUsersToWorkspace = (newUsers) => async (dispatch) => {
  // newUsers = [ { email, role }, ... ]
  try {
    const body = JSON.stringify({ newUsers });
    const res = await axios.post(`${API_URL}/workspace/addUsers`, body, POST_CONFIG);
    dispatch({
      type: WORKSPACE_ADD_NEW_USER,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAuth.userAddedToWs", newUsers.length === 1, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const changeUserRole = (userId, newRole, type, email) => async (dispatch) => {
  try {
    const body = JSON.stringify({ userId, newRole, type, email });
    await axios.post(`${API_URL}/workspace/changeUserRole`, body, POST_CONFIG);
    dispatch({
      type: CHANGE_USER_ROLE_WORKSPACE,
      payload: { userId, newRole, type, email },
    });
    dispatch(setAlert(translate("aAuth.userRoleUpdated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const removeUserFromWorkspace = (userId, type, email) => async (dispatch) => {
  try {
    const body = JSON.stringify({ userId, type, email });
    await axios.post(`${API_URL}/workspace/removeUser`, body, POST_CONFIG);
    dispatch({
      type: REMOVE_USER_FROM_WORKSPACE,
      payload: { userId, type, email },
    });
    dispatch(setAlert(translate("aAuth.userRemovedFromWs", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const leaveWorkspace = (wsId) => async (dispatch) => {
  try {
    const body = JSON.stringify({ wsId });
    await axios.post(`${API_URL}/workspace/leave`, body, POST_CONFIG);
    dispatch({
      type: LEAVE_WORKSPACE,
      payload: wsId,
    });
    dispatch(setAlert(translate("aAuth.youHaveLeftWs", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const changeWorkspaceName = (wsId, newWsName) => async (dispatch) => {
  try {
    const body = JSON.stringify({ wsId, newWsName });
    await axios.post(`${API_URL}/workspace/changeName`, body, POST_CONFIG);
    dispatch({
      type: CHANGE_WORKSPACE_NAME,
      payload: { wsId, newWsName },
    });
    dispatch(setAlert(translate("aAuth.changedWsName", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const deleteWorkspace = (wsId) => async (dispatch) => {
  try {
    const body = JSON.stringify({ wsId });
    await axios.post(`${API_URL}/workspace/delete`, body, POST_CONFIG);
    dispatch({
      type: DELETE_WORKSPACE,
      payload: wsId,
    });
    dispatch(setAlert(translate("aAuth.deletedWs", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const addNewContactformToWorkspace = (wsId, contactForms) => async (dispatch) => {
  dispatch({
    type: ADD_NEW_CONTACTFORM_TO_WORKSPACE,
    payload: { wsId, contactForms },
  });
};

export const updateWsValidatedEmails = (wsId, arrValidatedEmails) => async (dispatch) => {
  dispatch({
    type: UPDATE_WS_VALIDATED_EMAILS,
    payload: { wsId, arrValidatedEmails },
  });
};

export const editWorkspaceLocationInfo = (wsId, street, postal, city, countryCode) => async (dispatch) => {
  try {
    const body = JSON.stringify({ wsId, street, postal, city, countryCode });
    await axios.post(`${API_URL}/workspace/editLocationInfo`, body, POST_CONFIG);
    dispatch({
      type: EDIT_WS_LOCATION_DATA,
      payload: { wsId, locationObj: { street, postal, city, countryCode } },
    });
    dispatch(setAlert(translate("aAuth.changedWsLocationInfo", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

export const editWorkspaceCustomerInfo = (wsId, customerType, companyName, businessVatNumber) => async (dispatch) => {
  try {
    const body = JSON.stringify({ wsId, customerType, companyName, businessVatNumber });
    await axios.post(`${API_URL}/workspace/editCustomerInfo`, body, POST_CONFIG);
    dispatch({
      type: EDIT_WS_CUSTOMER_DATA,
      payload: { wsId, customerType, companyName, businessVatNumber },
    });
    dispatch(setAlert(translate("aAuth.changedWsCustomerInfo", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAuth.errorOccured", false, null), "danger"));
  }
};

// ========================
// === User account management
// ========================

// Update user contact details
export const updateUserContactDetails = (email, firstName, lastName, phoneNumber, companyName, street, postal, city, country) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_CONTACT_DETAILS,
    payload: { email, firstName, lastName, phoneNumber, companyName, street, postal, city, country },
  });
};

// Function - Cancel account
export const cancelAccount = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAuth.cancellingAccount", false, null)));
  try {
    await axios.get(`${API_URL}/users/cancelAccount`);
    dispatch({
      type: ACCOUNT_DELETED,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

// Submit the daily pulse check
export const submitPulse = (pulseValue) => async (dispatch) => {
  try {
    // Dispatch first, to keep track locally and not keep double-asking if there's a server issue
    let pulseSubmission = {
      date: new Date(),
      value: pulseValue,
    };
    // Don't dispatch, so that user object doesn't change and thus not requiring a re-render
    // dispatch({
    //   type: SUBMIT_PULSE,
    //   payload: pulseSubmission,
    // });
    // Send to backend
    const body = JSON.stringify({ pulseSubmission });
    await axios.post(`${API_URL}/users/submitPulse`, body, POST_CONFIG);
    return true;
  } catch (error) {
    return false;
  }
};

// =========================
// === Read notifications ==
// =========================

// Set selected notifications to { read: true }
export const readNotificationsUser = (notifications) => async (dispatch) => {
  // notifications = [ { _id, date, read, text }, ... ]
  let notificationIds = notifications.map((n) => n._id);
  try {
    const body = JSON.stringify({ notificationIds });
    await axios.post(`${API_URL}/users/readNotifications`, body, POST_CONFIG);
    // Settimeout to update reducer a bit later, to let css fade effect work
    setTimeout(() => {
      dispatch({
        type: READ_NOTIFICATIONS_USER,
        payload: notificationIds,
      });
    }, 1000);
  } catch (error) {
    console.error(error);
  }
};

export const readNotificationsWorkspace = (wsId, notifications) => async (dispatch) => {
  // notifications = [ { _id, date, read, text }, ... ]
  let notificationIds = notifications.map((n) => n._id);
  try {
    const body = JSON.stringify({ wsId, notificationIds });
    await axios.post(`${API_URL}/workspace/readNotifications`, body, POST_CONFIG);
    // Settimeout to update reducer a bit later, to let css fade effect work
    setTimeout(() => {
      dispatch({
        type: READ_NOTIFICATIONS_WORKSPACE,
        payload: { wsId, notificationIds },
      });
    }, 1000);
  } catch (error) {
    console.error(error);
  }
};

// ====================
// === Subscriptions ==
// ====================

// Set subscription isMarkedForCancellation value
export const setSubToCancel = (wsId, sub, boolToCancel) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAuth.updatingSub", false, null)));
  try {
    const body = JSON.stringify({ sub, boolToCancel });
    const res = await axios.post(`${API_URL}/workspace/setSubToCancel`, body, POST_CONFIG);
    dispatch({
      type: UPDATE_WS_SUBS,
      payload: { wsId, subscriptions: res.data },
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

// Return a sub and get a refund
export const returnSub = (wsId, sub) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAuth.returningSub", false, null)));
  try {
    const body = JSON.stringify({ sub });
    const res = await axios.post(`${API_URL}/workspace/returnSub`, body, POST_CONFIG);
    dispatch({
      type: UPDATE_WS_SUBS,
      payload: { wsId, subscriptions: res.data },
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

// ===============
// === FX rates ==
// ===============

export const getFxRates = () => async (dispatch) => {
  // Version: 19/10/2023
  try {
    const res = await axios.get(`${API_URL}/admin/finance/getFxRate/all/latest`);
    dispatch({
      type: SET_FX_RATES,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
};
