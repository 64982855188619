export const pEmCampaignAudit = {
  campaignNotFound: {
    en: "Campaign not found.",
    nl: "Campagne niet gevonden.",
  },
  notFound: {
    en: "Not found",
    nl: "Niet gevonden",
  },
  noMoreRecipients: {
    en: "There are no more recipients",
    nl: "Er zijn geen verdere ontvangers",
  },
  seeAdnlRecipients: {
    en: "See additional recipients",
    nl: "Bekijk verdere ontvangers",
  },
  showingRecipients: {
    en: "Showing recipients",
    nl: "Ontvangers",
  },
  of: {
    en: "of",
    nl: "van",
  },
  auditDetailsFor: {
    en: "Audit details for",
    nl: "Audit informatie voor",
  },
  noCampaignRecipients: {
    en: "No recipients found for campaign",
    nl: "Geen ontvangers gevonden voor deze campaigne",
  },
  recipient: {
    en: "Recipient",
    nl: "Ontvanger",
  },
  sentAt: {
    en: "Sent at",
    nl: "Verzonden",
  },
  emailVersionSent: {
    en: "Email version sent",
    nl: "Email versie",
  },
  received: {
    en: "Received?",
    nl: "Ontvangen?",
  },
  timesOpened: {
    en: "Times opened",
    nl: "Keer geopend",
  },
  clickedLinks: {
    en: "Clicked links",
    nl: "Geklikte links",
  },
  unsubd: {
    en: "Unsubscribed?",
    nl: "Afgemeld?",
  },
  version: {
    en: "Version",
    nl: "Versie",
  },
  yes: {
    en: "Yes",
    nl: "Ja",
  },
  no: {
    en: "No",
    nl: "Nee",
  },
  seePdf: {
    en: "See email that was sent and save to PDF",
    nl: "Bekijk PDF versie van verzonden email",
  },
};
