import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import ListTickets from "../components/support/ListTickets";
import NoTickets from "../components/support/NoTickets";
import CreateNewTicket from "../components/support/CreateNewTicket";
import Spinner from "../components/layout/Spinner";
import { API_URL } from "../lib/GeneralVars";
import { setAlert } from "../actions/alert";
import { translate } from "../translations/translations";

const Support = ({ auth: { user }, setAlert }) => {
  const btnCreateNewTicket = useRef();

  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  useEffect(() => {
    user !== null && getUserTickets();
    // eslint-disable-next-line
  }, [user]);

  const getUserTickets = async () => {
    setLocalLoading({ isLoading: true, msg: translate("pSupport.creatingNewTicket", false, null) });
    try {
      const res = await axios.get(`${API_URL}/support/tickets`);
      setOpenTickets(res.data.filter((ticket) => ticket.isOpen));
      setClosedTickets(res.data.filter((ticket) => !ticket.isOpen));
    } catch (error) {
      console.error(error);
      setAlert(translate("common.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const clickCreateNewTicket = () => {
    btnCreateNewTicket.current.click();
  };

  return (
    <>
      <h1 className="text-primary mb-5">{translate("pSupport.support", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : localLoading.isLoading ? (
        <div className="mt-5">
          <Spinner msg={localLoading.msg} />
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-10 col-xl-8">
            {/* Nav tabs */}
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#open_tickets">
                  <span className="d-block d-md-none">{translate("pSupport.openTicketsShort", false, null)}</span>
                  <span className="d-none d-md-block">{translate("pSupport.openTickets", false, null)}</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#closed_tickets">
                  <span className="d-block d-md-none">{translate("pSupport.closedTicketsShort", false, null)}</span>
                  <span className="d-none d-md-block">{translate("pSupport.closedTickets", false, null)}</span>
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a className="nav-link bg-light link-dark text-bold" data-bs-toggle="tab" href="#create_new_ticket" ref={btnCreateNewTicket}>
                  <span className="d-block d-md-none">{translate("pSupport.newTicketShort", false, null)}</span>
                  <span className="d-none d-md-block">{translate("pSupport.newTicket", false, null)}</span>
                </a>
              </li>
            </ul>

            {/* Content */}
            <div className="tab-content border border-top-0 rounded border-light p-3">
              <div id="open_tickets" className="tab-pane px-0 py-3 active">
                {openTickets.length === 0 ? (
                  <NoTickets clickCreateNewTicket={clickCreateNewTicket} boolOpenTickets={true} />
                ) : (
                  <ListTickets
                    tickets={openTickets}
                    status={"open"}
                    setLocalLoading={setLocalLoading}
                    getUserTickets={getUserTickets}
                    lang={user.language}
                  />
                )}
              </div>
              <div id="closed_tickets" className="tab-pane px-0 py-3 fade">
                {closedTickets.length === 0 ? (
                  <NoTickets clickCreateNewTicket={clickCreateNewTicket} boolOpenTickets={false} />
                ) : (
                  <ListTickets
                    tickets={closedTickets}
                    status={"closed"}
                    setLocalLoading={setLocalLoading}
                    getUserTickets={getUserTickets}
                    lang={user.language}
                  />
                )}
              </div>
              <div id="create_new_ticket" className="tab-pane px-0 py-3 fade">
                <CreateNewTicket setLocalLoading={setLocalLoading} getUserTickets={getUserTickets} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Support.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(Support);
