import React, { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadAllData } from "../../lib/GeneralFunctions";

const AuthRequired = ({ auth: { user, isAuthenticated, loading, activeWorkspace } }) => {
  useEffect(() => {
    user !== null && activeWorkspace !== null && loadAllData(activeWorkspace);
    // eslint-disable-next-line
  }, [user !== null && user._id]);

  let location = useLocation();

  return !isAuthenticated && !loading ? <Navigate to="/login" state={{ from: location }} replace /> : <Outlet />;
};

AuthRequired.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AuthRequired);
