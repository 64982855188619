import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FormatDate from "../../../layout/FormatDate";

import { translate } from "../../../../translations/translations";
import { EB_URL } from "../../../../lib/GeneralVars";

const EbListProjects = ({ auth: { token, user, activeWorkspace }, emailMarketing: { ebProjects } }) => {
  // Pagination
  const PER_PAGE = 8;
  const [projectsToRender, setProjectsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  let maxPages = Math.ceil(ebProjects === null ? 0 : ebProjects.length / PER_PAGE);

  useEffect(() => {
    renderProjects();
    // eslint-disable-next-line
  }, [ebProjects, currPage]);

  const renderProjects = () => {
    const start = currPage * PER_PAGE;
    const end = start + PER_PAGE;
    setProjectsToRender(ebProjects.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const clickEditProject = (projectId) => {
    try {
      // console.log(`clickEditProject // link: ${EB_URL}/open/${token}/${activeWorkspace._id}/${projectId}`);
      window.location.replace(`${EB_URL}/open/${token}/${activeWorkspace._id}/${projectId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const Pagination = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(-1)} disabled={currPage === 0}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate("cSitebuilder.showingProjects", false, null)}{" "}
          <span className="text-bold">
            {Math.min(currPage * PER_PAGE + 1, ebProjects.length)} - {Math.min((currPage + 1) * PER_PAGE, ebProjects.length)}
          </span>{" "}
          {translate("cSitebuilder.of", false, null)} <span className="text-bold">{ebProjects.length}</span>
        </span>
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(1)} disabled={currPage + 1 === maxPages}>
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    );
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cEmailBuilder.yourProjects", false, null)}</h2>
      {ebProjects.length === 0 ? (
        <p className="text-italic m-0">{translate("cEmailBuilder.noProjectsFound", false, null)}</p>
      ) : (
        <>
          <div className="row">
            {projectsToRender.map((ebProject) => (
              <div className="col-12 col-md-6 col-xl-3 p-2" key={ebProject._id}>
                <div className="flexSameHeight justify-content-center align-items-center shadow-light rounded-3 p-3 trans-3 posRelative">
                  <Link
                    className="text-primary textHover-primary cursorPointer trans-3"
                    style={{ position: "absolute", right: "1rem", top: "1rem", fontSize: "1.25rem", zIndex: "99" }}
                    title={translate("cEmailBuilder.openMenu", false, null)}
                    to={`menu/${ebProject._id}`}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </Link>
                  <h3 className="text-primary text-center toGrow">{ebProject.projectName}</h3>
                  <span
                    className="my-4 fontSize25 text-primary bg-light rounded-circle border border-primary d-flex justify-content-center align-items-center"
                    style={{ height: "75px", width: "75px" }}
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </span>
                  <div className="text-center text-gray fontSize08 mb-4">
                    <p className="my-0">
                      <span className="text-bold">{translate("cEmailBuilder.created", false, null)}:</span>{" "}
                      <FormatDate dateString={ebProject.createdAt} lang={user.language} formatStr={"dmyy"} />
                    </p>
                    <p className="my-0">
                      <span className="text-bold">{translate("cEmailBuilder.lastChange", false, null)}:</span>{" "}
                      <FormatDate dateString={ebProject.updatedAt} lang={user.language} formatStr={"dmyy"} />
                    </p>
                  </div>
                  <button className="btn btn-primary trans-3 w-100" onClick={() => clickEditProject(ebProject._id)}>
                    {translate("cEmailBuilder.editInEb", false, null)}
                  </button>
                </div>
              </div>
            ))}
          </div>

          <Pagination />
        </>
      )}
    </>
  );
};

EbListProjects.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, null)(EbListProjects);
