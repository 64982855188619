import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../../layout/Spinner";
import { translate } from "../../../translations/translations";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import { countries } from "../../../lib/Countries";
import { editWorkspaceLocationInfo } from "../../../actions/auth";
import { validatePostalcode, getExamplePostalcode } from "../../../lib/GeneralFunctions";

const WsEditLocationInfo = ({ auth: { activeWorkspace }, editWorkspaceLocationInfo }) => {
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  const [street, setStreet] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [errMsg_street, setErrMsg_street] = useState("");
  const [errMsg_postal, setErrMsg_postal] = useState("");
  const [errMsg_city, setErrMsg_city] = useState("");
  const [errMsg_countryCode, setErrMsg_countryCode] = useState("");

  useEffect(() => {
    try {
      if (activeWorkspace !== null) {
        setStreet(activeWorkspace.location.street);
        setPostal(activeWorkspace.location.postal);
        setCity(activeWorkspace.location.city);
        setCountryCode(activeWorkspace.location.countryCode);
      }
    } catch (error) {
      setStreet("");
      setPostal("");
      setCity("");
      setCountryCode("");
    }
  }, [activeWorkspace]);

  const clickEditInfo = async () => {
    setLocalLoading({ isLoading: true, msg: translate("aAuth.updatingWsLocationInfo", false, null) });
    if (checkErrors() === 0) {
      editWorkspaceLocationInfo(
        activeWorkspace._id,
        capitalizeFirstLetter(street),
        capitalizeFirstLetter(postal),
        capitalizeFirstLetter(city),
        countryCode
      );
      resetStateVars();
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (street === "") {
      setErrMsg_street(translate("cWsEditLocationInfo.provideAddress", false, null));
      errors++;
    }
    if (postal === "" || !validatePostalcode(countryCode, postal)) {
      const postalExample = getExamplePostalcode(countryCode);
      setErrMsg_postal(translate("cUserProfile.provideValidPostalcode", false, { postalExample }));
      errors++;
    }
    if (city === "") {
      setErrMsg_city(translate("cWsEditLocationInfo.provideCity", false, null));
      errors++;
    }
    if (!countries.map((country) => country.code).includes(countryCode)) {
      setErrMsg_countryCode(translate("cWsEditLocationInfo.provideCountry", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    // setStreet("");
    // setPostal("");
    // setCity("");
    // setCountryCode("");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg_street("");
    setErrMsg_postal("");
    setErrMsg_city("");
    setErrMsg_countryCode("");
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cWsEditLocationInfo.editLocationInformation", false, null)}</h2>
      {localLoading.isLoading ? (
        <div className="mt-5">
          <Spinner msg={localLoading.msg} />
        </div>
      ) : (
        <div className="row mt-4">
          <div className="col-12 col-lg-9 col-xl-6">
            {/* Street */}
            <div className="mb-3">
              <label htmlFor="street" className="form-label">
                {translate("cWsEditLocationInfo.street", false, null)}
              </label>
              <input
                type="text"
                className={`form-control ${errMsg_street === "" ? "" : "is-invalid"}`}
                name="street"
                placeholder={translate("cWsEditLocationInfo.street", false, null)}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_street}</div>
            </div>
            {/* Postal */}
            <div className="mb-3">
              <label htmlFor="postal" className="form-label">
                {translate("cWsEditLocationInfo.postalOrZip", false, null)}
              </label>
              <input
                type="text"
                className={`form-control ${errMsg_postal === "" ? "" : "is-invalid"}`}
                name="postal"
                placeholder={translate("cWsEditLocationInfo.postalOrZip", false, null)}
                value={postal}
                onChange={(e) => setPostal(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_postal}</div>
            </div>
            {/* City */}
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                {translate("cWsEditLocationInfo.city", false, null)}
              </label>
              <input
                type="text"
                className={`form-control ${errMsg_city === "" ? "" : "is-invalid"}`}
                name="city"
                placeholder={translate("cWsEditLocationInfo.city", false, null)}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <div className="invalid-feedback">{errMsg_city}</div>
            </div>
            {/* Country */}
            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                {translate("cWsEditLocationInfo.country", false, null)}
              </label>
              <select
                className={`form-select ${errMsg_countryCode === "" ? "" : "is-invalid"}`}
                name="country"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option value="">{translate("cWsEditLocationInfo.selectCountry", false, null)}</option>
                {countries
                  .filter((country) => country.active)
                  .map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.enName}
                    </option>
                  ))}
              </select>
              <div className="invalid-feedback">{errMsg_countryCode}</div>
            </div>
            <div>
              <button className="btn btn-success w-100" onClick={clickEditInfo}>
                {translate("cWsEditLocationInfo.editLocationInformation", false, null)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WsEditLocationInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  editWorkspaceLocationInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { editWorkspaceLocationInfo })(WsEditLocationInfo);
