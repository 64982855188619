import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromEvent } from "file-selector";
import axios from "axios";

import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { setAlert } from "../../actions/alert";
import { TICKET_TOPICS, ACCEPTED_MIME_TYPES } from "./lib/supportVars";

import { translate } from "../../translations/translations";

const CreateNewTicket = ({ setLocalLoading, getUserTickets, setAlert }) => {
  const [topic, setTopic] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [errMsg_topic, setErrMsg_topic] = useState("");
  const [errMsg_subject, setErrMsg_subject] = useState("");
  const [errMsg_description, setErrMsg_description] = useState("");
  // Drag & drop file upload
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToRender, setFilesToRender] = useState([]);
  const fileInputRef = useRef();

  useEffect(() => {
    // Turn selectedFiles array into an array of items to be rendered inside the drag/drop container
    // filesToRender should only show 1 folder for any file that has the same top level parent folder
    let arrToRender = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      let obj = {
        type: "file",
        name: selectedFiles[i].name,
      };
      if (isFolder(selectedFiles[i])) {
        let folderName = getFirstFolderName(selectedFiles[i].path);
        if (arrToRender.filter((fileToCheck) => fileToCheck.type === "folder" && fileToCheck.name === folderName).length > 0) {
          // Folder name already exists => don't add again
          continue;
        } else {
          obj.type = "folder";
          obj.name = folderName;
        }
      }
      arrToRender.push(obj);
    }
    setFilesToRender(arrToRender);
    // eslint-disable-next-line
  }, [selectedFiles]);

  const clickCreateTicket = async () => {
    if (checkErrors() === 0) {
      await createTicket();
      resetStateVars();
    }
  };

  const createTicket = async () => {
    setLocalLoading({ isLoading: true, msg: translate("cSupportComponents.creatingTicket", false, null) });
    try {
      const body = JSON.stringify({
        topic,
        subject: capitalizeFirstLetter(subject),
        description: capitalizeFirstLetter(description),
        attachments: selectedFiles.map((file) => file.name.replace(/\s/g, "-")),
      });
      let res = await axios.post(`${API_URL}/support/create`, body, POST_CONFIG);
      if (selectedFiles.length > 0) {
        const formData = getFormData(selectedFiles, res.data.ticketId);
        await axios.post(`${API_URL}/support/uploadAttachments`, formData);
      }
      await getUserTickets();
      setAlert(translate("cSupportComponents.ticketHasBeenCreated", false, null), "success");
    } catch (error) {
      console.error(error);
      setAlert(translate("cSupportComponents.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (topic === "") {
      setErrMsg_topic(translate("cSupportComponents.provideTopic", false, null));
      errors++;
    }
    if (subject === "") {
      setErrMsg_subject(translate("cSupportComponents.provideSubject", false, null));
      errors++;
    }
    if (description === "") {
      setErrMsg_description(translate("cSupportComponents.describeProblem", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setTopic("");
    setSubject("");
    setDescription("");
    setSelectedFiles([]);
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg_topic("");
    setErrMsg_subject("");
    setErrMsg_description("");
  };

  // ============
  // == File upload ==
  // ============

  const getFormData = (files, ticketId) => {
    // formData.append(name, value, filename); => "name" field needs to equal the multer.array("name") in the API
    const formData = new FormData();
    // Text fields need to come before file uploads; else multer can't access req.body when its needed
    formData.append("ticketId", ticketId);
    files.forEach((file) => {
      // Add each file to formData object and replace any spaces in filename with -
      formData.append("files", file, file.name.replace(/\s/g, "-"));
    });
    return formData;
  };

  const clearFiles = () => {
    setSelectedFiles([]);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = async (e) => {
    e.preventDefault();
    const files = await fromEvent(e);
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    // Don't accept folders and only accept selected mime types
    setSelectedFiles((prevArray) => [...prevArray, ...files.filter((file) => !isFolder(file)).filter((file) => isAcceptedMimeType(file))]);
  };

  const isFolder = (file) => {
    // File = { path: "favicon.ico", name: "favicon.ico", lastModified: 1650110220241, … }
    // File = { type: "image/vnd.microsoft.icon", path: "/Satonda logo/favicon.ico", name: "favicon.ico", … }
    return file.name !== file.path;
  };

  const isAcceptedMimeType = (file) => {
    return ACCEPTED_MIME_TYPES.includes(file.type);
  };

  const fileInputClicked = (e) => {
    // If the click was on a button, don't open the file prompt
    if (!e.target.classList.contains("fileDnD_btn")) {
      fileInputRef.current.click();
    }
  };

  const filesSelected = async (e) => {
    const files = await fromEvent(e);
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  const removeItemsFromSelection = (itemToRemove) => {
    // items = an array of {type: "file" or "folder", name: "name"} from filedToRender state var
    // If type === file, remove that specific file from selectedFiles (= where file.path === item.name)
    // If type === folder, remove all files in selectedFiles that have that folder as its toplevel parent folder
    setSelectedFiles((prevArray) =>
      prevArray.filter((file) =>
        itemToRemove.type === "file" ? file.path !== itemToRemove.name : getFirstFolderName(file.path) !== itemToRemove.name
      )
    );
  };

  const getFirstFolderName = (path) => {
    return getFolderStructureFromFilePath(path)[0];
  };

  const getFolderStructureFromFilePath = (path) => {
    // eslint-disable-next-line no-useless-escape
    return (path.match(/.+?[\/|\\]/g) || [""]).map((str) => str.replace(/[\/|\\]/g, ""));
  };

  return (
    <>
      <h4 className="text-secondary">{translate("cSupportComponents.createNewSupportTicket", false, null)}</h4>

      {/* Topic */}
      <div>
        <select className={`form-select mt-4${errMsg_topic === "" ? "" : " is-invalid"}`} value={topic} onChange={(e) => setTopic(e.target.value)}>
          <option value="">{translate("cSupportComponents.selectTicketTopic", false, null)}</option>
          {Object.keys(TICKET_TOPICS).map((ticketTopic) => (
            <option key={ticketTopic} value={ticketTopic}>
              {TICKET_TOPICS[ticketTopic]}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{errMsg_topic}</div>
      </div>

      {/* Subject */}
      <div>
        <input
          type="text"
          className={`form-control mt-3${errMsg_subject === "" ? "" : " is-invalid"}`}
          placeholder={translate("cSupportComponents.ticketSubject", false, null)}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <div className="invalid-feedback">{errMsg_subject}</div>
      </div>

      {/* Description */}
      <div>
        <textarea
          className={`form-control mt-3${errMsg_description === "" ? "" : " is-invalid"}`}
          rows="7"
          placeholder={translate("cSupportComponents.describeProblemInDetail", false, null)}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <div className="invalid-feedback">{errMsg_description}</div>
      </div>

      {/* Drag/drop file drop */}
      <div className="mt-3 bg-light">
        <div
          className="posRelative d-flex justify-content-center align-items-center py-3 border border-midgray rounded cursorPointer"
          style={{ minHeight: "100px" }}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          {filesToRender.length === 0 ? (
            <div className="text-center text-dark">
              <i className="fas fa-upload fontSize15 mb-3" />
              <p className="m-0">{translate("cSupportComponents.browseOrDnD", false, null)}</p>
            </div>
          ) : (
            <>
              <span
                className="z-99 posAbs posTop posRight mt-1 me-2 cursorPointer text-danger"
                title={translate("cSupportComponents.clearFiles", false, null)}
                onClick={clearFiles}
              >
                <i className="fas fa-times fileDnD_btn" />
              </span>
              <div className="row m-0 p-0 h-100 w-100">
                {filesToRender.map((file, i) => (
                  <div key={`${file.name}${i}`} className="col-3 col-md-2 col-lg-1 p-2 text-center posRelative">
                    <span
                      className="z-99 posAbs posTop posRight me-2 cursorPointer text-danger fontSize09"
                      title={translate("cSupportComponents.removeFileFromSelection", false, null)}
                      onClick={() => removeItemsFromSelection(file)}
                    >
                      <i className="fas fa-times fileDnD_btn"></i>
                    </span>
                    <i className={`far fa-${file.type} fontSize15`}></i>
                    {file.name.length > 20 ? (
                      <p className="m-0 fontSize08 text-break" title={file.name}>
                        {file.name.substring(0, 20)}...
                      </p>
                    ) : (
                      <p className="m-0 fontSize08 text-break" title={file.name}>
                        {file.name}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <input ref={fileInputRef} className="d-none" type="file" multiple onChange={filesSelected} />
        </div>
      </div>
      <p className="mt-1 mb-0 text-italic fontSize08">{translate("cSupportComponents.allowedFileTypes", false, null)}</p>

      <button className="btn btn-primary w-100 mt-3" onClick={clickCreateTicket}>
        {translate("cSupportComponents.createTicket", false, null)}
      </button>
    </>
  );
};

CreateNewTicket.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

//   const mapStateToProps = (state) => ({
//     auth: state.auth,
//   });

export default connect(null, { setAlert })(CreateNewTicket);
