import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { editAudienceName } from "../../actions/audiences";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";

import { translate } from "../../translations/translations";

const AudienceMenuEditName = ({ editAudienceName }) => {
  let { audienceId } = useParams();

  const [audienceName, setAudienceName] = useState("");

  const onType = (val) => {
    setAudienceName(val.replace(/[^A-Za-z0-9_-\s]/g, ""));
  };

  const clickEditName = () => {
    if (audienceName !== "") {
      editAudienceName(audienceId, capitalizeFirstLetter(audienceName.replace(/^\s+/, "").replace(/\s+$/, "").replace(/\s+/g, " ")));
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cAudienceMenuEditName.editAudienceName", false, null)}</h2>
      {audienceId === "" ? (
        <p className="text-italic m-0">{translate("cAudienceMenuEditName.audienceNotFound", false, null)}</p>
      ) : (
        <div className="input-group mb-3">
          <input
            type="text"
            className={`form-control`}
            placeholder={translate("cAudienceMenuEditName.newName", false, null)}
            value={audienceName}
            onChange={(e) => onType(e.target.value)}
          />
          <button className="btn btn-outline-primary" disabled={audienceName === ""} onClick={clickEditName}>
            {translate("cAudienceMenuEditName.editName", false, null)}
          </button>
        </div>
      )}
    </>
  );
};

AudienceMenuEditName.propTypes = {
  editAudienceName: PropTypes.func.isRequired,
};

export default connect(null, { editAudienceName })(AudienceMenuEditName);
