// Bring in action types
import { GET_EB_PROJECTS, GET_PENDING_CAMPAIGNS, GET_EXECUTED_CAMPAIGNS } from "../actions/types";

// Set initialState to an empty array
const initialState = {
  ebProjects: [],
  pendingEmailCampaigns: [],
  executedEmailCampaigns: [],
};

// Reducer functionality
export default function emailMarketing(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;
  switch (type) {
    case GET_EB_PROJECTS:
      // Payload is [ { ebProject }, ... ]
      return { ...state, ebProjects: payload };
    case GET_PENDING_CAMPAIGNS:
      // Payload is [ { pendingCampaign }, ... ]
      return { ...state, pendingEmailCampaigns: payload };
    case GET_EXECUTED_CAMPAIGNS:
      // Payload is [ { executedCampaign }, ... ]
      return { ...state, executedEmailCampaigns: payload };
    default:
      return state;
  }
}
