import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { readNotificationsWorkspace } from "../../actions/auth";
import Notifications from "../../components/Notifications";
import { userIsWsAdmin } from "../../components/workspace/lib/wsFunctions";

import { translate } from "../../translations/translations";

const WsNotifications = ({ auth: { user, workspaces }, readNotificationsWorkspace }) => {
  let { wsId } = useParams();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    try {
      let ws = workspaces.filter((ws) => ws._id === wsId)[0] || null;
      if (userIsWsAdmin(ws, user)) {
        setSelectedWorkspace(ws);
        setAllNotifications(ws.notifications.sort(sortByDate));
      }
    } catch (error) {
      setSelectedWorkspace(null);
    }
    // eslint-disable-next-line
  }, [workspaces, wsId]);

  const sortByDate = (a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <h1 className="text-primary">{translate("pWsPages.wsNotifications", false, null)}</h1>
      {selectedWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <Notifications allNotifications={allNotifications} readNotifications={readNotificationsWorkspace} wsId={wsId} />
      )}
    </>
  );
};

WsNotifications.propTypes = {
  auth: PropTypes.object.isRequired,
  readNotificationsWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { readNotificationsWorkspace })(WsNotifications);
