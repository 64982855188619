import { combineReducers } from "redux";
import auth from "./auth";
import globalLoading from "./globalLoading";
import alert from "./alert";
import cart from "./cart";
import emailMarketing from "./emailMarketing";
import brandProducts from "./brandProducts";
import sitebuilder from "./sitebuilder";
import audiences from "./audiences";

export default combineReducers({
  auth,
  globalLoading,
  alert,
  cart,
  emailMarketing,
  brandProducts,
  sitebuilder,
  audiences,
});
