// Auth - Login
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// Workspace
export const SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE";
export const CREATE_NEW_WORKSPACE = "CREATE_NEW_WORKSPACE";
export const WORKSPACE_ADD_NEW_USER = "WORKSPACE_ADD_NEW_USER";
export const CHANGE_USER_ROLE_WORKSPACE = "CHANGE_USER_ROLE_WORKSPACE";
export const REMOVE_USER_FROM_WORKSPACE = "REMOVE_USER_FROM_WORKSPACE";
export const LEAVE_WORKSPACE = "LEAVE_WORKSPACE";
export const CHANGE_WORKSPACE_NAME = "CHANGE_WORKSPACE_NAME";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const ADD_NEW_CONTACTFORM_TO_WORKSPACE = "ADD_NEW_CONTACTFORM_TO_WORKSPACE";
export const UPDATE_WS_VALIDATED_EMAILS = "UPDATE_WS_VALIDATED_EMAILS";
export const EDIT_WS_LOCATION_DATA = "EDIT_WS_LOCATION_DATA";
export const EDIT_WS_CUSTOMER_DATA = "EDIT_WS_CUSTOMER_DATA";
export const UPDATE_WS_SUBS = "UPDATE_WS_SUBS";

// Site settings
export const SET_LANG_CODE = "SET_LANG_CODE";

// Auth - User account management
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const SUBMIT_PULSE = "SUBMIT_PULSE";
export const READ_NOTIFICATIONS_USER = "READ_NOTIFICATIONS_USER";
export const READ_NOTIFICATIONS_WORKSPACE = "READ_NOTIFICATIONS_WORKSPACE";
export const UPDATE_USER_CONTACT_DETAILS = "UPDATE_USER_CONTACT_DETAILS";

// Auth - FX rates
export const SET_FX_RATES = "SET_FX_RATES";

// Global loading
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

// Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Brand and products
export const SET_BRAND_DATA = "SET_BRAND_DATA";
export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_TLD_DATA = "SET_TLD_DATA";
export const BRAND_ERROR = "BRAND_ERROR";

// Shopping cart
export const PREPAY_SUB = "PREPAY_SUB";
export const CART_ERROR = "CART_ERROR";
export const SET_SESSION_CART = "SET_SESSION_CART";
export const LOAD_CART = "LOAD_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const PAY_OVERDUE_INVOICE = "PAY_OVERDUE_INVOICE";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";

// Email marketing
export const GET_EB_PROJECTS = "GET_EB_PROJECTS";
export const GET_PENDING_CAMPAIGNS = "GET_PENDING_CAMPAIGNS";
export const GET_EXECUTED_CAMPAIGNS = "GET_EXECUTED_CAMPAIGNS";
// export const XXXXXX = "XXXXXX";
// export const XXXXXX = "XXXXXX";
// export const XXXXXX = "XXXXXX";
// export const XXXXXX = "XXXXXX";

// Domains
export const GET_WS_DOMAINS = "GET_WS_DOMAINS";

// Hosting
export const ADD_HOSTED_DOMAIN_WEB = "ADD_HOSTED_DOMAIN_WEB";

// Sitebuilder
export const GET_SB_PROJECTS = "GET_SB_PROJECTS";

// Audiences
export const GET_AUDIENCES = "GET_AUDIENCES";
