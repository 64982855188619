export const cUserProfile = {
  areYouSureDeleteAccount: {
    en: "Are you sure you want to permanently cancel your account? We will delete all your data and it is therefore irreversible.",
    nl: "Weet u zeker dat u uw account wilt verwijderen? Dit verwijdert al uw data en kan daarom niet teruggedraaid worden.",
  },
  cancelAccount: {
    en: "Cancel account",
    nl: "Verwijder account",
  },
  permanentlyCancelAccount: {
    en: "Permanently cancel your account and delete all your data",
    nl: "Verwijder uw account en delete alle data",
  },
  storedPaymentMethod: {
    en: "Stored payment method",
    nl: "Bewaarde betalingsmethode",
  },
  noStoredPaymentMethodFound: {
    en: "No stored payment method found",
    nl: "Geen betalingsmethode gevonden",
  },
  updatingContactInfo: {
    en: "Updating contact information..",
    nl: "Bijwerken contactinformatie..",
  },
  serverErrorContactInfo: {
    en: "Server error while changing your contact information. Please try again.",
    nl: "Server fout tijdens het bijwerken van uw contactinformatie. Probeer het aub opnieuw.",
  },
  cannotBeEmpty: {
    en: "Mandatory field",
    nl: "Verplicht veld",
  },
  //
  //
  //
  providePw: {
    en: "Please provide a password",
    nl: "Geef aub een wachtwoord op",
  },
  pleaseConfirmPw: {
    en: "Please confirm your password",
    nl: "Bevestig uw wachtwoord",
  },
  provideFirstName: {
    en: "Please provide your first name",
    nl: "Geef aub uw voornaam op",
  },
  provideLastName: {
    en: "Please provide your last name",
    nl: "Geef aub uw achternaam op",
  },
  //
  //
  //
  provideValidEmail: {
    en: "Please provide a valid email format",
    nl: "Ongeldig email adres",
  },
  provideValidPhonenumber: {
    en: "Please provide your phone number in international format (e.g.: +31.611111111)",
    nl: "Specificeer aub uw telefoonnummer in internationaal formaat (bijv.: +31.611111111)",
  },
  provideValidCountryCode: {
    en: "Please provide a valid country",
    nl: "Ongeldig land",
  },
  provideValidPostalcode: {
    en: "Please provide a valid postal code for your selected country, such as %postalExample%",
    nl: "Geef een geldige postcode op voor uw geselecteerde land, zoals %postalExample%",
  },
  updateContactDetails: {
    en: "Update contact details",
    nl: "Update contactinformatie",
  },
  firstName: {
    en: "First name",
    nl: "Voornaam",
  },
  lastName: {
    en: "Last name",
    nl: "Achternaam",
  },
  emailAddress: {
    en: "Email address",
    nl: "Email adres",
  },
  exampleEmail: {
    en: "example@example.com",
    nl: "bij@voorbeeld.nl",
  },
  companyName: {
    en: "Company name",
    nl: "Bedrijfsnaam",
  },
  street: {
    en: "Street name and number",
    nl: "Straatnaam en nummer",
  },
  postal: {
    en: "Postal / ZIP code",
    nl: "Postcode",
  },
  city: {
    en: "City",
    nl: "Stad",
  },
  country: {
    en: "Country",
    nl: "Land",
  },
  selectCountry: {
    en: "Select country",
    nl: "Selecteer land",
  },
  phoneNumber: {
    en: "Phone number",
    nl: "Telefoonnummer",
  },
  phoneIntFormat: {
    en: "Phone number in international format (e.g.: +31.611111111)",
    nl: "Telefoonnummer in internationaal formaat (bijv.: +31.611111111)",
  },
  alsoUpdateDomainInfo: {
    en: "Update your domains' ownership data as well",
    nl: "Update ook meteen de informatie voor uw domeinnamen",
  },
  updatingEmailPrefs: {
    en: "Updating email preferences..",
    nl: "Bijwerken email voorkeuren",
  },
  emailPrefsUpdated: {
    en: "Email preferences updated!",
    nl: "Email voorkeuren bijgewerkt!",
  },
  serverErrorUpdatingEmailPrefs: {
    en: "Server error while updating email preferences. Please try again.",
    nl: "Server fout tijdens het bijwerken van uw email voorkeuren. Probeer het aub opnieuw.",
  },
  updateEmailPreferences: {
    en: "Update email preferences",
    nl: "Update email voorkeuren",
  },
  receiveEmailsProducts: {
    en: "Receive emails about new products",
    nl: "Ontvang emails over nieuwe producten",
  },
  receiveEmailsPromos: {
    en: "Receive emails about promotions",
    nl: "Ontvang emails over promoties",
  },
  updatingPw: {
    en: "Updating password..",
    nl: "Wachtwoord bijwerken..",
  },
  serverErrorPw: {
    en: "Server error while changing your password. Please try again.",
    nl: "Server fout tijdens het bijwerken van uw wachtwoord. Probeer het aub opnieuw.",
  },
  pwMinChars: {
    en: "Password need to have at least 8 characters",
    nl: "Wachtwoord moet minimaal 8 tekens bevatten",
  },
  pwsDontMatch: {
    en: "Passwords don't match",
    nl: "Wachtwoorden zijn niet hetzelfde",
  },
  newPwCantMatchOld: {
    en: "Your new password can't be the same as your old one",
    nl: "Uw nieuwe wachtwoord kan niet hetzelfde zijn als uw oude",
  },
  updatePw: {
    en: "Update password",
    nl: "Update wachtwoord",
  },
  currentPw: {
    en: "Current password",
    nl: "Huidige wachtwoord",
  },
  password: {
    en: "Password",
    nl: "Wachtwoord",
  },
  newPw: {
    en: "New password",
    nl: "Nieuw wachtwoord",
  },
  confirmNewPw: {
    en: "Confirm new password",
    nl: "Bevestig nieuw wachtwoord",
  },
  confirmPw: {
    en: "Confirm password",
    nl: "Bevestig wachtwoord",
  },
  wrongPw: {
    en: "Wrong password",
    nl: "Verkeerde wachtwoord",
  },
  serverErrorGeneral: {
    en: "A server error occured. Please try again.",
    nl: "Er is een server fout opgetreden. Probeer het aub opnieuw.",
  },
  emailAlreadyExists: {
    en: "Email address is already in use",
    nl: "Email adres bestaat al",
  },
};
