export const pEmailChangePw = {
  pwRequirements: {
    en: "Please provide a password of at least 8 characters, including at least 1 lower case, 1 upper case and 1 digit",
    nl: "Wachtwoord moet minimaal 8 tekens lang zijn, met minimaal 1 kleine letter, 1 grote letter en 1 getal",
  },
  emailAddrNotFound: {
    en: "Email address not found.",
    nl: "Email adres niet gevonden.",
  },
  updatePwFor: {
    en: "Update password for",
    nl: "Update wachtwoord voor",
  },
  password: {
    en: "Password",
    nl: "Wachtwoord",
  },
  generateSecurePw: {
    en: "Generate secure password",
    nl: "Genereer een veilig wachtwoord",
  },
  updatePw: {
    en: "Update password",
    nl: "Update wachtwoord",
  },
};
