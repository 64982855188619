import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { deleteSbProject } from "../../../../actions/sitebuilder";

import { translate } from "../../../../translations/translations";

const SbMenuDelete = ({ auth: { user }, sitebuilder: { sbProjects }, deleteSbProject }) => {
  let { projectId } = useParams();
  const navigate = useNavigate();

  const [sbProject, setSbProject] = useState(null);

  useEffect(() => {
    if (user !== null && projectId !== "") {
      setSbProject(sbProjects.filter((project) => project._id === projectId)[0] || null);
    } else {
      setSbProject(null);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  const clickDeleteProject = async () => {
    if (window.confirm(translate("cSitebuilder.areYouSureDelete", false, { projectName: sbProject.projectName }))) {
      await deleteSbProject(projectId);
      navigate(`/sitebuilder/manage`, { replace: true });
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cSitebuilder.deleteProject", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cSitebuilder.projectNotFound", false, null)}</p>
      ) : (
        <button className="btn btn-danger" onClick={clickDeleteProject}>
          {translate("cSitebuilder.deleteProject", false, null)}
        </button>
      )}
    </>
  );
};

SbMenuDelete.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
  deleteSbProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, { deleteSbProject })(SbMenuDelete);
