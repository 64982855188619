import store from "../store";

// Common
import { common } from "./translations/common";
// Pages - Main
import { pMain } from "./translations/pages/pMain";
import { pNotfound } from "./translations/pages/pNotfound";
import { pCart } from "./translations/pages/pCart";
import { pFeedback } from "./translations/pages/pFeedback";
import { pSupport } from "./translations/pages/pSupport";
// Pages - Checkout
import { pCheckoutPayment } from "./translations/pages/checkout/pCheckoutPayment";
import { pCheckoutStatus } from "./translations/pages/checkout/pCheckoutStatus";
// Pages - Contact form
import { pContactFormMessages } from "./translations/pages/contactForm/pContactFormMessages";
// Pages - Products - Audiences
import { pAudienceMain } from "./translations/pages/products/audiences/pAudienceMain";
import { pAudienceEntries } from "./translations/pages/products/audiences/pAudienceEntries";
// Pages - Products - Domains
import { pDomainsAllTlds } from "./translations/pages/products/domains/pDomainsAllTlds";
import { pDomainsManageDns } from "./translations/pages/products/domains/pDomainsManageDns";
import { pDomainsManageDomains } from "./translations/pages/products/domains/pDomainsManageDomains";
import { pDomainsTransfer } from "./translations/pages/products/domains/pDomainsTransfer";
import { pDomainsUpdOwner } from "./translations/pages/products/domains/pDomainsUpdOwner";
import { pDomainsMain } from "./translations/pages/products/domains/pDomainsMain";
// Pages - Products - Email marketing
import { pEmCampaignAudit } from "./translations/pages/products/emailmarketing/pEmCampaignAudit";
import { pEmCampaignStats } from "./translations/pages/products/emailmarketing/pEmCampaignStats";
import { pEmEbProject } from "./translations/pages/products/emailmarketing/pEmEbProject";
import { pEmExecutedCampaigns } from "./translations/pages/products/emailmarketing/pEmExecutedCampaigns";
import { pEmManageEm } from "./translations/pages/products/emailmarketing/pEmManageEm";
import { pEmNewCampaign } from "./translations/pages/products/emailmarketing/pEmNewCampaign";
import { pEmMain } from "./translations/pages/products/emailmarketing/pEmMain";
// Pages - Products - Hosting
import { pAliasChangeRec } from "./translations/pages/products/hosting/pAliasChangeRec";
import { pEmailChangePw } from "./translations/pages/products/hosting/pEmailChangePw";
import { pEmailManager } from "./translations/pages/products/hosting/pEmailManager";
import { pFirstDomain } from "./translations/pages/products/hosting/pFirstDomain";
import { pManageEmailAccounts } from "./translations/pages/products/hosting/pManageEmailAccounts";
import { pManageHosting } from "./translations/pages/products/hosting/pManageHosting";
import { pResetPleskPw } from "./translations/pages/products/hosting/pResetPleskPw";
import { pHostingMain } from "./translations/pages/products/hosting/pHostingMain";
// Pages - Products - Sitebuilder
import { pSbMain } from "./translations/pages/products/sitebuilder/pSbMain";
import { pSbOthers } from "./translations/pages/products/sitebuilder/pSbOthers";
// Pages - Public: No translations as it is unknown what locale it should be at given no user has logged in?
// Pages - User
import { pUserPages } from "./translations/pages/user/pUserPages";
// Pages - Workspace
import { pWsMembers } from "./translations/pages/workspace/pWsMembers";
import { pWsPages } from "./translations/pages/workspace/pWsPages";
import { pWsReferrals } from "./translations/pages/workspace/pWsReferrals";
// Components - audiences
import { cAudienceEntriesTable } from "./translations/components/audiences/cAudienceEntriesTable";
import { cAudienceMenuDelete } from "./translations/components/audiences/cAudienceMenuDelete";
import { cAudienceMenuEditName } from "./translations/components/audiences/cAudienceMenuEditName";
import { cAudienceMenuLoadDatafile } from "./translations/components/audiences/cAudienceMenuLoadDatafile";
import { cCreateNewAudience } from "./translations/components/audiences/cCreateNewAudience";
import { cListWsAudiences } from "./translations/components/audiences/cListWsAudiences";
import { cAudienceFunctions } from "./translations/components/audiences/cAudienceFunctions";
// Components - dashboard
import { cUserDashboard } from "./translations/components/dashboard/cUserDashboard";
// Components - cart
import { cCartOverdueInvoice } from "./translations/components/cart/cCartOverdueInvoice";
import { cCartSubscription } from "./translations/components/cart/cCartSubscription";
// Components - Checkout
import { cPendingPayments } from "./translations/components/checkout/cPendingPayments";
import { cStripeCheckout } from "./translations/components/checkout/cStripeCheckout";
// Components - Layout
import { cNavigation } from "./translations/components/layout/cNavigation";
// Components - Products - Domains
import { cCreateDnsRecords } from "./translations/components/products/domains/cCreateDnsRecords";
import { cDnsNameservers } from "./translations/components/products/domains/cDnsNameservers";
import { cEditDnsRecord } from "./translations/components/products/domains/cEditDnsRecord";
import { cListDnsDomains } from "./translations/components/products/domains/cListDnsDomains";
import { cAddNewDomainToDns } from "./translations/components/products/domains/cAddNewDomainToDns";
import { cDomainChecker } from "./translations/components/products/domains/cDomainChecker";
import { cDomainOwnership } from "./translations/components/products/domains/cDomainOwnership";
import { cDomainsSeeAll } from "./translations/components/products/domains/cDomainsSeeAll";
import { cTransfer } from "./translations/components/products/domains/cTransfer";
import { cTldCard } from "./translations/components/products/domains/cTldCard";
// Components - Products - Email marketing
import { cCampaignName } from "./translations/components/products/emailmarketing/cCampaignName";
import { cEmailBuilder } from "./translations/components/products/emailmarketing/cEmailBuilder";
import { cEmAllSubs } from "./translations/components/products/emailmarketing/cEmAllSubs";
import { cEmailCampaignStatsTop } from "./translations/components/products/emailmarketing/cEmailCampaignStatsTop";
import { cModalRecipientEmail } from "./translations/components/products/emailmarketing/cModalRecipientEmail";
import { cMsgStats } from "./translations/components/products/emailmarketing/cMsgStats";
import { cPendingEmailCampaigns } from "./translations/components/products/emailmarketing/cPendingEmailCampaigns";
import { cListValidatedEmails } from "./translations/components/products/emailmarketing/cListValidatedEmails";
import { cValidateNewEmail } from "./translations/components/products/emailmarketing/cValidateNewEmail";
// Components - Products - Hosting
import { cEmailHostingComponents } from "./translations/components/products/hosting/cEmailHostingComponents";
import { cHostingAllSubs } from "./translations/components/products/hosting/cHostingAllSubs";
// Components - Products - Sitebuilder
import { cSbAllSubs } from "./translations/components/products/sitebuilder/cSbAllSubs";
import { cSitebuilder } from "./translations/components/products/sitebuilder/cSitebuilder";
// Components - Products
import { cProductTable } from "./translations/components/products/cProductTable";
import { productVars } from "./translations/components/products/productVars";
// Components - Support
import { cSupportComponents } from "./translations/components/support/cSupportComponents";
// Components - User
import { cPulseCheck } from "./translations/components/user/cPulseCheck";
import { cUserProfile } from "./translations/components/user/cUserProfile";
import { cUserWorkspaces } from "./translations/components/user/cUserWorkspaces";
// Components - Workspace
import { cWsBilling } from "./translations/components/workspaces/cWsBilling";
import { cWsSubs } from "./translations/components/workspaces/cWsSubs";
import { cWsEditCustomerInfo } from "./translations/components/workspaces/cWsEditCustomerInfo";
import { cWsEditLocationInfo } from "./translations/components/workspaces/cWsEditLocationInfo";
// Components
import { cNotifications } from "./translations/components/cNotifications";
// Actions
import { aAudiences } from "./translations/actions/aAudiences";
import { aAuth } from "./translations/actions/aAuth";
import { aCart } from "./translations/actions/aCart";
import { aDomains } from "./translations/actions/aDomains";
import { aEmailMarketing } from "./translations/actions/aEmailMarketing";
import { aHosting } from "./translations/actions/aHosting";
import { aSitebuilder } from "./translations/actions/aSitebuilder";

// Commonly used -- User not found => {translate("common.userNotFound", false, null)}

export const translate = (objString, boolPlural, objVars) => {
  try {
    // Get translated text with english as fallback
    let objText = translations[objString.split(".")[0]][objString.split(".")[1]];
    if (typeof objText === "undefined") {
      return "";
    }
    let text = typeof objText[store.getState().auth.langCode] === "undefined" ? objText["en"] : objText[store.getState().auth.langCode];
    // Make plural if needed -- In translation file: { en: "Landing #page|pages#" }
    text = text.replace(/#(.+?)\|(.+?)#/, boolPlural ? "$2" : "$1");
    // Add variables -- In translation file: { en: "Welcome, %name%" } -- In translate(): translate(Translations.landingPage, true, { name: "Eddo" })
    if (objVars !== null) {
      Object.keys(objVars).forEach((key) => {
        text = text.replace(`%${key}%`, objVars[key]);
      });
    }
    return text;
  } catch (error) {
    // console.error(error);
    console.error(`Translation error`, objString, boolPlural, objVars);
    return "";
  }
};

export const LANGUAGES = { en: "English", nl: "Nederlands" };

// Plural: #word|words#
// Variables: %var%

const translations = {
  common,
  // Pages
  pMain,
  pNotfound,
  pCart,
  pFeedback,
  pSupport,
  // Pages > checkout
  pCheckoutPayment,
  pCheckoutStatus,
  // Pages > contact form
  pContactFormMessages,
  // Pages > audiences
  pAudienceMain,
  pAudienceEntries,
  // Pages > products > domains
  pDomainsAllTlds,
  pDomainsManageDns,
  pDomainsManageDomains,
  pDomainsTransfer,
  pDomainsUpdOwner,
  pDomainsMain,
  // Pages - Products - Email marketing
  pEmCampaignAudit,
  pEmCampaignStats,
  pEmEbProject,
  pEmExecutedCampaigns,
  pEmManageEm,
  pEmNewCampaign,
  pEmMain,
  // Pages > products > hosting
  pAliasChangeRec,
  pEmailChangePw,
  pEmailManager,
  pFirstDomain,
  pManageEmailAccounts,
  pManageHosting,
  pResetPleskPw,
  pHostingMain,
  // Pages > products > sitebuilder
  pSbMain,
  pSbOthers,
  // pages > user
  pUserPages,
  // Pages - Workspace
  pWsMembers,
  pWsPages,
  pWsReferrals,
  // Components
  // Components - Audiences
  cAudienceEntriesTable,
  cAudienceMenuDelete,
  cAudienceMenuEditName,
  cAudienceMenuLoadDatafile,
  cCreateNewAudience,
  cListWsAudiences,
  cAudienceFunctions,
  // Components - dashboard
  cUserDashboard,
  // Components - Cart
  cCartOverdueInvoice,
  cCartSubscription,
  // Components > checkout
  cPendingPayments,
  cStripeCheckout,
  // Components - Layout
  cNavigation,
  // Components - Products
  cProductTable,
  productVars,
  // Components - products - domains
  cCreateDnsRecords,
  cDnsNameservers,
  cEditDnsRecord,
  cAddNewDomainToDns,
  cListDnsDomains,
  cDomainChecker,
  cDomainOwnership,
  cDomainsSeeAll,
  cTransfer,
  cTldCard,
  // Components - Products - Email marketing
  cCampaignName,
  cEmailBuilder,
  cEmAllSubs,
  cEmailCampaignStatsTop,
  cModalRecipientEmail,
  cMsgStats,
  cPendingEmailCampaigns,
  cListValidatedEmails,
  cValidateNewEmail,
  // Components - Products - Hosting
  cEmailHostingComponents,
  cHostingAllSubs,
  // Components - Products - Sitebuilder
  cSbAllSubs,
  cSitebuilder,
  // Components - Support
  cSupportComponents,
  // Components - User
  cPulseCheck,
  cUserProfile,
  cUserWorkspaces,
  // Components - Workspace
  cWsBilling,
  cWsSubs,
  cWsEditCustomerInfo,
  cWsEditLocationInfo,
  // Components
  cNotifications,
  // Actions
  aAudiences,
  aAuth,
  aCart,
  aDomains,
  aEmailMarketing,
  aHosting,
  aSitebuilder,
};
