import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProductTable from "./ProductTable";
import SelectPeriod from "./SelectPeriod";

import { translate } from "../../../translations/translations";
import { PERIOD_ANNUALLY, PRODUCT_NAMES } from "../lib/ProductVars";
import { getProductTableData } from "../lib/productTableFunctions";

const ProductTableSb = ({ auth: { activeWorkspace, fxRates }, brandProducts: { brandData, brandOffers, products } }) => {
  const [period, setPeriod] = useState(PERIOD_ANNUALLY);
  const [productPackages, setProductPackages] = useState([]);
  const [surchargeMonthlyPayment, setSurchargeMonthlyPayment] = useState(0);

  useEffect(() => {
    if (activeWorkspace !== null && brandData !== null && brandOffers.length > 0 && products.length > 0) {
      const { productPackages, surchargeMonthlyPayment } = getProductTableData(
        activeWorkspace,
        products,
        brandData,
        brandOffers,
        PRODUCT_NAMES.sitebuilder,
        fxRates
      );
      setProductPackages(productPackages);
      setSurchargeMonthlyPayment(surchargeMonthlyPayment);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, brandData, brandOffers, products]);

  //   Product data
  const numTemplates = "25+";
  const numProjects = { starter: 1, advanced: 5, expert: translate("cProductTable.unlimited", false, null) };
  const numPages = { starter: 2, advanced: 10, expert: translate("cProductTable.unlimited", false, null) };

  const SPECS_STARTER = [
    translate("cProductTable.sb_easyToUseEditor", false, null),
    translate("cProductTable.sb_optimizedAllScreens", false, null),
    translate("cProductTable.sb_optimizedSpeed", false, null),
    translate("cProductTable.sb_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.sb_numProjects", numProjects.starter > 1, { numProjects: numProjects.starter }),
    translate("cProductTable.sb_numPages", true, { numPages: numPages.starter }),
  ];
  const SPECS_ADVANCED = [
    translate("cProductTable.sb_easyToUseEditor", false, null),
    translate("cProductTable.sb_optimizedAllScreens", false, null),
    translate("cProductTable.sb_optimizedSpeed", false, null),
    translate("cProductTable.sb_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.sb_numProjects", numProjects.advanced > 1, { numProjects: numProjects.advanced }),
    translate("cProductTable.sb_numPages", true, { numPages: numPages.advanced }),
  ];
  const SPECS_EXPERT = [
    translate("cProductTable.sb_easyToUseEditor", false, null),
    translate("cProductTable.sb_optimizedAllScreens", false, null),
    translate("cProductTable.sb_optimizedSpeed", false, null),
    translate("cProductTable.sb_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.sb_numProjects", true, { numProjects: numProjects.expert }),
    translate("cProductTable.sb_numPages", true, { numPages: numPages.expert }),
  ];

  return productPackages.length > 0 ? (
    <>
      <SelectPeriod period={period} setPeriod={setPeriod} surchargeMonthlyPayment={surchargeMonthlyPayment} />
      <div className="row mt-3">
        <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[0]} specs={SPECS_STARTER} />
        <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[1]} specs={SPECS_ADVANCED} />
        <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[2]} specs={SPECS_EXPERT} />
      </div>
    </>
  ) : (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  );
};

ProductTableSb.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(ProductTableSb);
