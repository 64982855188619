export const cCreateDnsRecords = {
  enterHostname: {
    en: "Please enter a valid hostname",
    nl: "Specificeer aub een geldige hostname",
  },
  enterIpv4address: {
    en: "Please enter a valid IPv4 address",
    nl: "Specificeer aub een bestaand IPv4 adres",
  },
  enterIpv6address: {
    en: "Please enter a valid IPv6 address",
    nl: "Specificeer aub een bestaand IPv6 adres",
  },
  enterTtl: {
    en: "Please enter a TTL",
    nl: "Specifeer aub een TTL",
  },
  recordTypeHostnameCombiAlreadyExists: {
    en: "A record for this hostname and type already exists",
    nl: "Er bestaat al een record van dit type voor de gekozen hostname",
  },
  aRecordExplanation: {
    en: "Use @ to create the record at the root of the domain or enter a hostname to create it elsewhere (e.g. a subdomain). A records are for IPv4 addresses only and tell a request where your domain should direct to.",
    nl: "Gebruik @ om een record te maken voor de hoofddomein of geef een hostname op voor een andere locatie (bijv. een subdomein). AAAA records worden alleen voor IPv4 addressen gebruikt en verwijzen een bezoeker van uw domein door naar de juiste locatie.",
  },
  aaaaRecordExplanation: {
    en: "Use @ to create the record at the root of the domain or enter a hostname to create it elsewhere (e.g. a subdomain). AAAA records are for IPv6 addresses only and tell a request where your domain should direct to.",
    nl: "Gebruik @ om een record te maken voor de hoofddomein of geef een hostname op voor een andere locatie (bijv. een subdomein). AAAA records worden alleen voor IPv6 addressen gebruikt en verwijzen een bezoeker van uw domein door naar de juiste locatie.",
  },
  hostname: {
    en: "Hostname",
    nl: "Hostname",
  },
  atOrHostname: {
    en: "@ or hostname",
    nl: "@ of hostname",
  },
  pointsTo: {
    en: "Points to",
    nl: "Verwijst naar",
  },
  ipv4address: {
    en: "IPv4 address",
    nl: "IPv4 adres",
  },
  ipv6address: {
    en: "IPv6 address",
    nl: "IPv6 addres",
  },
  ttlSeconds: {
    en: "TTL (seconds)",
    nl: "TTL (seconden)",
  },
  createRecord: {
    en: "Create record",
    nl: "Creer record",
  },
  createNewRecord: {
    en: "Create new record",
    nl: "Creer nieuw record",
  },
  updateRecord: {
    en: "Update record",
    nl: "Update record",
  },
  cancelUpdateRecord: {
    en: "Cancel",
    nl: "Annuleren",
  },
  updateExistingRecord: {
    en: "Update existing record",
    nl: "Update bestaand record",
  },
  enterCertificateAuth: {
    en: "Please enter the certificate authority",
    nl: "Specificeer aub de certificaatauthoriteit",
  },
  enterTag: {
    en: "Please enter the tag",
    nl: "Specificeer aub de tag",
  },
  enterFlags: {
    en: "Please enter the flags",
    nl: "Specifeer aub de flags",
  },
  caaRecordExplanation: {
    en: "CAA records specify which certificate authorities are permitted to issue certificates for a domain. Use them to reduce the risk of unintended certificate mis-issue.",
    nl: "CAA records specificeren welke certificaatauthoriteiten toegestaan zijn om certificaten voor een domein uit te geven. Gebruik deze records om het risico van onbedoeld verkeerd uitgeven van certificaten te voorkomen.",
  },
  authGrantedFor: {
    en: "Authority granted for",
    nl: "Toegang gegeven voor",
  },
  tag: {
    en: "Tag",
    nl: "Tag",
  },
  selectTag: {
    en: "Select tag",
    nl: "Selecteer een tag",
  },
  issue: {
    en: "issue",
    nl: "issue",
  },
  issuewild: {
    en: "issuewild",
    nl: "issuewild",
  },
  iodef: {
    en: "iodef",
    nl: "iodef",
  },
  flags: {
    en: "Flags",
    nl: "Flags",
  },
  cnameRecordExplanation: {
    en: "CNAME records act as an alias by mapping a hostname to another hostname.",
    nl: "CNAME records worden gebruikt om een alias van een hostname te verwijzen naar een andere hostname.",
  },
  isAliasOf: {
    en: "Is an alias of",
    nl: "Is een alias van",
  },
  aliasHostname: {
    en: "Alias hostname",
    nl: "Alias hostname",
  },
  enterMailServer: {
    en: "Please enter a mail server",
    nl: "Specificeer aub een mail server",
  },
  enterPriority: {
    en: "Please enter a priority",
    nl: "Specificeer aub een prioriteit",
  },
  mxRecordExplanation: {
    en: "MX records specify the mail server responsible for accepting emails on behalf of your domain. Specify different priority values if your provider has a number of mail servers for contingency.",
    nl: "MX records specifieren de mail server die gebruikt wordt om emails te accepteren namens uw domain. Specificeer verschillende prioriteit waardes als uw provider meerdere mail servers opereert voor u.",
  },
  mailServer: {
    en: "Mail server",
    nl: "Mail server",
  },
  priority: {
    en: "Priority",
    nl: "Prioriteit",
  },
  priorityExample: {
    en: "e.g. 10",
    nl: "bijv. 10",
  },
  enterTarget: {
    en: "Please enter the target",
    nl: "Specificeer aub het het doel",
  },
  srvRecordExplanation: {
    en: "SRV records specify the location (hostname and port number) of servers for specific services. You can use service records to direct certain types of traffic to particular servers.",
    nl: "SRV records specificeren de locatie (hostname en poort) van servers voor specifieke services. U kunt service records gebruiken om bepaalde bezoekers door te verwijzen naar specifieke servers.",
  },
  directsTo: {
    en: "Directs to",
    nl: "Verwijst naar",
  },
  srvTarget: {
    en: "Target",
    nl: "Doel",
  },
  srvPort: {
    en: "Port",
    nl: "Poort",
  },
  srvPortExample: {
    en: "e.g. 4040",
    nl: "Bijv. 4040",
  },
  srvWeight: {
    en: "Weight",
    nl: "Weging",
  },
  srvWeightExample: {
    en: "e.g. 100",
    nl: "bijv. 100",
  },
  enterTxtString: {
    en: "Please enter the txt string",
    nl: "Specificeer aub de txt waarde",
  },
  txtRecordExplanation: {
    en: "TXT records are used to associate a string of text with a hostname. These are primarily used for verification.",
    nl: "TXT records linken een text waarde met een hostname. Deze records worden vooral ter verificatie gebruikt.",
  },
  txtString: {
    en: "TXT string",
    nl: "TXT waarde",
  },
  aliasOf: {
    en: "Alias of",
    nl: "Alias voor",
  },
  text: {
    en: "Text",
    nl: "Tekst",
  },
  dnsRecord: {
    en: "DNS record",
    nl: "DNS record",
  },
  thereAreNoDnsRecords: {
    en: "There are no DNS records set yet for this domain.",
    nl: "Er zijn geen DNS records gezet voor deze domeinnaam.",
  },
  type: {
    en: "Type",
    nl: "Type",
  },
  value: {
    en: "Value",
    nl: "Waarde",
  },
  remove: {
    en: "Remove",
    nl: "Verwijder",
  },
  removeRecord: {
    en: "Remove record",
    nl: "Verwijder record",
  },
  edit: {
    en: "Edit",
    nl: "Aanpassen",
  },
  editRecord: {
    en: "Edit record",
    nl: "Pas record aan",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  dnsRecords: {
    en: "DNS records",
    nl: "DNS records",
  },
  notApplicable: {
    en: "n.a.",
    nl: "n.v.t.",
  },
};
