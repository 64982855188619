import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getDomainProductPackage, getDomainActionPrice } from "./lib/domainsFunctions";
import { clickAddToCart } from "../lib/ProductFunctions";
import { PRODUCT_NAMES } from "../lib/ProductVars";
import { translate } from "../../../translations/translations";
import { getCurrency } from "../../../lib/Currencies";
import { formatNumber } from "../../../lib/formattingFunctions";
import { userIsWsAdmin } from "../../workspace/lib/wsFunctions";

const DomainCard = ({ auth: { user, activeWorkspace, fxRates }, cart: { sessionId, cartItems }, brandProducts: { products, TLDs }, data }) => {
  // data = { domainName, tld, available, premium, error, currency, prices: { register, registerDiscount, renew, transfer, restore } }

  const DOMAIN_PRICE_ACTION = "register";

  const [domainNamesInCart, setDomainNamesInCart] = useState([]);

  useEffect(() => {
    setDomainNamesInCart(cartItems.filter((cartItem) => cartItem.productName === PRODUCT_NAMES.domains).map((cartItem) => cartItem.packageName));
  }, [cartItems]);

  // Price calculation:
  // - data has the currency RTR charges in and the price set on admin panel
  // - Price to user needs to be quoted in the currency of the workspace
  // - Need to get the FX rate from the workspace currency to the RTR currency:
  //   1. Translate from RTR's currency to EUR
  //   2. Translate from EUR to workspace currency
  //   => Net FX rate from RTR to ws: 1 / getFxRate(data.currency, fxRates) * getFxRate(wsCurrency, fxRates)

  const wsCurrency = getCurrency(activeWorkspace.location.priceCountry).currencyCode;
  const price = getDomainActionPrice(data, DOMAIN_PRICE_ACTION, wsCurrency, fxRates);
  const inCart = domainNamesInCart.includes(data.domainName);

  const clickAddDomainToCart = (item, priceWsCurr) => {
    // item = { domainName, tld, available, premium, error, currency, prices: { register, registerDiscount, renew, transfer, restore } }
    if (item.available && userIsWsAdmin(activeWorkspace, user)) {
      const productPackage = getDomainProductPackage(activeWorkspace, products, TLDs, item, priceWsCurr, DOMAIN_PRICE_ACTION);
      clickAddToCart(
        activeWorkspace,
        user,
        sessionId,
        productPackage,
        productPackage.selectedPeriod,
        productPackage.payableAtCheckout,
        productPackage.cartItemActionType
      );
    }
  };

  return (
    <div className={`col-12 col-lg-6 col-xl-4`}>
      <div
        className={`d-flex align-items-center justify-content-between domainChecker-item ${
          inCart ? "inCart" : data.available ? "available" : "taken"
        } bg-white shadow-light noHover rounded-3 my-2 overflowHidden`}
        title={
          inCart
            ? translate("cDomainChecker.isInCart", false, null)
            : data.available
            ? translate("cDomainChecker.clickAddToCart", false, null)
            : translate("cDomainChecker.domainTaken", false, null)
        }
        onClick={() => data.available && !inCart && clickAddDomainToCart(data, price)}
      >
        <span
          className={`d-block border-end m-0 text-center py-2 text-${data.available ? "success" : "danger"} bg-${
            data.available ? "success" : "danger"
          }-light`}
          style={{ minWidth: "32px", width: "32px" }}
        >
          {inCart ? (
            <i className="fa-solid fa-cart-shopping" />
          ) : data.available ? (
            <span className="domainChecker-iconFlip"></span>
          ) : (
            <i className="fas fa-times" />
          )}
        </span>
        <span className="d-block m-0 p-2 toGrow text-truncate">{data.domainName}</span>
        {data.available && !inCart && (
          <span className="d-block m-0 px-2 fontSize08 text-nowrap">
            <span className="bg-success-light rounded py-1 px-2">
              {getCurrency(activeWorkspace.location.priceCountry).currencySign} {formatNumber(price, "0.00")}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

DomainCard.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(DomainCard);
