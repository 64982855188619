import {
  SET_SESSION_CART,
  LOAD_CART,
  ADD_TO_CART,
  PAY_OVERDUE_INVOICE,
  REMOVE_FROM_CART,
  PREPAY_SUB,
  EMPTY_CART,
  CART_ERROR,
} from "../actions/types";

// Set initialState to an empty array
const initialState = {
  sessionId: localStorage.getItem("cartSessionId") || "",
  cartItems: [],
  cartType: "",
  cartError: "",
};

// Reducer functionality
export default function cart(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  switch (type) {
    case SET_SESSION_CART:
      return {
        ...state,
        sessionId: payload,
        cartError: "",
      };
    case LOAD_CART:
    case ADD_TO_CART:
    case PREPAY_SUB:
    case PAY_OVERDUE_INVOICE:
    case REMOVE_FROM_CART:
      localStorage.setItem("cartSessionId", payload.sessionId);
      return {
        ...state,
        // payload is the { sessionId: "", items: [], cartType: "" }
        sessionId: payload.sessionId,
        cartItems: payload.items,
        cartType: payload.cartType,
        cartError: "",
      };
    case EMPTY_CART:
      // Reset all vars
      localStorage.removeItem("cartSessionId");
      return {
        ...state,
        sessionId: "",
        cartItems: [],
        cartType: "",
        cartError: "",
      };
    case CART_ERROR:
      localStorage.removeItem("cartSessionId");
      return {
        ...state,
        sessionId: "",
        cartItems: [],
        cartType: "",
        cartError: payload,
      };
    default:
      return state;
  }
}
