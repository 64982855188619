import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

import { translate } from "../../../translations/translations";
import DashboardErrorItem from "../DashboardErrorItem";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Audiences = ({ auth: { activeWorkspace }, audiences: { audiences } }) => {
  const [audienceData, setAudienceData] = useState(null);

  useEffect(() => {
    getAudienceData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getAudienceData = () => {
    try {
      const largestAudiences = getTop5Audiences();
      const labels = largestAudiences.map((audience) => audience.audienceName);
      const data = largestAudiences.map((audience) => audience.data.length);
      setAudienceData({ labels, data });
    } catch (error) {
      setAudienceData(null);
    }
  };

  const getTop5Audiences = () => {
    const sortedAudiences = audiences.sort((a, b) => b.data - a.data);
    return sortedAudiences.slice(0, 5);
  };

  const data = {
    labels: audienceData === null ? [""] : audienceData.labels,
    datasets: [
      {
        label: translate("cListWsAudiences.entries", false, null),
        data: audienceData === null ? [""] : audienceData.data,
        backgroundColor: "rgba(229, 42, 5, 0.2)",
        borderColor: "rgba(229, 42, 5, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return audienceData === null || audienceData.data.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noAudienceDataFound", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

Audiences.propTypes = {
  auth: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(Audiences);
