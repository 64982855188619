import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../../translations/translations";
import DashboardErrorItem from "../DashboardErrorItem";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";

const YourWorkspaces = ({ auth: { user, workspaces, activeWorkspace } }) => {
  const PER_PAGE = 10;

  const [workspacesToRender, setWorkspacesToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);

  let maxPages = Math.ceil(workspaces.length / PER_PAGE);

  useEffect(() => {
    renderWorkspaces();
    // eslint-disable-next-line
  }, [workspaces, currPage]);

  const renderWorkspaces = () => {
    const start = currPage * PER_PAGE;
    const end = start + PER_PAGE;
    setWorkspacesToRender(workspaces.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const getWsRole = (ws) => {
    const role = ws.members.filter((member) => member.userId === user._id)[0].role;
    const roles = {
      owner: translate("cUserDashboard.wsRoleOwner", false, null),
      admin: translate("cUserDashboard.wsRoleAdmin", false, null),
      member: translate("cUserDashboard.wsRoleMember", false, null),
    };
    return roles[role];
  };

  return workspaces.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noWorkspacesFound", false, null)} />
  ) : (
    <div className="w-100">
      <div className="table-responsive">
        <table className="table table-sm table-hover rounded-3 overflowHidden align-middle mt-3 fontSize09">
          <thead>
            <tr className="bg-primary text-white border-none">
              <th scope="col">{translate("cUserDashboard.wsName", false, null)}</th>
              <th scope="col">{translate("cUserDashboard.wsYourRole", false, null)}</th>
              <th scope="col">{translate("cUserDashboard.wsNumMembers", false, null)}</th>
            </tr>
          </thead>
          <tbody>
            {workspacesToRender.map((ws) => (
              <tr key={ws._id}>
                <td>{capitalizeFirstLetter(ws.name)}</td>
                <td>{getWsRole(ws)}</td>
                <td>{ws.members.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(-1)} disabled={currPage === 0}>
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate("cUserDashboard.showingWorkspaces", false, null)}{" "}
          <span className="text-bold">
            {Math.min(currPage * PER_PAGE + 1, workspaces.length)} - {Math.min((currPage + 1) * PER_PAGE, workspaces.length)}
          </span>{" "}
          {translate("cListDnsDomains.of", false, null)} <span className="text-bold">{workspaces.length}</span>
        </span>
        <button className="btn btn-outline-primary trans-3" onClick={() => clickPageBtn(1)} disabled={currPage + 1 === maxPages}>
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

YourWorkspaces.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(YourWorkspaces);
