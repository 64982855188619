export const cProductTable = {
  // Product table common
  packageAlreadyInCart: {
    en: "Package already in cart",
    nl: "Pakket staat in winkelwagen",
  },
  productAlreadyInCart: {
    en: "Same product already in cart",
    nl: "Zelfde product staat in winkelwagen",
  },
  alreadyPrepaid: {
    en: "Already prepaid",
    nl: "Al vooruitbetaald",
  },
  upgradeExplanationPoint: {
    en: "Upgrade!",
    nl: "Upgraden!",
  },
  upgrade: {
    en: "Upgrade",
    nl: "Upgraden",
  },
  downgradePrepayExplanationPoint: {
    en: "Downgrade & prepay!",
    nl: "Downgrade & betaal vooruit!",
  },
  downgradePrepay: {
    en: "Downgrade & prepay",
    nl: "Downgrade & betaal vooruit",
  },
  prepayExplanationPoint: {
    en: "Prepay!",
    nl: "Betaal vooruit!",
  },
  prepay: {
    en: "Prepay",
    nl: "Betaal vooruit",
  },
  upgradePrepayExplanationPoint: {
    en: "Upgrade & prepay!",
    nl: "Upgrade & betaal vooruit!",
  },
  upgradePrepay: {
    en: "Upgrade & prepay",
    nl: "Upgrade & betaal vooruit",
  },
  downgradeExplanationPoint: {
    en: "Downgrade!",
    nl: "Downgraden",
  },
  alreadySubscribed: {
    en: "Already subscribed",
    nl: "Al geabonneerd",
  },
  addToCart: {
    en: "Add to cart!",
    nl: "Voeg toe aan winkelwagen!",
  },
  firstXMonths: {
    en: "First %trialPeriodInMonths% months",
    nl: "Eerste %trialPeriodInMonths% maanden",
  },
  perMonth: {
    en: "/month",
    nl: "/maand",
  },
  afterwards: {
    en: "afterwards",
    nl: "daarna",
  },
  commaPaidUpfront: {
    en: ", paid upfront",
    nl: ", vooraf betaald",
  },
  year: {
    en: "year",
    nl: "jaar",
  },
  month: {
    en: "month",
    nl: "maand",
  },
  yourExistingSubFor: {
    en: "your existing subscription for",
    nl: "uw bestaande abonnement voor",
  },
  payAnnuallyAndGetDiscount: {
    en: "Pay annually and get %percent% off!",
    nl: "Betaal jaarlijks en krijg %percent% korting!",
  },
  unlimited: {
    en: "Unlimited",
    nl: "Onbeperkt aantal",
  },
  highlightText: {
    en: "Popular",
    nl: "Populair",
  },
  // Hosting specs
  freeSSL: {
    en: "Free SSL certificates",
    nl: "Gratis SSL certificaten",
  },
  storage: {
    en: "%storage% storage",
    nl: "%storage% opslag",
  },
  traffic: {
    en: "Unlimited traffic",
    nl: "Ongelimiteerde bandbreedte",
  },
  mailboxes: {
    en: "%mailboxes% mailboxes",
    nl: "%mailboxes% mailboxes",
  },
  domains: {
    en: "%domains% (sub)domains",
    nl: "%domains% (sub)domeinen",
  },
  databases: {
    en: "%databases% #database|databases#",
    nl: "%databases% #database|databases#",
  },
  wpToolkit: {
    en: "WordPress Toolkit",
    nl: "WordPress Toolkit",
  },
  tagStarter: {
    en: "Best for small, static sites",
    nl: "Beste keus voor kleine, statische sites",
  },
  tagAdvanced: {
    en: "Best for small WordPress sites",
    nl: "Beste keus voor kleine WordPress sites",
  },
  tagPro: {
    en: "Best for medium WordPress sites or small webshops",
    nl: "Beste keus voor medium WordPress sites of kleine webshops",
  },
  tagExpert: {
    en: "Best for larger WordPress sites or medium webshops",
    nl: "Beste keus voor grotere WordPress sites of medium webshops",
  },
  tagElite: {
    en: "Best for larger webshops",
    nl: "Beste keus voor grotere webshops",
  },
  // Sitebuilder specs
  sb_easyToUseEditor: {
    en: "Easy to use drag & drop editor",
    nl: "Gemakkelijk te gebruiken drag & drop editor",
  },
  sb_optimizedAllScreens: {
    en: "Optimized for all screen sizes",
    nl: "Geoptimaliseerd voor alle schermgroottes",
  },
  sb_optimizedSpeed: {
    en: "Optimized for speed and SEO ranking",
    nl: "Geoptimaliseerd voor snelheid en SEO ranking",
  },
  sb_accessToTemplates: {
    en: "Access to %numTemplates% templates",
    nl: "Toegang tot %numTemplates% templates",
  },
  sb_numProjects: {
    en: "%numProjects% #project|projects#",
    nl: "%numProjects% #project|projecten#",
  },
  sb_numPages: {
    en: "%numPages% #page|pages",
    nl: "%numPages% #pagina|paginas#",
  },
  // Email marketing specs
  em_easyToUseEditor: {
    en: "Easy to use drag & drop editor",
    nl: "Gemakkelijk te gebruiken drag & drop editor",
  },
  em_looksBeautiful: {
    en: "Looks beautiful in all email clients on all devices",
    nl: "Ziet er prachtig uit op elk apparaat",
  },
  em_accessToTemplates: {
    en: "Access to %numTemplates% templates",
    nl: "Toegang tot %numTemplates% templates",
  },
  em_numProjects: {
    en: "%numProjects% projects",
    nl: "%numProjects% projecten",
  },
  em_numEmails: {
    en: "Send %numEmails% emails per month",
    nl: "Verstuur %numEmails% emails per maand",
  },
  // xxxxxxxxxxxx: {
  //   en: "yyyyyyy",
  //   nl: "zzzzzzzzzzz",
  // },
  // xxxxxxxxxxxx: {
  //   en: "yyyyyyy",
  //   nl: "zzzzzzzzzzz",
  // },
  // xxxxxxxxxxxx: {
  //   en: "yyyyyyy",
  //   nl: "zzzzzzzzzzz",
  // },
};
