export const cTransfer = {
  transferDomain: {
    en: "Transfer domain",
    nl: "Zet domein over",
  },
  for: {
    en: "for",
    nl: "voor",
  },
};
