import { getCurrency } from "../../../lib/Currencies";
import {
  PERIOD_ANNUALLY,
  PRODUCT_NAMES,
  PACKAGE_NAMES,
  PACKAGE_NAMES_HOSTING,
  PACKAGE_NAMES_SITEBUILDER,
  PACKAGE_NAMES_EMAIL_MARKETING,
} from "./ProductVars";
import { CART_ITEM_ACTION_TYPES } from "../../../actions/lib/cartFunctionsVars";
import { getFxRate, formListPrice, getPayableAtCheckout, getProductPackageNames } from "./ProductFunctions";
import { translate } from "../../../translations/translations";
import { formatNumber } from "../../../lib/formattingFunctions";

export const PAY_UPFRONT_DURING_TRIAL_PERIOD = false; // = true if user has to prepay the non-trial period amount at order

export const getProductTableData = (activeWorkspace, products, brandData, brandOffers, productName, fxRates) => {
  // Set currency to use
  const currency = getCurrency(activeWorkspace.location.priceCountry).currencyCode;

  // Get product and surchargeMonthlyPayment
  const product = products.filter((product) => product.name === productName)[0];
  const surchargeMonthlyPayment = product.offerMonthlyPayment ? product.surchargeMonthlyPayment : 0;

  const brandPackages = brandOffers.filter((offer) => offer.productId === product._id)[0].packages;

  // Form new array of objects with required data:
  // { packageId, name, packageDetails, currency, fullPriceMonthly, discountedPriceMonthly, fullPriceAnnually, discountedPriceAnnually }
  let productPackagesToShow = [];
  brandPackages.forEach((brandPackage) => {
    let standardPackage = product.packages.filter((productPackage) => productPackage._id === brandPackage.packageId)[0];
    let fullPriceAnnually = 0;
    let fullPriceMonthly = 0;
    // 1) Get undiscounted annual and monthly prices
    if (brandPackage.takeStandardPackagePricing) {
      // Get the package's standard pricing
      if (standardPackage.calcPriceFromMargin) {
        // Calculate price from margin
        let priceInEUR = standardPackage.standardCost / (1 - standardPackage.standardMargin);
        let priceInTargetCurr = priceInEUR * getFxRate(currency, fxRates);
        fullPriceAnnually = formListPrice(priceInTargetCurr * 12, 1, true);
        fullPriceMonthly = getMonthlyPrice(fullPriceAnnually, surchargeMonthlyPayment);
      } else {
        // Take price from standardPrice
        fullPriceAnnually = formListPrice(standardPackage.standardPrice[currency] * 12, 1, true);
        fullPriceMonthly = getMonthlyPrice(fullPriceAnnually, surchargeMonthlyPayment);
      }
    } else {
      // Calculate brand specific pricing
      if (brandPackage.calcPriceFromMargin) {
        // Calculate price from margin
        let priceInEUR = standardPackage.standardCost / (1 - brandPackage.standardMargin);
        let priceInTargetCurr = priceInEUR * getFxRate(currency, fxRates);
        fullPriceAnnually = formListPrice(priceInTargetCurr * 12, 1, true);
        fullPriceMonthly = getMonthlyPrice(fullPriceAnnually, surchargeMonthlyPayment);
      } else {
        // Take price from standardPrice
        fullPriceAnnually = formListPrice(brandPackage.standardPrice[currency] * 12, 1, true);
        fullPriceMonthly = getMonthlyPrice(fullPriceAnnually, surchargeMonthlyPayment);
      }
    }
    // 2) Get discounted prices
    let discountedPriceAnnually = fullPriceAnnually * (1 - brandPackage.privateDiscount);
    let discountedPriceMonthly = fullPriceMonthly * (1 - brandPackage.privateDiscount);
    // 3) Get trial period prices
    let trialPeriodPriceAnnually = fullPriceAnnually * (1 - brandPackage.trialPeriodDiscount);
    let trialPeriodPriceMonthly = fullPriceMonthly * (1 - brandPackage.trialPeriodDiscount);
    let trialPeriodInMonths = brandPackage.trialPeriodLength;
    if (!PAY_UPFRONT_DURING_TRIAL_PERIOD) {
      // If user doesn't have to prepay the non-trial period amount of the sub, then at least get 0.01 to confirm identity
      trialPeriodPriceAnnually = Math.max(0.12, trialPeriodPriceAnnually);
      trialPeriodPriceMonthly = Math.max(0.01, trialPeriodPriceMonthly);
    }

    // TODO: Add relative price increase from having been renewed a few times.
    // For example, 1st year monthly price = 1.25. 2nd year this becomes 1.375
    // If user wants to prepay or upgrade+prepay, take the price from discountedPriceAnnually * (1.375/1.25)

    // 4) Add non-price data
    productPackagesToShow.push({
      brandId: brandData._id,
      productId: product._id,
      productName: product.name,
      packageId: brandPackage.packageId,
      packageDetails: standardPackage.packageDetails,
      currency,
      countryCode: activeWorkspace.location.countryCode,
      fullPriceMonthly,
      discountedPriceMonthly,
      fullPriceAnnually,
      discountedPriceAnnually,
      trialPeriodPriceAnnually,
      trialPeriodPriceMonthly,
      trialPeriodInMonths,
      payUpfrontDuringTrialPeriod: PAY_UPFRONT_DURING_TRIAL_PERIOD,
    });
  });
  // 4) Sort based on price
  productPackagesToShow.sort(sortByMonthlyPrice_lowToHigh);
  // 5) Add package names
  productPackagesToShow = productPackagesToShow.map((productPackage, i) => ({
    ...productPackage,
    packageName: getPackageName(productPackage.productName, i),
  }));

  return { productPackages: productPackagesToShow, surchargeMonthlyPayment };
};

const getMonthlyPrice = (annualPrice, surchargeMonthlyPayment) => {
  return parseFloat(((annualPrice * (1 + surchargeMonthlyPayment)) / 12).toFixed(2));
};

const getPackageName = (productName, i) => {
  if (productName === PRODUCT_NAMES.hosting) {
    return PACKAGE_NAMES_HOSTING[i];
  }
  if (productName === PRODUCT_NAMES.sitebuilder) {
    return PACKAGE_NAMES_SITEBUILDER[i];
  }
  if (productName === PRODUCT_NAMES.emailMarketing) {
    return PACKAGE_NAMES_EMAIL_MARKETING[i];
  }
  return PACKAGE_NAMES[i];
};

const sortByMonthlyPrice_lowToHigh = (a, b) => {
  if (a.fullPriceMonthly < b.fullPriceMonthly) {
    return -1;
  }
  if (a.fullPriceMonthly > b.fullPriceMonthly) {
    return 1;
  }
  return 0;
};

export const getPackageData = (productPackage, activeWorkspace, cartItems, period) => {
  // Check: package already in cart?
  if (cartItems.filter((cartItem) => cartItem.packageId === productPackage.packageId).length > 0) {
    return {
      btnText: translate("cProductTable.packageAlreadyInCart", false, null),
      disabled: true,
      price: <p className="mt-5 mb-0 text-italic fontSize09">{translate("cProductTable.packageAlreadyInCart", false, null)}</p>,
      payableAtCheckout: getPayableAtCheckout(productPackage, period, null),
      actionType: CART_ITEM_ACTION_TYPES.new,
    };
  }
  // Check: product already in cart?
  if (cartItems.filter((cartItem) => cartItem.productId === productPackage.productId).length > 0) {
    return {
      btnText: translate("cProductTable.productAlreadyInCart", false, null),
      disabled: true,
      price: <p className="mt-5 mb-0 text-italic fontSize09">{translate("cProductTable.productAlreadyInCart", false, null)}</p>,
      payableAtCheckout: getPayableAtCheckout(productPackage, period, null),
      actionType: CART_ITEM_ACTION_TYPES.new,
    };
  }
  // Product already an active subscription of the user
  const arrActiveProductSubs = activeWorkspace.subscriptions.filter(
    (sub) => sub.productId === productPackage.productId && typeof sub.cancelDate === "undefined"
  );

  if (arrActiveProductSubs.length > 0) {
    // arrActiveProductSubs.length === 1 => take only found activeProductSub
    // arrActiveProductSubs.length === 2 => sub is being renewed and has the old one as well as the to be renewed one. Take the to be renewed one by filtering on isMarkedForCancellation
    const activeProductSub =
      arrActiveProductSubs.length === 1 ? arrActiveProductSubs[0] : arrActiveProductSubs.filter((sub) => !sub.isMarkedForCancellation)[0];
    // console.log("getPackageData // activeProductSub:", activeProductSub);
    // Possibilities:
    //                      Active sub period
    //                      --------------------------------------------
    //                      Monthly                 Annually
    // -----------------------------------------------------------------
    // Selected   Monthly   - Downgrade             Already prepaid
    // period               - Already active        Already prepaid
    //                      - Upgrade               Already prepaid
    //            ------------------------------------------------------
    //            Annually  - Downgrade + prepay    Already prepaid
    //                      - Prepay existing sub   Already prepaid
    //                      - Upgrade + prepay      Upgrade
    // -----------------------------------------------------------------
    const productPackageNames = getProductPackageNames(activeProductSub.productName);
    const indexActivePackage = productPackageNames.indexOf(activeProductSub.packageName);
    const indexRenderedPackage = productPackageNames.indexOf(productPackage.packageName);
    if (activeProductSub.prepaid) {
      // Active sub - Annually
      if (period === PERIOD_ANNUALLY) {
        // Selected period - Annually
        if (indexActivePackage >= indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.alreadyPrepaid", false, null),
            disabled: true,
            price: <p className="mt-5 mb-0 text-italic fontSize09">{translate("cProductTable.alreadyPrepaid", false, null)}</p>,
            payableAtCheckout: 0,
            actionType: CART_ITEM_ACTION_TYPES.upgrade,
          };
        } else {
          let payableAtCheckout = getPayableAtCheckout(productPackage, period, activeProductSub);
          return {
            btnText: translate("cProductTable.upgradeExplanationPoint", false, null),
            disabled: false,
            price: pricePrepay(translate("cProductTable.upgrade", false, null), payableAtCheckout, activeWorkspace),
            payableAtCheckout,
            actionType: CART_ITEM_ACTION_TYPES.upgrade,
          };
        }
      } else {
        // Selected period - Monthly
        return {
          btnText: translate("cProductTable.alreadyPrepaid", false, null),
          disabled: true,
          price: <p className="mt-5 mb-0 text-italic fontSize09">{translate("cProductTable.alreadyPrepaid", false, null)}</p>,
          payableAtCheckout: 0,
          actionType: CART_ITEM_ACTION_TYPES.upgrade,
        };
      }
    } else {
      // Active sub - Monthly
      if (period === PERIOD_ANNUALLY) {
        // Selected period - Annually
        let payableAtCheckout = getPayableAtCheckout(productPackage, period, activeProductSub);
        if (indexActivePackage > indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.downgradePrepayExplanationPoint", false, null),
            disabled: false,
            price: pricePrepay(translate("cProductTable.downgradePrepay", false, null), payableAtCheckout, activeWorkspace),
            payableAtCheckout,
            actionType: CART_ITEM_ACTION_TYPES.prepay,
          };
        }
        if (indexActivePackage === indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.prepayExplanationPoint", false, null),
            disabled: false,
            price: pricePrepay(translate("cProductTable.prepay", false, null), payableAtCheckout, activeWorkspace),
            payableAtCheckout,
            actionType: CART_ITEM_ACTION_TYPES.prepay,
          };
        }
        if (indexActivePackage < indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.upgradePrepayExplanationPoint", false, null),
            disabled: false,
            price: pricePrepay(translate("cProductTable.upgradePrepay", false, null), payableAtCheckout, activeWorkspace),
            payableAtCheckout,
            actionType: CART_ITEM_ACTION_TYPES.prepay,
          };
        }
      } else {
        // Selected period - Monthly
        if (indexActivePackage > indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.downgradeExplanationPoint", false, null),
            disabled: false,
            price: priceAddToCart(productPackage, CART_ITEM_ACTION_TYPES.upgrade, activeWorkspace, period),
            payableAtCheckout: 0,
            actionType: CART_ITEM_ACTION_TYPES.upgrade,
          };
        }
        if (indexActivePackage === indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.alreadySubscribed", false, null),
            disabled: true,
            price: <p className="mt-5 mb-0 text-italic fontSize09">{translate("cProductTable.alreadySubscribed", false, null)}</p>,
            payableAtCheckout: 0,
            actionType: CART_ITEM_ACTION_TYPES.upgrade,
          };
        }
        if (indexActivePackage < indexRenderedPackage) {
          return {
            btnText: translate("cProductTable.upgradeExplanationPoint", false, null),
            disabled: false,
            price: priceAddToCart(productPackage, CART_ITEM_ACTION_TYPES.upgrade, activeWorkspace, period),
            payableAtCheckout: 0,
            actionType: CART_ITEM_ACTION_TYPES.upgrade,
          };
        }
      }
    }
  }
  // If neither already in cart nor already subscribed to, return standard values
  return {
    btnText: translate("cProductTable.addToCart", false, null),
    disabled: false,
    price: priceAddToCart(productPackage, CART_ITEM_ACTION_TYPES.new, activeWorkspace, period),
    payableAtCheckout: getPayableAtCheckout(productPackage, period, null),
    actionType: CART_ITEM_ACTION_TYPES.new,
  };
};

const priceAddToCart = (productPackage, actionType, activeWorkspace, period) => {
  const {
    trialPeriodInMonths,
    trialPeriodPriceAnnually,
    trialPeriodPriceMonthly,
    discountedPriceAnnually,
    discountedPriceMonthly,
    fullPriceAnnually,
    fullPriceMonthly,
  } = productPackage;
  const curr = getCurrency(activeWorkspace.location.priceCountry).currencySign;

  // Price possibilities:
  //                                                Paid annually                                             Paid monthly
  // Trial period (only for actionType === "new" )  First x months $xx/mo, afterwards $xx/mo, paid upfront    First x months $xx/mo, afterwards $xx/mo
  // No trial period                                $xx/year                                                  $xx/month

  return actionType === CART_ITEM_ACTION_TYPES.new && trialPeriodInMonths > 0 ? (
    <>
      <p className="mt-5 mb-0 fontSize09">{translate("cProductTable.firstXMonths", false, { trialPeriodInMonths })}</p>
      <p className="mt-1 mb-0 text-satonda h2 text-bold">
        {curr}
        {formatNumber(period === PERIOD_ANNUALLY ? formListPrice(trialPeriodPriceAnnually / 12, 4, true) : trialPeriodPriceMonthly, "0.00")}
      </p>
      <p className="mt-1 mb-0 fontSize09">
        {translate("cProductTable.afterwards", false, null)} {curr}
        {formatNumber(period === PERIOD_ANNUALLY ? formListPrice(fullPriceAnnually / 12, 2, true) : fullPriceMonthly, "0.00")}/
        {translate("cProductTable.month", false, null)}
      </p>
    </>
  ) : (
    <>
      <p className="mt-5 mb-0 text-satonda h2 text-bold">
        {curr}
        {formatNumber(period === PERIOD_ANNUALLY ? discountedPriceAnnually : discountedPriceMonthly, "0.00")}
      </p>
      <p className="mt-1 mb-0 fontSize09">
        /{period === PERIOD_ANNUALLY ? translate("cProductTable.year", false, null) : translate("cProductTable.month", false, null)}
      </p>
    </>
  );
};

const pricePrepay = (actionText, payableAtCheckout, activeWorkspace) => {
  return (
    <>
      <p className="mt-5 mb-0 fontSize09 text-center">
        {actionText} {translate("cProductTable.yourExistingSubFor", false, null)}
      </p>
      <p className="mt-1 mb-0 text-satonda h2 text-bold">
        {getCurrency(activeWorkspace.location.priceCountry).currencySign}
        {formatNumber(payableAtCheckout, "0.00")}
      </p>
    </>
  );
};
