import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alerts = ({ alert: { activeAlerts } }) => {
  return (
    activeAlerts.length > 0 && (
      <div className="mt-3">
        {activeAlerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.alertType}`}>
            {alert.msg}
          </div>
        ))}
      </div>
    )
  );
};

// Specify what type a property should be
Alerts.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

// Connect requires to specify the state you want to use as well as the methods you want to use
// You also need to add the methods you want to use as props in the function declaration on top
export default connect(
  // The state from mapStateToProps (post). If none, it set to null
  mapStateToProps,
  // An object of the methods you want to use
  null
)(Alerts);
