export const cListDnsDomains = {
  managedDomains: {
    en: "Managed domains",
    nl: "Beheerde domeinen",
  },
  externalDomains: {
    en: "External domains",
    nl: "Externe domeinen",
  },
  domain: {
    en: "Domain",
    nl: "Domein",
  },
  noDomainsOnDnsYet: {
    en: "You don't have any domains hosted on Satonda's DNS servers yet.",
    nl: "Satonda host nog geen domeinnamen op onze DNS servers voor u.",
  },
  externalDomainsExplain: {
    en: "External domains are domains that you host on your server but are not managed by Satonda. We show you their DNS records so you can easily copy them to your DNS service.",
    nl: "Externe domeinen zijn domeinen die u host op uw server maar die niet beheerd worden via Satonda. We laten u hun DNS records zien zodat u deze gemakkelijk kunt kopieren naar de service waar u hun DNS beheert.",
  },
  noExternalDomains: {
    en: "We haven't found any domains you're hosting that are not managed by Satonda",
    nl: "We hebben geen domeinen gevonden die u host en die niet beheerd worden via Satonda",
  },
  records: {
    en: "Records",
    nl: "Records",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  editDomainDnsRecords: {
    en: "Edit domain's DNS records",
    nl: "Pas DNS records van domein aan",
  },
  removeAllDomainDnsRecords: {
    en: "Remove all domain's DNS records",
    nl: "Verwijder alle DNS records van domein",
  },
  showingManagedDomains: {
    en: "Showing managed domains",
    nl: "Beheerde domeinen",
  },
  of: {
    en: "of",
    nl: "van",
  },
  summaryDns: {
    en: "DNS overview",
    nl: "DNS overzicht",
  },
};
