export const pCheckoutStatus = {
  // New payment messages
  msg1Pending_payment: {
    en: "Your order has been received and payment is pending confirmation.",
    nl: "Uw bestelling is ontvangen en betaling is in behandeling",
  },
  msg1Success_payment: {
    en: "Your order has been placed successfully!",
    nl: "Uw bestelling is succesvol ontvangen!",
  },
  msg2Pending_payment: {
    en: "We will notify you through an email when your payment has been confirmed and your selected subscriptions are ready to be used.",
    nl: "We zullen u op de hoogte houden via email wanneer uw betaling is ontvangen en de geselecteerde abonnementen klaar staan.",
  },
  msg2Success_payment: {
    en: "We will notify you through an email when your new subscriptions are ready to be used. In most cases, this should not take more than a few minutes.",
    nl: "We zullen u op de hoogte houden via email wanneer uw nieuwe abonnementen klaar zijn voor gebruik. In de meeste gevallen zou dit niet meer dan een paar minuten moeten duren.",
  },
  // Overdue invoice messages
  msg1Pending_overdue: {
    en: "Your payment is pending confirmation.",
    nl: "Uw betaling is in behandeling",
  },
  msg1Success_overdue: {
    en: "Your payment has been received successfully!",
    nl: "Uw betaling is succesvol ontvangen!",
  },
  msg2Pending_overdue: {
    en: "We will notify you through an email when your payment has been confirmed.",
    nl: "We zullen u op de hoogte houden via email wanneer uw betaling is ontvangen.",
  },
  // General
  msg1Failed: {
    en: "Payment was refused. Please try a different payment method or card.",
    nl: "Betaling was geweigerd. Probeer aub een andere betaalmethode of creditcard.",
  },
  msg1Err: {
    en: "An error occured.",
    nl: "Er is een fout opgetreden.",
  },
  returnHome: {
    en: "Return home",
    nl: "Terug naar startpagina",
  },
};
