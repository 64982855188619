import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormatDate from "../layout/FormatDate";
import FormatNumber from "../layout/FormatNumber";

import { getTotalAmountAtCheckoutIncVat } from "../products/lib/ProductFunctions";
import { translate } from "../../translations/translations";
import { getVatRate, isWsVatNumberConfirmed } from "../../lib/VatRates";

// Doesn't seem possible to cancel payments prior to authorisation?

// TODO: Rewrite to get pendingPayments from somewhere

const PendingPayments = ({ auth: { user, activeWorkspace } }) => {
  const pendingPayments = []; // Get from redux

  return user !== null && activeWorkspace !== null && pendingPayments.length > 0 ? (
    <div className="mb-5">
      <h2 className="text-secondary mb-3">{translate("cPendingPayments.youHavePendingPayments", false, null)}</h2>
      {pendingPayments.map((pendingPayment) => (
        <div className="alert alert-warning d-flex align-items-center my-2" key={pendingPayment._id}>
          <span>
            <span className="text-bold">{translate("cPendingPayments.date", false, null)}:</span>{" "}
            <FormatDate dateString={pendingPayment.createdAt} language={user.language} formatStr={"dmmmyy"} />
          </span>
          <span className="ms-5">
            <span className="text-bold">{translate("cPendingPayments.items", false, null)}:</span>{" "}
            {pendingPayment.items.map((item, i) => (
              <span key={item._id}>
                {i > 0 && ", "}
                {item.productName} ({item.packageName})
              </span>
            ))}
          </span>
          <span className="ms-5">
            <span className="text-bold">{translate("cPendingPayments.amount", false, null)}:</span> {pendingPayment.items[0].currency}{" "}
            <FormatNumber
              number={getTotalAmountAtCheckoutIncVat(
                pendingPayment.origin,
                pendingPayment.items,
                getVatRate(activeWorkspace.location.countryCode, activeWorkspace.customerType, isWsVatNumberConfirmed(activeWorkspace))
              )}
              formatStr={"0.00"}
            />
          </span>
          {/* <button className="btn btn-light ms-5 trans-3 px-5">Cancel</button> */}
        </div>
      ))}
    </div>
  ) : null;
};

PendingPayments.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(PendingPayments);
