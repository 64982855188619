import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { translate } from "../../../translations/translations";
import { getWorkspaceProductPackageDetails } from "../../products/lib/ProductFunctions";
import { PRODUCT_NAMES } from "../../products/lib/ProductVars";
import DashboardErrorItem from "../DashboardErrorItem";

ChartJS.register(ArcElement, Tooltip, Legend);

const EmEmailsSent = ({ auth: { activeWorkspace } }) => {
  const [emailData, setEmailData] = useState([]);

  useEffect(() => {
    getEmailData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getEmailData = () => {
    try {
      const emQuota = parseInt(
        getWorkspaceProductPackageDetails(activeWorkspace.subscriptions, PRODUCT_NAMES.emailMarketing).filter(
          (quota) => quota.name === "emMailsSent"
        )[0].value
      );
      const sent =
        typeof activeWorkspace.emailMarketing.emailsSentThisMonth === "undefined" ? 0 : parseInt(activeWorkspace.emailMarketing.emailsSentThisMonth);
      setEmailData([sent, emQuota - sent]);
    } catch (error) {
      setEmailData([]);
    }
  };

  const data = {
    labels: [translate("cUserDashboard.emStatsSent", false, null), translate("cUserDashboard.emStatsUnused", false, null)],
    datasets: [
      {
        label: translate("cUserDashboard.emStatsLabel", false, null),
        data: emailData,
        backgroundColor: ["rgba(32, 158, 78, 0.2)", "rgba(206, 212, 218, 0.2)"],
        borderColor: ["rgba(32, 158, 78, 1)", "rgba(206, 212, 218, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return emailData.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noEmStatsFound", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

EmEmailsSent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(EmEmailsSent);
