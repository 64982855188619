import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProductTable from "./ProductTable";
import SelectPeriod from "./SelectPeriod";

import { translate } from "../../../translations/translations";
import { PERIOD_ANNUALLY, PRODUCT_NAMES } from "../lib/ProductVars";
import { getProductTableData } from "../lib/productTableFunctions";

const ProductTableHosting = ({ auth: { activeWorkspace, fxRates }, brandProducts: { brandData, brandOffers, products }, src }) => {
  const [period, setPeriod] = useState(PERIOD_ANNUALLY);
  const [productPackages, setProductPackages] = useState([]);
  const [surchargeMonthlyPayment, setSurchargeMonthlyPayment] = useState(0);

  useEffect(() => {
    if (activeWorkspace !== null && brandData !== null && brandOffers.length > 0 && products.length > 0) {
      const { productPackages, surchargeMonthlyPayment } = getProductTableData(
        activeWorkspace,
        products,
        brandData,
        brandOffers,
        PRODUCT_NAMES.hosting,
        fxRates
      );
      setProductPackages(productPackages);
      setSurchargeMonthlyPayment(surchargeMonthlyPayment);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, brandData, brandOffers, products]);

  // Product data
  const storage = { starter: "2GB", advanced: "5GB", pro: "25GB", expert: "150GB", elite: "450GB" };
  const mailboxes = {
    starter: "5",
    advanced: "25",
    pro: "100",
    expert: translate("cProductTable.unlimited", false, null),
    elite: translate("cProductTable.unlimited", false, null),
  };
  const domains = {
    starter: "3",
    advanced: translate("cProductTable.unlimited", false, null),
    pro: translate("cProductTable.unlimited", false, null),
    expert: translate("cProductTable.unlimited", false, null),
    elite: translate("cProductTable.unlimited", false, null),
  };
  const databases = { starter: "1", advanced: "3", pro: "10", expert: "50", elite: "100" };

  const SPECS_STARTER = [
    translate("cProductTable.freeSSL", false, null),
    translate("cProductTable.storage", false, { storage: storage.starter }),
    translate("cProductTable.traffic", false, null),
    translate("cProductTable.mailboxes", false, { mailboxes: mailboxes.starter }),
    translate("cProductTable.domains", false, { domains: domains.starter }),
    translate("cProductTable.databases", false, { databases: databases.starter }),
  ];
  const SPECS_ADVANCED = [
    translate("cProductTable.freeSSL", false, null),
    translate("cProductTable.storage", false, { storage: storage.advanced }),
    translate("cProductTable.traffic", false, null),
    translate("cProductTable.mailboxes", false, { mailboxes: mailboxes.advanced }),
    translate("cProductTable.domains", false, { domains: domains.advanced }),
    translate("cProductTable.databases", true, { databases: databases.advanced }),
    translate("cProductTable.wpToolkit", false, null),
  ];
  const SPECS_PRO = [
    translate("cProductTable.freeSSL", false, null),
    translate("cProductTable.storage", false, { storage: storage.pro }),
    translate("cProductTable.traffic", false, null),
    translate("cProductTable.mailboxes", false, { mailboxes: mailboxes.pro }),
    translate("cProductTable.domains", false, { domains: domains.pro }),
    translate("cProductTable.databases", true, { databases: databases.pro }),
    translate("cProductTable.wpToolkit", false, null),
  ];
  const SPECS_EXPERT = [
    translate("cProductTable.freeSSL", false, null),
    translate("cProductTable.storage", false, { storage: storage.expert }),
    translate("cProductTable.traffic", false, null),
    translate("cProductTable.mailboxes", false, { mailboxes: mailboxes.expert }),
    translate("cProductTable.domains", false, { domains: domains.expert }),
    translate("cProductTable.databases", true, { databases: databases.expert }),
    translate("cProductTable.wpToolkit", false, null),
  ];
  const SPECS_ELITE = [
    translate("cProductTable.freeSSL", false, null),
    translate("cProductTable.storage", false, { storage: storage.elite }),
    translate("cProductTable.traffic", false, null),
    translate("cProductTable.mailboxes", false, { mailboxes: mailboxes.elite }),
    translate("cProductTable.domains", false, { domains: domains.elite }),
    translate("cProductTable.databases", true, { databases: databases.elite }),
    translate("cProductTable.wpToolkit", false, null),
  ];

  const TAG_LINES = [
    translate("cProductTable.tagStarter", false, null),
    translate("cProductTable.tagAdvanced", false, null),
    translate("cProductTable.tagPro", false, null),
    translate("cProductTable.tagExpert", false, null),
    translate("cProductTable.tagElite", false, null),
  ];

  return productPackages.length > 0 ? (
    <>
      <SelectPeriod period={period} setPeriod={setPeriod} surchargeMonthlyPayment={surchargeMonthlyPayment} />
      <div className="row mt-3">
        {src === "woo" ? (
          <>
            <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[2]} specs={SPECS_PRO} tagLine={TAG_LINES[2]} />
            <ProductTable
              period={period}
              col={4}
              boolHighlight={false}
              productData={productPackages[3]}
              specs={SPECS_EXPERT}
              tagLine={TAG_LINES[3]}
            />
            <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[4]} specs={SPECS_ELITE} tagLine={TAG_LINES[4]} />
          </>
        ) : src === "wp" ? (
          <>
            <ProductTable
              period={period}
              col={4}
              boolHighlight={false}
              productData={productPackages[1]}
              specs={SPECS_ADVANCED}
              tagLine={TAG_LINES[1]}
            />
            <ProductTable period={period} col={4} boolHighlight={false} productData={productPackages[2]} specs={SPECS_PRO} tagLine={TAG_LINES[2]} />
            <ProductTable
              period={period}
              col={4}
              boolHighlight={false}
              productData={productPackages[3]}
              specs={SPECS_EXPERT}
              tagLine={TAG_LINES[3]}
            />
          </>
        ) : (
          <>
            <ProductTable
              period={period}
              col={3}
              boolHighlight={false}
              productData={productPackages[0]}
              specs={SPECS_STARTER}
              tagLine={TAG_LINES[0]}
            />
            <ProductTable
              period={period}
              col={3}
              boolHighlight={false}
              productData={productPackages[1]}
              specs={SPECS_ADVANCED}
              tagLine={TAG_LINES[1]}
            />
            <ProductTable period={period} col={3} boolHighlight={false} productData={productPackages[2]} specs={SPECS_PRO} tagLine={TAG_LINES[2]} />
            <ProductTable
              period={period}
              col={3}
              boolHighlight={false}
              productData={productPackages[3]}
              specs={SPECS_EXPERT}
              tagLine={TAG_LINES[3]}
            />
          </>
        )}
      </div>
    </>
  ) : (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  );
};

ProductTableHosting.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(ProductTableHosting);
