import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import Spinner from "../../layout/Spinner";
import DomainCard from "./DomainCard";

import { addPricesToDomainSuggestions } from "./lib/domainsFunctions";
import { translate } from "../../../translations/translations";
import { setAlert } from "../../../actions/alert";
import { API_URL, POST_CONFIG } from "../../../lib/GeneralVars";

const DomainSuggestions = ({ auth: { langCode, activeWorkspace }, brandProducts: { TLDs }, setAlert }) => {
  const wrapper = useRef();
  const slide1 = useRef();
  const slide2 = useRef();
  const slide3 = useRef();
  const slide4 = useRef();

  const WRAPPER_HEIGHT = "200px";
  const ROW_CLASS = "row justify-content-center trans-5";
  const COL_CLASSS = "col-12";
  const BTN_ROW = "d-flex justify-content-between mt-3";
  const BTN_NEXT = "btn btn-primary px-5 ms-auto";
  const BTN_PREV = "btn btn-midgray px-5";

  const [businessType, setBusinessType] = useState("");
  const [location, setLocation] = useState("");
  const [keywords, setKeywords] = useState("");
  const [activeSlide, setActiveSlide] = useState(1);
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [suggestions, setSuggestions] = useState([]);
  const [lastSearch, setLastSearch] = useState("");

  const sleep = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
  };

  useEffect(() => {
    if (activeSlide === 4) {
      sleep(100);
      resizeWrapper(4);
    }
    // eslint-disable-next-line
  }, [suggestions]);

  const slide = (direction) => {
    // If reaching for results, call API
    if (activeSlide === 3 && direction === 1) {
      const params = JSON.stringify({ businessType, location, keywords });
      if (params !== lastSearch) {
        getSuggestions();
        setLastSearch(params);
      }
    }
    // Slide forward
    if (activeSlide === 1 && direction === 1) {
      slide1.current.style.left = "-300%";
      slide1.current.style.right = "200%";
      slide2.current.style.left = "0";
      slide2.current.style.right = "0";
    }
    if (activeSlide === 2 && direction === 1) {
      slide2.current.style.left = "-300%";
      slide2.current.style.right = "200%";
      slide3.current.style.left = "0";
      slide3.current.style.right = "0";
    }
    if (activeSlide === 3 && direction === 1) {
      slide3.current.style.left = "-300%";
      slide3.current.style.right = "200%";
      slide4.current.style.left = "0";
      slide4.current.style.right = "0";
    }
    // Slide backward
    if (activeSlide === 2 && direction === -1) {
      slide2.current.style.left = "200%";
      slide2.current.style.right = "-300%";
      slide1.current.style.left = "0";
      slide1.current.style.right = "0";
    }
    if (activeSlide === 3 && direction === -1) {
      slide3.current.style.left = "200%";
      slide3.current.style.right = "-300%";
      slide2.current.style.left = "0";
      slide2.current.style.right = "0";
    }
    if (activeSlide === 4 && direction === -1) {
      slide4.current.style.left = "200%";
      slide4.current.style.right = "-300%";
      slide3.current.style.left = "0";
      slide3.current.style.right = "0";
    }
    // Update height of wrapper div
    resizeWrapper(activeSlide + direction);
    // Update activeSlide state
    setActiveSlide((prev) => prev + direction);
  };

  const resizeWrapper = (slide) => {
    let height = parseInt(WRAPPER_HEIGHT);
    if (slide === 1) {
      height = slide1.current.clientHeight;
    }
    if (slide === 2) {
      height = slide2.current.clientHeight;
    }
    if (slide === 3) {
      height = slide3.current.clientHeight;
    }
    if (slide === 4) {
      height = slide4.current.clientHeight;
    }
    wrapper.current.style.height = `${height}px`;
  };

  const BtnNext = ({ disabled, btnText }) => (
    <button
      className={BTN_NEXT}
      // disabled={businessType === ""}
      title="Go to the next step"
      onClick={() => slide(1)}
      disabled={disabled}
    >
      {btnText ? btnText : "Next"}
    </button>
  );

  const BtnPrev = () => (
    <button className={BTN_PREV} title="Go back" onClick={() => slide(-1)}>
      Back
    </button>
  );

  //   const TEST_SUGGESTIONS = [
  //     {
  //       domainName: "test.com",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "eenstuklangerenaamvoorbeeld.nl",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "nu.com",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "cnn.com",
  //       available: false,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "fr12.nl",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "feyenoord.nl",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "voetbal.nu",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "whatever.be",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "german.de",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "paris.fr",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //     {
  //       domainName: "london.co.uk",
  //       available: true,
  //       premium: false,
  //       error: false,
  //     },
  //   ];

  // const addPrices = (data) => {
  //   return data
  //     .map((item) => {
  //       const { domainName } = item;
  //       const tld = getTldFromDomain(domainName);
  //       // Add price/currency data
  //       const tldData = TLDs.filter((t) => t.tld === tld)[0];
  //       if (typeof tldData === "undefined") {
  //         return { ...item, error: true };
  //       }
  //       return { ...item, ...tldData };
  //     })
  //     .filter((item) => !item.error);
  // };

  const getSuggestions = async () => {
    setLocalLoading({ isLoading: true, msg: "Generating suggestions.." });
    try {
      //   await new Promise((r) => setTimeout(r, 2000));
      //   setSuggestions(addPrices(TEST_SUGGESTIONS));
      const body = JSON.stringify({
        type: businessType,
        location,
        keywords: keywords.split(",").map((x) => x.replace(/^\s+/, "").replace(/\s+$/, "")), // remove spaces from begin & end
        langCode,
      });
      const res = await axios.post(`${API_URL}/domains/getSuggestions`, body, POST_CONFIG);
      setSuggestions(addPricesToDomainSuggestions(res.data, TLDs));
    } catch (error) {
      console.error(error);
      setAlert(translate("common.serverError", false, null), "danger");
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  return activeWorkspace === null ? (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  ) : (
    <>
      <div className="">
        <h1 className="text-primary mb-4">Need inspiration? Let AI suggest domain names for you!</h1>
      </div>
      <div className="posRelative trans-5" style={{ height: WRAPPER_HEIGHT, overflow: "hidden" }} ref={wrapper}>
        {/* Slide 1 - Business/site type */}
        <div className={ROW_CLASS} style={{ position: "absolute", left: "0", right: "0" }} ref={slide1}>
          <div className={COL_CLASSS}>
            <p className="mb-3">
              Tell us what type of business or website you will be running. For example, a restaurant, blog or advisory services.
            </p>
            <input
              type="text"
              className="form-control"
              placeholder="Restaurant"
              value={businessType}
              onChange={(e) => setBusinessType(e.target.value)}
            />
            <div className={BTN_ROW}>
              <BtnNext disabled={businessType === ""} />
            </div>
          </div>
        </div>
        {/* Slide 2 - Location */}
        <div className={ROW_CLASS} style={{ position: "absolute", left: "200%", right: "-300%" }} ref={slide2}>
          <div className={COL_CLASSS}>
            <p className="mb-3">
              Where is your business located or what area do you serve? This is not relevant for all business, so you can leave it empty if you want.
            </p>
            <input
              type="text"
              className="form-control"
              placeholder="Amsterdam, the Netherlands"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <div className={BTN_ROW}>
              <BtnPrev />
              <BtnNext disabled={false} />
            </div>
          </div>
        </div>
        {/* Slide 3 - Keywords */}
        <div className={ROW_CLASS} style={{ position: "absolute", left: "200%", right: "-300%" }} ref={slide3}>
          <div className={COL_CLASSS}>
            <p className="mb-3">Provide a list of keywords that describe your business. Separate different keywords with a ","</p>
            <input
              type="text"
              className="form-control"
              placeholder="Italian, healthy, pasta"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
            <div className={BTN_ROW}>
              <BtnPrev />
              <BtnNext disabled={keywords === ""} btnText={"Get suggestions"} />
            </div>
          </div>
        </div>
        {/* Slide 4 - Results */}
        <div className={ROW_CLASS} style={{ position: "absolute", left: "200%", right: "-300%" }} ref={slide4}>
          <div className={COL_CLASSS}>
            {localLoading.isLoading ? (
              <div className="mt-5">
                <Spinner msg={localLoading.msg} />
              </div>
            ) : (
              <>
                {suggestions.length === 0 ? (
                  <p className="my-3 text-italic">No suggestions were generated. Please change some parameters and try again.</p>
                ) : (
                  <div className="row mt-3">
                    {suggestions.map((suggestion, i) => (
                      <DomainCard data={suggestion} key={`sugg${i}`} />
                    ))}
                  </div>
                )}
                <div className={BTN_ROW}>
                  <BtnPrev />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

DomainSuggestions.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, { setAlert })(DomainSuggestions);
