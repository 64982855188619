export const cValidateNewEmail = {
  validateNewEmail: {
    en: "Validate new email address",
    nl: "Valideer nieuw emailadres",
  },
  verificationCode: {
    en: "Verification code",
    nl: "Bevestigingscode",
  },
  validateEmailAddress: {
    en: "Validate email address",
    nl: "Valideer emailadres",
  },
  sendVerificationCode: {
    en: "Send verification code",
    nl: "Verstuur bevestigingscode",
  },
  verifyingEmailAddress: {
    en: "Verifying email address..",
    nl: "Emailadres verifieren..",
  },
  emailAddressVerified: {
    en: "Email address has been successfully verified!",
    nl: "Emailadres is succesvol geverifieerd!",
  },
  invalidVerificationCode: {
    en: "Invalid verification code. Please try again.",
    nl: "Ongeldige bevestigingscode. Probeer het aub opnieuw.",
  },
  sendingVerificationCode: {
    en: "Sending verification code..",
    nl: "Versturen bevestigingscode",
  },
  verificationCodeSent: {
    en: "Verification code has been sent to %emailAddress%! Please check your inbox.",
    nl: "Bevestigingscode is verstuurd naar %emailAddress%! Ga aub naar uw inbox.",
  },
  provideFromName: {
    en: `Please provide a default "from" name`,
    nl: `Geef aub een standaard "van" naam op`,
  },
  provideValidEmail: {
    en: "Please provide a valid email address",
    nl: "Geef aub een geldig emailadres op",
  },
  emailAlreadyValidated: {
    en: "Email address is already validated",
    nl: "Emailadres is al gevalideerd",
  },
};
