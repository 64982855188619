export const cAudienceEntriesTable = {
  removeEntry: {
    en: "Remove entry",
    nl: "Verwijder gegevens",
  },
  thereAreNoMoreEntries: {
    en: "There are no more entries",
    nl: "Er zijn geen meer gegevens",
  },
  seeAddnlEntries: {
    en: "See additional entries",
    nl: "Bekijk meer gegevens",
  },
  showingEntries: {
    en: "Showing entries",
    nl: "Gegevens",
  },
  of: {
    en: "of",
    nl: "van",
  },
  setNewFilter: {
    en: "Set new filter rule",
    nl: "Voeg nieuwe filter toe",
  },
  filterByVar: {
    en: "Filter by variable",
    nl: "Filter bij variabele",
  },
  selectVar: {
    en: "Select variable",
    nl: "Selecteer variabele",
  },
  emailAddress: {
    en: "Email address",
    nl: "Email adres",
  },
  selectValues: {
    en: "Select value(s)",
    nl: "Selecteer waarde(n)",
  },
  noValuesFound: {
    en: "No values found",
    nl: "Geen waarden gevonden",
  },
  activeFilters: {
    en: "Active filters",
    nl: "Actieve filters",
  },
  noFiltersSet: {
    en: "No filters have been set",
    nl: "Er zijn geen filters toegepast",
  },
  removeFilter: {
    en: "Remove filter",
    nl: "Verwijder filter",
  },
  noEntriesMatchingFilter: {
    en: "No entries matching filter.",
    nl: "Er zijn geen gegevens die aan de filter voldoen.",
  },
};
