import React from "react";

import EbCreateNewProject from "../../../../components/products/email-marketing/manage/EbCreateNewProject";
import EbListProjects from "../../../../components/products/email-marketing/manage/EbListProjects";

import { translate } from "../../../../translations/translations";

const ManageEmailBuilderProjects = () => {
  return (
    <>
      <h1 className="text-primary">{translate("pEmEbProject.manageProjects", false, null)}</h1>
      {/* Start new project */}
      <EbCreateNewProject />
      {/* Edit existing projects */}
      <EbListProjects />
    </>
  );
};

export default ManageEmailBuilderProjects;
