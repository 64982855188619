export const pHostingMain = {
  whyUs: {
    en: "Why host your website with us",
    nl: "Waarom u website hosten met ons",
  },
  highPerformance: {
    en: "High performance",
    nl: "Sterke prestaties",
  },
  highPerformanceTag: {
    en: "World-class servers offering lightning-fast loading that will impress your visitors and skyrocket your SEO ranking!",
    nl: "Servers van wereldklasse bieden bliksemsnelle laadtijden waar uw bezoekers onder de indruk van zullen zijn en uw SEO score omhoog doet schieten!",
  },
  securityFirst: {
    en: "Security first",
    nl: "Veiligheid voorop",
  },
  securityFirstTag: {
    en: "You will get a free SSL certificate for each website you host with us!",
    nl: "U krijgt een gratis SSL certificaat voor elke website die u host bij ons!",
  },
  customize: {
    en: "Make it your own",
    nl: "Maak het uniek",
  },
  customizeTag: {
    en: "Customize your WordPress site with thousands of themes and plugins!",
    nl: "Personaliseer uw WordPress site met duizenden themas en plugins!",
  },
  planForYou: {
    en: "The right plan for you",
    nl: "Het juiste pakket voor u",
  },
  planForYouTag: {
    en: "We offer different plans, so you can decide which one fits your needs best. When you grow, you can easily upgrade!",
    nl: "Wij bieden meerdere pakketten aan, zodat u het pakket kan kiezen die het best bij u past. Later upgraden is gemakkelijk!",
  },
  webHosting: {
    en: "Web hosting",
    nl: "Web hosting",
  },
  wpHosting: {
    en: "WordPress hosting",
    nl: "WordPress hosting",
  },
  ecommerceHosting: {
    en: "E-commerce hosting",
    nl: "Webshop hosting",
  },
  readMore: {
    en: "Read more",
    nl: "Lees meer",
  },
};
