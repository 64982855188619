export const pEmNewCampaign = {
  errorCampaignType: {
    en: "Please specify a campaign type",
    nl: "Specificeer aub een campagne type",
  },
  errorCampaignName: {
    en: "Please specify a campaign name",
    nl: "Specificeer aub een campagne naam",
  },
  errorCampaignSender: {
    en: "Please specify a sender name",
    nl: "Specificeer aub een naam van de zender",
  },
  errorCampaignEmail: {
    en: "Please specify a valid sender email",
    nl: "Specificeer aub de zender's email adres",
  },
  errorCampaignPw: {
    en: "Please specify the email address password",
    nl: "Specificeer aub het wachtwoord van de zender's email adres",
  },
  errorCampaignAudience: {
    en: "No audience has been selected or no recipient matches your filters",
    nl: "Er is geen audience geselecteerd of geen enkele ontvanger voldoet aan de filters",
  },
  errorCampaignTemplate: {
    en: "Please select an email template",
    nl: "Selecteer aub een email template",
  },
  errorCampaignSubject: {
    en: "Please specify an email subject line",
    nl: "Specificeer aub het email onderwerp",
  },
  errorCampaignDynVar: {
    en: "Please assign all dynamic content variables to an audience data key",
    nl: "Wijs aub alle dynamische inhoud variabelen toe aan een audience datasleutel",
  },
  errorCampaignTime: {
    en: "Please specify the moment the email should be send (at least 20 minutes from now)",
    nl: "Specificeer aub wanneer de email verzonden moet worden (meer dan 20 minuten van nu)",
  },
  launchNewCampaign: {
    en: "Launch new email marketing campaign",
    nl: "Lanceer nieuwe email marketing campagne",
  },
  campaignSetup: {
    en: "Campaign setup",
    nl: "Campagne setup",
  },
  campaignType: {
    en: "Campaign type",
    nl: "Campagne type",
  },
  typeProactive: {
    en: "Proactive: Mails are send at the moment you schedule it",
    nl: "Proactief: Emails worden verstuurd op het moment u wilt",
  },
  typeReactive: {
    en: "Reactive: Mails are send when user completes an action",
    nl: "Reactief: Email worden verstuurd zodra een gebruiker de gespecificeerde actie heeft voldaan",
  },
  campaignName: {
    en: "Campaign name",
    nl: "Campagne naam",
  },
  campaignNameDetail: {
    en: "Marketing campaign name",
    nl: "Marketing campagne naam",
  },
  senderName: {
    en: "Sender name",
    nl: "Zender naam",
  },
  senderNameDetail: {
    en: "Name shown as the sender in the email",
    nl: "Naam zoals in de email zal komen te staan",
  },
  senderEmail: {
    en: "Sender email address",
    nl: "Zender email adres",
  },
  selectSenderEmailDetail: {
    en: "Select validated email adress to send the email from",
    nl: "Selecteer het gevalideerde adres waaruit de email verzonden wordt",
  },
  senderEmailDetail: {
    en: "Email address to send the email from",
    nl: "Het adres waaruit de email verzonden wordt",
  },
  senderPw: {
    en: "Sender email address password",
    nl: "Zender email adres wachtwoord",
  },
  senderPwDetail: {
    en: "Password of the email account to login with",
    nl: "Het wachtwoord van het geselecteerde email account",
  },
  recipients: {
    en: "Recipients",
    nl: "Ontvangers",
  },
  recipientsReactive: {
    en: "Reactive emails are send to the email address provided by the user at the moment an action is completed.",
    nl: "Reactieve emails worden verstuurd aan het email adres zoals de gebruiker aangeeft.",
  },
  selectAudience: {
    en: "Select audience",
    nl: "Selecteer audience",
  },
  selectAnAudience: {
    en: "Select an audience",
    nl: "Selecteer audience",
  },
  emailContent: {
    en: "Set email content",
    nl: "Email inhoud",
  },
  selectTemplate: {
    en: "Select email template to use",
    nl: "Selecteer te gebruiken email template",
  },
  selectATemplate: {
    en: "Select an email template",
    nl: "Selecteer een email template",
  },
  setSubjectLine: {
    en: "Set email subject line",
    nl: "Email onderwerp",
  },
  emailSubjectLine: {
    en: "Email subject line",
    nl: "Email onderwerp",
  },
  assignAudienceKeys: {
    en: "Assign audience data keys to dynamic content",
    nl: "Wijs audience datasleutels toe aan dynamische inhoud",
  },
  assignInputfieldNames: {
    en: "Assign form inputfield names to dynamic content",
    nl: "Wijs namen van het formulierveld toe aan dynamische inhoud",
  },
  noDynContentFound: {
    en: "No dynamic content found in email template",
    nl: "Er is geen dynamische inhoud gevonden in de geselecteerde email template",
  },
  selectDynContentKey: {
    en: "Select a key from your audience data set",
    nl: "Selecteer een datasleutel van de geselecteerde audience",
  },
  selectDynContentInputfieldName: {
    en: "Form's inputfield name as used in Sitebuilder",
    nl: "De naam van het veld in het formulier zoals gebruikt in Sitebuilder",
  },
  schedule: {
    en: "Schedule",
    nl: "Plan",
  },
  scheduleReactive: {
    en: "Reactive emails are send at the moment the user completes the action.",
    nl: "Reactieve emails worden verstuurd op het moment de gebruiker de gespecificeerde actie heeft voldaan.",
  },
  scheduleWhen: {
    en: "Schedule when email will be sent",
    nl: "Bepaal wanneer de emails verzonden zullen worden",
  },
  selectDate: {
    en: "Select date",
    nl: "Selecteer datum",
  },
  selectTime: {
    en: "Select time (hh:mm)",
    nl: "Selecteer tijd (uu:mm)",
  },
  clickLaunchNewCampaign: {
    en: "Launch new campaign",
    nl: "Lanceer nieuwe campagne",
  },
};
