import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  isValidFqdn,
  getDnsDomainIdFromDomainName,
  hostnameTypeCombinationAlreadyExists,
  takeoutTrailingDotInHostname,
  parseIntTtl,
} from "../lib/dnsFunctions";

import { createDnsRecord, updateDnsRecord } from "../../../../actions/dns";

import { translate } from "../../../../translations/translations";

const RecordCAA = ({ auth: { activeWorkspace }, dnsRecords, formDisabled, existingData, createDnsRecord, updateDnsRecord }) => {
  let { domainName } = useParams();
  let navigate = useNavigate();

  const [hostname, setHostname] = useState(existingData ? existingData.recordFields.hostname : "");
  const [errorMsgHostname, setErrorMsgHostname] = useState("");
  const [authGrantedFor, setAuthGrantedFor] = useState(existingData ? existingData.recordFields.authGrantedFor : "");
  const [errorMsgAuthGrantedFor, setErrorMsgAuthGrantedFor] = useState("");
  const [tag, setTag] = useState(existingData ? existingData.recordFields.tag : "");
  const [errorMsgTag, setErrorMsgTag] = useState("");
  const [flags, setFlags] = useState(existingData ? existingData.recordFields.flags : "");
  const [errorMsgFlags, setErrorMsgFlags] = useState("");
  const [TTL, setTTL] = useState(existingData ? existingData.recordFields.TTL : "3600");
  const [errorMsgTTL, setErrorMsgTTL] = useState("");

  const RECORD_TYPE = "CAA";

  const ensureDoubleQuotes = (str) => {
    // CAA record's auth granted for needs to be escaped
    return `"${str.replace(/"/g, "")}"`;
  };

  const clickCreateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      createDnsRecord(activeWorkspace._id, domainId, RECORD_TYPE, {
        hostname: hostnameToUse,
        authGrantedFor: ensureDoubleQuotes(authGrantedFor),
        tag,
        flags,
        TTL: parseIntTtl(TTL),
      });
      resetStateVars();
    }
  };

  const clickUpdateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      updateDnsRecord(activeWorkspace._id, domainId, existingData._id, RECORD_TYPE, {
        hostname: hostnameToUse,
        authGrantedFor: ensureDoubleQuotes(authGrantedFor),
        tag,
        flags,
        TTL: parseIntTtl(TTL),
      });
      resetStateVars();
      navigate(`/dns/${domainName}`);
    }
  };

  const checkErrors = (hostnameToUse) => {
    resetErrorMessages();
    let errors = 0;
    if (hostnameToUse === "" || !isValidFqdn(hostnameToUse)) {
      setErrorMsgHostname(translate("cCreateDnsRecords.enterHostname", false, null));
      errors++;
    }
    if (authGrantedFor === "") {
      setErrorMsgAuthGrantedFor(translate("cCreateDnsRecords.enterCertificateAuth", false, null));
      errors++;
    }
    if (tag === "") {
      setErrorMsgTag(translate("cCreateDnsRecords.enterTag", false, null));
      errors++;
    }
    if (flags === "") {
      setErrorMsgFlags(translate("cCreateDnsRecords.enterFlags", false, null));
      errors++;
    }
    if (TTL === "") {
      setErrorMsgTTL(translate("cCreateDnsRecords.enterTtl", false, null));
      errors++;
    }
    if (hostnameTypeCombinationAlreadyExists(hostnameToUse, RECORD_TYPE, dnsRecords)) {
      setErrorMsgHostname(translate("cCreateDnsRecords.recordTypeHostnameCombiAlreadyExists", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setHostname("");
    setAuthGrantedFor("");
    setTag("");
    setFlags("");
    setTTL("3600");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrorMsgHostname("");
    setErrorMsgAuthGrantedFor("");
    setErrorMsgTag("");
    setErrorMsgFlags("");
    setErrorMsgTTL("");
  };

  return (
    <>
      <p>{translate("cCreateDnsRecords.caaRecordExplanation", false, null)}</p>
      <div className="row">
        {/* Hostname */}
        <div className="col-12 col-md-3 py-1">
          <label className="fontSize08" htmlFor="CAA_hostname">
            {translate("cCreateDnsRecords.hostname", false, null)}
          </label>
          <div className="input-group input-group-sm">
            <input
              type="text"
              className={`form-control form-control-sm${errorMsgHostname === "" ? "" : " is-invalid"}`}
              id="CAA_hostname"
              placeholder={translate("cCreateDnsRecords.hostname", false, null)}
              value={hostname}
              onChange={(e) => setHostname(e.target.value)}
              disabled={formDisabled}
            />
            <span className="input-group-text">.{domainName}</span>
            <div className="invalid-feedback">{errorMsgHostname}</div>
          </div>
        </div>
        {/* Authority granted for */}
        <div className="col-12 col-md-3 py-1">
          <label className="fontSize08" htmlFor="CAA_AuthGrantedFor">
            {translate("cCreateDnsRecords.authGrantedFor", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgAuthGrantedFor === "" ? "" : " is-invalid"}`}
            id="CAA_AuthGrantedFor"
            placeholder={translate("cCreateDnsRecords.authGrantedFor", false, null)}
            value={authGrantedFor}
            onChange={(e) => setAuthGrantedFor(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgAuthGrantedFor}</div>
        </div>
        {/* Tag */}
        <div className="col-12 col-md-2 py-1">
          <label className="fontSize08" htmlFor="CAA_tag">
            {translate("cCreateDnsRecords.tag", false, null)}
          </label>
          <select
            id="CAA_tag"
            className={`form-select form-select-sm${errorMsgTag === "" ? "" : " is-invalid"}`}
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            disabled={formDisabled}
          >
            <option value="">{translate("cCreateDnsRecords.selectTag", false, null)}</option>
            <option value="issue">{translate("cCreateDnsRecords.issue", false, null)}</option>
            <option value="issuewild">{translate("cCreateDnsRecords.issuewild", false, null)}</option>
            <option value="iodef">{translate("cCreateDnsRecords.iodef", false, null)}</option>
          </select>
          <div className="invalid-feedback">{errorMsgTag}</div>
        </div>
        {/* Flags */}
        <div className="col-12 col-md-2 py-1">
          <label className="fontSize08" htmlFor="CAA_Flags">
            {translate("cCreateDnsRecords.flags", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgFlags === "" ? "" : " is-invalid"}`}
            id="CAA_Flags"
            placeholder={translate("cCreateDnsRecords.flags", false, null)}
            value={flags}
            onChange={(e) => setFlags(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgFlags}</div>
        </div>
        {/* TTL */}
        <div className="col-12 col-md-2 py-1">
          <label className="fontSize08" htmlFor="CAA_TTL">
            {translate("cCreateDnsRecords.ttlSeconds", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgTTL === "" ? "" : " is-invalid"}`}
            id="CAA_TTL"
            placeholder="TTL"
            value={TTL}
            onChange={(e) => setTTL(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgTTL}</div>
        </div>
      </div>

      <div className="row mx-0 mt-2">
        {typeof existingData === "undefined" ? (
          <div className="col-12 col-md-6 col-xl-4 px-0 py-2">
            <button className="btn btn-sm btn-success w-100" onClick={clickCreateRecord} disabled={formDisabled}>
              {translate("cCreateDnsRecords.createRecord", false, null)}
            </button>
          </div>
        ) : (
          <>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-gray w-100" onClick={() => navigate(-1)} disabled={formDisabled}>
                {translate("cCreateDnsRecords.cancelUpdateRecord", false, null)}
              </button>
            </div>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-success w-100" onClick={clickUpdateRecord} disabled={formDisabled}>
                {translate("cCreateDnsRecords.updateRecord", false, null)}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

RecordCAA.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object,
  formDisabled: PropTypes.bool.isRequired,
  existingData: PropTypes.object,
  createDnsRecord: PropTypes.func.isRequired,
  updateDnsRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createDnsRecord, updateDnsRecord })(RecordCAA);
