import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import EmailCampaignStatsAuditTop from "../../../../components/products/email-marketing/manage/EmailCampaignStatsAuditTop";
import DashboardItem from "../../../../components/dashboard/DashboardItem";
import MsgStats from "../../../../components/products/email-marketing/manage/StatsCharts/MsgStats";
import VersionsSent from "../../../../components/products/email-marketing/manage/StatsCharts/VersionsSent";
import EmailsOpened from "../../../../components/products/email-marketing/manage/StatsCharts/EmailsOpened";

import { translate } from "../../../../translations/translations";
import { getDataMsgStats } from "./lib";

const EmailCampaignStats = ({
  auth: { user, activeWorkspace },
  emailMarketing: { ebProjects, executedEmailCampaigns },
  audiences: { audiences },
}) => {
  let { campaignId } = useParams();

  const [campaign, setCampaign] = useState(null);
  const [dataToUse, setDataToUse] = useState([]);
  const [versionsAvailable, setVersionsAvailable] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const STANDARD_COLORS = [
    "229, 42, 5",
    "246, 129, 0",
    "40, 167, 69",
    "13, 202, 240",
    "111, 66, 193",
    "32, 201, 151",
    "52, 58, 64",
    "214, 51, 132",
    "253, 126, 20",
    "173, 181, 189",
  ];

  useEffect(() => {
    if (activeWorkspace !== null && user !== null && campaignId !== "") {
      let selectedCampaign = executedEmailCampaigns.filter((campaign) => campaign._id === campaignId)[0];
      if (typeof selectedCampaign === "undefined") {
        setCampaign(null);
      } else {
        let usedAudience = audiences.filter((audience) => audience._id === selectedCampaign.audienceId)[0];
        let usedEbProject = ebProjects.filter((ebProject) => ebProject._id === selectedCampaign.emailTemplateId)[0];
        setCampaign({
          ...selectedCampaign,
          usedAudience: typeof usedAudience === "undefined" ? translate("pEmCampaignStats.notFound", false, null) : usedAudience.audienceName,
          usedEbProject: typeof usedEbProject === "undefined" ? translate("pEmCampaignStats.notFound", false, null) : usedEbProject.projectName,
        });
        setDataToUse(selectedCampaign.recipients);
        let availableVersions = getAvailableVersions(selectedCampaign.recipients);
        setVersionsAvailable(availableVersions);
        setSelectedVersion(availableVersions.length > 0 ? availableVersions[0] : "");
      }
    } else {
      setCampaign(null);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, user, campaignId]);

  useEffect(() => {
    if (selectedVersion !== "") {
      setDataToUse(campaign.recipients.filter((recipient) => recipient.emailVersionToReceive === selectedVersion));
    }
    // eslint-disable-next-line
  }, [campaign, selectedVersion]);

  const getAvailableVersions = (arrRecipients) => {
    return [...new Set(arrRecipients.map((recipient) => recipient.emailVersionToReceive))];
  };

  const getDataVersionsReceived = () => {
    let data = versionsAvailable.map((v) => []);
    for (const recipient of campaign.recipients) {
      const { emailVersionToReceive, received } = recipient;
      if (received) {
        data[versionsAvailable.indexOf(emailVersionToReceive)].push(1);
      }
    }
    return data.map((arr) => arr.length);
  };

  const getDataTimesEmailOpened = () => {
    let recipientsReceived = dataToUse.filter((recipient) => recipient.received);
    let recipientsOpened = recipientsReceived.map((recipient) => recipient.opened);
    let labels = [...new Set(recipientsOpened)].sort(sortAsc);
    let data = labels.map((l) => []);
    recipientsOpened.forEach((recipient) => {
      data[labels.indexOf(recipient)].push(1);
    });
    data = data.map((arr) => arr.length);
    return { labels, data };
  };

  const getDataTimesRecipientClickedLink = () => {
    let recipientsOpened = dataToUse.filter((recipient) => recipient.received && recipient.opened);
    let recipientsNumClicks = recipientsOpened.map((recipient) => recipient.clickedOn.filter((linkId) => !linkId.includes("unsubscribe")).length);
    let labels = [...new Set(recipientsNumClicks)].sort(sortAsc);
    let data = labels.map((l) => []);
    recipientsNumClicks.forEach((recipient) => {
      data[labels.indexOf(recipient)].push(1);
    });
    data = data.map((arr) => arr.length);
    return { labels, data };
  };

  const getDataTimesLinkIdClicked = () => {
    // linkId => labels array
    // numClicked => data array
    let recipientsOpened = dataToUse.filter((recipient) => recipient.received && recipient.opened);
    let recipientClickedOn = recipientsOpened.map((recipient) => recipient.clickedOn);
    let linkIdsClicked = recipientClickedOn.flat().filter((linkId) => !linkId.includes("unsubscribe"));
    let uniqueLinkIds = [...new Set(linkIdsClicked)];
    let numClicked = uniqueLinkIds.map((l) => []);
    linkIdsClicked.forEach((linkId) => {
      numClicked[uniqueLinkIds.indexOf(linkId)].push(1);
    });
    numClicked = numClicked.map((arr) => arr.length);
    let arrObj = numClicked
      .map((num, i) => ({ linkId: uniqueLinkIds[i], numClicked: num }))
      .sort((a, b) => (a.numClicked < b.numClicked ? 1 : a.numClicked > b.numClicked ? -1 : 0));
    let data = arrObj.map((link) => link.numClicked);
    let labels = arrObj.map((link) => link.linkId);
    return { labels, data };
  };

  const getBgColors = (length) => {
    return STANDARD_COLORS.slice(0, length).map((color) => `rgba(${color}, 0.2)`);
  };

  const getBorderColors = (length) => {
    return STANDARD_COLORS.slice(0, length).map((color) => `rgba(${color}, 1)`);
  };

  const sortAsc = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  return campaign === null ? (
    <p className="text-italic">{translate("pEmCampaignStats.campaignNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">
        {translate("pEmCampaignStats.statsFor", false, null)} "<span className="text-italic">{campaign.campaignName}</span>"
      </h1>
      {/* Email subject / audience name / email template name */}
      <EmailCampaignStatsAuditTop emailSubject={campaign.emailSubject} usedAudience={campaign.usedAudience} usedEbProject={campaign.usedEbProject} />
      {/* Select email version */}
      <div className="row mt-4 align-items-center">
        <div className="col-12 col-md-4 col-lg-4 col-xl-2">{translate("pEmCampaignStats.selectEmailVersion", false, null)}</div>
        <div className="col-12 col-md-8 col-lg-5 col-xl-3">
          <select className="form-select form-select-sm" value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)}>
            <option value="">{translate("pEmCampaignStats.selectEmailVersion", false, null)}</option>
            {versionsAvailable.map((v) => (
              <option key={v} value={v}>
                {translate("pEmCampaignStats.version", false, null)} {v}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* Message stats */}
      {dataToUse.length === 0 ? (
        <p className="text-italic mt-4">{translate("pEmCampaignStats.noResultsFound", false, null)}</p>
      ) : (
        <div className="row mt-4">
          <DashboardItem
            colClass={"col-12 col-lg-6"}
            title={translate("pEmCampaignStats.deliveryInteraction", false, null)}
            titleClass={"text-secondary text-center"}
            content={
              <MsgStats heading={translate("pEmCampaignStats.deliveryInteraction", false, null)} arrData={getDataMsgStats()} height={"250px"} />
            }
          />
          <DashboardItem
            colClass={"col-12 col-lg-6"}
            title={translate("pEmCampaignStats.versionsReceived", false, null)}
            titleClass={"text-secondary text-center"}
            content={
              <VersionsSent
                heading={translate("pEmCampaignStats.versionsReceived", false, null)}
                arrData={getDataVersionsReceived()}
                arrLabels={versionsAvailable.map((v) => `${translate("pEmCampaignStats.version", false, null)} ${v.toUpperCase()}`)}
                bgColors={getBgColors(versionsAvailable.length)}
                borderColors={getBorderColors(versionsAvailable.length)}
              />
            }
          />
          {/* Times opened out of total emails that were delivered */}
          <DashboardItem
            colClass={"col-12"}
            title={translate("pEmCampaignStats.timesOpened", false, null)}
            titleClass={"text-secondary text-center"}
            content={
              <EmailsOpened
                heading={translate("pEmCampaignStats.timesOpened", false, null)}
                labelsAndData={getDataTimesEmailOpened()}
                bgColors={getBgColors(1)}
                borderColors={getBorderColors(1)}
                // cbLabel={(context) => {
                //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
                // }}
                cbLabel={(context) =>
                  translate("pEmCampaignStats.numRecipientsOpenedTimes", false, { numRecipients: context.formattedValue, numOpened: context.label })
                }
              />
            }
          />
          {/* Clicks out of emails that were opened */}
          <DashboardItem
            colClass={"col-12"}
            title={translate("pEmCampaignStats.timesClickedLink", false, null)}
            titleClass={"text-secondary text-center"}
            content={
              <EmailsOpened
                heading={translate("pEmCampaignStats.timesClickedLink", false, null)}
                labelsAndData={getDataTimesRecipientClickedLink()}
                bgColors={getBgColors(1)}
                borderColors={getBorderColors(1)}
                // cbLabel={(context) => {
                //   return `${context.formattedValue} recipients have clicked a link ${context.label} times`;
                // }}
                cbLabel={(context) =>
                  translate("pEmCampaignStats.numRecipientsClickedLinks", false, { numRecipients: context.formattedValue, numOpened: context.label })
                }
              />
            }
          />
          <DashboardItem
            colClass={"col-12"}
            title={translate("pEmCampaignStats.highestClickedLinks", false, null)}
            titleClass={"text-secondary text-center"}
            content={
              <EmailsOpened
                heading={translate("pEmCampaignStats.highestClickedLinks", false, null)}
                labelsAndData={getDataTimesLinkIdClicked()}
                bgColors={getBgColors(1)}
                borderColors={getBorderColors(1)}
                // cbLabel={(context) => {
                //   return `Link ID ${context.label} has been clicked ${context.formattedValue} times`;
                // }}
                cbLabel={(context) =>
                  translate("pEmCampaignStats.linkIdClickedTimes", false, { linkId: context.label, numClicked: context.formattedValue })
                }
              />
            }
          />
        </div>
      )}
    </>
  );
};

EmailCampaignStats.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(EmailCampaignStats);
