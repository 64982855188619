export const SUPPORTED_CURRENCIES = ["EUR", "GBP", "USD"];

export const EMPTY_STANDARD_PRICE_OBJ = SUPPORTED_CURRENCIES.reduce((a, v) => ({ ...a, [v]: 0 }), {});
export const EMPTY_STANDARD_PRICE_MARGIN_OBJ = SUPPORTED_CURRENCIES.reduce((a, v) => ({ ...a, [v]: "n.a." }), {});

export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

// export const API_URL = "https://orca-app-wrigz.ondigitalocean.app/api"; // No trailing "/"
export const API_URL = "https://api.satonda.com/api"; // No trailing "/"
// export const API_URL = "http://localhost:5000/api"; // No trailing "/"

// export const SB_URL = "http://localhost:3001"; // No trailing "/"
// export const EB_URL = "http://localhost:3002"; // No trailing "/"
// export const SB_URL = "https://sitebuilder.satonda.com"; // No trailing "/"
// export const EB_URL = "https://emailbuilder.satonda.com"; // No trailing "/"

// export const MY_SATONDA_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://my.satonda.com";
// export const SB_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://sitebuilder.satonda.com";
// export const EB_URL = process.env.NODE_ENV === "development" ? "http://localhost:3002" : "https://emailbuilder.satonda.com";

export const MY_SATONDA_URL = "https://my.satonda.com";
export const SB_URL = "https://sitebuilder.satonda.com";
export const EB_URL = "https://emailbuilder.satonda.com";
