export const pEmMain = {
  whyUs: {
    en: "Why use Satonda's email marketing platform",
    nl: "Waarom gebruik maken van Satonda's email marketing platform",
  },
  highRoi: {
    en: "High ROI",
    nl: "Hoge ROI",
  },
  highRoiTag: {
    en: "Email marketing is the digital marketing method with the highest return on investment. Through our platform, you can benefit as well!",
    nl: "Email marketing is de digital marketing methode met de hoogste return on investment. Via ons platform kunt ook u hiervan profiteren!",
  },
  unlimitedContacts: {
    en: "Unlimited contacts",
    nl: "Ongelimiteerde contacten",
  },
  unlimitedContactsTag: {
    en: "We don't limit you on the number of contacts or audiences you can have, so you can reach all of them!",
    nl: "Wij zetten geen limiet aan het aantal contacten of audiences die u kunt hebben, zodat u iedereen die u wil kunt bereiken!",
  },
  abVersions: {
    en: "A/B test versions",
    nl: "A/B tests",
  },
  abVersionsTag: {
    en: "Easily make multiple versions of the same email that we will randomly send to recipients, so you can track which version performs best and optimize your conversions!",
    nl: "Maak gemakkelijk meerdere versies van dezelfde email die wij willekeurig naar uw ontvangers sturen, zodat u kunt bijhouden welke versie het beter deed en daarmee uw conversies kan optimaliseren!",
  },
  reportingAnalytics: {
    en: "Reporting & analytics",
    nl: "Rapportages & analyses",
  },
  reportingAnalyticsTag: {
    en: "Get valuable insights in the performance of your marketing campaign to uncover areas for improvement!",
    nl: "Krijg waardevolle inzichten in de prestaties van uw marketing campagnes en ontdek gebieden ter verbetering!",
  },
  readMore: {
    en: "Read more",
    nl: "Lees meer",
  },
  selectYourPlan: {
    en: "Select your plan",
    nl: "Kies uw pakket",
  },
};
