import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setGlobalLoading, removeGlobalLoading } from "../../../actions/globalLoading";
import { setAlert } from "../../../actions/alert";

import { POST_CONFIG, API_URL } from "../../../lib/GeneralVars";
import { errorStatus_initial, errorMsg_initial, getServerSideErrors } from "./lib/profileFunctions";

import { translate } from "../../../translations/translations";

const UpdatePassword = ({ auth: { user }, setGlobalLoading, removeGlobalLoading, setAlert }) => {
  const [profileFields, setProfileFields] = useState({
    curr_password: "",
    password: "",
    password2: "",
  });
  const [errorStatus, setErrorStatus] = useState(errorStatus_initial);
  const [errorMsg, setErrorMsg] = useState(errorMsg_initial);

  const { curr_password, password, password2 } = profileFields;
  const { err_curr_password, err_password, err_password2 } = errorStatus;
  const { errMsg_curr_password, errMsg_password, errMsg_password2 } = errorMsg;

  const onChange = (e) => setProfileFields({ ...profileFields, [e.target.name]: e.target.value });

  const clickChangePw = async () => {
    // First simple client-side validation (all info provided, passwords match)
    let proceed = clientSideValidation_changePw();
    // If client-side all ok, check with backend
    if (proceed) {
      let loadingId = setGlobalLoading(translate("cUserProfile.updatingPw", false, null));
      try {
        const body = JSON.stringify({ curr_password, password });
        let res = await axios.post(`${API_URL}/users/update/pw`, body, POST_CONFIG);
        if (res.data.success) {
          setAlert(res.data.msg, "success");
        } else if (res.data.errors) {
          serverSideValidation(res.data.errors);
        } else {
          throw new Error("Error");
        }
      } catch (error) {
        console.error(error);
        setAlert(translate("cUserProfile.serverErrorPw", false, null), "danger");
      }
      removeGlobalLoading(loadingId);
    }
  };

  const clientSideValidation_changePw = () => {
    let errors = 0;
    let updErrorStatus = errorStatus_initial;
    let updErrorMsg = errorMsg_initial;
    // 1) Check for empty fields
    Object.keys(profileFields).forEach((field) => {
      if (field.includes("password") && profileFields[field] === "") {
        errors++;
        updErrorStatus = { ...updErrorStatus, [`err_${field}`]: "is-invalid" };
        updErrorMsg = { ...updErrorMsg, [`errMsg_${field}`]: translate("cUserProfile.cannotBeEmpty", false, null) };
      }
    });
    // 2) Field-specific checks
    // Password min length
    if (password.length < 8) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_password: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_password: translate("cUserProfile.pwMinChars", false, null) };
    }
    if (password2.length < 8) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_password2: "is-invalid" };
      updErrorMsg = { ...updErrorMsg, errMsg_password2: translate("cUserProfile.pwMinChars", false, null) };
    }
    // Passwords match
    if (password !== "" && password2 !== "" && password.length > 8 && password2.length > 8 && password !== password2) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_password: "is-invalid", err_password2: "is-invalid" };
      updErrorMsg = {
        ...updErrorMsg,
        errMsg_password: translate("cUserProfile.pwsDontMatch", false, null),
        errMsg_password2: translate("cUserProfile.pwsDontMatch", false, null),
      };
    }
    // Old and new are not the same
    if (curr_password === password) {
      errors++;
      updErrorStatus = { ...updErrorStatus, err_curr_password: "is-invalid", err_password: "is-invalid" };
      updErrorMsg = {
        ...updErrorMsg,
        errMsg_curr_password: translate("cUserProfile.newPwCantMatchOld", false, null),
        errMsg_password: translate("cUserProfile.newPwCantMatchOld", false, null),
      };
    }
    setErrorStatus(updErrorStatus);
    setErrorMsg(updErrorMsg);
    return errors === 0;
  };

  const serverSideValidation = (errors) => {
    const { updErrorStatus, updErrorMsg } = getServerSideErrors(errors);
    setErrorStatus(updErrorStatus);
    setErrorMsg(updErrorMsg);
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-0">{translate("cUserProfile.updatePw", false, null)}</h2>
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="password">{translate("cUserProfile.currentPw", false, null)}</label>
          <input
            type="password"
            className={`form-control ${err_curr_password}`}
            name="curr_password"
            value={curr_password}
            onChange={onChange}
            placeholder={translate("cUserProfile.password", false, null)}
            required
            minLength="8"
          />
          <div className="invalid-feedback">{errMsg_curr_password}</div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 py-2 py-md-0">
          <label htmlFor="password">{translate("cUserProfile.newPw", false, null)}</label>
          <input
            type="password"
            className={`form-control ${err_password}`}
            name="password"
            value={password}
            onChange={onChange}
            placeholder={translate("cUserProfile.password", false, null)}
            required
            minLength="8"
          />
          <div className="invalid-feedback">{errMsg_password}</div>
        </div>
        <div className="col-md-6 py-2 py-md-0 ">
          <label htmlFor="password2">{translate("cUserProfile.confirmNewPw", false, null)}</label>
          <input
            type="password"
            className={`form-control ${err_password2}`}
            name="password2"
            value={password2}
            onChange={onChange}
            placeholder={translate("cUserProfile.confirmPw", false, null)}
            required
            minLength="8"
          />
          <div className="invalid-feedback">{errMsg_password2}</div>
        </div>
      </div>
      <button className="btn btn-primary w-100 mt-3" onClick={clickChangePw}>
        {translate("cUserProfile.updatePw", false, null)}
      </button>
    </>
  );
};

UpdatePassword.propTypes = {
  auth: PropTypes.object.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setGlobalLoading, removeGlobalLoading, setAlert })(UpdatePassword);
