export const countries = [
  {
    enName: "Afghanistan",
    localName: "",
    code: "af",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{4}",
    postalExample: 1057,
  },
  {
    enName: "Åland Islands",
    localName: "",
    code: "ax",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Albania",
    localName: "",
    code: "al",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 5300,
  },
  {
    enName: "Algeria",
    localName: "",
    code: "dz",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: 16000,
  },
  {
    enName: "American Samoa",
    localName: "",
    code: "as",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "967\\d{2}(-\\d{4})?",
    postalExample: "96799, 96799-9999",
  },
  {
    enName: "Andorra",
    localName: "",
    code: "ad",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "AD\\d{3}",
    postalExample: "AD100",
  },
  {
    enName: "Angola",
    localName: "",
    code: "ao",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Anguilla",
    localName: "",
    code: "ai",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(AI-2640)",
    postalExample: "AI-2640",
  },
  {
    enName: "Antarctica",
    localName: "",
    code: "aq",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(7151)",
    postalExample: 7151,
  },
  {
    enName: "Antigua and Barbuda",
    localName: "",
    code: "ag",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
    postalExample: 33901,
  },
  {
    enName: "Argentina",
    localName: "",
    code: "ar",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "([A-Z]\\d{4}[A-Z]{3})|([A-Z]\\d{4})",
    postalExample: "C1425CLA, U9000",
  },
  {
    enName: "Armenia",
    localName: "",
    code: "am",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "(\\d{4})|(\\d{6})",
    postalExample: "0010, 001011",
  },
  {
    enName: "Aruba",
    localName: "",
    code: "aw",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Australia",
    localName: "",
    code: "au",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "\\d{4}",
    postalExample: 2599,
  },
  {
    enName: "Austria",
    localName: "Österreich",
    code: "at",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 1010,
  },
  {
    enName: "Azerbaijan",
    localName: "",
    code: "az",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "(AZ)(\\d{4})|(AZ )(\\d{4})",
    postalExample: "AZ 1000, AZ1000",
  },
  {
    enName: "Bahamas",
    localName: "",
    code: "bs",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Bahrain",
    localName: "",
    code: "bh",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{3}\\d?",
    postalExample: "317, 1216",
  },
  {
    enName: "Bangladesh",
    localName: "",
    code: "bd",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "\\d{4}",
    postalExample: 1219,
  },
  {
    enName: "Barbados",
    localName: "",
    code: "bb",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "BB\\d{5}",
    postalExample: "BB15094",
  },
  {
    enName: "Belarus",
    localName: "",
    code: "by",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{6}",
    postalExample: 231300,
  },
  {
    enName: "Belgium",
    localName: "België",
    code: "be",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 1049,
  },
  {
    enName: "Belize",
    localName: "",
    code: "bz",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Benin",
    localName: "",
    code: "bj",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Bermuda",
    localName: "",
    code: "bm",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[A-Z]{2} \\d{2}",
    postalExample: "CR 03",
  },
  {
    enName: "Bhutan",
    localName: "",
    code: "bt",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "\\d{5}",
    postalExample: 31002,
  },
  {
    enName: "Bolivia",
    localName: "",
    code: "bo",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Bosnia and Herzegovina",
    localName: "",
    code: "ba",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 71000,
  },
  {
    enName: "Botswana",
    localName: "",
    code: "bw",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Bouvet Island",
    localName: "",
    code: "bv",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Brazil",
    localName: "",
    code: "br",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[0-9]{5}-[0-9]{3}",
    postalExample: "28999-999",
  },
  {
    enName: "British Indian Ocean Territory",
    localName: "",
    code: "io",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "(BB9D 1ZZ)",
    postalExample: "BB9D 1ZZ",
  },
  {
    enName: "Brunei Darussalam",
    localName: "",
    code: "bn",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "[A-Z]{2}\\d{4}",
    postalExample: "KB2333",
  },
  {
    enName: "Bulgaria",
    localName: "Bŭlgarija",
    code: "bg",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 1000,
  },
  {
    enName: "Burkina Faso",
    localName: "",
    code: "bf",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "[1-9]\\d{4}",
    postalExample: "99999",
  },
  {
    enName: "Burundi",
    localName: "",
    code: "bi",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Cambodia",
    localName: "",
    code: "kh",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "\\d{5,6}",
    postalExample: "01501, 010102, 120209",
  },
  {
    enName: "Cameroon",
    localName: "",
    code: "cm",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Canada",
    localName: "",
    code: "ca",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[A-Z][0-9][A-Z] [0-9][A-Z][0-9]",
    postalExample: "K1A 0T6",
  },
  {
    enName: "Cape Verde",
    localName: "",
    code: "cv",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 5110,
  },
  {
    enName: "Cayman Islands",
    localName: "",
    code: "ky",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[K][Y][0-9]{1}[-]([0-9]){4}",
    postalExample: "KY1-1800",
  },
  {
    enName: "Central African Republic",
    localName: "",
    code: "cf",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Chad",
    localName: "",
    code: "td",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Chile",
    localName: "",
    code: "cl",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{7}",
    postalExample: 9340000,
  },
  {
    enName: "China",
    localName: "",
    code: "cn",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: 710000,
  },
  {
    enName: "Christmas Island",
    localName: "",
    code: "cx",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(6798)",
    postalExample: 6798,
  },
  {
    enName: "Cocos (Keeling) Islands",
    localName: "",
    code: "cc",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(6799)",
    postalExample: 6799,
  },
  {
    enName: "Colombia",
    localName: "",
    code: "co",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{6}",
    postalExample: 111121,
  },
  {
    enName: "Comoros",
    localName: "",
    code: "km",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Congo",
    localName: "",
    code: "cg",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Congo, The Democratic Republic of the",
    localName: "",
    code: "cd",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Cook Islands",
    localName: "",
    code: "ck",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Costa Rica",
    localName: "",
    code: "cr",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 10101,
  },
  {
    enName: "Cote D'Ivoire",
    localName: "",
    code: "ci",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Croatia",
    localName: "",
    code: "hr",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-5]\\d{4}",
    postalExample: 21000,
  },
  {
    enName: "Cuba",
    localName: "",
    code: "cu",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(CP)?\\d{5}",
    postalExample: "CP10400",
  },
  {
    enName: "Cyprus",
    localName: "Kýpros",
    code: "cy",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{3}",
    postalExample: 4999,
  },
  {
    enName: "Czech Republic",
    localName: "Česko",
    code: "cz",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-7][0-9]{2} [0-9]{2}|[1-7][0-9]{4}",
    postalExample: "160 00, 16000",
  },
  {
    enName: "Denmark",
    localName: "Danmark",
    code: "dk",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 2000,
  },
  {
    enName: "Djibouti",
    localName: "",
    code: "dj",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Dominica",
    localName: "",
    code: "dm",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Dominican Republic",
    localName: "",
    code: "do",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 10103,
  },
  {
    enName: "Ecuador",
    localName: "",
    code: "ec",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{6}",
    postalExample: 170515,
  },
  {
    enName: "Egypt",
    localName: "",
    code: "eg",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: 12411,
  },
  {
    enName: "El Salvador",
    localName: "",
    code: "sv",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{4}",
    postalExample: 1201,
  },
  {
    enName: "Equatorial Guinea",
    localName: "",
    code: "gq",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Eritrea",
    localName: "",
    code: "er",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Estonia",
    localName: "Eesti",
    code: "ee",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 10111,
  },
  {
    enName: "Ethiopia",
    localName: "",
    code: "et",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 3020,
  },
  {
    enName: "Falkland Islands (Malvinas)",
    localName: "",
    code: "fk",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(FIQQ 1ZZ)",
    postalExample: "FIQQ 1ZZ",
  },
  {
    enName: "Faroe Islands",
    localName: "",
    code: "fo",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{3}",
    postalExample: 927,
  },
  {
    enName: "Fiji",
    localName: "",
    code: "fj",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Finland",
    localName: "Suomi",
    code: "fi",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: "00180",
  },
  {
    enName: "France",
    localName: "France",
    code: "fr",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 75008,
  },
  {
    enName: "French Guiana",
    localName: "",
    code: "gf",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "French Polynesia",
    localName: "",
    code: "pf",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "((987)\\d{2})",
    postalExample: 98755,
  },
  {
    enName: "French Southern Territories",
    localName: "",
    code: "tf",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
    postalExample: 98413,
  },
  {
    enName: "Gabon",
    localName: "",
    code: "ga",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Gambia",
    localName: "",
    code: "gm",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Georgia",
    localName: "",
    code: "ge",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: "0100",
  },
  {
    enName: "Germany",
    localName: "Deutschland",
    code: "de",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 60320,
  },
  {
    enName: "Ghana",
    localName: "",
    code: "gh",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Gibraltar",
    localName: "",
    code: "gi",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: "(GX11 1AA)",
    postalExample: "GX11 1AA",
  },
  {
    enName: "Greece",
    localName: "Hellas",
    code: "gr",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "(\\d{3}) \\d{2}|\\d{5}",
    postalExample: "241 00, 24100",
  },
  {
    enName: "Greenland",
    localName: "",
    code: "gl",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "39\\d{2}",
    postalExample: 3905,
  },
  {
    enName: "Grenada",
    localName: "",
    code: "gd",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Guadeloupe",
    localName: "",
    code: "gp",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Guam",
    localName: "",
    code: "gu",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "((969)[1-3][0-2])(-\\d{4})?",
    postalExample: "96911, 96911-9999",
  },
  {
    enName: "Guatemala",
    localName: "",
    code: "gt",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: "01002",
  },
  {
    enName: "Guernsey",
    localName: "",
    code: "gg",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
    postalExample: "GY1 3HR",
  },
  {
    enName: "Guinea",
    localName: "",
    code: "gn",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "\\d{3}",
    postalExample: "001",
  },
  {
    enName: "Guinea-Bissau",
    localName: "",
    code: "gw",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "\\d{4}",
    postalExample: 1000,
  },
  {
    enName: "Guyana",
    localName: "",
    code: "gy",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Haiti",
    localName: "",
    code: "ht",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(HT)(\\d{4})|(HT) (\\d{4})",
    postalExample: "HT1440, HT 1440",
  },
  {
    enName: "Heard Island and Mcdonald Islands",
    localName: "",
    code: "hm",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(7151)",
    postalExample: 7151,
  },
  {
    enName: "Holy See (Vatican City State)",
    localName: "",
    code: "va",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "(00120)",
    postalExample: "00120",
  },
  {
    enName: "Honduras",
    localName: "",
    code: "hn",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 34101,
  },
  {
    enName: "Hong Kong",
    localName: "",
    code: "hk",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "(999077)",
    postalExample: 999077,
  },
  {
    enName: "Hungary",
    localName: "Magyarország",
    code: "hu",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{3}",
    postalExample: 2310,
  },
  {
    enName: "Iceland",
    localName: "",
    code: "is",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "[1-9]\\d{2}",
    postalExample: 101,
  },
  {
    enName: "India",
    localName: "",
    code: "in",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "[1-9]\\d{5}",
    postalExample: 500012,
  },
  {
    enName: "Indonesia",
    localName: "",
    code: "id",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "[1-9]\\d{4}",
    postalExample: 15360,
  },
  {
    enName: "Iran",
    localName: "",
    code: "ir",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{5}[\\-]?\\d{5}",
    postalExample: "9187158198, 15119-43943",
  },
  {
    enName: "Iraq",
    localName: "",
    code: "iq",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{5}",
    postalExample: 58019,
  },
  {
    enName: "Ireland",
    localName: "Ireland",
    code: "ie",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: ".{1,255}",
    postalExample: "D02 AF30",
  },
  {
    enName: "Isle of Man",
    localName: "",
    code: "im",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
    postalExample: "IM5 1JS",
  },
  {
    enName: "Israel",
    localName: "",
    code: "il",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{7}",
    postalExample: 1029200,
  },
  {
    enName: "Italy",
    localName: "Italia",
    code: "it",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 36051,
  },
  {
    enName: "Jamaica",
    localName: "",
    code: "jm",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(JM)[A-Z]{3}\\d{2}",
    postalExample: "JMAAW19",
  },
  {
    enName: "Japan",
    localName: "",
    code: "jp",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "(\\d{3}-\\d{4})",
    postalExample: "408-0307",
  },
  {
    enName: "Jersey",
    localName: "",
    code: "je",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "JE[0-9]{1}[\\s]([\\d][A-Z]{2})",
    postalExample: "JE1 1AG",
  },
  {
    enName: "Jordan",
    localName: "",
    code: "jo",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{5}",
    postalExample: 11118,
  },
  {
    enName: "Kazakhstan",
    localName: "",
    code: "kz",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "([A-Z]\\d{2}[A-Z]\\d[A-Z]\\d)|(\\d{6})",
    postalExample: "A10A5T4, 010010",
  },
  {
    enName: "Kenya",
    localName: "",
    code: "ke",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: 40406,
  },
  {
    enName: "Kiribati",
    localName: "",
    code: "ki",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "KI\\d{4}",
    postalExample: "KI0107",
  },
  {
    enName: "Korea, Democratic People'S Republic of",
    localName: "",
    code: "kp",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Korea, Republic of",
    localName: "",
    code: "kr",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 11962,
  },
  {
    enName: "Kuwait",
    localName: "",
    code: "kw",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{5}",
    postalExample: 60000,
  },
  {
    enName: "Kyrgyzstan",
    localName: "",
    code: "kg",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: 720020,
  },
  {
    enName: "Lao People'S Democratic Republic",
    localName: "",
    code: "la",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 13000,
  },
  {
    enName: "Latvia",
    localName: "Latvija",
    code: "lv",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "((LV)[\\-])?(\\d{4})",
    postalExample: "LV-1010, 1010",
  },
  {
    enName: "Lebanon",
    localName: "",
    code: "lb",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{4}( \\d{4})?",
    postalExample: "2038 3054, 1103",
  },
  {
    enName: "Lesotho",
    localName: "",
    code: "ls",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{3}",
    postalExample: 100,
  },
  {
    enName: "Liberia",
    localName: "",
    code: "lr",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 1000,
  },
  {
    enName: "Libya",
    localName: "",
    code: "ly",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
    postalExample: "13.05.312",
  },
  {
    enName: "Liechtenstein",
    localName: "",
    code: "li",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: "9490",
  },
  {
    enName: "Lithuania",
    localName: "Lietuva",
    code: "lt",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "((LT)[\\-])?(\\d{5})",
    postalExample: "LT-01100, 01100",
  },
  {
    enName: "Luxembourg",
    localName: "Luxembourg",
    code: "lu",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "((L)[\\-])?(\\d{4})",
    postalExample: "1019, L-2530",
  },
  {
    enName: "Macao",
    localName: "",
    code: "mo",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: ".{1,255}",
    postalExample: 999078,
  },
  {
    enName: "Macedonia",
    localName: "",
    code: "mk",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 1045,
  },
  {
    enName: "Madagascar",
    localName: "",
    code: "mg",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{3}",
    postalExample: 101,
  },
  {
    enName: "Malawi",
    localName: "",
    code: "mw",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: "101100, 307100",
  },
  {
    enName: "Malaysia",
    localName: "",
    code: "my",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 50050,
  },
  {
    enName: "Maldives",
    localName: "",
    code: "mv",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 20195,
  },
  {
    enName: "Mali",
    localName: "",
    code: "ml",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Malta",
    localName: "Malta",
    code: "mt",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}",
    postalExample: "VLT 1117, TP01, TP 01, RBT1676, QRM09, BKR 01",
  },
  {
    enName: "Marshall Islands",
    localName: "",
    code: "mh",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "((969)[6-7][0-9])(-\\d{4})?",
    postalExample: "96960, 96960-9999",
  },
  {
    enName: "Martinique",
    localName: "",
    code: "mq",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Mauritania",
    localName: "",
    code: "mr",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Mauritius",
    localName: "",
    code: "mu",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "([0-9A-R]\\d{4})",
    postalExample: "A0000, 20101",
  },
  {
    enName: "Mayotte",
    localName: "",
    code: "yt",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Mexico",
    localName: "",
    code: "mx",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 97229,
  },
  {
    enName: "Micronesia, Federated States of",
    localName: "",
    code: "fm",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "9694\\d{1}(-\\d{4})?",
    postalExample: "96942, 96942-9999",
  },
  {
    enName: "Moldova, Republic of",
    localName: "",
    code: "md",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "(MD[\\-]?)?(\\d{4})",
    postalExample: "MD2001, MD-2001, 2001",
  },
  {
    enName: "Monaco",
    localName: "",
    code: "mc",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Mongolia",
    localName: "",
    code: "mn",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: "16080",
  },
  {
    enName: "Montserrat",
    localName: "",
    code: "ms",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "MSR\\d{4}",
    postalExample: "MSR1120",
  },
  {
    enName: "Morocco",
    localName: "",
    code: "ma",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "[1-9]\\d{4}",
    postalExample: 20192,
  },
  {
    enName: "Mozambique",
    localName: "",
    code: "mz",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 1104,
  },
  {
    enName: "Myanmar",
    localName: "",
    code: "mm",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 11121,
  },
  {
    enName: "Namibia",
    localName: "",
    code: "na",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: "10003",
  },
  {
    enName: "Nauru",
    localName: "",
    code: "nr",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(NRU68)",
    postalExample: "NRU68",
  },
  {
    enName: "Nepal",
    localName: "",
    code: "np",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 44600,
  },
  {
    enName: "Netherlands",
    localName: "Nederland",
    code: "nl",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{3} [A-Z]{2}|[1-9]\\d{3}[A-Z]{2}",
    postalExample: "1011 AC, 1011AC",
  },
  {
    enName: "Netherlands Antilles",
    localName: "",
    code: "an",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "New Caledonia",
    localName: "",
    code: "nc",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "988\\d{2}",
    postalExample: "98814",
  },
  {
    enName: "New Zealand",
    localName: "",
    code: "nz",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "\\d{4}",
    postalExample: 8041,
  },
  {
    enName: "Nicaragua",
    localName: "",
    code: "ni",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 11001,
  },
  {
    enName: "Niger",
    localName: "",
    code: "ne",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 8001,
  },
  {
    enName: "Nigeria",
    localName: "",
    code: "ng",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "[1-9]\\d{5}",
    postalExample: "100001",
  },
  {
    enName: "Niue",
    localName: "",
    code: "nu",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(9974)",
    postalExample: 9974,
  },
  {
    enName: "Norfolk Island",
    localName: "",
    code: "nf",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(2899)",
    postalExample: 2899,
  },
  {
    enName: "Northern Mariana Islands",
    localName: "",
    code: "mp",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "9695\\d{1}(-\\d{4})?",
    postalExample: "96950, 96950-9999",
  },
  {
    enName: "Norway",
    localName: "",
    code: "no",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{4}",
    postalExample: 5262,
  },
  {
    enName: "Oman",
    localName: "",
    code: "om",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{3}",
    postalExample: 112,
  },
  {
    enName: "Pakistan",
    localName: "",
    code: "pk",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "[1-9]\\d{4}",
    postalExample: "75600",
  },
  {
    enName: "Palau",
    localName: "",
    code: "pw",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(96939|96940)",
    postalExample: " 96939, 96940",
  },
  {
    enName: "Palestinian Territory",
    localName: "",
    code: "ps",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "(P[1-9]\\d{6})|(\\d{3}-\\d{3})",
    postalExample: "600-699, P3600700",
  },
  {
    enName: "Panama",
    localName: "",
    code: "pa",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{4}",
    postalExample: "0601, 1001",
  },
  {
    enName: "Papua New Guinea",
    localName: "",
    code: "pg",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "\\d{3}",
    postalExample: 244,
  },
  {
    enName: "Paraguay",
    localName: "",
    code: "py",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{4}",
    postalExample: 3180,
  },
  {
    enName: "Peru",
    localName: "",
    code: "pe",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}",
    postalExample: 15001,
  },
  {
    enName: "Philippines",
    localName: "",
    code: "ph",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{4}",
    postalExample: 4104,
  },
  {
    enName: "Pitcairn",
    localName: "",
    code: "pn",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(PCR9 1ZZ)",
    postalExample: "PCR9 1ZZ",
  },
  {
    enName: "Poland",
    localName: "Polska",
    code: "pl",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[0-9]{2}[-]([0-9]){3}",
    postalExample: "87-100",
  },
  {
    enName: "Portugal",
    localName: "Portugal",
    code: "pt",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{3}((-)\\d{3})",
    postalExample: "1000-260",
  },
  {
    enName: "Puerto Rico",
    localName: "",
    code: "pr",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Qatar",
    localName: "",
    code: "qa",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Reunion",
    localName: "",
    code: "re",
    inEU: true,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Romania",
    localName: "România",
    code: "ro",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{6}",
    postalExample: 507085,
  },
  {
    enName: "Russian Federation",
    localName: "",
    code: "ru",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{6}",
    postalExample: 385100,
  },
  {
    enName: "Rwanda",
    localName: "",
    code: "rw",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Saint Helena",
    localName: "",
    code: "sh",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)",
    postalExample: "ASCN 1ZZ, TDCU 1ZZ, STHL 1ZZ",
  },
  {
    enName: "Saint Kitts and Nevis",
    localName: "",
    code: "kn",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "KN\\d{4}(\\-\\d{4})?",
    postalExample: "KN0101, KN0802, KN0801-0802, KN0901-0902",
  },
  {
    enName: "Saint Lucia",
    localName: "",
    code: "lc",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "LC\\d{2}  \\d{3}",
    postalExample: "LC05 201",
  },
  {
    enName: "Saint Pierre and Miquelon",
    localName: "",
    code: "pm",
    inEU: false,
    active: false,
    region: "",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Saint Vincent and the Grenadines",
    localName: "",
    code: "vc",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(VC)(\\d{4})",
    postalExample: "VC0100",
  },
  {
    enName: "Samoa",
    localName: "",
    code: "ws",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "WS[1-2]\\d{3}",
    postalExample: "WS1382",
  },
  {
    enName: "San Marino",
    localName: "",
    code: "sm",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "(4789\\d)",
    postalExample: "47894",
  },
  {
    enName: "Sao Tome and Principe",
    localName: "",
    code: "st",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Saudi Arabia",
    localName: "",
    code: "sa",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "[1-8]\\d{4}([\\-]\\d{4})?",
    postalExample: "11564, 75311-8538",
  },
  {
    enName: "Senegal",
    localName: "",
    code: "sn",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "[1-8]\\d{4}",
    postalExample: "10200",
  },
  {
    enName: "Serbia and Montenegro",
    localName: "",
    code: "cs",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Seychelles",
    localName: "",
    code: "sc",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Sierra Leone",
    localName: "",
    code: "sl",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Singapore",
    localName: "",
    code: "sg",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: 570150,
  },
  {
    enName: "Slovakia",
    localName: "Slovensko",
    code: "sk",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "(\\d{3} \\d{2})|\\d{5}",
    postalExample: "811 01, 81101",
  },
  {
    enName: "Slovenia",
    localName: "Slovenija",
    code: "si",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{3}",
    postalExample: "8341",
  },
  {
    enName: "Solomon Islands",
    localName: "",
    code: "sb",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Somalia",
    localName: "",
    code: "so",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
    postalExample: "JH 09010",
  },
  {
    enName: "South Africa",
    localName: "",
    code: "za",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 6001,
  },
  {
    enName: "South Georgia and the South Sandwich Islands",
    localName: "",
    code: "gs",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(SIQQ 1ZZ)",
    postalExample: "SIQQ 1ZZ",
  },
  {
    enName: "Spain",
    localName: "España",
    code: "es",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 28006,
  },
  {
    enName: "Sri Lanka",
    localName: "",
    code: "lk",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 80212,
  },
  {
    enName: "Sudan",
    localName: "",
    code: "sd",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: 13315,
  },
  {
    enName: "Suriname",
    localName: "",
    code: "sr",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Svalbard and Jan Mayen",
    localName: "",
    code: "sj",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Swaziland",
    localName: "",
    code: "sz",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "([A-Z]\\d{3})",
    postalExample: "M201",
  },
  {
    enName: "Sweden",
    localName: "Sverige",
    code: "se",
    inEU: true,
    active: true,
    region: "europe",
    postalRegex: "[1-9]\\d{2} \\d{2}",
    postalExample: "113 51",
  },
  {
    enName: "Switzerland",
    localName: "",
    code: "ch",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "[1-9]\\d{3}",
    postalExample: 8050,
  },
  {
    enName: "Syria",
    localName: "",
    code: "sy",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Taiwan",
    localName: "",
    code: "tw",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "(\\d{3}\\-\\d{3})|(\\d{3}[-]\\d{2})|(\\d{6})|(\\d{3})",
    postalExample: "237-01, 407, 999999, 999(-)999",
  },
  {
    enName: "Tajikistan",
    localName: "",
    code: "tj",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "7\\d{5}",
    postalExample: 799999,
  },
  {
    enName: "Tanzania, United Republic of",
    localName: "",
    code: "tz",
    inEU: false,
    active: false,
    region: "pacific",
    postalRegex: "\\d{5}",
    postalExample: 31324,
  },
  {
    enName: "Thailand",
    localName: "",
    code: "th",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{5}",
    postalExample: 10240,
  },
  {
    enName: "Timor-Leste",
    localName: "",
    code: "tl",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Togo",
    localName: "",
    code: "tg",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Tokelau",
    localName: "",
    code: "tk",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Tonga",
    localName: "",
    code: "to",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Trinidad and Tobago",
    localName: "",
    code: "tt",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{6}",
    postalExample: 120110,
  },
  {
    enName: "Tunisia",
    localName: "",
    code: "tn",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{4}",
    postalExample: 3200,
  },
  {
    enName: "Turkey",
    localName: "",
    code: "tr",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: "\\d{5}",
    postalExample: 34000,
  },
  {
    enName: "Turkmenistan",
    localName: "",
    code: "tm",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "7\\d{5}",
    postalExample: "745180",
  },
  {
    enName: "Turks and Caicos Islands",
    localName: "",
    code: "tc",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(TKCA 1ZZ)",
    postalExample: "TKCA 1ZZ",
  },
  {
    enName: "Tuvalu",
    localName: "",
    code: "tv",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Uganda",
    localName: "",
    code: "ug",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Ukraine",
    localName: "",
    code: "ua",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "\\d{5}",
    postalExample: 65000,
  },
  {
    enName: "United Arab Emirates",
    localName: "",
    code: "ae",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: ".{1,255}",
  },
  {
    enName: "United Kingdom",
    localName: "",
    code: "gb",
    inEU: false,
    active: false,
    region: "europe",
    postalRegex: "([G][I][R] 0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})",
    postalExample: "DT3 6GB, L2 2DP",
  },
  {
    enName: "United States",
    localName: "",
    code: "us",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "\\d{5}(-\\d{4})?",
    postalExample: "11550, 11550-9999, 00716, 00716-9999",
  },
  {
    enName: "United States Minor Outlying Islands",
    localName: "",
    code: "um",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Uruguay",
    localName: "",
    code: "uy",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[1-9]\\d{4}",
    postalExample: "11700",
  },
  {
    enName: "Uzbekistan",
    localName: "",
    code: "uz",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: 702100,
  },
  {
    enName: "Vanuatu",
    localName: "",
    code: "vu",
    inEU: false,
    active: false,
    region: "",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Venezuela",
    localName: "",
    code: "ve",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "[1-8]\\d{3}",
    postalExample: "1061",
  },
  {
    enName: "Vietnam",
    localName: "",
    code: "vn",
    inEU: false,
    active: false,
    region: "asia",
    postalRegex: "\\d{6}",
    postalExample: 112132,
  },
  {
    enName: "Virgin Islands, British",
    localName: "",
    code: "vg",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "(VG11)[0-6][0]",
    postalExample: "VG1120",
  },
  {
    enName: "Virgin Islands, U.S.",
    localName: "",
    code: "vi",
    inEU: false,
    active: false,
    region: "americas",
    postalRegex: "008\\d{2}(-\\d{4})?",
    postalExample: "00850, 00850-9999",
  },
  {
    enName: "Wallis and Futuna",
    localName: "",
    code: "wf",
    inEU: false,
    active: false,
    region: "",
    postalRegex: "(986)\\d{2}",
    postalExample: "98600",
  },
  {
    enName: "Western Sahara",
    localName: "",
    code: "eh",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: null,
    postalExample: "",
  },
  {
    enName: "Yemen",
    localName: "",
    code: "ye",
    inEU: false,
    active: false,
    region: "mideast",
    postalRegex: ".{1,255}",
  },
  {
    enName: "Zambia",
    localName: "",
    code: "zm",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: "\\d{5}",
    postalExample: 50100,
  },
  {
    enName: "Zimbabwe",
    localName: "",
    code: "zw",
    inEU: false,
    active: false,
    region: "africa",
    postalRegex: ".{1,255}",
  },
];
