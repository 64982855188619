import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { isValidFqdn, getDnsDomainIdFromDomainName, takeoutTrailingDotInHostname, parseIntTtl } from "../lib/dnsFunctions";

import { createDnsRecord, updateDnsRecord } from "../../../../actions/dns";

import { translate } from "../../../../translations/translations";

const RecordTXT = ({ auth: { activeWorkspace }, formDisabled, existingData, createDnsRecord, updateDnsRecord }) => {
  let { domainName } = useParams();
  let navigate = useNavigate();

  const [hostname, setHostname] = useState(existingData ? existingData.recordFields.hostname : "");
  const [errorMsgHostname, setErrorMsgHostname] = useState("");
  const [txtString, setTxtString] = useState(existingData ? existingData.recordFields.txtString : "");
  const [errorMsgTxtString, setErrorMsgTxtString] = useState("");
  const [TTL, setTTL] = useState(existingData ? existingData.recordFields.TTL : "3600");
  const [errorMsgTTL, setErrorMsgTTL] = useState("");

  const RECORD_TYPE = "TXT";

  const clickCreateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      createDnsRecord(activeWorkspace._id, domainId, RECORD_TYPE, { hostname: hostnameToUse, txtString, TTL: parseIntTtl(TTL) });
      resetStateVars();
    }
  };

  const clickUpdateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      updateDnsRecord(activeWorkspace._id, domainId, existingData._id, RECORD_TYPE, {
        hostname: hostnameToUse,
        txtString,
        TTL: parseIntTtl(TTL),
      });
      resetStateVars();
      navigate(`/dns/${domainName}`);
    }
  };

  const checkErrors = (hostnameToUse) => {
    resetErrorMessages();
    let errors = 0;
    if (hostnameToUse === "" || !isValidFqdn(hostnameToUse)) {
      setErrorMsgHostname(translate("cCreateDnsRecords.enterHostname", false, null));
      errors++;
    }
    if (txtString === "") {
      setErrorMsgTxtString(translate("cCreateDnsRecords.enterTxtString", false, null));
      errors++;
    }
    if (TTL === "") {
      setErrorMsgTTL(translate("cCreateDnsRecords.enterTtl", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setHostname("");
    setTxtString("");
    setTTL("3600");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrorMsgHostname("");
    setErrorMsgTxtString("");
    setErrorMsgTTL("");
  };

  return (
    <>
      <p>{translate("cCreateDnsRecords.txtRecordExplanation", false, null)}</p>
      <div className="row">
        {/* Hostname */}
        <div className="col-12 col-md-4 py-1">
          <label className="fontSize08" htmlFor="TXT_hostname">
            {translate("cCreateDnsRecords.hostname", false, null)}
          </label>
          <div className="input-group input-group-sm">
            <input
              type="text"
              className={`form-control form-control-sm${errorMsgHostname === "" ? "" : " is-invalid"}`}
              id="TXT_hostname"
              placeholder={translate("cCreateDnsRecords.hostname", false, null)}
              value={hostname}
              onChange={(e) => setHostname(e.target.value)}
              disabled={formDisabled}
            />
            <span className="input-group-text">.{domainName}</span>
            <div className="invalid-feedback">{errorMsgHostname}</div>
          </div>
        </div>
        {/* Alias of */}
        <div className="col-12 col-md-3 py-1">
          <label className="fontSize08" htmlFor="TXT_TxtString">
            {translate("cCreateDnsRecords.txtString", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgTxtString === "" ? "" : " is-invalid"}`}
            id="TXT_TxtString"
            placeholder={translate("cCreateDnsRecords.txtString", false, null)}
            value={txtString}
            onChange={(e) => setTxtString(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgTxtString}</div>
        </div>
        {/* TTL */}
        <div className="col-12 col-md-3 py-1">
          <label className="fontSize08" htmlFor="TXT_TTL">
            {translate("cCreateDnsRecords.ttlSeconds", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgTTL === "" ? "" : " is-invalid"}`}
            id="TXT_TTL"
            placeholder="TTL"
            value={TTL}
            onChange={(e) => setTTL(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgTTL}</div>
        </div>
      </div>

      <div className="row mx-0 mt-2">
        {typeof existingData === "undefined" ? (
          <div className="col-12 col-md-6 col-xl-4 px-0 py-2">
            <button className="btn btn-sm btn-success w-100" onClick={clickCreateRecord} disabled={formDisabled}>
              {translate("cCreateDnsRecords.createRecord", false, null)}
            </button>
          </div>
        ) : (
          <>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-gray w-100" onClick={() => navigate(-1)} disabled={formDisabled}>
                {translate("cCreateDnsRecords.cancelUpdateRecord", false, null)}
              </button>
            </div>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-success w-100" onClick={clickUpdateRecord} disabled={formDisabled}>
                {translate("cCreateDnsRecords.updateRecord", false, null)}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

RecordTXT.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object,
  formDisabled: PropTypes.bool.isRequired,
  existingData: PropTypes.object,
  createDnsRecord: PropTypes.func.isRequired,
  updateDnsRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createDnsRecord, updateDnsRecord })(RecordTXT);
