export const aSitebuilder = {
  errorOccured: {
    en: "An error occured. Please try again.",
    nl: "Er is een fout opgetreden. Probeer het aub opnieuw.",
  },
  retrievingProjects: {
    en: "Retrieving sitebuilder projects..",
    nl: "Ophalen sitebuilder projecten..",
  },
  creatingProject: {
    en: "Creating sitebuilder project..",
    nl: "Aanmaken sitebuilder project..",
  },
  projectCreated: {
    en: "Sitebuilder project has been created!",
    nl: "Sitebuilder project aangemaakt!",
  },
  updatingProjectName: {
    en: "Updating project name..",
    nl: "Bijwerken project naam..",
  },
  updatedProjectName: {
    en: "Project name updated!",
    nl: "Project naam bijgewerkt!",
  },
  deletingProject: {
    en: "Deleting project..",
    nl: "Verwijderen project..",
  },
  projectDeleted: {
    en: "Project deleted!",
    nl: "Project verwijderd!",
  },
  duplicatingProject: {
    en: "Duplicating project..",
    nl: "Dupliceren project..",
  },
  projectDuplicated: {
    en: "Project duplicated!",
    nl: "Project gedupliceerd!",
  },
  retractingSite: {
    en: "Retracting site..",
    nl: "Site intrekken..",
  },
  siteRetracted: {
    en: "Site retracted!",
    nl: "Site ingetrokken!",
  },
};
