import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { readNotificationsUser } from "../../actions/auth";

import Notifications from "../../components/Notifications";

import { translate } from "../../translations/translations";

const UserNotifications = ({ auth: { user }, readNotificationsUser }) => {
  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    if (user === null && typeof user.notifications !== "undefined") {
      setAllNotifications([]);
    } else {
      setAllNotifications(user.notifications.sort(sortByDate));
    }
    // eslint-disable-next-line
  }, [user]);

  const sortByDate = (a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <h1 className="text-primary">{translate("pUserPages.yourNotifications", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : (
        <Notifications allNotifications={allNotifications} readNotifications={readNotificationsUser} />
      )}
    </>
  );
};

UserNotifications.propTypes = {
  auth: PropTypes.object.isRequired,
  readNotificationsUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { readNotificationsUser })(UserNotifications);
