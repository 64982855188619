import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// import { doughnutlabelplugin } from "../../../../dashboard/ChartJsDougnutLabel";

import { translate } from "../../../../../translations/translations";

ChartJS.register(ArcElement, Tooltip, Legend);

const MsgStats = ({ heading, arrData, height }) => {
  const labels = [
    translate("cMsgStats.notDelivered", false, null),
    translate("cMsgStats.notOpened", false, null),
    translate("cMsgStats.notInteracted", false, null),
    translate("cMsgStats.interacted", false, null),
    translate("cMsgStats.unsubscribed", false, null),
  ];
  const extendedLabels = [
    translate("cMsgStats.notDeliveredExt", false, null),
    translate("cMsgStats.notOpenedExt", false, null),
    translate("cMsgStats.notInteractedExt", false, null),
    translate("cMsgStats.interactedExt", false, null),
    translate("cMsgStats.unsubscribedExt", false, null),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: heading,
        data: arrData,
        backgroundColor: [
          "rgba(0, 0, 0, 0.2)",
          "rgba(206, 212, 218, 0.2)",
          "rgba(40, 167, 69, 0.2)",
          "rgba(40, 167, 69, 0.4)",
          "rgba(229, 42, 5, 0.2)",
        ],
        borderColor: ["rgba(0, 0, 0, 1)", "rgba(206, 212, 218, 1)", "rgba(40, 167, 69, 1)", "rgba(40, 167, 69, 1)", "rgba(229, 42, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || "";
            return `${extendedLabels[labels.indexOf(label)]}: ${context.formattedValue} (${new Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(context.raw / context.dataset.data.reduce((val, add) => val + add, 0))})`;
          },
        },
      },
    },
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height }}>
        <Doughnut
          data={data}
          options={options}
          // plugins={[doughnutlabelplugin]}
        />
      </div>
    </>
  );
};

export default MsgStats;
