import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormatNumber from "../../layout/FormatNumber";

import { isValidDomain, getDomainProductPackage, getTldFromDomain } from "./lib/domainsFunctions";
import { addToCart } from "../../../actions/cart";

import { translate } from "../../../translations/translations";
import { getCurrency } from "../../../lib/Currencies";
import { userIsWsAdmin } from "../../workspace/lib/wsFunctions";

const Transfer = ({ auth: { user, activeWorkspace }, cart: { sessionId, cartItems }, brandProducts: { products, TLDs }, addToCart }) => {
  const [domainInput, setDomainInput] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [priceTldTransfer, setPriceTldTransfer] = useState(0);

  const clickAddToCart = () => {
    // Set up productPackage
    let productPackage = getDomainProductPackage(activeWorkspace, products, TLDs, { domain: domainInput, price: priceTldTransfer }, "Transfer");
    // Add to cart
    addToCart(sessionId, productPackage);
    clearField();
  };

  const clearField = () => {
    setDomainInput("");
    setPriceTldTransfer(0);
    setBtnDisabled(true);
  };

  const onType = (text) => {
    setDomainInput(text);
    if (isValidDomain(text)) {
      let tld = getTldFromDomain(text);
      let tldObj = TLDs.filter((domain) => domain.tld === tld)[0];
      if (typeof tldObj === "undefined") {
        setPriceTldTransfer(0);
        setBtnDisabled(true);
      } else {
        // TODO:
        // setPriceTldTransfer(getTldPrices(tldObj, getCurrency(activeWorkspace.location.priceCountry).currencyCode, fxRates).transfer);
        setBtnDisabled(false);
      }
    } else {
      setBtnDisabled(true);
      setPriceTldTransfer(0);
    }
  };

  let curr = activeWorkspace === null ? "" : getCurrency(activeWorkspace.location.priceCountry).currencyCode;

  return (
    <div className="row mt-4">
      <div className="col-12 col-sm-9 col-md-8 col-lg-6">
        <div className="posRelative">
          <span className="posAbs d-flex align-items-center h-100 z-9 ms-2" style={{ width: "40px" }}>
            www.
          </span>
          <div className="input-group trans-3">
            <input
              type="text"
              className="form-control"
              id="domainCheckerInputBox"
              // width of "www." = 40px + padding
              style={{ paddingLeft: "50px" }}
              placeholder={translate("cDomainChecker.exampleCom", false, null)}
              aria-label={translate("cDomainChecker.domainInputField", false, null)}
              value={domainInput}
              onChange={(e) => onType(e.target.value)}
            />
            <div className="input-group-append posRelative">
              <span
                className={`posAbs d-flex align-items-center h-100 z-9 text-danger ${
                  domainInput === "" ? "opacity-0" : "opacity-1 cursorPointer"
                }  trans-3`}
                title={domainInput === "" ? undefined : translate("cDomainChecker.clear", false, null)}
                onClick={() => clearField()}
                style={{ left: "-20px" }}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
              <button
                className="btn btn-outline-primary trans-3"
                type="button"
                disabled={btnDisabled || !userIsWsAdmin(activeWorkspace, user)}
                onClick={clickAddToCart}
              >
                {translate("cTransfer.transferDomain", false, null)}{" "}
                {priceTldTransfer > 0 && (
                  <>
                    {translate("cTransfer.for", false, null)} {curr} <FormatNumber number={priceTldTransfer} formatStr={"0.00"} />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Transfer.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, { addToCart })(Transfer);
