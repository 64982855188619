import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { logout } from "../../actions/auth";
import { setLangCode } from "../../actions/auth";

import PulseCheck from "../user/PulseCheck";

import { LANGUAGES, translate } from "../../translations/translations";

const Navbar = ({ auth: { user, langCode }, cart: { cartItems }, logout, setLangCode }) => {
  let [newNotifications, setNewNotifications] = useState(0);

  useEffect(() => {
    setNewNotifications(user === null ? 0 : user.notifications.filter((n) => !n.read).length);
  }, [user]);

  return (
    <>
      <PulseCheck />
      <div className="d-flex align-items-center bg-light shadow-medium noHover px-3 py-4" style={{ zIndex: "101" }}>
        <Link to="/">
          <img src="/assets/img/satonda_gradient.png" alt="Satonda" style={{ width: "140px" }} />
        </Link>
        <div className="ms-auto d-flex align-items-center">
          {/* Cart */}
          <Link to="/cart" className="navbar-icon me-3" title={translate("cNavigation.seeCart", false, null)}>
            <i className="fa-solid fa-cart-shopping"></i>
            {cartItems.length > 0 && (
              <span className="navbarNumBadge">
                <span>{cartItems.length}</span>
              </span>
            )}
          </Link>

          {/* Notifications */}
          <Link
            to="/user/notifications"
            className="navbar-icon me-3"
            title={
              newNotifications === 0
                ? translate("cNavigation.seeNotifications", false, null)
                : translate("cNavigation.youHaveNewNotifcations", false, { newNotifications })
            }
          >
            <i className="fa-regular fa-bell"></i>
            {newNotifications > 0 && (
              <span className="navbarNumBadge">
                <span>{newNotifications}</span>
              </span>
            )}
          </Link>

          {/* User */}
          <div className="nav-item dropdown navbar-icon me-3">
            <i className="fa-regular fa-circle-user" id="navbarDropdown_user" data-bs-toggle="dropdown" aria-expanded="false"></i>
            <ul className="dropdown-menu p-0" aria-labelledby="navbarDropdown_user">
              <p className="p-2 m-0">
                {translate("cNavigation.welcome", false, null)} {user && user.firstName}
              </p>
              <li>
                <Link to="/user/profile" className="dropdown-item p-2" title={translate("cNavigation.userProfile", false, null)}>
                  {translate("cNavigation.yourProfile", false, null)}
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider m-0" />
              </li>
              <li>
                <span className="dropdown-item p-2" onClick={logout}>
                  {translate("cNavigation.logout", false, null)}
                </span>
              </li>
            </ul>
          </div>

          {/* Language */}
          <div className="nav-item dropdown navbar-icon me-3">
            <div
              id="navbarDropdown_language"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title={translate("cNavigation.selectLanguage", false, null)}
            >
              <img src={`/assets/img/flags/${langCode}.png`} alt={LANGUAGES[langCode]} />
            </div>
            <ul className="dropdown-menu p-0" aria-labelledby="navbarDropdown_language">
              <p className="p-2 m-0">{translate("cNavigation.selectLanguage", false, null)}:</p>
              {Object.keys(LANGUAGES).map((lang) => (
                <li key={`lang_${lang}`}>
                  <div className="dropdown-item p-2" onClick={() => setLangCode(lang)}>
                    <img src={`/assets/img/flags/${lang}.png`} alt={LANGUAGES[lang]} />
                    <span className="ms-2">{LANGUAGES[lang]}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  setLangCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, { logout, setLangCode })(Navbar);
