import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../../components/layout/Spinner";

import {
  userIsWsAdmin,
  userIsWsOwner,
  WS_ROLE_OWNER,
  WS_ROLE_ADMIN,
  WS_ROLE_MEMBER,
  WS_MEMBER_TYPE_ACTIVATED,
  WS_MEMBER_TYPE_PENDING,
} from "../../components/workspace/lib/wsFunctions";
import { capitalizeFirstLetter } from "../../lib/formattingFunctions";

import { setAlert } from "../../actions/alert";
import { changeUserRole, addNewUsersToWorkspace, removeUserFromWorkspace } from "../../actions/auth";
import { isValidEmailAddress } from "../../components/products/hosting/lib/emailFunctions";

import { translate } from "../../translations/translations";

const Members = ({ auth: { user, activeWorkspace }, setAlert, changeUserRole, addNewUsersToWorkspace, removeUserFromWorkspace }) => {
  // const [userIsAdmin, setUserIsAdmin] = useState(userIsWsAdmin(activeWorkspace, user));
  const NEW_USER_OBJ = { email: "", role: WS_ROLE_MEMBER };
  const [newUsers, setNewUsers] = useState([NEW_USER_OBJ]);
  const [newUsersErrMsgs, setNewUsersErrMsgs] = useState([""]);
  const [loadingAddNewMember, setLoadingAddNewMember] = useState({ isLoading: false, msg: "" });
  const userIsAdmin = userIsWsAdmin(activeWorkspace, user);
  const userIsOwner = userIsWsOwner(activeWorkspace, user);

  const WsMemberRow = ({ wsMember, type }) => {
    const [loadingMemberRow, setLoadingMemberRow] = useState({ isLoading: false, msg: "" });

    const clickChangeUserRole = async (newRole) => {
      if (type === WS_MEMBER_TYPE_ACTIVATED && wsMember.role === WS_ROLE_OWNER && newRole !== WS_ROLE_OWNER) {
        setAlert(translate("pWsMembers.needsAtleastOneOwner", false, null), "danger");
      } else if (type === WS_MEMBER_TYPE_PENDING && wsMember.role === WS_ROLE_OWNER) {
        setAlert(translate("pWsMembers.cantSetPendingUserAsOwner", false, null), "danger");
      } else {
        setLoadingMemberRow({ isLoading: true, msg: translate("pWsMembers.updatingUserRole", false, null) });
        let userId = type === WS_MEMBER_TYPE_ACTIVATED ? wsMember.userId : "na";
        let email = type === WS_MEMBER_TYPE_PENDING ? wsMember.email : "na";
        await changeUserRole(userId, newRole, type, email);
        setLoadingMemberRow({ isLoading: false, msg: "" });
      }
    };

    const clickRemoveUser = async () => {
      if (type === WS_MEMBER_TYPE_ACTIVATED && activeWorkspace.members.length === 1) {
        setAlert(translate("pWsMembers.needsAtleastOneMember", false, null), "danger");
      } else {
        setLoadingMemberRow({ isLoading: true, msg: translate("pWsMembers.removingUSer", false, null) });
        let userId = type === WS_MEMBER_TYPE_ACTIVATED ? wsMember.userId : "na";
        let email = type === WS_MEMBER_TYPE_PENDING ? wsMember.email : "na";
        await removeUserFromWorkspace(userId, type, email);
        setLoadingMemberRow({ isLoading: false, msg: "" });
      }
    };

    return loadingMemberRow.isLoading ? (
      <tr>
        <td colSpan="5">
          <Spinner msg={loadingMemberRow.msg} direction={"hor"} />
        </td>
      </tr>
    ) : (
      <tr>
        {type === WS_MEMBER_TYPE_ACTIVATED ? (
          <>
            <td>{capitalizeFirstLetter(wsMember.firstName)}</td>
            <td>{capitalizeFirstLetter(wsMember.lastName)}</td>
          </>
        ) : (
          <td colSpan="2">
            <span className="text-italic">{translate("pWsMembers.pendingUserReg", false, null)}</span>
          </td>
        )}
        <td>{wsMember.email}</td>
        {userIsAdmin ? (
          <td>
            <select className={`form-select form-select-sm`} value={wsMember.role} onChange={(e) => clickChangeUserRole(e.target.value)}>
              {userIsOwner && <option value={WS_ROLE_OWNER}>{translate("pWsMembers.owner", false, null)}</option>}
              <option value={WS_ROLE_ADMIN}>{translate("pWsMembers.admin", false, null)}</option>
              <option value={WS_ROLE_MEMBER}>{translate("pWsMembers.member", false, null)}</option>
            </select>
          </td>
        ) : (
          <td>{capitalizeFirstLetter(wsMember.role)}</td>
        )}
        <td>
          <span
            className="text-primary cursorPointer trans-3"
            title={
              type === WS_MEMBER_TYPE_ACTIVATED
                ? translate("pWsMembers.removeUserFromWs", false, null)
                : translate("pWsMembers.retractInvite", false, null)
            }
            onClick={() => clickRemoveUser()}
          >
            <i className="fa-solid fa-trash-can"></i>
          </span>
        </td>
      </tr>
    );
  };

  const updateNewUserValue = (i, field, val) => {
    setNewUsers((prev) => prev.map((newUser, j) => (i === j ? { ...newUser, [field]: val } : newUser)));
  };

  const addNewUserRow = () => {
    setNewUsers((prev) => [...prev, NEW_USER_OBJ]);
    setNewUsersErrMsgs((prev) => [...prev, ""]);
  };

  const removeNewUserRow = (i) => {
    if (newUsers.length === 1) {
      setNewUsers([NEW_USER_OBJ]);
      setNewUsersErrMsgs([""]);
    } else {
      setNewUsers((prev) => prev.filter((row, j) => i !== j));
      setNewUsersErrMsgs((prev) => prev.filter((row, j) => i !== j));
    }
  };

  const clickCreateUsers = async () => {
    if (checkErrors() === 0) {
      setLoadingAddNewMember({ isLoading: true, msg: translate("pWsMembers.addingUsersToWs", false, null) });
      await addNewUsersToWorkspace(newUsers);
      setLoadingAddNewMember({ isLoading: false, msg: "" });
      resetStateVars();
    }
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    let errMsgs = [];
    newUsers.forEach((newUserRow) => {
      let errMsg = "";
      if (!isValidEmailAddress(newUserRow.email)) {
        errMsg = translate("pWsMembers.provideValidEmail", false, null);
        errors++;
      }
      errMsgs.push(errMsg);
    });
    setNewUsersErrMsgs(errMsgs);
    return errors;
  };

  const resetStateVars = () => {
    setNewUsers([NEW_USER_OBJ]);
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setNewUsersErrMsgs([""]);
  };

  return (
    <>
      <h1 className="text-primary">{translate("pWsMembers.wsMembers", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          {/* Current members */}
          <h2 className="text-secondary mt-5">
            {translate("pWsMembers.membersOf", false, null)} <span className="text-italic">{activeWorkspace.name}</span>
          </h2>
          {activeWorkspace.members.length === 0 ? (
            <p className="text-italic m-0">{translate("pWsMembers.wsHasNoMembers", false, null)}</p>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover rounded-3 overflowHidden align-middle mt-3">
                <thead>
                  <tr className="bg-primary text-white border-none">
                    <th scope="col">{translate("pWsMembers.firstName", false, null)}</th>
                    <th scope="col">{translate("pWsMembers.lastName", false, null)}</th>
                    <th scope="col">{translate("pWsMembers.email", false, null)}</th>
                    <th scope="col">{translate("pWsMembers.role", false, null)}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {activeWorkspace.members.map((wsMember) => (
                    <WsMemberRow key={`ws_${activeWorkspace._id}_${wsMember._id}`} wsMember={wsMember} type={WS_MEMBER_TYPE_ACTIVATED} />
                  ))}
                  {activeWorkspace.pendingInvites.map((wsMember) => (
                    <WsMemberRow key={`ws_${activeWorkspace._id}_${wsMember._id}`} wsMember={wsMember} type={WS_MEMBER_TYPE_PENDING} />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {/* Add new member (if admin rights) */}
          {userIsAdmin && (
            <>
              <h2 className="text-secondary mt-5">{translate("pWsMembers.addNewMembers", false, null)}</h2>
              {loadingAddNewMember.isLoading ? (
                <div className="mt-5">
                  <Spinner msg={loadingAddNewMember.msg} />
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 col-lg-9 col-xl-7">
                    {/* Form */}
                    {newUsers.map((newUserData, i) => (
                      <div key={`ws_addUser_${i}`} className="row my-2 mx-n2">
                        <div className="col-7 p-1">
                          <input
                            type="email"
                            className={`form-control form-control-sm ${newUsersErrMsgs[i] === "" ? "" : "is-invalid"}`}
                            placeholder={translate("pWsMembers.userEmail", false, null)}
                            value={newUserData.email}
                            onChange={(e) => updateNewUserValue(i, "email", e.target.value)}
                          />
                          <div className="invalid-feedback">{newUsersErrMsgs[i]}</div>
                        </div>
                        <div className="col-4 p-1">
                          <select
                            className={`form-select form-select-sm`}
                            value={newUserData.role}
                            onChange={(e) => updateNewUserValue(i, "role", e.target.value)}
                          >
                            <option value={WS_ROLE_ADMIN}>{translate("pWsMembers.admin", false, null)}</option>
                            <option value={WS_ROLE_MEMBER}>{translate("pWsMembers.member", false, null)}</option>
                          </select>
                        </div>
                        <div className="col-1 p-1 d-flex flex-column justify-content-center">
                          <span
                            className="text-danger cursorPointer"
                            title={translate("pWsMembers.removeRow", false, null)}
                            onClick={() => removeNewUserRow(i)}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </div>
                      </div>
                    ))}
                    {/* Buttons */}
                    <div className="row mt-3 mx-n2">
                      <div className="col-11 py-2 px-1">
                        <button className="btn btn-sm btn-midgray w-100" onClick={addNewUserRow}>
                          {translate("pWsMembers.addAddnRow", false, null)}
                        </button>
                      </div>
                      <div className="col-11 py-2 px-1">
                        <button className="btn btn-sm btn-success w-100" onClick={clickCreateUsers}>
                          {translate("pWsMembers.addUserToWs", false, null)}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Members.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  removeUserFromWorkspace: PropTypes.func.isRequired,
  changeUserRole: PropTypes.func.isRequired,
  addNewUsersToWorkspace: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, changeUserRole, addNewUsersToWorkspace, removeUserFromWorkspace })(Members);
