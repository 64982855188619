import React from "react";

import BoxDesc from "./BoxDesc";
import BoxAttachments from "./BoxAttachments";

import { translate } from "../../translations/translations";

const TicketDesc = ({ ticket }) => {
  return (
    <>
      <p className="mb-2 text-bold">{translate("cSupportComponents.description", false, null)}:</p>
      <BoxDesc desc={ticket.description} descId={ticket._id} />
      {ticket.attachments.length > 0 && (
        <>
          <p className="mt-3 mb-2 text-bold">{translate("cSupportComponents.attachments", false, null)}:</p>
          <BoxAttachments attachments={ticket.attachments} ticketId={ticket._id} />
        </>
      )}
    </>
  );
};

export default TicketDesc;
