import axios from "axios";

import { GET_AUDIENCES } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";

import { translate } from "../translations/translations";

export const getWsAudiences = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAudiences.retrievingAudiences", false, null)));
  try {
    const res = await axios.get(`${API_URL}/audiences/all`);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const createNewAudience = (audienceName, audienceData) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAudiences.creatingAudience", false, null)));
  try {
    const body = JSON.stringify({ audienceName, audienceData });
    const res = await axios.post(`${API_URL}/audiences/createFromFile`, body, POST_CONFIG);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAudiences.audienceCreated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAudiences.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const editAudienceName = (audienceId, audienceName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAudiences.updatingName", false, null)));
  try {
    const body = JSON.stringify({ audienceId, audienceName });
    const res = await axios.put(`${API_URL}/audiences/edit/name`, body, POST_CONFIG);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAudiences.nameUpdated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAudiences.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const loadAudienceData = (audienceId, audienceData) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAudiences.loadingData", false, null)));
  try {
    const body = JSON.stringify({ audienceId, audienceData });
    const res = await axios.put(`${API_URL}/audiences/loadData`, body, POST_CONFIG);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAudiences.dataLoaded", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAudiences.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const deleteAudience = (audienceId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aAudiences.deletingAudience", false, null)));
  try {
    const res = await axios.delete(`${API_URL}/audiences/delete/${audienceId}`);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAudiences.audienceDeleted", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAudiences.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const removeAudienceEntry = (audienceId, entryId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/audiences/removeEntry/${audienceId}/${entryId}`);
    dispatch({
      type: GET_AUDIENCES,
      payload: res.data,
    });
    dispatch(setAlert(translate("aAudiences.entryRemoved", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aAudiences.errorOccured", false, null), "danger"));
  }
};
