import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProductTable from "./ProductTable";
import SelectPeriod from "./SelectPeriod";

import { translate } from "../../../translations/translations";
import { PERIOD_ANNUALLY, PRODUCT_NAMES } from "../lib/ProductVars";
import { getProductTableData } from "../lib/productTableFunctions";
import { formatNumber } from "../../../lib/formattingFunctions";

const ProductTableEm = ({ auth: { activeWorkspace, fxRates }, brandProducts: { brandData, brandOffers, products } }) => {
  const [period, setPeriod] = useState(PERIOD_ANNUALLY);
  const [productPackages, setProductPackages] = useState([]);
  const [surchargeMonthlyPayment, setSurchargeMonthlyPayment] = useState(0);

  useEffect(() => {
    if (activeWorkspace !== null && brandData !== null && brandOffers.length > 0 && products.length > 0) {
      const { productPackages, surchargeMonthlyPayment } = getProductTableData(
        activeWorkspace,
        products,
        brandData,
        brandOffers,
        PRODUCT_NAMES.emailMarketing,
        fxRates
      );
      setProductPackages(productPackages);
      setSurchargeMonthlyPayment(surchargeMonthlyPayment);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, brandData, brandOffers, products]);

  //   Product data
  const numTemplates = "15+";
  const numProjects = {
    starter: 5,
    advanced: 25,
    pro: translate("cProductTable.unlimited", false, null),
    expert: translate("cProductTable.unlimited", false, null),
  };
  const numEmails = { starter: 250, advanced: 1000, pro: 4000, expert: 10000 };

  const SPECS_STARTER = [
    translate("cProductTable.em_easyToUseEditor", false, null),
    translate("cProductTable.em_looksBeautiful", false, null),
    translate("cProductTable.em_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.em_numProjects", false, { numProjects: numProjects.starter }),
    translate("cProductTable.em_numEmails", false, { numEmails: formatNumber(numEmails.starter, "0") }),
  ];
  const SPECS_ADVANCED = [
    translate("cProductTable.em_easyToUseEditor", false, null),
    translate("cProductTable.em_looksBeautiful", false, null),
    translate("cProductTable.em_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.em_numProjects", false, { numProjects: numProjects.advanced }),
    translate("cProductTable.em_numEmails", false, { numEmails: formatNumber(numEmails.advanced, "0") }),
  ];
  const SPECS_PRO = [
    translate("cProductTable.em_easyToUseEditor", false, null),
    translate("cProductTable.em_looksBeautiful", false, null),
    translate("cProductTable.em_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.em_numProjects", false, { numProjects: numProjects.pro }),
    translate("cProductTable.em_numEmails", false, { numEmails: formatNumber(numEmails.pro, "0") }),
  ];
  const SPECS_EXPERT = [
    translate("cProductTable.em_easyToUseEditor", false, null),
    translate("cProductTable.em_looksBeautiful", false, null),
    translate("cProductTable.em_accessToTemplates", false, { numTemplates }),
    translate("cProductTable.em_numProjects", false, { numProjects: numProjects.expert }),
    translate("cProductTable.em_numEmails", false, { numEmails: formatNumber(numEmails.expert, "0") }),
  ];

  return productPackages.length > 0 ? (
    <>
      <SelectPeriod period={period} setPeriod={setPeriod} surchargeMonthlyPayment={surchargeMonthlyPayment} />
      <div className="row mt-3">
        <ProductTable period={period} col={3} boolHighlight={false} productData={productPackages[0]} specs={SPECS_STARTER} />
        <ProductTable period={period} col={3} boolHighlight={false} productData={productPackages[1]} specs={SPECS_ADVANCED} />
        <ProductTable period={period} col={3} boolHighlight={false} productData={productPackages[2]} specs={SPECS_PRO} />
        <ProductTable period={period} col={3} boolHighlight={false} productData={productPackages[3]} specs={SPECS_EXPERT} />
      </div>
    </>
  ) : (
    <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
  );
};

ProductTableEm.propTypes = {
  auth: PropTypes.object.isRequired,
  brandProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  brandProducts: state.brandProducts,
});

export default connect(mapStateToProps, null)(ProductTableEm);
