import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { canRefund } from "./lib/subscriptionFunctions";
import { PRODUCT_NAMES } from "../../products/lib/ProductVars";
import { formatNumber } from "../../../lib/formattingFunctions";

import FormatNumber from "../../layout/FormatNumber";
import FormatDate from "../../layout/FormatDate";

import { setSubToCancel, returnSub } from "../../../actions/auth";
import { translate } from "../../../translations/translations";

const SubRow = ({ auth: { user, activeWorkspace }, sub, setSubToCancel, returnSub }) => {
  const SubProductPackage = () => {
    return (
      <>
        {sub.productName} - {sub.packageName}
      </>
    );
  };

  const SubUsageMetrics = () => {
    const getQuotaName = (quotaName) => {
      return translate(`cWsSubs.${quotaName}`, false, null);
    };

    const getQuotaValue = (quota) => {
      if (quota.name === "storage") {
        return `${formatNumber(quota.value / 1000, "0")}GB`;
      }
      if (["wpToolkit", "emAbTesting"].includes(quota.name)) {
        return quota.value === "1" ? `${translate("cWsSubs.included", false, null)}` : `${translate("cWsSubs.excluded", false, null)}`;
      }
      if (["traffic", "domains", "mailboxes"].includes(quota.name)) {
        return quota.value === "0" ? `${translate("cWsSubs.unlimited", false, null)}` : `${formatNumber(quota.value, "0")}`;
      }
      // Rest: only show the number
      return `${formatNumber(quota.value, "0")}`;
    };

    return sub.packageDetails === null || sub.packageDetails.length === 0 ? (
      <p className="text-italic m-0">{translate("cWsSubs.noQuotas", false, null)}</p>
    ) : (
      sub.packageDetails.map((detail, i) => (
        <p className="m-0" key={detail._id}>
          {getQuotaName(detail.name)}: {getQuotaValue(detail)}
        </p>
      ))
    );

    // return sub.packageDetails === null || sub.packageDetails.length === 0 ? (
    //   <span className="text-italic">{translate("cWsSubs.noQuotas", false, null)}</span>
    // ) : (
    //   sub.packageDetails.map((detail, i) => (
    //     <span key={detail._id}>
    //       {i > 0 ? ", " : ""}
    //       {getQuotaName(detail.name)}: {getQuotaValue(detail)}
    //     </span>
    //   ))
    // );
  };

  const SubBillingDetails = () => {
    return (
      <>
        <p className="m-0">
          {sub.prepaid ? (
            translate("cWsSubs.subPrepaid", false, null)
          ) : (
            <>
              {sub.currency} <FormatNumber number={sub.prepaid ? sub.paidAtCheckout : sub.monthlyPrice} formatStr={"0.00"} />{" "}
              {translate("cWsSubs.invoicedMonthly", false, null)}
            </>
          )}
        </p>
        <p className="m-0">
          {translate("cWsSubs.subValidUntil", false, null)} <FormatDate dateString={sub.renewDate} lang={user.language} formatStr={"dmmmyy"} />
        </p>
      </>
    );
  };

  const BtnCancel = () => {
    let textBtn = translate("cWsSubs.toBeCancelledMonth", false, null);
    let textTitle = translate("cWsSubs.setSubToBeCancelledMonth", false, null);
    if (sub.isMarkedForCancellation) {
      textBtn = translate("cWsSubs.toBeCancelled", false, null);
      textTitle = translate("cWsSubs.reverseCancellation", false, null);
    }
    if (sub.prepaid && !sub.isMarkedForCancellation) {
      textBtn = translate("cWsSubs.cancelAtEndPeriod", false, null);
      textTitle = translate("cWsSubs.setSubToBeCancelledPeriod", false, null);
    }
    return (
      <button
        className={`btn btn-sm ${sub.isMarkedForCancellation ? "btn-danger" : "btn-outline-danger"} trans-3 my-1 me-2`}
        title={textTitle}
        onClick={() => setSubToCancel(activeWorkspace._id, sub, !sub.isMarkedForCancellation)}
      >
        {textBtn}
      </button>
    );
  };

  const LinkSubOptions = () => {
    let text = translate("cWsSubs.upgradeDowngradePrepay", !sub.prepaid, null);
    return (
      <Link className="btn btn-sm btn-outline-dark trans-3 my-1 me-2" to="/all-services" title={text}>
        {text}
      </Link>
    );
  };

  const BtnRefund = () => {
    const clickRefund = () => {
      if (window.confirm(translate("cWsSubs.confirmReturnSub", false, { subDesc: `${sub.productName} - ${sub.packageName}` }))) {
        returnSub(activeWorkspace._id, sub);
      }
    };

    let textBtn = translate("cWsSubs.returnSub", false, null);
    let textTitle = translate("cWsSubs.returnSubTitle", false, null);
    return (
      <button className={`btn btn-sm btn-outline-danger trans-3 my-1 me-2`} title={textTitle} onClick={clickRefund}>
        {textBtn}
      </button>
    );
  };

  return (
    <tr>
      <td>
        <SubProductPackage />
      </td>
      <td>
        <SubUsageMetrics />
      </td>
      <td>
        <SubBillingDetails />
      </td>
      <td>
        {sub.productName !== PRODUCT_NAMES.domains && <LinkSubOptions />}
        <BtnCancel />
        {canRefund(activeWorkspace, sub) && <BtnRefund />}
      </td>
    </tr>
  );
};

SubRow.propTypes = {
  auth: PropTypes.object.isRequired,
  sub: PropTypes.object.isRequired,
  setSubToCancel: PropTypes.func.isRequired,
  returnSub: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setSubToCancel, returnSub })(SubRow);
