import React from "react";

import { translate } from "../../translations/translations";

const BtnToggleSidebar = () => {
  return (
    <div
      className="d-flex d-lg-none btnToggleSidebar"
      title={translate("cNavigation.toggleSidebar", false, null)}
      data-bs-toggle="offcanvas"
      data-bs-target="#SidebarOffCanvas"
      aria-controls="SidebarOffCanvas"
    >
      <i className="fa-solid fa-bars"></i>
    </div>
  );
};

export default BtnToggleSidebar;
