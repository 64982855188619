import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UserDashboard from "../components/dashboard/UserDashboard";

import { translate } from "../translations/translations";

const Main = ({ auth: { user } }) => {
  return user === null ? (
    <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">{translate("pMain.welcome", false, { name: user.firstName })}</h1>
      <UserDashboard />
    </>
  );
};

Main.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Main);
