export const cListWsAudiences = {
  yourAudiences: {
    en: "Your audiences",
    nl: "Uw audiences",
  },
  noAudiencesFound: {
    en: "No existing audiences found.",
    nl: "Geen audiences gevonden.",
  },
  openMenu: {
    en: "Open menu",
    nl: "Open menu",
  },
  entries: {
    en: "Entries",
    nl: "Gegevens",
  },
  created: {
    en: "Created",
    nl: "Gemaakt",
  },
  lastChange: {
    en: "Last change",
    nl: "Veranderd",
  },
  seeEntries: {
    en: "See entries",
    nl: "Bekijk gegevens",
  },
};
