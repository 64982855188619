import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./styles/style.css";

import AuthRequired from "./components/auth/AuthRequired";
import WsHasProduct from "./components/auth/WsHasProduct";
import Layout from "./components/layout/Layout";
// Public pages
import Login from "./pages/Login";
import ForgotPw from "./pages/public/ForgotPw";
import ChangePw from "./pages/public/ChangePw";
import RegisterWsInvite from "./pages/public/RegisterWsInvite";
import ConfirmEmail from "./pages/public/ConfirmEmail";
import NotFound from "./pages/NotFound";
// General
import Main from "./pages/Main";
import Cart from "./pages/Cart";
import Feedback from "./pages/Feedback";
import Support from "./pages/Support";
// User
import Profile from "./pages/user/Profile";
import UserNotifications from "./pages/user/UserNotifications";
import UserWorkspaces from "./pages/user/UserWorkspaces";
// Workspace
import Subscriptions from "./pages/workspace/Subscriptions";
import Billing from "./pages/workspace/Billing";
import Referrals from "./pages/workspace/Referrals";
import Members from "./pages/workspace/Members";
import ChangeWsName from "./pages/workspace/ChangeWsName";
import WsNotifications from "./pages/workspace/WsNotifications";
import WsAdminInfo from "./pages/workspace/WsAdminInfo";
// Products - Domains - Explore
import DomainsMain from "./pages/products/domains/explore/DomainsMain";
import AllTLDs from "./pages/products/domains/explore/AllTLDs";
import DomainTransfer from "./pages/products/domains/explore/DomainTransfer";
// Products - Domains - Manage
import ManageDomains from "./pages/products/domains/manage/ManageDomains";
// Products - DNS
import ManageDNS from "./pages/products/dns/ManageDNS";
import ManageDomainDnsRecords from "./pages/products/dns/ManageDomainDnsRecords";
import EditDnsRecord from "./pages/products/dns/EditDnsRecord";
import EditNsRecord from "./pages/products/dns/EditNsRecord";
// Products - Hosting - Explore
import HostingMain from "./pages/products/hosting/explore/HostingMain";
// Products - Hosting - Manage
import ManageHosting from "./pages/products/hosting/manage/ManageHosting";
import FirstDomain from "./pages/products/hosting/manage/FirstDomain";
import ResetPleskPw from "./pages/products/hosting/manage/ResetPleskPw";
// Products - Sitebuilder - Explore
import SbMain from "./pages/products/sitebuilder/explore/SbMain";
// Products - Sitebuilder - Manage
import ManageSitebuilder from "./pages/products/sitebuilder/manage/ManageSitebuilder";
import SitebuilderProjectMenu from "./pages/products/sitebuilder/manage/SitebuilderProjectMenu";
import SitebuilderPublishProject from "./pages/products/sitebuilder/manage/SitebuilderPublishProject";
// Products - Contact form
import Messages from "./pages/contactForm/Messages";
// Products - Email marketing - Explore
import EmailMarketingMain from "./pages/products/email-marketing/explore/EmailMarketingMain";
// Products - Email marketing - Manage
import ManageEmailMarketing from "./pages/products/email-marketing/manage/ManageEmailMarketing";
import NewEmailCampaign from "./pages/products/email-marketing/manage/NewEmailCampaign";
import ExecutedEmailCampaigns from "./pages/products/email-marketing/manage/ExecutedEmailCampaigns";
import EmailCampaignStats from "./pages/products/email-marketing/manage/EmailCampaignStats";
import EmailCampaignAudit from "./pages/products/email-marketing/manage/EmailCampaignAudit";
import ManageEmailBuilderProjects from "./pages/products/email-marketing/manage/ManageEmailBuilderProjects";
import EmailbuilderProjectMenu from "./pages/products/email-marketing/manage/EmailbuilderProjectMenu";
// import ManageValidatedEmails from "./pages/products/email-marketing/manage/ManageValidatedEmails";
// Products - Audiences
import AudiencesMain from "./pages/products/audiences/AudiencesMain";
import AudienceEntries from "./pages/products/audiences/AudienceEntries";
import AudienceMenu from "./pages/products/audiences/AudienceMenu";
// Checkout (Stripe payment)
import Payment from "./pages/stripe/Payment";
import Status from "./pages/stripe/Status";
import Overdue from "./pages/stripe/Overdue";

// import { loadCart } from "./actions/cart";
// Auth functions
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import setWorkspace from "./utils/setWorkspace";

import { PRODUCT_NAMES } from "./components/products/lib/ProductVars";

// Check if a token is set in localstorage => If there is, add it to axios global headers so it gets sent with each request
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

if (localStorage.workspace) {
  setWorkspace(localStorage.workspace);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    // localStorage.cartSessionId && store.dispatch(loadCart(localStorage.cartSessionId));
    // eslint-disable-next-line
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* Auth protected pages */}
          <Route element={<AuthRequired />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Main />} />
              <Route path="cart" element={<Cart />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="support" element={<Support />} />
              {/* User */}
              <Route path="user">
                <Route path="profile" element={<Profile />} />
                <Route path="notifications" element={<UserNotifications />} />
                <Route path="workspaces" element={<UserWorkspaces />} />
              </Route>
              {/* Workspace */}
              <Route path="workspace">
                <Route path="members" element={<Members />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="billing" element={<Billing />} />
                <Route path="notifications/:wsId" element={<WsNotifications />} />
                <Route path="referrals" element={<Referrals />} />
                <Route path="change-name/:wsId" element={<ChangeWsName />} />
                <Route path="admin-info" element={<WsAdminInfo />} />
              </Route>
              {/* Services */}
              <Route path="domains">
                <Route path="all-domains" element={<AllTLDs />} />
                <Route path="transfer" element={<DomainTransfer />} />
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.domains]} />}>
                  <Route path="manage" element={<ManageDomains />} />
                </Route>
                <Route index element={<DomainsMain />} />
              </Route>
              <Route path="dns">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.domains, PRODUCT_NAMES.hosting]} />}>
                  <Route path=":domainName" element={<ManageDomainDnsRecords />} />
                  <Route path=":domainName/edit-record/:recordId" element={<EditDnsRecord />} />
                  <Route path=":domainName/edit-ns" element={<EditNsRecord />} />
                  <Route index element={<ManageDNS />} />
                </Route>
              </Route>
              <Route path="hosting">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.hosting]} />}>
                  <Route path="manage" element={<ManageHosting />} />
                  <Route path="first-domain" element={<FirstDomain />} />
                  <Route path="reset-plesk-pw" element={<ResetPleskPw />} />
                </Route>
                <Route index element={<HostingMain />} />
              </Route>
              <Route path="sitebuilder">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.sitebuilder]} />}>
                  <Route path="manage" element={<ManageSitebuilder />} />
                  <Route path="manage/menu/:projectId" element={<SitebuilderProjectMenu />} />
                  <Route path="manage/publish/:projectId/:domainName/:dir" element={<SitebuilderPublishProject />} />
                </Route>
                <Route index element={<SbMain />} />
              </Route>
              <Route path="contact-form">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.sitebuilder, PRODUCT_NAMES.emailMarketing]} />}>
                  <Route path="messages" element={<Messages />} />
                  {/* <Route index element={<Messages />} /> */}
                </Route>
              </Route>
              <Route path="email-marketing">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.emailMarketing]} />}>
                  <Route path="manage" element={<ManageEmailMarketing />} />
                  <Route path="manage/campaigns/new" element={<NewEmailCampaign />} />
                  <Route path="manage/campaigns/stats" element={<ExecutedEmailCampaigns />} />
                  <Route path="manage/campaigns/stats/:campaignId" element={<EmailCampaignStats />} />
                  <Route path="manage/campaigns/audit/:campaignId" element={<EmailCampaignAudit />} />
                  <Route path="manage/builder" element={<ManageEmailBuilderProjects />} />
                  <Route path="manage/builder/menu/:projectId" element={<EmailbuilderProjectMenu />} />
                  {/* <Route path="manage/validated-emails" element={<ManageValidatedEmails />} /> */}
                </Route>
                <Route index element={<EmailMarketingMain />} />
              </Route>
              <Route path="audiences">
                <Route element={<WsHasProduct arrProductNames={[PRODUCT_NAMES.sitebuilder, PRODUCT_NAMES.emailMarketing]} />}>
                  <Route path="menu/:audienceId" element={<AudienceMenu />} />
                  <Route path="entries/:audienceId" element={<AudienceEntries />} />
                  <Route index element={<AudiencesMain />} />
                </Route>
              </Route>
              {/* Checkout (Stripe payment) */}
              <Route path="checkout">
                <Route path="payment" element={<Payment />} />
                <Route path="overdue/:invoiceId" element={<Overdue />} />
                <Route path="status/:checkoutLocation/:paymentIntentStatus" element={<Status />} />
              </Route>
            </Route>
          </Route>
          {/* Public pages */}
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPw />} />
          <Route path="reset-password/:reqId" element={<ChangePw />} />
          <Route path="register/:wsInviteData" element={<RegisterWsInvite />} />
          <Route path="confirm-email/:token" element={<ConfirmEmail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
