export const common = {
  wsNotFound: {
    en: "Workspace not found.",
    nl: "Workspace niet gevonden.",
  },
  wsNotSubscribed: {
    en: "Active workspace is not subscribed to this service.",
    nl: "De actieve workspace is niet geabonneerd op deze service.",
  },
  userNotFound: {
    en: "User not found.",
    nl: "Gebruiker niet gevonden.",
  },
  serverError: {
    en: "A server error occurred. Please try again.",
    nl: "Er is een serverfout opgetreden. Probeer het aub opnieuw.",
  },
  // Product names
  productNameDomains: {
    en: "Domains",
    nl: "Domeinnamen",
  },
  productNameHosting: {
    en: "Hosting",
    nl: "Hosting",
  },
  productNameSb: {
    en: "Sitebuilder",
    nl: "Sitebuilder",
  },
  productNameEmailMarketing: {
    en: "Email marketing",
    nl: "Email marketing",
  },
  productNameAudiences: {
    en: "Audiences",
    nl: "Audiences",
  },
  productNameContactForms: {
    en: "Contact forms",
    nl: "Contact formulieren",
  },
  // Product FAQ
  anyQuestions: {
    en: "Any questions? Have a look at",
    nl: "Vragen? Bekijk",
  },
  ourFaq: {
    en: "our FAQ",
    nl: "onze Veel Gestelde Vragen",
  },
  or: {
    en: "or",
    nl: "of",
  },
  ourHelpdesk: {
    en: "ask our helpdesk!",
    nl: "vraag het onze experts!",
  },
};
