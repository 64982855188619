import React from "react";

import { PERIOD_ANNUALLY, PERIOD_MONTHLY } from "../lib/ProductVars";
import { formatNumber } from "../../../lib/formattingFunctions";
import { translate } from "../../../translations/translations";

const SelectPeriod = ({ period, setPeriod, surchargeMonthlyPayment }) => {
  return (
    <div className="d-flex justify-content-end align-items-center mb-3 px-2">
      <p className={`h6 text-${period === PERIOD_ANNUALLY ? "primary" : "gray"} text-bold d-inline my-0 me-3 trans-3`}>
        {translate("cProductTable.payAnnuallyAndGetDiscount", false, {
          percent: formatNumber(1 - 1 / (1 + surchargeMonthlyPayment), "0%"),
        })}
      </p>
      <span
        className={`switchWrapper ${period === PERIOD_ANNUALLY ? "checked" : ""}`}
        onClick={() => setPeriod((prev) => (prev === PERIOD_ANNUALLY ? PERIOD_MONTHLY : PERIOD_ANNUALLY))}
      >
        <span className="switchBall"></span>
      </span>
    </div>
  );
};

export default SelectPeriod;
