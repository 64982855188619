// Bring in action types
import { SET_BRAND_DATA, SET_PRODUCT_DATA, SET_TLD_DATA, BRAND_ERROR } from "../actions/types";

// Set initialState to an empty array
const initialState = { brandData: {}, brandOffers: [], products: [], TLDs: [], tldCategories: [] };

// Reducer functionality
export default function brandProducts(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  switch (type) {
    case SET_BRAND_DATA:
      return {
        ...state,
        // Payload is { brandData, brandOffers }
        brandData: payload.brandData,
        brandOffers: payload.brandOffers,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        // Payload is array of products that are offered by the brand
        products: payload,
      };
    case SET_TLD_DATA:
      return {
        ...state,
        // Payload = { categories, TLDs }
        TLDs: payload.TLDs,
        tldCategories: payload.categories,
      };
    case BRAND_ERROR:
      return {
        ...state,
        brandData: {},
        brandOffers: [],
        products: [],
        TLDs: [],
      };
    default:
      return state;
  }
}
