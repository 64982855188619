import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updatePleskCustomerPw } from "../../../../actions/hosting";
import { translate } from "../../../../translations/translations";

const ResetPleskPw = ({ auth: { activeWorkspace }, updatePleskCustomerPw }) => {
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [toggleShowPw, setToggleShowPw] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    setBtnDisabled(pw1 !== pw2 || !passwordStrongEnough(pw1) || !passwordStrongEnough(pw2));
  }, [pw1, pw2]);

  const genRandomPw = () => {
    const pwLength = 12;
    let generatedPw = [];
    const allowedChars = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz01234567890123456789!@#%^*-_";
    const allowedCharsLength = allowedChars.length;
    let strongEnough = false;
    while (!strongEnough) {
      generatedPw = [];
      for (let i = 0; i < pwLength; i++) {
        generatedPw.push(allowedChars.charAt(Math.floor(Math.random() * allowedCharsLength)));
      }
      strongEnough = passwordStrongEnough(generatedPw.join(""));
    }
    setPw1(generatedPw.join(""));
    setPw2(generatedPw.join(""));
  };

  const passwordStrongEnough = (str) => {
    // >8 chars
    // >1 capital
    // >1 lower
    // >1 digit
    // >1 special char
    if (
      str.length >= 8 &&
      str.match(/[A-Z]/) !== null &&
      str.match(/[a-z]/) !== null &&
      str.match(/[0-9]/) !== null &&
      str.match(/[!@#%^*\-_]/) !== null
    ) {
      return true;
    }
    return false;
  };

  const clickResetPw = () => {
    updatePleskCustomerPw(activeWorkspace.shortName.replace("_", ""), pw1);
  };

  return (
    <>
      <h1 className="text-primary">{translate("pResetPleskPw.resetPleskPw", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <div className="row mt-5">
          <div className="col-12 col-sm-9 col-md-8 col-lg-6">
            <p className="mb-3">
              {translate("pResetPleskPw.yourAdminUsername", false, null)}:{" "}
              <span className="text-bold">{activeWorkspace.shortName.replace("_", "")}</span>
            </p>
            <input
              type={toggleShowPw ? "text" : "password"}
              className="form-control"
              placeholder={translate("pResetPleskPw.newPw", false, null)}
              value={pw1}
              onChange={(e) => setPw1(e.target.value)}
            />
            <input
              type={toggleShowPw ? "text" : "password"}
              className="form-control mt-2"
              placeholder={translate("pResetPleskPw.confirmNewPw", false, null)}
              value={pw2}
              onChange={(e) => setPw2(e.target.value)}
            />
            <div className="row mt-2">
              <div className="col-6">
                <button className="btn btn-primary w-100" onClick={() => setToggleShowPw(!toggleShowPw)}>
                  {toggleShowPw ? "Hide" : "Show"} {translate("pResetPleskPw.password", false, null)}
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-primary w-100" onClick={genRandomPw}>
                  {translate("pResetPleskPw.generateNewPw", false, null)}
                </button>
              </div>
            </div>
            <p className="mt-2 mb-0 fontSize08 text-italic">{translate("pResetPleskPw.pwRequirements", false, null)}</p>
            <button className="btn btn-primary trans-3 w-100 mt-3" type="button" disabled={btnDisabled} onClick={clickResetPw}>
              {translate("pResetPleskPw.changePw", false, null)}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

ResetPleskPw.propTypes = {
  auth: PropTypes.object.isRequired,
  updatePleskCustomerPw: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updatePleskCustomerPw })(ResetPleskPw);
