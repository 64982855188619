import React from "react";

import SbCreateNewProject from "../../../../components/products/sitebuilder/manage/SbCreateNewProject";
import SbListProjects from "../../../../components/products/sitebuilder/manage/SbListProjects";
import SbSeeContactForms from "../../../../components/products/sitebuilder/manage/SbSeeContactForms";

import { translate } from "../../../../translations/translations";

const ManageSitebuilder = () => {
  return (
    <>
      <h1 className="text-primary">{translate("pSbOthers.manageSbProjects", false, null)}</h1>
      {/* New project */}
      <SbCreateNewProject />
      {/* Manage existing */}
      <SbListProjects />
      {/* See messages from contact forms */}
      <SbSeeContactForms />
    </>
  );
};

export default ManageSitebuilder;
