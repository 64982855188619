import React from "react";

import { translate } from "../../translations/translations";

const TicketReference = ({ ticketId }) => {
  return (
    <p className="mb-2 text-gray">
      <span className="text-bold">{translate("cSupportComponents.ticketReference", false, null)}: </span>
      {ticketId}
    </p>
  );
};

export default TicketReference;
