import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { deleteAudience } from "../../actions/audiences";

import { translate } from "../../translations/translations";

const EbMenuDelete = ({ auth: { user, activeWorkspace }, audiences: { audiences }, deleteAudience }) => {
  let { audienceId } = useParams();
  const navigate = useNavigate();

  const [audience, setAudience] = useState(null);

  useEffect(() => {
    if (activeWorkspace !== null && user !== null && audienceId !== "") {
      setAudience(audiences.filter((audience) => audience._id === audienceId)[0] || null);
    } else {
      setAudience(null);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, user, audienceId]);

  const clickDeleteAudience = async () => {
    if (window.confirm(translate("cAudienceMenuDelete.confirmDeleteAudience", false, { audienceName: audience.audienceName }))) {
      await deleteAudience(audienceId);
      navigate(`/audiences`, { replace: true });
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cAudienceMenuDelete.deleteAudience", false, null)}</h2>
      {audienceId === "" ? (
        <p className="text-italic m-0">{translate("cAudienceMenuDelete.audienceNotFound", false, null)}</p>
      ) : (
        <button className="btn btn-danger" onClick={clickDeleteAudience}>
          {translate("cAudienceMenuDelete.deleteAudience", false, null)}
        </button>
      )}
    </>
  );
};

EbMenuDelete.propTypes = {
  auth: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
  deleteAudience: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  audiences: state.audiences,
});

export default connect(mapStateToProps, { deleteAudience })(EbMenuDelete);
