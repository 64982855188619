export const cCreateNewAudience = {
  createNewAudienceFromFile: {
    en: "Create new audience from data file",
    nl: "Maak nieuwe audience met data uit een bestand",
  },
  audienceName: {
    en: "Audience name",
    nl: "Audience naam",
  },
  pleaseUpgrade: {
    en: "Please upgrade to create additional audiences",
    nl: "Upgrade uw pakket om meer audiences te kunnen maken",
  },
  clearFile: {
    en: "Clear file",
    nl: "Verwijder bestand",
  },
  optional: {
    en: "Optional. Only CSV files are allowed. Data keys (without spaces) should be on the first row. Subsequent rows will be loaded as audience entries.",
    nl: "Optioneel. Alleen CSV bestanden zijn toegestaan. Datasleutels (zonder spaties) moeten op de eerste regel staan. Volgende regels worden geladen als audience gegevens.",
  },
  createAudience: {
    en: "Create audience!",
    nl: "Maak audience!",
  },
};
