export const getCurrency = (countryCode) => {
  try {
    let country = currencies.filter((c) => c.countryCode === countryCode)[0];
    if (country === null || typeof country === "undefined") {
      throw new Error("Country not found");
    }
    return country;
  } catch (error) {
    return { countryCode, currencyCode: "EUR", currencySign: "€" };
  }
};

export const getCurrencySign = (currencyCode) => {
  try {
    let currency = currencies.filter((c) => c.currencyCode === currencyCode)[0];
    if (currency === null || typeof currency === "undefined") {
      throw new Error("Currency not found");
    }
    return currency.currencySign;
  } catch (error) {
    return currencyCode;
  }
};

export const currencies = [
  // Offer only EUR, USD, GBP?
  { countryCode: "us", currencyCode: "USD", currencySign: "$" },
  { countryCode: "uk", currencyCode: "GBP", currencySign: "£" },
  { countryCode: "at", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "be", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "bg", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "bg", currencyCode: "BGN", currencySign: "лв" }, // non-euro
  { countryCode: "cy", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "cz", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "cz", currencyCode: "CZK", currencySign: "Kč" }, // non-euro
  { countryCode: "dk", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "dk", currencyCode: "DKK", currencySign: "kr." }, // non-euro
  { countryCode: "ee", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "fi", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "fr", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "de", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "gr", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "hr", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "hu", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "hu", currencyCode: "HUF", currencySign: "Ft" }, // non-euro
  { countryCode: "ie", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "it", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "lv", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "lt", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "lu", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "mt", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "nl", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "pl", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "pl", currencyCode: "PLN", currencySign: "zł" }, // non-euro
  { countryCode: "pt", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "ro", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "ro", currencyCode: "RON", currencySign: "lei" }, // non-euro
  { countryCode: "sk", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "si", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "es", currencyCode: "EUR", currencySign: "€" },
  { countryCode: "se", currencyCode: "EUR", currencySign: "€" },
  // { countryCode: "se", currencyCode: "SEK", currencySign: "kr" }, // non-euro
];
