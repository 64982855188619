export const cCampaignName = {
  setCampaignName: {
    en: "Set campaign name",
    nl: "Specificeer campagne naam",
  },
  marketingCampaigName: {
    en: "Marketing campaign name",
    nl: "Marketing campagne naam",
  },
};
