import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import WsEditLocationInfo from "../../components/workspace/adminInfo/WsEditLocationInfo";
import WsEditCustomerInfo from "../../components/workspace/adminInfo/WsEditCustomerInfo";

import { translate } from "../../translations/translations";

const WsAdminInfo = ({ auth: { activeWorkspace } }) => {
  return (
    <>
      <h1 className="text-primary">{translate("pWsPages.wsAdminInfo", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <>
          <WsEditLocationInfo />
          <WsEditCustomerInfo />
        </>
      )}
    </>
  );
};

WsAdminInfo.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(WsAdminInfo);
