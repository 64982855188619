import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { editSbProjectName } from "../../../../actions/sitebuilder";
import { capitalizeFirstLetter } from "../../../../lib/formattingFunctions";

import { translate } from "../../../../translations/translations";

const SbMenuEditName = ({ editSbProjectName }) => {
  let { projectId } = useParams();

  const [projectName, setProjectName] = useState("");

  const onType = (val) => {
    setProjectName(val.replace(/\s/g, "-").replace(/[^A-Za-z0-9_-]/g, ""));
  };

  const clickEditName = () => {
    if (projectName !== "") {
      editSbProjectName(projectId, capitalizeFirstLetter(projectName));
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cSitebuilder.editProjectName", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cSitebuilder.projectNotFound", false, null)}</p>
      ) : (
        <div className="input-group mb-3">
          <input
            type="text"
            className={`form-control`}
            placeholder={translate("cSitebuilder.newProjectName", false, null)}
            value={projectName}
            onChange={(e) => onType(e.target.value)}
          />
          <button className="btn btn-outline-primary" disabled={projectName === ""} onClick={clickEditName}>
            {translate("cSitebuilder.editName", false, null)}
          </button>
        </div>
      )}
    </>
  );
};

SbMenuEditName.propTypes = {
  //   sitebuilder: PropTypes.object.isRequired,
  editSbProjectName: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   sitebuilder: state.sitebuilder,
// });

export default connect(null, { editSbProjectName })(SbMenuEditName);
