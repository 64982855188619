export const pCheckoutPayment = {
  loading: {
    en: "Loading..",
    nl: "Laden..",
  },
  checkout: {
    en: "Checkout",
    nl: "Afrekenen",
  },
  invoiceNotFound: {
    en: "Invoice not found.",
    nl: "Factuur niet gevonden.",
  },
  selectPaymentMethod: {
    en: "Select your preferred payment method to pay amount due of",
    nl: "Selecteer een methode om het volgende bedrag te betalen:",
  },
};
