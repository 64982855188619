export const pManageHosting = {
  manageHostingServices: {
    en: "Manage your hosting services",
    nl: "Beheer uw hosting serivces",
  },
  addFirstDomain: {
    en: "Add first domain",
    nl: "Host eerste domein",
  },
  hostFIrstDomain: {
    en: "Host first domain on server",
    nl: "Host eerste domein op uw server",
  },
  openPlesk: {
    en: "Open Plesk admin panel",
    nl: "Open Plesk controle paneel",
  },
  pleskPw: {
    en: "Plesk password",
    nl: "Plesk wachtwoord",
  },
  resetPleskPw: {
    en: "Reset Plesk admin password",
    nl: "Verander Plesk admin wachtwoord",
  },
  emailManager: {
    en: "Email manager",
    nl: "Email manager",
  },
  openEmailManager: {
    en: "Open email manager",
    nl: "Open email manager",
  },
};
