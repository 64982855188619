export const pWsMembers = {
  youreWsOwner: {
    en: "You're the owner of this workspace. Before you can leave, first make someone else owner.",
    nl: "U bent de eigenaar van deze workspace. Voordat u deze kunt verlaten, moet u iemand anders eigenaar maken.",
  },
  needsAtleastOneOwner: {
    en: "There needs to be an owner of the workspace",
    nl: "Een workspace moet een eigenaar hebben",
  },
  cantSetPendingUserAsOwner: {
    en: "Workspace owner has to be an activated user account.",
    nl: "De eigenaar van de workspace moet een geactiveerd account zijn.",
  },
  needsAtleastOneAdmin: {
    en: "There needs to be at least one admin user in the workspace",
    nl: "Een workspace moet in ieder geval een admin hebben",
  },
  updatingUserRole: {
    en: "Updating user's role..",
    nl: "Updaten van gebruiker's rol..",
  },
  needsAtleastOneMember: {
    en: "There needs to be at least one user in the workspace",
    nl: "Een workspace moet in ieder geval een gebruiker hebben",
  },
  removingUSer: {
    en: "Removing user..",
    nl: "Verwijderen van gebruiker..",
  },
  pendingUserReg: {
    en: "Pending user registration",
    nl: "In afwachting van gebruiker's registratie",
  },
  owner: {
    en: "Owner",
    nl: "Eigenaar",
  },
  admin: {
    en: "Admin",
    nl: "Admin",
  },
  member: {
    en: "Member",
    nl: "Lid",
  },
  removeUserFromWs: {
    en: "Remove user from workspace",
    nl: "Verwijder gebruiker van workspace",
  },
  retractInvite: {
    en: "Retract invite",
    nl: "Trek uitnodiging terug",
  },
  addingUsersToWs: {
    en: "Adding users to workspace..",
    nl: "Toevoegen leden..",
  },
  provideValidEmail: {
    en: "Please provide a valid email address",
    nl: "Geef aub een geldig email adres op",
  },
  wsMembers: {
    en: "Workspace members",
    nl: "Workspace leden",
  },
  membersOf: {
    en: "Members of",
    nl: "Leden van",
  },
  wsHasNoMembers: {
    en: "Workspace does not have any members.",
    nl: "Workspace heeft geen leden.",
  },
  firstName: {
    en: "First name",
    nl: "Voornaam",
  },
  lastName: {
    en: "Last name",
    nl: "Achternaam",
  },
  email: {
    en: "Email",
    nl: "Email",
  },
  role: {
    en: "Role",
    nl: "Rol",
  },
  addNewMembers: {
    en: "Add new member(s)",
    nl: "Voeg nieuwe leden toe",
  },
  userEmail: {
    en: "User's email address",
    nl: "Gebruiker's email adres",
  },
  removeRow: {
    en: "Remove row",
    nl: "Verwijder regel",
  },
  addAddnRow: {
    en: "Add additional row",
    nl: "Extra regel",
  },
  addUserToWs: {
    en: "Add users to workspace",
    nl: "Voeg gebruikers toe aan workspace",
  },
};
