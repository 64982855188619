import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { translate } from "../../../translations/translations";
import { deleteDnsRecord } from "../../../actions/dns";

const ListDomainDnsRecords = ({ auth: { activeWorkspace }, dnsRecords, deleteDnsRecord }) => {
  // dnsRecords = { _id, domainName, nameservers, records }

  const sortAlpha = (a, b) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  };

  const renderDnsRecordGetValue = (record) => {
    if (record.type === "A" || record.type === "AAAA") {
      return record.recordFields.pointsTo;
    }
    if (record.type === "CNAME") {
      return record.recordFields.aliasOf;
    }
    if (record.type === "MX") {
      return record.recordFields.mailServer;
    }
    if (record.type === "TXT") {
      return record.recordFields.txtString.length > 30 ? `${record.recordFields.txtString.substring(0, 30)}...` : record.recordFields.txtString;
    }
    if (record.type === "SRV") {
      return record.recordFields.directsTo;
    }
    if (record.type === "CAA") {
      return record.recordFields.authGrantedFor;
    }
  };

  const renderDnsRecordGetFullDesc = (record) => {
    if (record.type === "A" || record.type === "AAAA") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.pointsTo",
        false,
        null
      )}: ${record.recordFields.pointsTo} - TTL: ${record.recordFields.TTL}`;
    }
    if (record.type === "CNAME") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.aliasOf",
        false,
        null
      )}: ${record.recordFields.aliasOf} - TTL: ${record.recordFields.TTL}`;
    }
    if (record.type === "MX") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.aliasOf",
        false,
        null
      )}: ${record.recordFields.mailServer} - ${translate("cCreateDnsRecords.priority", false, null)}: ${record.recordFields.priority} - TTL: ${
        record.recordFields.TTL
      }`;
    }
    if (record.type === "TXT") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.text",
        false,
        null
      )}: ${record.recordFields.txtString} - TTL: ${record.recordFields.TTL}`;
    }
    if (record.type === "SRV") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.directsTo",
        false,
        null
      )}: ${record.recordFields.directsTo} - ${translate("cCreateDnsRecords.srvPort", false, null)}: ${record.recordFields.port} - ${translate(
        "cCreateDnsRecords.srvWeight",
        false,
        null
      )}: ${record.recordFields.weight} - ${translate("cCreateDnsRecords.priority", false, null)}: ${record.recordFields.priority} - TTL: ${
        record.recordFields.TTL
      }`;
    }
    if (record.type === "CAA") {
      return `${translate("cCreateDnsRecords.hostname", false, null)}: ${record.recordFields.hostname} - ${translate(
        "cCreateDnsRecords.authGrantedFor",
        false,
        null
      )}: ${record.recordFields.authGrantedFor} - ${translate("cCreateDnsRecords.tag", false, null)}: ${record.recordFields.tag} - ${translate(
        "cCreateDnsRecords.flags",
        false,
        null
      )}: ${record.recordFields.flags} - TTL: ${record.recordFields.TTL}`;
    }
  };

  const clickDeleteDnsRecord = (record) => {
    deleteDnsRecord(activeWorkspace._id, dnsRecords._id, record._id);
  };

  const TableRowsNameservers = () => {
    let ns = [];
    for (let i = 0; i < 6; i++) {
      let keyNs = `ns${i + 1}`;
      let keyIp = `ip${i + 1}`;
      if (dnsRecords.nameservers[keyNs] !== "") {
        ns.push({ ns: dnsRecords.nameservers[keyNs], ip: dnsRecords.nameservers[keyIp] });
      }
    }

    return ns.length === 0
      ? null
      : ns.map((ns, i) => (
          <tr key={`ns${i}`}>
            <td>NS</td>
            <td>{ns.ns}</td>
            <td>{ns.ip}</td>
            <td>{translate("cCreateDnsRecords.notApplicable", false, null)}</td>
            <td>
              <Link to={`edit-ns`} className="cursorPointer text-success me-3">
                <i className="fa-solid fa-pencil" title={translate("cCreateDnsRecords.editRecord", false, null)}></i>
              </Link>
            </td>
          </tr>
        ));
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cCreateDnsRecords.dnsRecords", false, null)}</h2>
      {dnsRecords === null ? (
        <p className="text-italic m-0">{translate("cCreateDnsRecords.thereAreNoDnsRecords", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col">{translate("cCreateDnsRecords.type", false, null)}</th>
                <th scope="col">{translate("cCreateDnsRecords.hostname", false, null)}</th>
                <th scope="col">{translate("cCreateDnsRecords.value", false, null)}</th>
                <th scope="col">{translate("cCreateDnsRecords.ttlSeconds", false, null)}</th>
                <th scope="col">{translate("cCreateDnsRecords.actions", false, null)}</th>
              </tr>
            </thead>
            <tbody>
              {dnsRecords.records.sort(sortAlpha).map((record) => (
                <tr key={record._id} title={renderDnsRecordGetFullDesc(record)} style={{ cursor: "help" }}>
                  <td>{record.type}</td>
                  <td>{record.recordFields.hostname}</td>
                  <td>{renderDnsRecordGetValue(record)}</td>
                  <td>{record.recordFields.TTL}</td>
                  <td>
                    <Link to={`edit-record/${record._id}`} className="cursorPointer text-success me-3">
                      <i className="fa-solid fa-pencil" title={translate("cCreateDnsRecords.editRecord", false, null)}></i>
                    </Link>
                    <span
                      className="text-danger cursorPointer"
                      title={translate("cCreateDnsRecords.removeRecord", false, null)}
                      onClick={() => clickDeleteDnsRecord(record)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </td>
                </tr>
              ))}
              <TableRowsNameservers />
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

ListDomainDnsRecords.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object.isRequired,
  deleteDnsRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteDnsRecord })(ListDomainDnsRecords);
