export const pFirstDomain = {
  addFirstDomain: {
    en: "Add your first domain to your server",
    nl: "Host uw eerste domain op uw server",
  },
  alreadyHasDomain: {
    en: "A domain is already hosted in your server. Please login to the Plesk admin panel to add additional domains.",
    nl: "Een domein wordt al gehost op uw server. Login aub in op uw Plesk admin panel om extra domeinnamen toe te voegen.",
  },
  exampleCom: {
    en: "example.com",
    nl: "voorbeeld.nl",
  },
  addDomain: {
    en: "Add domain",
    nl: "Host domein",
  },
};
