import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import EmailCampaignStatsAuditTop from "../../../../components/products/email-marketing/manage/EmailCampaignStatsAuditTop";
import ModalRecipientEmail from "../../../../components/products/email-marketing/manage/ModalRecipientEmail";
import FormatDate from "../../../../components/layout/FormatDate";

import { translate } from "../../../../translations/translations";

const EmailCampaignAudit = ({
  auth: { user, activeWorkspace },
  emailMarketing: { ebProjects, executedEmailCampaigns },
  audiences: { audiences },
}) => {
  let { campaignId } = useParams();

  const [campaign, setCampaign] = useState(null);
  const [sortedRecipients, setSortedRecipients] = useState([]);
  // Pagination
  const [rowsToRender, setRowsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const PER_PAGE = 20;
  // Sorting
  const [sortByKey, setSortByKey] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  // Email modal
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  useEffect(() => {
    if (activeWorkspace !== null && user !== null && campaignId !== "") {
      let selectedCampaign = executedEmailCampaigns.filter((campaign) => campaign._id === campaignId)[0];
      if (typeof selectedCampaign === "undefined") {
        setCampaign(null);
      } else {
        let usedAudience = audiences.filter((audience) => audience._id === selectedCampaign.audienceId)[0];
        let usedEbProject = ebProjects.filter((ebProject) => ebProject._id === selectedCampaign.emailTemplateId)[0];
        setCampaign({
          ...selectedCampaign,
          usedAudience: typeof usedAudience === "undefined" ? translate("pEmCampaignAudit.notFound", false, null) : usedAudience.audienceName,
          usedEbProject: typeof usedEbProject === "undefined" ? translate("pEmCampaignAudit.notFound", false, null) : usedEbProject.projectName,
        });
        setSortedRecipients(selectedCampaign.recipients);
      }
    } else {
      setCampaign(null);
    }
    // eslint-disable-next-line
  }, [activeWorkspace, user, campaignId]);

  useEffect(() => {
    setMaxPages(campaign === null ? 0 : Math.ceil(sortedRecipients.length / PER_PAGE));
    setCurrPage(0);
    setRowsToRender(campaign === null ? [] : sortedRecipients.slice(0, PER_PAGE));
    // eslint-disable-next-line
  }, [campaign]);

  useEffect(() => {
    setRowsToRender(campaign === null ? [] : sortedRecipients.slice(currPage * PER_PAGE, currPage * PER_PAGE + PER_PAGE));
    // eslint-disable-next-line
  }, [currPage]);

  const clickSortBy = (key) => {
    if (key === sortByKey) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
        sortRecipients(key, "desc");
      } else {
        setSortDirection("asc");
        sortRecipients(key, "asc");
      }
    } else {
      setSortByKey(key);
      setSortDirection("desc");
      sortRecipients(key, "desc");
    }
  };

  const sortRecipients = (key, dir) => {
    let sorted = campaign.recipients.sort((a, b) => {
      if (typeof a[key] === "undefined" || typeof b[key] === "undefined") {
        return 1;
      }
      if (key === "clickedOn") {
        if (dir === "desc") {
          return a[key].length < b[key].length ? -1 : a[key].length > b[key].length ? 1 : 0;
        } else {
          return a[key].length > b[key].length ? -1 : a[key].length < b[key].length ? 1 : 0;
        }
      } else {
        if (dir === "desc") {
          return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
        } else {
          return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
        }
      }
    });
    setSortedRecipients(sorted);
    setCurrPage(0);
    setRowsToRender(sorted.slice(0, PER_PAGE));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const getRecipientClicks = (arrClickedOn) => {
    if (arrClickedOn.length === 0) {
      return "--";
    }
    return [...new Set(arrClickedOn)]
      .map((linkId) => ({ linkId, numClicked: arrClickedOn.filter((link) => link === linkId).length }))
      .sort((a, b) => (a.numClicked < b.numClicked ? 1 : a.numClicked > b.numClicked ? -1 : 0))
      .map((data) => `${data.linkId} (${data.numClicked}x)`)
      .join(", ");
  };

  const Pagination = () => {
    return (
      sortedRecipients.length > 0 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3 me-4"
            onClick={() => clickPageBtn(-1)}
            title={
              currPage === 0
                ? translate("pEmCampaignAudit.noMoreRecipients", false, null)
                : translate("pEmCampaignAudit.seeAdnlRecipients", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("pEmCampaignAudit.showingRecipients", false, null)}{" "}
            <span className="text-bold">
              {Math.min(currPage * PER_PAGE + 1, sortedRecipients.length)} - {Math.min((currPage + 1) * PER_PAGE, sortedRecipients.length)}
            </span>{" "}
            {translate("pEmCampaignAudit.of", false, null)} <span className="text-bold">{sortedRecipients.length}</span>
          </span>
          <button
            className="btn btn-outline-primary trans-3 ms-4"
            onClick={() => clickPageBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("pEmCampaignAudit.noMoreRecipients", false, null)
                : translate("pEmCampaignAudit.seeAdnlRecipients", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      )
    );
  };

  const clickDlEmail = (recipient) => {
    setSelectedRecipient(recipient);
    // setSearchParams(`recipientId=${recipient._id}`);
  };

  return campaign === null ? (
    <p className="text-italic">{translate("pEmCampaignAudit.campaignNotFound", false, null)}</p>
  ) : (
    <>
      <h1 className="text-primary">
        {translate("pEmCampaignAudit.auditDetailsFor", false, null)} "<span className="text-italic">{campaign.campaignName}</span>"
      </h1>
      <EmailCampaignStatsAuditTop emailSubject={campaign.emailSubject} usedAudience={campaign.usedAudience} usedEbProject={campaign.usedEbProject} />
      {sortedRecipients.length === 0 ? (
        <p className="text-italic mt-4">{translate("pEmCampaignAudit.noCampaignRecipients", false, null)}</p>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table table-sm fontSize09 table-hover rounded-3 overflowHidden align-middle mt-4">
              <thead>
                <tr className="bg-primary text-white border-none">
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("emailAddress")}>
                    {translate("pEmCampaignAudit.recipient", false, null)}{" "}
                    {sortByKey === "emailAddress" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("sentAt")}>
                    {translate("pEmCampaignAudit.sentAt", false, null)}{" "}
                    {sortByKey === "sentAt" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("emailVersionToReceive")}>
                    {translate("pEmCampaignAudit.emailVersionSent", false, null)}{" "}
                    {sortByKey === "emailVersionToReceive" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("received")}>
                    {translate("pEmCampaignAudit.received", false, null)}{" "}
                    {sortByKey === "received" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("opened")}>
                    {translate("pEmCampaignAudit.timesOpened", false, null)}{" "}
                    {sortByKey === "opened" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("clickedOn")}>
                    {translate("pEmCampaignAudit.clickedLinks", false, null)}{" "}
                    {sortByKey === "clickedOn" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col" className="cursorPointer" onClick={() => clickSortBy("unsubscribed")}>
                    {translate("pEmCampaignAudit.unsubd", false, null)}{" "}
                    {sortByKey === "unsubscribed" && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {rowsToRender.map((recipient) => (
                  <tr key={recipient._id}>
                    <td>{recipient.emailAddress}</td>
                    <td>
                      {typeof recipient.sentAt === "undefined" ? (
                        <FormatDate dateString={campaign.scheduledAt} lang={user.language} formatStr={"dmmyy hm"} />
                      ) : (
                        <FormatDate dateString={recipient.sentAt} lang={user.language} formatStr={"dmmyy hm"} />
                      )}
                    </td>
                    <td>
                      {translate("pEmCampaignAudit.version", false, null)} {recipient.emailVersionToReceive}
                    </td>
                    <td>{recipient.received ? translate("pEmCampaignAudit.yes", false, null) : translate("pEmCampaignAudit.no", false, null)}</td>
                    <td>{recipient.opened}</td>
                    <td>{getRecipientClicks(recipient.clickedOn)}</td>
                    <td>{recipient.unsubscribed ? translate("pEmCampaignAudit.yes", false, null) : translate("pEmCampaignAudit.no", false, null)}</td>
                    <td className="text-end">
                      <span
                        className="text-dark textHover-primary trans-3 me-1 cursorPointer"
                        title={translate("pEmCampaignAudit.seePdf", false, null)}
                        onClick={() => clickDlEmail(recipient)}
                        data-bs-toggle="modal"
                        data-bs-target="#ModalRecipientEmail"
                      >
                        <i className="fa-regular fa-eye"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination />
        </>
      )}
      <ModalRecipientEmail campaign={campaign} selectedRecipient={selectedRecipient} />
    </>
  );
};

EmailCampaignAudit.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(EmailCampaignAudit);
