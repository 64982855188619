import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { formatDate, capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { removeAudienceEntry } from "../../actions/audiences";
import { getEmailFromEntry } from "./lib/audienceFunctions";

import { translate } from "../../translations/translations";

const AudienceEntries = ({ auth: { user }, allEntries, setAllEntries, filteredEntries, setFilteredEntries, boolEditable, removeAudienceEntry }) => {
  let { audienceId } = useParams();

  // Full dataset
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  // Pagination
  const [rowsToRender, setRowsToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  // Sorting
  const [sortByKey, setSortByKey] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  // Filtering
  const [originalEmailKey, setOriginalEmailKey] = useState("");
  const [newFilterBy, setNewFilterBy] = useState("");
  const [uniqueStringValues, setUniqueStringValues] = useState(null);
  const [filterSelectedStringValues, setFilterSelectedStringValues] = useState([]);
  const [newFilterNumberDateOp, setNewFilterNumberDateOp] = useState("");
  const [newFilterValNumber, setNewFilterValNumber] = useState("");
  const [newFilterValDate, setNewFilterValDate] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const [activeFiltersByKey, setActiveFiltersByKey] = useState(null);

  const KEY_EMAIL = "emailAddress";
  const PER_PAGE = 20;

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line
  }, [filteredEntries]);

  useEffect(() => {
    setMaxPages(Math.ceil(tableRows.length / PER_PAGE));
    setCurrPage(0);
    setRowsToRender(tableRows.slice(0, PER_PAGE));
    // eslint-disable-next-line
  }, [tableRows]);

  useEffect(() => {
    setRowsToRender(tableRows.slice(currPage * PER_PAGE, currPage * PER_PAGE + PER_PAGE));
    // eslint-disable-next-line
  }, [currPage]);

  useEffect(() => {
    groupFiltersByKey();
    // eslint-disable-next-line
  }, [activeFilters]);

  useEffect(() => {
    refilterEntries();
    // eslint-disable-next-line
  }, [activeFiltersByKey]);

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const getTableData = () => {
    let uniqueEmailAddresses = [];
    let originalEmailKey = "";
    let entries =
      filteredEntries === null
        ? []
        : filteredEntries
            .map((entry) => {
              // Remove entries fields that you don't need to show
              const { message, subject, ...rest } = entry;
              // Standardize the key name of the email address value
              let { emailKey, emailAddress } = getEmailFromEntry(rest);
              originalEmailKey = emailKey;
              delete rest[emailKey];
              // Check whether email is a duplicate
              if (uniqueEmailAddresses.includes(emailAddress)) {
                emailAddress = "";
              } else {
                uniqueEmailAddresses.push(emailAddress);
              }
              return { ...rest, emailAddress };
            })
            .filter((row) => row.emailAddress !== "");

    let headers = getTableHeaders(entries);

    setTableHeaders(headers);
    setTableRows(entries);

    getUniqueStringValues(headers, originalEmailKey);
  };

  const getTableHeaders = (entries) => {
    // JSON approach: [...new Set([...JSON.stringify(entries).matchAll(/[{|,]"(.+?)":/g)].map((m) => m[1]))];

    const HEADERS_EXCLUDE_KEYS = [KEY_EMAIL, "_id"];
    let headers = [];
    let uniqueHeaderKeys = [];
    entries.forEach((entry) => {
      Object.keys(entry)
        .filter((key) => !HEADERS_EXCLUDE_KEYS.includes(key) && !uniqueHeaderKeys.includes(key))
        .forEach((key) => {
          uniqueHeaderKeys.push(key);
          headers.push({ key, dataType: getDatatype(entry[key]) });
        });
    });
    return headers;
  };

  const getUniqueStringValues = (headers, originalEmailKey) => {
    let keys = [KEY_EMAIL, ...headers.filter((header) => header.dataType === "string").map((header) => header.key)];
    let res = {};
    keys.forEach((key) => {
      res = { ...res, [key]: [] };
    });
    allEntries !== null &&
      allEntries.forEach((entry) => {
        keys.forEach((key) => {
          let val = key === KEY_EMAIL ? entry[originalEmailKey] : entry[key];
          if (!res[key].includes(val)) {
            res[key].push(val);
          }
        });
      });
    setUniqueStringValues(res);
    setOriginalEmailKey(originalEmailKey);
  };

  const getDatatype = (val) => {
    if (typeof val === "string" && new Date(val).toString() !== "Invalid Date") {
      return "date";
    }
    if (!Number.isNaN(parseFloat(val)) && Number.isFinite(parseFloat(val))) {
      return "number";
    }
    return "string";
  };

  const getCellFormattedValue = (key, val) => {
    if (val === "" || typeof val === "undefined") {
      return "";
    }
    let dataType = tableHeaders.filter((header) => header.key === key)[0].dataType;
    if (dataType === "date") {
      return formatDate(val, user.language, "dmyy hm");
    }
    if (dataType === "number") {
      return parseFloat(val);
    }
    // string
    return val;
  };

  const EntryRow = ({ entry }) => {
    const clickRemoveFilter = async () => {
      removeAudienceEntry(audienceId, entry._id);
      allEntries !== null && setAllEntries((prev) => prev.filter((prevEntry) => prevEntry._id !== entry._id));
      filteredEntries !== null && setFilteredEntries((prev) => prev.filter((prevEntry) => prevEntry._id !== entry._id));
    };

    return (
      <tr>
        <td>{entry[KEY_EMAIL]}</td>
        {tableHeaders.map((header, j) => (
          <td key={`${entry._id}_${j}`}>{getCellFormattedValue(header.key, entry[header.key])}</td>
        ))}
        {boolEditable && (
          <td className="text-end">
            <i
              className="fa-regular fa-trash-can cursorPointer text-danger me-2"
              title={translate("cAudienceEntriesTable.removeEntry", false, null)}
              onClick={() => clickRemoveFilter()}
            ></i>
          </td>
        )}
      </tr>
    );
  };

  const Pagination = () => {
    return (
      tableRows.length > 0 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button
            className="btn btn-outline-primary trans-3 me-4"
            onClick={() => clickPageBtn(-1)}
            title={
              currPage === 0
                ? translate("cAudienceEntriesTable.thereAreNoMoreEntries", false, null)
                : translate("cAudienceEntriesTable.seeAddnlEntries", false, null)
            }
            disabled={currPage === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <span className="fontSize08 text-dark">
            {translate("cAudienceEntriesTable.showingEntries", false, null)}{" "}
            <span className="text-bold">
              {Math.min(currPage * PER_PAGE + 1, tableRows.length)} - {Math.min((currPage + 1) * PER_PAGE, tableRows.length)}
            </span>{" "}
            {translate("cAudienceEntriesTable.of", false, null)} <span className="text-bold">{tableRows.length}</span>
          </span>
          <button
            className="btn btn-outline-primary trans-3 ms-4"
            onClick={() => clickPageBtn(1)}
            title={
              currPage + 1 === maxPages
                ? translate("cAudienceEntriesTable.thereAreNoMoreEntries", false, null)
                : translate("cAudienceEntriesTable.seeAddnlEntries", false, null)
            }
            disabled={currPage + 1 === maxPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      )
    );
  };

  const clickSortBy = (key) => {
    if (key === sortByKey) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
        sortTableRows(key, "desc");
      } else {
        setSortDirection("asc");
        sortTableRows(key, "asc");
      }
    } else {
      setSortByKey(key);
      setSortDirection("desc");
      sortTableRows(key, "desc");
    }
  };

  const sortTableRows = (key, dir) => {
    // TODO: Test this. This somehow doesn't work
    console.log("sortTableRows // tableHeaders:", tableHeaders, "key:", key, "dir:", dir);
    let dataType = tableHeaders.filter((header) => header.key === key)[0].dataType || "string";
    let sorted = tableRows.sort((a, b) => {
      if (dataType === "string") {
        if (dir === "desc") {
          return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
        } else {
          return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
        }
      }
      if (dataType === "number") {
        if (dir === "desc") {
          return parseFloat(a[key]) < parseFloat(b[key]) ? -1 : parseFloat(a[key]) > parseFloat(b[key]) ? 1 : 0;
        } else {
          return parseFloat(a[key]) > parseFloat(b[key]) ? -1 : parseFloat(a[key]) < parseFloat(b[key]) ? 1 : 0;
        }
      }
      if (dataType === "date") {
        if (dir === "desc") {
          return new Date(a[key]) < new Date(b[key]) ? -1 : new Date(a[key]) > new Date(b[key]) ? 1 : 0;
        } else {
          return new Date(a[key]) > new Date(b[key]) ? -1 : new Date(a[key]) < new Date(b[key]) ? 1 : 0;
        }
      }
      return 0;
    });
    setTableRows(sorted);
    setCurrPage(0);
    setRowsToRender(sorted.slice(0, PER_PAGE));
  };

  const onChangeStringFilter = (val) => {
    if (filterSelectedStringValues.includes(val)) {
      setFilterSelectedStringValues((prev) => prev.filter((item) => item !== val));
    } else {
      setFilterSelectedStringValues((prev) => [...prev, val]);
    }
  };

  const clickAddFilter = () => {
    if ((tableHeaders.filter((header) => header.key === newFilterBy)[0] || { dataType: "string" }).dataType === "string") {
      // Strings
      let addFilters = filterSelectedStringValues.map((val) => ({
        key: newFilterBy,
        val,
        op: "=",
        text: `${capitalizeFirstLetter(newFilterBy)}: ${val}`,
      }));
      setActiveFilters((prev) => [...prev.filter((filter) => filter.key !== newFilterBy), ...addFilters]);
    } else {
      // Numbers/dates
      let dataType = tableHeaders.filter((header) => header.key === newFilterBy)[0].dataType;
      let val = dataType === "number" ? parseFloat(newFilterValNumber) : new Date(newFilterValDate);
      let addFilter = {
        key: newFilterBy,
        val,
        op: newFilterNumberDateOp,
        text: `${capitalizeFirstLetter(newFilterBy)} ${newFilterNumberDateOp} ${
          dataType === "number" ? val : formatDate(val, user.language, "dmyy hm")
        }`,
      };
      setActiveFilters((prev) => [...prev, addFilter]);
    }
  };

  const clickRemoveFilter = (filterObj) => {
    setActiveFilters((prev) => prev.filter((filter) => filter.text !== filterObj.text));
  };

  const groupFiltersByKey = () => {
    // Group activeFilters by key
    let activeFiltersByKey = {};
    let filteredKeys = [...new Set(activeFilters.map((activeFilter) => activeFilter.key))];
    filteredKeys.forEach((key) => {
      key === KEY_EMAIL && (key = originalEmailKey);
      activeFiltersByKey = { ...activeFiltersByKey, [key]: [] };
    });
    activeFilters.forEach((activeFilter) => {
      // activeFilters = [ { key, val, op, text } ] with op = "=", ">", ">=", "<" or "<="
      activeFiltersByKey[activeFilter.key].push(activeFilter);
    });
    setActiveFiltersByKey(activeFiltersByKey);
  };

  const refilterEntries = () => {
    setFilteredEntries(allEntries === null ? [] : allEntries.filter((entry) => entryPassesFilter(entry)));
  };

  const entryPassesFilter = (entry) => {
    // entry = { KEY_EMAIL: val, key: val, ... }
    // activeFiltersByKey = { key: [ { key, val, op, text } ], ... }  with op = "=", ">", ">=", "<" or "<="

    // Loop through each activeKeyFilter
    // Group filters by operation
    // entry passes the filter if:
    // 1) no filter is set on a key (catch by setting default pass=true)
    // 2) if a filter is set on a key and entry's key's value passes the op (find through the forof loop)
    //    (i.e., test for when it doesn't pass and then set pass=false)

    let pass = true;
    if (activeFiltersByKey !== null) {
      for (const key of Object.keys(activeFiltersByKey)) {
        const dataType = (tableHeaders.filter((header) => header.key === key)[0] || { dataType: "string" }).dataType;
        const filters = activeFiltersByKey[key];
        const entryVal = dataType === "number" ? parseFloat(entry[key]) : dataType === "date" ? new Date(entry[key]).getTime() : entry[key];

        // op "="
        let opFilters = filters.filter((filter) => filter.op === "=").map((filter) => filter.val);
        if (opFilters.length > 0 && !opFilters.includes(entryVal)) {
          pass = false;
          break;
        }

        // op ">"
        opFilters = filters
          .filter((filter) => filter.op === ">")
          .map((filter) => (dataType === "number" ? parseFloat(filter.val) : new Date(filter.val).getTime()));
        if (opFilters.length > 0 && entryVal <= Math.min(opFilters)) {
          pass = false;
          break;
        }

        // op ">="
        opFilters = filters
          .filter((filter) => filter.op === ">=")
          .map((filter) => (dataType === "number" ? parseFloat(filter.val) : new Date(filter.val).getTime()));
        if (opFilters.length > 0 && entryVal < Math.min(opFilters)) {
          pass = false;
          break;
        }

        // op "<"
        opFilters = filters
          .filter((filter) => filter.op === "<")
          .map((filter) => (dataType === "number" ? parseFloat(filter.val) : new Date(filter.val).getTime()));
        if (opFilters.length > 0 && entryVal >= Math.max(opFilters)) {
          pass = false;
          break;
        }

        // op "<="
        opFilters = filters
          .filter((filter) => filter.op === "<=")
          .map((filter) => (dataType === "number" ? parseFloat(filter.val) : new Date(filter.val).getTime()));
        if (opFilters.length > 0 && entryVal > Math.max(opFilters)) {
          pass = false;
          break;
        }
      }
    }
    return pass;
  };

  const FilterComponent = () => {
    return (
      <>
        <h5 className="text-secondary mb-2">{translate("cAudienceEntriesTable.setNewFilter", false, null)}</h5>

        <div className="row row-cols-lg-auto g-3 align-items-center mb-4">
          <div className="col-12">
            <p className="m-0 fontSize09">{translate("cAudienceEntriesTable.filterByVar", false, null)}</p>
          </div>
          <div className="col-12">
            <select className="form-select form-select-sm" value={newFilterBy} onChange={(e) => setNewFilterBy(e.target.value)}>
              <option value="">{translate("cAudienceEntriesTable.selectVar", false, null)}</option>
              <option value={KEY_EMAIL}>{translate("cAudienceEntriesTable.emailAddress", false, null)}</option>
              {tableHeaders.map((header) => (
                <option key={header.key} value={header.key}>
                  {capitalizeFirstLetter(header.key)}
                </option>
              ))}
            </select>
          </div>
          {(tableHeaders.filter((header) => header.key === newFilterBy)[0] || { dataType: "string" }).dataType === "string" ? (
            <div className="col-12">
              <div className="dropdown">
                <button
                  className="btn btn-outline-primary btn-sm dropdown-toggle"
                  type="button"
                  id="filterAudienceByStringValue"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {translate("cAudienceEntriesTable.selectValues", false, null)}
                </button>
                <ul className="dropdown-menu fontSize08 py-0" aria-labelledby="filterAudienceByStringValue">
                  {uniqueStringValues === null ||
                  typeof uniqueStringValues[newFilterBy] === "undefined" ||
                  uniqueStringValues[newFilterBy].length === 0 ? (
                    <p className="m-2">{translate("cAudienceEntriesTable.noValuesFound", false, null)}</p>
                  ) : (
                    uniqueStringValues[newFilterBy].map((val) => (
                      <li key={val}>
                        <div className="dropdown-item">
                          <div className="form-check" style={{ minHeight: "1rem", paddingLeft: "1rem" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={filterSelectedStringValues.includes(val)}
                              onChange={() => onChangeStringFilter(val)}
                              id={`filter_id_${val}`}
                            />
                            <label className="form-check-label" htmlFor={`filter_id_${val}`}>
                              {val}
                            </label>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          ) : (tableHeaders.filter((header) => header.key === newFilterBy)[0] || { dataType: "string" }).dataType === "number" ? (
            <>
              <div className="col-12">
                <select
                  className="form-select form-select-sm"
                  value={newFilterNumberDateOp}
                  onChange={(e) => setNewFilterNumberDateOp(e.target.value)}
                >
                  <option value="">Select operation</option>
                  <option value="=">Equals</option>
                  <option value=">">Greater than</option>
                  <option value=">=">Equal to or greater than</option>
                  <option value="<">Smaller than</option>
                  <option value="<=">Equal to or smaller than</option>
                </select>
              </div>
              <div className="col-12">
                <input
                  type="number"
                  className="form-control form-control-sm"
                  placeholder="number"
                  value={newFilterValNumber}
                  onChange={(e) => setNewFilterValNumber(e.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <select
                  className="form-select form-select-sm"
                  value={newFilterNumberDateOp}
                  onChange={(e) => setNewFilterNumberDateOp(e.target.value)}
                >
                  <option value="">Select operation</option>
                  <option value="=">Equals</option>
                  <option value=">">Greater than</option>
                  <option value=">=">Equal to or greater than</option>
                  <option value="<">Smaller than</option>
                  <option value="<=">Equal to or smaller than</option>
                </select>
              </div>
              <div className="col-12">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={newFilterValDate}
                  onChange={(e) => setNewFilterValDate(e.target.value)}
                />
              </div>
            </>
          )}
          <div className="col-12">
            <button className="btn btn-success btn-sm px-4" onClick={clickAddFilter}>
              Add filter
            </button>
          </div>
        </div>
      </>
    );
  };

  const ActiveFilters = () => {
    return (
      <>
        <h5 className="text-secondary m-0">{translate("cAudienceEntriesTable.activeFilters", false, null)}</h5>
        {activeFilters.length === 0 ? (
          <p className="mt-2 mb-2 text-italic fontSize08">{translate("cAudienceEntriesTable.noFiltersSet", false, null)}</p>
        ) : (
          <>
            {activeFilters.map((filter, i) => (
              <div
                key={i}
                className="d-inline-flex align-items-center px-2 py-1 fontSize08 border border-primary rounded-pill text-primary mt-2 me-2"
              >
                <p className="m-0 me-2">{filter.text}</p>
                <i
                  className="fa-regular fa-trash-can cursorPointer"
                  title={translate("cAudienceEntriesTable.removeFilter", false, null)}
                  onClick={() => clickRemoveFilter(filter)}
                ></i>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <FilterComponent />
      <ActiveFilters />
      {filteredEntries === null || filteredEntries.length === 0 ? (
        <p className="text-italic mt-3">{translate("cAudienceEntriesTable.noEntriesMatchingFilter", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-sm table-hover rounded-3 overflowHidden align-middle mt-3 fontSize09">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col" className="cursorPointer" onClick={() => clickSortBy(KEY_EMAIL)}>
                  {translate("cAudienceEntriesTable.emailAddress", false, null)}{" "}
                  {sortByKey === KEY_EMAIL && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                </th>
                {tableHeaders.map((header) => (
                  <th scope="col" className="cursorPointer" key={header.key} onClick={() => clickSortBy(header.key)}>
                    {capitalizeFirstLetter(header.key)}{" "}
                    {sortByKey === header.key && <i className={`ms-2 fa-solid fa-caret-${sortDirection === "asc" ? "up" : "down"}`} />}
                  </th>
                ))}
                {boolEditable && <th scope="col"> </th>}
              </tr>
            </thead>
            <tbody>
              {rowsToRender.map((entry) => (
                <EntryRow key={entry._id} entry={entry} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination />
    </>
  );
};

AudienceEntries.propTypes = {
  auth: PropTypes.object.isRequired,
  removeAudienceEntry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { removeAudienceEntry })(AudienceEntries);
