export const cUserWorkspaces = {
  needsAtleastOneAdmin: {
    en: "There needs to be at least one admin remaining in the workspace",
    nl: "Er moet minimaal een admin van de workspace blijven",
  },
  areYouSureLeave: {
    en: `Are you sure you want to leave workspace "%wsName%"?`,
    nl: `Weet u zeker dat u workspace "%wsName%" wilt verlaten?`,
  },
  leavingWs: {
    en: "Leaving workspace..",
    nl: "Verlaten workspace..",
  },
  areYouSureDelete: {
    en: `Are you sure you want to delete workspace "%wsName%""? This will cancel all workspace's subscriptions at the end of their validity and deletes related data.`,
    nl: `Weet u zeker dat u workspace "%wsName%" wilt verwijderen? Dit annuleert alle abonnementen van de workspace wanneer ze aflopen en verwijdert alle gerelateerde data.`,
  },
  deletingWs: {
    en: "Deleting workspace..",
    nl: "Verwijderen workspace..",
  },
  youreAdmin: {
    en: "You're an admin",
    nl: "U bent een admin",
  },
  youreMember: {
    en: "You're a member",
    nl: "U bent een lid",
  },
  changeName: {
    en: "Change name",
    nl: "Verander naam",
  },
  leaveWs: {
    en: "Leave workspace",
    nl: "Verlaat workspace",
  },
  deleteWs: {
    en: "Delete workspace",
    nl: "Verwijder workspace",
  },
  yourWs: {
    en: "Your workspaces",
    nl: "Uw workspaces",
  },
  notAMemberOfAnyWs: {
    en: "You're not a member of any workspace.",
    nl: "U bent nog geen lid van een workspace.",
  },
  workspace: {
    en: "Workspace",
    nl: "Workspace",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
  provideWsName: {
    en: "Please provide a name for your workspace.",
    nl: "Geef aub een naam op voor uw workspace",
  },
  nameAlreadyExists: {
    en: "You already are a member of a workspace with the same name. Please pick another name.",
    nl: "U bent al een lid van een workspace met dezelfde naam. Kies aub een andere.",
  },
  createNewWs: {
    en: "Create new workspace",
    nl: "Maak nieuwe workspace",
  },
  wsName: {
    en: "Workspace name",
    nl: "Workspace naam",
  },
};
