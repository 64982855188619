import { v4 as uuidv4 } from "uuid";

// Bring in action types
import { SET_LOADING } from "./types";
import { REMOVE_LOADING } from "./types";

export const setGlobalLoading = (msg) => (dispatch) => {
  const id = uuidv4();
  dispatch({
    type: SET_LOADING,
    payload: { id, msg },
  });
  return id;
};

export const removeGlobalLoading = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_LOADING,
    payload: id,
  });
};
