export const getStripeElementsOptions = (clientSecret, ws, user) => {
  // Customize payment element: https://docs.stripe.com/payments/payment-element#options
  // Appearance API: https://docs.stripe.com/elements/appearance-api
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "rgb(229, 42, 5)",
    },
    rules: {
      ".AccordionItem:hover": {
        color: "rgb(229, 42, 5)",
        transition: "300ms",
      },
    },
  };
  const layout = {
    type: "accordion",
    defaultCollapsed: false,
    radios: false,
    spacedAccordionItems: true,
  };
  const fields = {
    // Need to submit details that user providers during registration
    // See https://docs.stripe.com/js/elements_object/create_payment_element#payment_element_create-options-fields & https://docs.stripe.com/api/payment_methods/object
    billingDetails: "auto",
  };
  const billingDetails = {
    address: {
      city: ws === null ? "" : ws.location.city,
      country: ws === null ? "" : ws.location.countryCode.toUpperCase(),
      line1: ws === null ? "" : ws.location.street,
      line2: null,
      postal_code: ws === null ? "" : ws.location.postal,
      state: null,
    },
    email: ws === null ? "" : ws.email,
    name: user === null ? "" : `${user.firstName} ${user.lastName}`,
    phone: user === null ? "" : user.phoneNumber.replace(".", ""),
  };
  return {
    clientSecret,
    appearance,
    layout,
    fields,
    defaultValues: {
      billingDetails,
    },
  };
};

// Payment methods: https://docs.stripe.com/api/payment_methods/object#payment_method_object-type
export const STRIPE_PAYMENT_METHODS = {
  acss_debit: "Automated Clearing Settlement System",
  affirm: "Affirm",
  afterpay_clearpay: "Afterpay / Clearpay",
  alipay: "Alipay",
  au_becs_debit: "BECS Direct Debit",
  bacs_debit: "Bacs Direct Debit",
  bancontact: "Bancontact",
  blik: "BLIK",
  boleto: "Boleto",
  card: "Credit card",
  card_present: "Credit card",
  cashapp: "Cash App Pay",
  customer_balance: "Customer's cash balance",
  eps: "EPS",
  fpx: "FPX",
  giropay: "Giropay",
  grabpay: "GrabPay",
  ideal: "iDEAL",
  interac_present: "Interac debit card",
  klarna: "Klarna",
  konbini: "Konbini",
  link: "Link",
  oxxo: "OXXO",
  p24: "Przelewy24",
  paynow: "PayNow",
  paypal: "PayPal",
  pix: "Pix",
  promptpay: "PromptPay",
  revolut_pay: "Revolut Pay",
  sepa_debit: "SEPA Direct Debit",
  sofort: "Sofort",
  swish: "Swish",
  us_bank_account: "ACH Direct Debit",
  wechat_pay: "WeChat Pay",
  zip: "Zip",
};
