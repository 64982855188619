import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { cancelEmailCampaign } from "../../../../actions/emailMarketing";
import FormatDate from "../../../layout/FormatDate";

import { translate } from "../../../../translations/translations";

const PendingEmailCampaigns = ({ auth: { user }, emailMarketing: { pendingEmailCampaigns }, cancelEmailCampaign }) => {
  return (
    pendingEmailCampaigns.length > 0 && (
      <>
        <h2 className="text-secondary mt-5 mb-3">{translate("cPendingEmailCampaigns.pendingEmails", false, null)}</h2>
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col">{translate("cPendingEmailCampaigns.campaignName", false, null)}</th>
                <th scope="col">{translate("cPendingEmailCampaigns.subject", false, null)}</th>
                <th scope="col">{translate("cPendingEmailCampaigns.scheduledFor", false, null)}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {pendingEmailCampaigns.map((campaign) => (
                <tr key={campaign._id}>
                  <td>{campaign.campaignName}</td>
                  <td>{campaign.emailSubject}</td>
                  <td>
                    {campaign.campaignType === "reactive" ? (
                      <span className="py-1 px-2 bg-primary-light text-primary text-bold rounded-3 border border-primary fontSize08 text-italic">
                        {translate("cPendingEmailCampaigns.reactive", false, null)}
                      </span>
                    ) : (
                      <FormatDate dateString={campaign.scheduledAt} lang={user.language} formatStr={"dmmyy hm"} />
                    )}
                  </td>
                  <td>
                    {campaign.campaignType !== "reactive" && (
                      <span
                        className="text-danger cursorPointer"
                        title={translate("cPendingEmailCampaigns.cancel", false, null)}
                        onClick={() => cancelEmailCampaign(campaign._id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  );
};

PendingEmailCampaigns.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  cancelEmailCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, { cancelEmailCampaign })(PendingEmailCampaigns);
