import React from "react";

import ProductCard from "../../../../components/layout/ProductCard";
import PendingEmailCampaigns from "../../../../components/products/email-marketing/manage/PendingEmailCampaigns";

import { translate } from "../../../../translations/translations";

const ManageEmailMarketing = () => {
  return (
    <>
      <h1 className="text-primary">{translate("pEmManageEm.emailMarketing", false, null)}</h1>
      <PendingEmailCampaigns />
      <h2 className="text-secondary mt-5 mb-3">{translate("pEmManageEm.manageEm", false, null)}</h2>
      <div className="row">
        {/* <ProductCard
          productTitle={translate("pEmManageEm.validatedEmailAddresses", false, null)}
          iconClassname={"fa-solid fa-at"}
          productLinkTo={"/email-marketing/manage/validated-emails"}
          productLinkText={translate("pEmManageEm.manageValidatedEmailAddresses", false, null)}
        /> */}
        <ProductCard
          productTitle={translate("pEmManageEm.launchNewCampaign", false, null)}
          iconClassname={"fa-regular fa-paper-plane"}
          productLinkTo={"/email-marketing/manage/campaigns/new"}
          productLinkText={translate("pEmManageEm.launchNewCampaign", false, null)}
        />
        <ProductCard
          productTitle={translate("pEmManageEm.campaignStats", false, null)}
          iconClassname={"fa-solid fa-chart-pie"}
          productLinkTo={"/email-marketing/manage/campaigns/stats"}
          productLinkText={translate("pEmManageEm.reviewCampaignStats", false, null)}
        />
        <ProductCard
          productTitle={translate("pEmManageEm.emailBuilder", false, null)}
          iconClassname={"fa-solid fa-screwdriver-wrench"}
          productLinkTo={"/email-marketing/manage/builder"}
          productLinkText={translate("pEmManageEm.manageEb", false, null)}
        />
      </div>
    </>
  );
};

export default ManageEmailMarketing;
