import React from "react";
import { Link } from "react-router-dom";

import { translate } from "../translations/translations";

const NotFound = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-primary mb-3">
        <i className="fas fa-exclamation-triangle me-2" />
        {translate("pNotfound.pageNotFound", false, null)}
      </h2>
      <p className="lead">
        {translate("pNotfound.pageNotExist", false, null)} <Link to="/">{translate("pNotfound.goBack", false, null)}</Link>
      </p>
    </div>
  );
};

export default NotFound;
