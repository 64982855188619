import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { translate } from "../../../../translations/translations";

const SbSeeContactForms = ({ auth: { activeWorkspace } }) => {
  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cSitebuilder.seeMessagesSites", false, null)}</h2>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("cSitebuilder.noWsSelected", false, null)}</p>
      ) : activeWorkspace.contactForms.length === 0 ? (
        <p className="text-italic m-0">{translate("cSitebuilder.noContactForms", false, null)}</p>
      ) : (
        <div className="mt-3">
          <Link className="btn btn-primary" to={`/contact-form/messages`}>
            {translate("cSitebuilder.clickToSeeMessages", false, null)}
          </Link>
        </div>
      )}
    </>
  );
};

SbSeeContactForms.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(SbSeeContactForms);
