export const cHostingAllSubs = {
  weOfferFullyIntegratedHosting: {
    en: "We offer a fully integrated web hosting infrastructure solution so you can focus on building your online presence!",
    nl: "Wij bieden een volledig geintegreerde hosting oplossing zodat dat u zich kunt concentreren om uw online aanwezigheid te bouwen!",
  },
  readMore: {
    en: "Read more.",
    nl: "Lees meer.",
  },
};
