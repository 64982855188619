export const cNavigation = {
  exploreAddnlServices: {
    en: "Explore additional services",
    nl: "Bekijk aanvullende services",
  },
  seeAll: {
    en: "See all",
    nl: "Bekijk alle",
  },
  manageActiveWs: {
    en: "Manage active workspace",
    nl: "Beheer actieve workspace",
  },
  members: {
    en: "Members",
    nl: "Leden",
  },
  subscriptions: {
    en: "Subscriptions",
    nl: "Abonnementen",
  },
  billingOverview: {
    en: "Billing overview",
    nl: "Facturering",
  },
  referrals: {
    en: "Referrals",
    nl: "Verwijzingen",
  },
  notifications: {
    en: "Notifications",
    nl: "Berichten",
  },
  adminInfo: {
    en: "Administrative",
    nl: "Administratie",
  },
  wsServices: {
    en: "Workspace services",
    nl: "Workspace services",
  },
  backToTop: {
    en: "Back to top",
    nl: "Omhoog",
  },
  toggleSidebar: {
    en: "See menu",
    nl: "Bekijk menu",
  },
  goBack: {
    en: "Go back",
    nl: "Terug",
  },
  selectWs: {
    en: "Select workspace",
    nl: "Selecteer workspace",
  },
  openWebmail: {
    en: "Open webmail",
    nl: "Open webmail",
  },
  seeCart: {
    en: "See your shopping cart",
    nl: "Bekijk winkelwagentje",
  },
  seeNotifications: {
    en: "See your notifications",
    nl: "Bekijk uw berichten",
  },
  youHaveNewNotifcations: {
    en: "You have %newNotifications% new notifications",
    nl: "U heeft %newNotifications% nieuwe berichten",
  },
  welcome: {
    en: "Welcome",
    nl: "Welkom",
  },
  userProfile: {
    en: "User profile",
    nl: "Profiel",
  },
  yourProfile: {
    en: "Your profile",
    nl: "Uw profiel",
  },
  logout: {
    en: "Logout",
    nl: "Uitloggen",
  },
  selectLanguage: {
    en: "Select language",
    nl: "Selecteer taal",
  },
  home: {
    en: "Home",
    nl: "Home",
  },
  yourAccount: {
    en: "Your account",
    nl: "Uw account",
  },
  profile: {
    en: "Profile",
    nl: "Profiel",
  },
  yourWs: {
    en: "Your workspaces",
    nl: "Uw workspaces",
  },
  support: {
    en: "Support",
    nl: "Helpdesk",
  },
  provideFeedback: {
    en: "Provide feedback",
    nl: "Geef feedback",
  },
};
