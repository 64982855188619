import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { setGlobalLoading, removeGlobalLoading } from "../../actions/globalLoading";
import { setAlert } from "../../actions/alert";

import { POST_CONFIG, API_URL } from "../../lib/GeneralVars";
import { isValidEmailAddress } from "../../components/products/hosting/lib/emailFunctions";
import { getCurrency } from "../../lib/Currencies";
import FormatNumber from "../../components/layout/FormatNumber";

import { translate } from "../../translations/translations";

const Referrals = ({ auth: { user, activeWorkspace }, setGlobalLoading, removeGlobalLoading, setAlert }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [validEmailAddress, setValidEmailAddress] = useState(false);

  const refLink = () => {
    return `https://satonda.com/referral/${activeWorkspace.referralCode}`;
  };

  const typeEmail = (str) => {
    setEmailAddress(str);
    setValidEmailAddress(isValidEmailAddress(str));
  };

  const clickSendInvite = async () => {
    if (isValidEmailAddress(emailAddress)) {
      let loadingId = setGlobalLoading(translate("pWsReferrals.sendingInvite", false, null));
      try {
        const body = JSON.stringify({
          recipientEmail: emailAddress,
          referralCode: activeWorkspace.referralCode,
          referralLink: refLink(),
          userName: `${user.firstName} ${user.lastName}`,
        });
        const res = await axios.post(`${API_URL}/workspace/inviteReferral`, body, POST_CONFIG);
        if (res.status === 202) {
          setAlert(translate("pWsReferrals.userAlreadyExists", false, null), "danger");
        } else {
          setAlert(translate("pWsReferrals.userHasBeenInvited", false, null), "success");
        }
      } catch (error) {
        console.error(error);
        setAlert(translate("common.serverError", false, null), "danger");
      }
      removeGlobalLoading(loadingId);
      // Reset state vars
      setEmailAddress("");
      setValidEmailAddress(false);
    }
  };

  return (
    <>
      <h1 className="text-primary">{translate("pWsReferrals.referrals", false, null)}</h1>
      {user === null ? (
        <p className="text-italic m-0">{translate("common.userNotFound", false, null)}</p>
      ) : activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-6">
            {/* Refer new user */}
            <h2 className="text-secondary mt-5">{translate("pWsReferrals.referNewUser", false, null)}</h2>
            <p className="mt-3 mb-2">{translate("pWsReferrals.earnCredits", false, null)}</p>
            <p className="mb-2">{translate("pWsReferrals.shareRefCode", false, null)}</p>
            <p className="mb-3">
              {translate("pWsReferrals.yourRefCode", false, null)} <span className="text-bold text-italic">{activeWorkspace.referralCode}</span>{" "}
              {translate("pWsReferrals.yourRefLink", false, null)} <span className="text-bold text-italic">{refLink()}</span>.
            </p>
            <p className="mb-2">{translate("pWsReferrals.inviteNewUser", false, null)}:</p>
            <div className="input-group mb-3">
              <input
                type="email"
                className={`form-control${emailAddress === "" || validEmailAddress ? "" : " is-invalid"}`}
                placeholder={translate("pWsReferrals.recipientEmailAddress", false, null)}
                value={emailAddress}
                onChange={(e) => typeEmail(e.target.value)}
              />
              <button className="btn btn-outline-primary" disabled={!validEmailAddress} onClick={clickSendInvite}>
                {translate("pWsReferrals.sendInvite", false, null)}
              </button>
              {emailAddress !== "" && <div className="invalid-feedback">{translate("pWsReferrals.provideValidEmail", false, null)}</div>}
            </div>

            {/* Referral credit balance */}
            <h2 className="text-secondary mt-5">{translate("pWsReferrals.yourRefCredit", false, null)}</h2>
            <p className="mt-3 mb-2">
              {translate("pWsReferrals.currentBalance", false, null)}{" "}
              <span className="text-bold text-success">
                {getCurrency(activeWorkspace.location.priceCountry).currencySign}
                <FormatNumber number={activeWorkspace.referralCredit} formatStr={"0.00"} />
              </span>
              .
            </p>
            <p className="m-0">{translate("pWsReferrals.willBeCredited", false, null)}</p>
          </div>
        </div>
      )}
    </>
  );
};

Referrals.propTypes = {
  auth: PropTypes.object.isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setGlobalLoading, removeGlobalLoading, setAlert })(Referrals);
