import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { cancelAccount } from "../../../actions/auth";

import { translate } from "../../../translations/translations";

const CancelAccount = ({ cancelAccount }) => {
  const clickCancelAccount = () => {
    // Make alert to confirm
    let confirm = window.confirm(translate("cUserProfile.areYouSureDeleteAccount", false, null));
    if (confirm) {
      cancelAccount();
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-0">{translate("cUserProfile.cancelAccount", false, null)}</h2>
      <button className="btn btn-primary w-100 mt-3" onClick={clickCancelAccount}>
        {translate("cUserProfile.permanentlyCancelAccount", false, null)}
      </button>
    </>
  );
};

CancelAccount.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { cancelAccount })(CancelAccount);
