export const pEmManageEm = {
  emailMarketing: {
    en: "Email marketing",
    nl: "Email marketing",
  },
  manageEm: {
    en: "Manage your email marketing services",
    nl: "Beheer uw email marketing services",
  },
  validatedEmailAddresses: {
    en: "Validated email addresses",
    nl: "Gevalideerde emailadressen",
  },
  manageValidatedEmailAddresses: {
    en: "Manage validated email addresses",
    nl: "Beheer emailadressen",
  },
  titleManageValidatedEmailAddresses: {
    en: "Manage validated email addresses",
    nl: "Beheer gevalideerde emailadressen",
  },
  launchNewCampaign: {
    en: "Launch new marketing campaign",
    nl: "Start nieuwe marketing campagne",
  },
  campaignStats: {
    en: "Campaign statistics",
    nl: "Campagne statistieken",
  },
  reviewCampaignStats: {
    en: "Review campaign statistics",
    nl: "Bekijk campagne statistieken",
  },
  emailBuilder: {
    en: "Email builder",
    nl: "Email builder",
  },
  manageEb: {
    en: "Manage email builder projects",
    nl: "Beheer email builder projecten",
  },
};
