import axios from "axios";

import { ADD_HOSTED_DOMAIN_WEB } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { translate } from "../translations/translations";

// =================
// == Web hosting ==
// =================

export const createPleskCustomerSubscription = (wsId, domainName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aHosting.addingFirstHostingDomain", false, null)));
  try {
    const body = JSON.stringify({ domainName });
    await axios.post(`${API_URL}/webHosting/createPleskCustomerSubscription`, body, POST_CONFIG);
    dispatch({
      type: ADD_HOSTED_DOMAIN_WEB,
      payload: { wsId, domainName },
    });
    dispatch(setAlert(translate("aHosting.hostingDomainAdded", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aHosting.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const updatePleskCustomerPw = (customerUsername, newPw) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aHosting.updatingAdminAccountPw", false, null)));
  try {
    const body = JSON.stringify({ customerUsername, newPw });
    await axios.post(`${API_URL}/webHosting/updatePleskCustomerPw`, body, POST_CONFIG);

    dispatch(setAlert(translate("aHosting.adminAccountPwUpdated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aHosting.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};
