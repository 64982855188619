import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FaRegGem, FaUserShield, FaRegThumbsUp, FaHandHoldingUsd } from "react-icons/fa";

import DomainChecker from "../../../../components/products/domains/DomainChecker";
import DomainSuggestions from "../../../../components/products/domains/DomainSuggestions";

import { translate } from "../../../../translations/translations";

const DomainsMain = ({ auth: { langCode } }) => {
  return (
    <>
      <section className="mt-5" id="header">
        <h1 className="text-primary mt-4 mb-4">{translate("pDomainsMain.whyUs", false, null)}</h1>
        <div className="row">
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaRegGem />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pDomainsMain.broadChoice", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pDomainsMain.broadChoiceTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaHandHoldingUsd />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pDomainsMain.price", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pDomainsMain.priceTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaRegThumbsUp />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pDomainsMain.easeOfUse", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pDomainsMain.easeOfUseTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon icon-satonda">
              <FaUserShield />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pDomainsMain.privacy", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pDomainsMain.privacyTag", false, null)}</p>
          </div>
        </div>
        <div className="mt-4">
          <a
            href={`https://www.satonda.com/${langCode}/products/domains`}
            className="btn btn-primary px-5 rounded-pill"
            target="_blank"
            rel="noreferrer"
          >
            {translate("pDomainsMain.readMore", false, null)}
          </a>
        </div>
      </section>
      <section className="mt-6" id="domainSuggestions">
        <DomainSuggestions />
      </section>
      <section className="mt-5 mb-6" id="domainChecker">
        <DomainChecker />
      </section>
    </>
  );
};

DomainsMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(DomainsMain);
