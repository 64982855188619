import { countries } from "./Countries";

export const getVatRate = (countryCode, customerType, vatNumberConfirmed, addnlLocationData = null) => {
  // customerType = "consumer" || "business"
  // vatNumberConfirmed = true || false depending on whether the EU VAT number has been confirmed or not
  // addnlLocationData = { ... } with data that is needed for the countryCode to find the right rate. E.g. US will need street/zip data
  try {
    return shouldChargeVat(countryCode, customerType, vatNumberConfirmed) ? getLocationVatRate(countryCode, addnlLocationData) : 0;
  } catch (error) {
    return 0;
  }
};

export const getLocationVatRate = (countryCode, addnlLocationData) => {
  return getCountryData(countryCode).taxRate;
};

export const shouldChargeVat = (countryCode, customerType, vatNumberConfirmed) => {
  // Charge tax or not:
  //
  //                  Customer type
  // Country          Consumer    Business
  // Netherlands      Yes         Yes
  // Rest of EU       Yes         vatNumberConfirmed ? No : Yes
  // Outside of EU    Yes         Yes
  if (
    customerType === "business" &&
    vatNumberConfirmed &&
    countryCode !== "nl" &&
    countries.filter((country) => country.code === countryCode && country.inEU)
  ) {
    return false;
  }
  return true;
};

export const getVatNameEn = (countryCode) => {
  try {
    return getCountryData(countryCode).taxNameEn;
  } catch (error) {
    return 0;
  }
};

export const getVatNameLocal = (countryCode) => {
  try {
    return getCountryData(countryCode).taxNameLocal;
  } catch (error) {
    return 0;
  }
};

export const getCountryData = (countryCode) => {
  let country = vatRates.filter((c) => c.countryCode === countryCode)[0];
  if (country === null || typeof country === "undefined") {
    throw new Error("Country not found");
  }
  return country;
};

export const isWsVatNumberConfirmed = (workspace) => {
  try {
    if (workspace.customerType === "business") {
      if (countries.filter((country) => country.code === workspace.location.countryCode && country.inEU).length > 0) {
        return workspace.businessVatNumber.EU.confirmed;
      }
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Rates: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm#shortcut-5%20European%20Commission
// Names: https://en.wikipedia.org/wiki/European_Union_value_added_tax
// addnlData: To be added for relevant jurisdictions
export const vatRates = [
  { countryCode: "at", euVatNumberCode: "AT", taxNameEn: "VAT", taxNameLocal: "MwSt", taxRate: 0.2, addnlData: null },
  { countryCode: "be", euVatNumberCode: "BE", taxNameEn: "VAT", taxNameLocal: "btw", taxRate: 0.21, addnlData: null },
  { countryCode: "bg", euVatNumberCode: "BG", taxNameEn: "VAT", taxNameLocal: "ДДС", taxRate: 0.2, addnlData: null },
  { countryCode: "cy", euVatNumberCode: "CY", taxNameEn: "VAT", taxNameLocal: "ΦΠΑ", taxRate: 0.19, addnlData: null },
  { countryCode: "cz", euVatNumberCode: "CZ", taxNameEn: "VAT", taxNameLocal: "DPH", taxRate: 0.21, addnlData: null },
  { countryCode: "dk", euVatNumberCode: "DK", taxNameEn: "VAT", taxNameLocal: "moms", taxRate: 0.25, addnlData: null },
  { countryCode: "ee", euVatNumberCode: "EE", taxNameEn: "VAT", taxNameLocal: "km", taxRate: 0.2, addnlData: null },
  { countryCode: "fi", euVatNumberCode: "FI", taxNameEn: "VAT", taxNameLocal: "ALV", taxRate: 0.24, addnlData: null },
  { countryCode: "fr", euVatNumberCode: "FR", taxNameEn: "VAT", taxNameLocal: "TVA", taxRate: 0.2, addnlData: null },
  { countryCode: "de", euVatNumberCode: "DE", taxNameEn: "VAT", taxNameLocal: "MwSt", taxRate: 0.19, addnlData: null },
  { countryCode: "gr", euVatNumberCode: "EL", taxNameEn: "VAT", taxNameLocal: "ΦΠΑ", taxRate: 0.24, addnlData: null },
  { countryCode: "hr", euVatNumberCode: "HR", taxNameEn: "VAT", taxNameLocal: "PDV", taxRate: 0.25, addnlData: null },
  { countryCode: "hu", euVatNumberCode: "HU", taxNameEn: "VAT", taxNameLocal: "ÁFA", taxRate: 0.27, addnlData: null },
  { countryCode: "ie", euVatNumberCode: "IE", taxNameEn: "VAT", taxNameLocal: "VAT", taxRate: 0.23, addnlData: null },
  { countryCode: "it", euVatNumberCode: "IT", taxNameEn: "VAT", taxNameLocal: "IVA", taxRate: 0.22, addnlData: null },
  { countryCode: "lv", euVatNumberCode: "LV", taxNameEn: "VAT", taxNameLocal: "PVN", taxRate: 0.21, addnlData: null },
  { countryCode: "lt", euVatNumberCode: "LT", taxNameEn: "VAT", taxNameLocal: "PVM", taxRate: 0.21, addnlData: null },
  { countryCode: "lu", euVatNumberCode: "LU", taxNameEn: "VAT", taxNameLocal: "TVA", taxRate: 0.17, addnlData: null },
  { countryCode: "mt", euVatNumberCode: "MT", taxNameEn: "VAT", taxNameLocal: "VAT", taxRate: 0.18, addnlData: null },
  { countryCode: "nl", euVatNumberCode: "NL", taxNameEn: "VAT", taxNameLocal: "btw", taxRate: 0.21, addnlData: null },
  { countryCode: "pl", euVatNumberCode: "PL", taxNameEn: "VAT", taxNameLocal: "PTU", taxRate: 0.23, addnlData: null },
  { countryCode: "pt", euVatNumberCode: "PT", taxNameEn: "VAT", taxNameLocal: "IVA", taxRate: 0.23, addnlData: null },
  { countryCode: "ro", euVatNumberCode: "RO", taxNameEn: "VAT", taxNameLocal: "TVA", taxRate: 0.19, addnlData: null },
  { countryCode: "sk", euVatNumberCode: "SK", taxNameEn: "VAT", taxNameLocal: "DPH", taxRate: 0.2, addnlData: null },
  { countryCode: "si", euVatNumberCode: "SI", taxNameEn: "VAT", taxNameLocal: "DDV", taxRate: 0.22, addnlData: null },
  { countryCode: "es", euVatNumberCode: "ES", taxNameEn: "VAT", taxNameLocal: "IVA", taxRate: 0.21, addnlData: null },
  { countryCode: "se", euVatNumberCode: "SE", taxNameEn: "VAT", taxNameLocal: "Moms", taxRate: 0.25, addnlData: null },
];
