import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Frame from "react-frame-component";

import FormatDate from "../../../layout/FormatDate";

import { translate } from "../../../../translations/translations";

const ModalRecipientEmail = ({ auth: { user }, campaign, selectedRecipient }) => {
  const iframeRef = useRef();

  const ShowEmail = () => {
    let emailHtml =
      campaign === null || selectedRecipient === null
        ? ""
        : campaign.emailContent.filter((v) => v.version === selectedRecipient.emailVersionToReceive)[0].html.replace(/\n/g, "");

    let customCss = [...emailHtml.matchAll(/<style.*?>(.+?)<\/style>/g)].map((tag) => tag[1]).filter((tag) => tag.match(/:root/) !== null)[0];
    let fontUrls = [...emailHtml.matchAll(/link href="(.+?)"/g)].map((fontUrl) => `@import url("${fontUrl[1]}");`).join(" ");
    let body = (emailHtml.match(/<body.*?>(.+?)<\/body>/ms) || ["", ""])[1];
    if (selectedRecipient !== null) {
      selectedRecipient.dynamicContentData.forEach((dynContent) => {
        body = body.replaceAll(dynContent.dynamicVar, dynContent.value);
      });
    }

    return campaign === null || selectedRecipient === null ? (
      <p className="mb-2 text-italic">{translate("cModalRecipientEmail.recipientNotFound", false, null)}</p>
    ) : (
      <Frame
        id="iframe_ModalRecipientEmail"
        style={{ width: "100%", height: "100%", border: "none" }}
        head={<style>{fontUrls + customCss}</style>}
        // onClick={(e) => onIframeClick(e)}
        ref={iframeRef}
        // contentDidMount={iframeLoad}
      >
        <>
          <p style={{ marginTop: "0", marginBottom: "0.25rem", fontSize: "0.9rem" }}>
            <span style={{ fontWeight: "bold" }}>{translate("cModalRecipientEmail.from", false, null)}: </span>
            {campaign.senderName} &lt;{campaign.senderEmail}&gt;
          </p>
          <p style={{ marginTop: "0", marginBottom: "0.25rem", fontSize: "0.9rem" }}>
            <span style={{ fontWeight: "bold" }}>{translate("cModalRecipientEmail.to", false, null)}: </span>
            {selectedRecipient.emailAddress}
          </p>
          <p style={{ marginTop: "0", marginBottom: "0.25rem", fontSize: "0.9rem" }}>
            <span style={{ fontWeight: "bold" }}>{translate("cModalRecipientEmail.sent", false, null)}: </span>
            <FormatDate dateString={campaign.scheduledAt} lang={user.language} formatStr={"dmmyy hm"} />
          </p>
          <p style={{ marginTop: "0", marginBottom: "0.25rem", fontSize: "0.9rem" }}>
            <span style={{ fontWeight: "bold" }}>{translate("cModalRecipientEmail.subject", false, null)}: </span>
            {campaign.emailSubject}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          ></div>
        </>
      </Frame>
    );
  };

  const clickPrintToPdf = () => {
    iframeRef.current.contentWindow.print();
  };

  return (
    <div
      className="modal fade"
      id={`ModalRecipientEmail`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`ModalRecipientEmailTitle`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content" style={{ height: "90vh" }}>
          <div className="modal-header">
            <h5 className="modal-title text-dark" id={`ModalRecipientEmailTitle`}>
              {translate("cModalRecipientEmail.emailSentTo", false, null)} {selectedRecipient === null ? "" : selectedRecipient.emailAddress}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ShowEmail />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-gray" data-bs-dismiss="modal">
              {translate("cModalRecipientEmail.cancel", false, null)}
            </button>
            <button type="button" className="btn btn-success" onClick={clickPrintToPdf}>
              {translate("cModalRecipientEmail.printToPdf", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalRecipientEmail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ModalRecipientEmail);
