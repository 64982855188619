import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Tooltip from "../../../layout/Tooltip";
import { sbRetractSite } from "../../../../actions/sitebuilder";

import { translate } from "../../../../translations/translations";

const SbMenuPublish = ({ auth: { user, activeWorkspace }, sitebuilder: { sbProjects }, sbRetractSite }) => {
  let { projectId } = useParams();
  const navigate = useNavigate();

  const [sbProject, setSbProject] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedDir, setSelectedDir] = useState("/");

  useEffect(() => {
    if (user !== null && projectId !== "") {
      setSbProject(sbProjects.filter((project) => project._id === projectId)[0] || null);
    } else {
      setSbProject(null);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  const clickPublishSite = () => {
    if (selectedDomain !== "" && selectedDir !== "") {
      navigate(`/sitebuilder/manage/publish/${projectId}/${selectedDomain}/${window.btoa(selectedDir.replace(/[/]+$/, ""))}`, { replace: true });
    }
  };

  const onTypeDir = (val) => {
    setSelectedDir(
      `/${val
        .replace(/^[-_/]+/, "")
        .replace(/\s/g, "-")
        .replace(/[^A-Za-z0-9_\-/]/g, "")
        .replace(/[/]+/g, "/")
        .replace(/[-]+/g, "-")
        .replace(/[_]+/g, "_")
        .replace(/[-][_]/g, "-")
        .replace(/[_][-]/g, "_")
        .replace(/[-_][/]/, "/")
        .replace(/[/][-_]/, "/")}`
    );
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">
        {sbProject === null || sbProject.status === "Development"
          ? translate("cSitebuilder.publishSite", false, null)
          : translate("cSitebuilder.retractSite", false, null)}
      </h2>
      {sbProject === null ? (
        <p className="text-italic m-0">{translate("cSitebuilder.projectNotFound", false, null)}</p>
      ) : sbProject.status === "Development" ? (
        <>
          <div className="row align-items-center mb-2">
            <div className="col-4">{translate("cSitebuilder.domain", false, null)}:</div>
            <div className="col-8">
              <select className="form-select" value={selectedDomain} onChange={(e) => setSelectedDomain(e.target.value)}>
                <option value="">{translate("cSitebuilder.selectDomain", false, null)}</option>
                {activeWorkspace.hosting.hostedDomains.map((domain) => (
                  <option key={domain} value={domain}>
                    {domain}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row align-items-center mb-2">
            <div className="col-4">
              {translate("cSitebuilder.directory", false, null)}:
              <Tooltip text={translate("cSitebuilder.directoryExplainer", false, null)} />
            </div>
            <div className="col-8">
              <input
                type="text"
                className={`form-control`}
                placeholder={translate("cSitebuilder.directoryToPublishOn", false, null)}
                value={selectedDir}
                onChange={(e) => onTypeDir(e.target.value)}
              />
            </div>
          </div>
          <button className="btn btn-outline-primary" disabled={selectedDomain === "" || selectedDir === ""} onClick={clickPublishSite}>
            {translate("cSitebuilder.publishSite", false, null)}
          </button>
        </>
      ) : (
        <>
          <p className="mb-3">
            {translate("cSitebuilder.siteHasBeenPublishedOn", false, null)} <span className="text-italic">{sbProject.publishedOnDomain}</span>.
          </p>
          <button className="btn btn-primary" onClick={() => sbRetractSite(sbProject)}>
            {translate("cSitebuilder.retractSite", false, null)}
          </button>
        </>
      )}
    </>
  );
};

SbMenuPublish.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
  sbRetractSite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, { sbRetractSite })(SbMenuPublish);
