import React from "react";
import { Link } from "react-router-dom";

import YourServices from "./sidebar/YourServices";
import AdditionalServices from "./sidebar/AdditionalServices";
import Workspace from "./sidebar/Workspace";

import { translate } from "../../translations/translations";

const Sidebar = () => {
  return (
    <>
      <ul className="list-unstyled ps-0">
        {/* Home */}
        <li className="mb-1" data-bs-dismiss="offcanvas">
          <Link to="/" className="btn btn-toggle align-items-center rounded">
            {translate("cNavigation.home", false, null)}
          </Link>
        </li>
        {/* Your account */}
        <li className="mb-1">
          <button
            className="btn btn-toggle align-items-center rounded collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#YourAccount-collapse"
            aria-expanded="false"
          >
            {translate("cNavigation.yourAccount", false, null)}
          </button>
          <div className="collapse" id="YourAccount-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li data-bs-dismiss="offcanvas">
                <Link to="/user/profile" className="link-dark rounded">
                  {translate("cNavigation.profile", false, null)}
                </Link>
              </li>
              <li data-bs-dismiss="offcanvas">
                <Link to="/user/workspaces" className="link-dark rounded">
                  {translate("cNavigation.yourWs", false, null)}
                </Link>
              </li>
            </ul>
          </div>
        </li>
        {/* Workspace */}
        <Workspace />
        {/* Your services */}
        <YourServices />
        {/* Additional services */}
        <AdditionalServices />
        {/* Support */}
        <li className="mb-1" data-bs-dismiss="offcanvas">
          <Link to="/support" className="btn btn-toggle align-items-center rounded">
            {translate("cNavigation.support", false, null)}
          </Link>
        </li>
        {/* Feedback */}
        <li className="mb-1" data-bs-dismiss="offcanvas">
          <Link to="/feedback" className="btn btn-toggle align-items-center rounded">
            {translate("cNavigation.provideFeedback", false, null)}
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Sidebar;
