export const cMsgStats = {
  notDelivered: {
    en: "Not delivered",
    nl: "Niet aangekomen",
  },
  notOpened: {
    en: "Not opened",
    nl: "Niet geopend",
  },
  notInteracted: {
    en: "Not interacted",
    nl: "Geen interactie",
  },
  interacted: {
    en: "Interacted",
    nl: "Interactie",
  },
  unsubscribed: {
    en: "Unsubscribed",
    nl: "Afgemeld",
  },
  notDeliveredExt: {
    en: "Email not delivered",
    nl: "Email was niet aangekomen",
  },
  notOpenedExt: {
    en: "Email delivered but not opened",
    nl: "Email was ontvangen maar niet geopend",
  },
  notInteractedExt: {
    en: "Email opened but no link was clicked",
    nl: "Email was geopend maar geen link was geklikt",
  },
  interactedExt: {
    en: "Opened and clicked on a link",
    nl: "Geopend en op een link geklikt",
  },
  unsubscribedExt: {
    en: "Recipient opened but unsubscribed",
    nl: "Geopend maar ontvanger heeft zich afgemeld van de maillijst",
  },
};
