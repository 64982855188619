import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { subIsCancelled, sortByRenewDate } from "../../../../components/workspace/subscriptions/lib/subscriptionFunctions";
import { PRODUCT_NAMES } from "../../../../components/products/lib/ProductVars";
import FormatDate from "../../../../components/layout/FormatDate";
import { userIsWsAdmin } from "../../../../components/workspace/lib/wsFunctions";

import { translate } from "../../../../translations/translations";

const ManageDomains = ({ auth: { user, activeWorkspace }, cart: { cartItems } }) => {
  const [activeSubs, setActiveSubs] = useState([]);

  useEffect(() => {
    if (activeWorkspace !== null) {
      setActiveSubs(
        activeWorkspace.subscriptions.filter((sub) => sub.productName === PRODUCT_NAMES.domains && !subIsCancelled(sub)).sort(sortByRenewDate)
      );
    }
  }, [activeWorkspace]);

  const SubBillingDetails = ({ sub }) => {
    return (
      <>
        <p className="m-0">
          {translate("pDomainsManageDomains.registeredUntil", false, null)}{" "}
          <FormatDate dateString={sub.renewDate} lang={user.language} formatStr={"dmmmyy"} />.{" "}
          {translate("pDomainsManageDomains.automaticRenewal", false, null)}
        </p>
      </>
    );
  };

  const BtnRestore = ({ sub }) => {
    let textBtn = translate("pDomainsManageDomains.restoreDomain", false, null);
    let textTitle = translate("pDomainsManageDomains.restoreYourDomain", false, null);
    let isInCart = cartItems.filter((item) => item.packageName === sub.packageName && item.additionalData.domainPriceAction === "restore").length > 0;
    if (isInCart) {
      textBtn = translate("pDomainsManageDomains.inCart", false, null);
      textTitle = translate("pDomainsManageDomains.checkoutCartToRestore", false, null);
    }
    return (
      <button
        className={`btn btn-sm ${isInCart ? "btn-success" : "btn-outline-success"} trans-3 my-1 me-2`}
        title={textTitle}
        disabled={isInCart || !userIsWsAdmin(activeWorkspace, user)}
        onClick={() => clickRestore(sub)}
      >
        {textBtn}
      </button>
    );
  };

  const BtnTransfer = ({ sub }) => {
    return (
      <button
        className={`btn btn-sm btn-outline-warning trans-3 my-1 me-2`}
        title={translate("pDomainsManageDomains.initTransfer", false, null)}
        onClick={() => clickTransfer(sub)}
      >
        {translate("pDomainsManageDomains.transferDomain", false, null)}
      </button>
    );
  };

  const LinkManageDns = ({ sub }) => {
    return (
      <Link className="btn btn-sm btn-outline-dark trans-3 my-1 me-2" to={`/dns/${sub.packageName}`}>
        {translate("pDomainsManageDomains.manageDns", false, null)}
      </Link>
    );
  };

  const LinkRenewOrCancel = () => {
    return (
      <Link className="btn btn-sm btn-outline-dark trans-3 my-1 me-2" to={`/workspace/subscriptions`}>
        {translate("pDomainsManageDomains.manageRenewal", false, null)}
      </Link>
    );
  };

  const clickRestore = (sub) => {
    // TODO: Domain restore
    // let tld = getTldFromDomain(sub.packageName);
    // let tldObj = TLDs.filter((domain) => domain.tld === tld)[0];
    // if (typeof tldObj !== "undefined") {
    //   let priceRestore = getTldPrices(tldObj, getCurrency(activeWorkspace.location.priceCountry).currencyCode, fxRates).restore;
    //   let productPackage = getDomainProductPackage(activeWorkspace, products, TLDs, { domain: sub.packageName, price: priceRestore }, "Restore");
    //   addToCart(sessionId, productPackage);
    // }
  };

  const clickTransfer = (sub) => {
    // TODO: Domain transfer
  };

  const showTransferBtn = (sub) => {
    const startDate = new Date(sub.startDate);
    startDate.setMonth(startDate.getMonth() + 6);
    const today = new Date();
    return today > startDate;
  };

  return (
    <>
      <h1 className="text-primary">{translate("pDomainsManageDomains.manageDomains", false, null)}</h1>
      {activeWorkspace === null ? (
        <p className="text-italic m-0">{translate("common.wsNotFound", false, null)}</p>
      ) : activeSubs.length === 0 ? (
        <p className="text-italic m-0">{translate("pDomainsManageDomains.noDomainsManaged", false, null)}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover rounded-3 overflowHidden align-middle mt-4">
            <thead>
              <tr className="bg-primary text-white border-none">
                <th scope="col">{translate("pDomainsManageDomains.domainName", false, null)}</th>
                <th scope="col">{translate("pDomainsManageDomains.billingDetails", false, null)}</th>
                <th scope="col">{translate("pDomainsManageDomains.actions", false, null)}</th>
              </tr>
            </thead>
            <tbody>
              {activeSubs.map((sub) => (
                <tr key={sub._id}>
                  <td>{sub.packageName}</td>
                  <td>
                    <SubBillingDetails sub={sub} />
                  </td>
                  <td>
                    <LinkManageDns sub={sub} />
                    {sub.isSuspended && <BtnRestore sub={sub} />}
                    {showTransferBtn(sub) && <BtnTransfer sub={sub} />}
                    <LinkRenewOrCancel />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

ManageDomains.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, null)(ManageDomains);
