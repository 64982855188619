import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProductTableHosting from "../../../../components/products/productTables/ProductTableHosting";

import { translate } from "../../../../translations/translations";

const HostingMain = ({ auth: { langCode } }) => {
  return (
    <>
      <section className="mt-5" id="header">
        <h1 className="text-primary mt-4 mb-4">{translate("pHostingMain.whyUs", false, null)}</h1>
        <div className="row">
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon">
              <i className="fa-solid fa-bolt-lightning text-satonda" />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pHostingMain.highPerformance", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pHostingMain.highPerformanceTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon">
              <i className="fa-solid fa-shield-halved text-satonda" />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pHostingMain.securityFirst", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pHostingMain.securityFirstTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon">
              <i className="fa-solid fa-wand-magic-sparkles text-satonda" />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pHostingMain.customize", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pHostingMain.customizeTag", false, null)}</p>
          </div>
          <div className="col-12 col-md-3 p-3">
            <span className="col-icon">
              <i className="fa-solid fa-user-check text-satonda" />
            </span>
            <h2 className="text-secondary h5 text-bold mt-3 mb-0">{translate("pHostingMain.planForYou", false, null)}</h2>
            <p className="mt-3 mb-0">{translate("pHostingMain.planForYouTag", false, null)}</p>
          </div>
        </div>
        <div className="mt-4">
          <a
            href={`https://www.satonda.com/${langCode}/products/hosting`}
            className="btn btn-primary px-5 rounded-pill"
            target="_blank"
            rel="noreferrer"
          >
            {translate("pHostingMain.readMore", false, null)}
          </a>
        </div>
      </section>
      <section className="mt-6 mb-6" id="pricing">
        <h1 className="text-primary">{translate("pHostingMain.webHosting", false, null)}</h1>
        <ProductTableHosting src={"web"} />
        <h1 className="text-primary mt-5">{translate("pHostingMain.wpHosting", false, null)}</h1>
        <ProductTableHosting src={"wp"} />
        <h1 className="text-primary mt-5">{translate("pHostingMain.ecommerceHosting", false, null)}</h1>
        <ProductTableHosting src={"woo"} />
      </section>
    </>
  );
};

HostingMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(HostingMain);
