export const cSupportComponents = {
  openAttachment: {
    en: "Open attachment",
    nl: "Open bijlage",
  },
  creatingTicket: {
    en: "Creating new ticket..",
    nl: "Aanmaken nieuw ticket..",
  },
  ticketHasBeenCreated: {
    en: "Your ticket has been created. We will answer it as soon as possible!",
    nl: "Uw ticket is aangemaakt. We zullen het zo snel mogelijk beantwoorden!",
  },
  serverError: {
    en: "A server error occurred. Please try again.",
    nl: "Er is een serverfout opgetreden. Probeer het aub opnieuw.",
  },
  provideTopic: {
    en: "Please provide a ticket topic",
    nl: "Specificeer aub een onderwerp",
  },
  provideSubject: {
    en: "Please provide a subject line",
    nl: "Specificeer aub een onderwerp",
  },
  describeProblem: {
    en: "Please describe the problem",
    nl: "Beschrijf aub het probleem",
  },
  createNewSupportTicket: {
    en: "Create new support ticket",
    nl: "Maak nieuw ticket aan",
  },
  selectTicketTopic: {
    en: "Select ticket topic",
    nl: "Selecteer onderwerp",
  },
  ticketSubject: {
    en: "Ticket subject",
    nl: "Ticket onderwerp",
  },
  describeProblemInDetail: {
    en: "Please describe the problem you're facing in as much detail as possible",
    nl: "Beschrijf uw probleem aub zo duidelijk mogelijk",
  },
  browseOrDnD: {
    en: "Browse or drag & drop files to add as attachments",
    nl: "Selecteer bestanden toe te voegen als bijlagen",
  },
  clearFiles: {
    en: "Clear all files",
    nl: "Wis alle bestanden",
  },
  removeFileFromSelection: {
    en: "Remove file from selection",
    nl: "Verwijder bestand",
  },
  allowedFileTypes: {
    en: "Please select individual files, not a whole folder. Allowed file types are: bmp, gif, jpg, jpeg, png, svg, webp, doc, docx, pdf.",
    nl: "Selecteer aub individuale bestanden, niet een hele folder. Toegestane bestandstypen zijn: bmp, gif, jpg, jpeg, png, svg, webp, doc, docx, pdf.",
  },
  createTicket: {
    en: "Create ticket",
    nl: "Maak ticket",
  },
  noTicketsToShow: {
    en: "There are no tickets to show.",
    nl: "Geen tickets gevonden.",
  },
  noOpenTickets: {
    en: "You don't have any open tickets",
    nl: "U heeft geen open tickets",
  },
  noClosedTickets: {
    en: "You don't have any closed tickets",
    nl: "U heeft geen gesloten tickets",
  },
  createNewTicket: {
    en: "Create new ticket",
    nl: "Maak nieuw ticket",
  },
  closeTicket: {
    en: "Close ticket",
    nl: "Sluit ticket",
  },
  ticketWasClosedOn: {
    en: "This ticket was closed on",
    nl: "Ticket was gesloten op",
  },
  yourRating: {
    en: "Your provided rating",
    nl: "Uw beoordeling",
  },
  addResponse: {
    en: "Add a response",
    nl: "Voeg een antwoord toe",
  },
  reopenAddResponse: {
    en: "Reopen and add a response",
    nl: "Heropen en voeg een antwoord toe",
  },
  addingResponse: {
    en: "Adding your response..",
    nl: "Toevoegen antwoord..",
  },
  responseHasBeenAdded: {
    en: "Your response has been added to your ticket. We will answer it as soon as possible!",
    nl: "Uw antwoord is toegevoegd aan uw ticket. We zullen het zo snel mogelijk beantwoorden!",
  },
  addYourResponse: {
    en: "Please add your response",
    nl: "Voeg uw antwoord aub toe",
  },
  addAResponse: {
    en: "Add a response",
    nl: "Beantwoord",
  },
  provideAdditionalDetail: {
    en: "Please provide any additional detail",
    nl: "Deel aub extra informatie",
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
  respond: {
    en: "Respond",
    nl: "Beantwoord",
  },
  closingTicket: {
    en: "Closing ticket..",
    nl: "Ticket sluiten..",
  },
  ticketHasBeenClosed: {
    en: "Your ticket has been closed. Please reach out to us again should you have any other questions!",
    nl: "Uw ticket is gesloten. Aarzel niet om contact op te nemen mocht u enige andere vragen hebben!",
  },
  provideARating: {
    en: "Please provide a rating",
    nl: "Geef aub een rating",
  },
  howWouldYouRate: {
    en: "How would you rate our helpfulness?",
    nl: "Wat vond je van onze behulpzaamheid?",
  },
  description: {
    en: "Description",
    nl: "Beschrijving",
  },
  attachments: {
    en: "Attachments",
    nl: "Bijlagen",
  },
  lastUpdate: {
    en: "Last update",
    nl: "Laatste update",
  },
  ticketReference: {
    en: "Ticket reference",
    nl: "Ticket referentie",
  },
  response: {
    en: "Response",
    nl: "Antwoord",
  },
  by: {
    en: "By",
    nl: "Door",
  },
  date: {
    en: "Date",
    nl: "Datum",
  },
  you: {
    en: "You",
    nl: "U",
  },
  helpdesk: {
    en: "Satonda helpdesk",
    nl: "Satonda helpdesk",
  },
  responseAttachments: {
    en: "Response attachments",
    nl: "Antwoord bijlagen",
  },
  domains: {
    en: "Domains",
    nl: "Domeinen",
  },
  hosting: {
    en: "Hosting",
    nl: "Hosting",
  },
  sitebuilder: {
    en: "Sitebuilder",
    nl: "Sitebuilder",
  },
  emailMarketing: {
    en: "Email marketing",
    nl: "Email marketing",
  },
  billing: {
    en: "Billing",
    nl: "Facturering",
  },
  other: {
    en: "Other",
    nl: "Ander",
  },
};
