import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PRODUCT_NAMES } from "../../lib/ProductVars";

import { createNewEbProject } from "../../../../actions/emailMarketing";
import { capitalizeFirstLetter } from "../../../../lib/formattingFunctions";
import { translate } from "../../../../translations/translations";

const EbCreateNewProject = ({ auth: { user, activeWorkspace }, emailMarketing: { ebProjects }, createNewEbProject }) => {
  const [projectName, setProjectName] = useState("");
  const [canCreateAdditional, setCanCreateAdditional] = useState(false);

  useEffect(() => {
    if (activeWorkspace !== null) {
      try {
        const quotaProjects = parseInt(
          activeWorkspace.subscriptions
            .filter((sub) => sub.productName === PRODUCT_NAMES.emailMarketing && typeof sub.cancelDate === "undefined")[0]
            .packageDetails.filter((quota) => quota.name === "ebProjects")[0].value
        );
        setCanCreateAdditional(quotaProjects === 0 || ebProjects.length < quotaProjects); // if quota = 0, it means unlimited
      } catch (error) {
        setCanCreateAdditional(false);
      }
    }
  }, [activeWorkspace, ebProjects]);

  const onType = (val) => {
    setProjectName(val.replace(/\s/g, "-").replace(/[^A-Za-z0-9_-]/g, ""));
  };

  const clickCreateNewProject = () => {
    if (projectName !== "") {
      createNewEbProject(capitalizeFirstLetter(projectName));
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-9 col-xl-6">
        <h2 className="text-secondary mt-5 mb-3">{translate("cEmailBuilder.createNewProject", false, null)}</h2>
        <div className="input-group mb-3">
          <input
            type="text"
            className={`form-control`}
            placeholder={
              canCreateAdditional ? translate("cEmailBuilder.projectName", false, null) : translate("cEmailBuilder.pleaseUpgrade", false, null)
            }
            value={projectName}
            onChange={(e) => onType(e.target.value)}
            disabled={!canCreateAdditional}
          />
          <button className="btn btn-outline-primary" disabled={projectName === ""} onClick={clickCreateNewProject}>
            {translate("cEmailBuilder.createProject", false, null)}
          </button>
        </div>
      </div>
    </div>
  );
};

EbCreateNewProject.propTypes = {
  auth: PropTypes.object.isRequired,
  emailMarketing: PropTypes.object.isRequired,
  createNewEbProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  emailMarketing: state.emailMarketing,
});

export default connect(mapStateToProps, { createNewEbProject })(EbCreateNewProject);
