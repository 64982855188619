export const pSbMain = {
  whyUs: {
    en: "Why build your site with Satonda's Sitebuilder",
    nl: "Waarom uw website bouwen met Satonda's Sitebuilder",
  },
  noCode: { en: "No technical knowledge needed", nl: "Geen technische kennis nodig" },
  noCodeTag: {
    en: "Making beautiful websites has never been easier with our intuitive drag & drop editor!",
    nl: "Een prachtige website maken is nog nooit zo makkelijk geweest met onze intuitieve drag & drop editor!",
  },
  allDevices: { en: "Optimized for all devices", nl: "Geoptimaliseerd voor alle apparaten" },
  allDevicesTag: {
    en: "Your website will look great on all devices and screen sizes, from mobile phones to tablets to desktops!",
    nl: "Uw website zal er geweldig uitzien op elk apparataat en schermgrootte, van mobiel tot computer!",
  },
  conversions: { en: "Track conversions", nl: "Houd conversies bij" },
  conversionsTag: {
    en: "Our Sitebuilder is a great tool for making landing pages, allowing you to track and store conversions",
    nl: "Onze Sitebuilder is een goede tool om landing pages mee te maken, waarmee u gemakkelijk conversies bij kan houden",
  },
  abVersions: { en: "A/B test versions", nl: "A/B tests" },
  abVersionsTag: {
    en: "Easily make multiple versions of the same page that will randomly be shown to users, to track which version performs best and optimize your conversions!",
    nl: "Maak meerdere versies van dezelfde pagina die wij willekeurig laten zien aan uw bezoeker, waardoor u kunt onderzoeken welke versie het best presteert en daarmee uw conversies kan maximaliseren!",
  },
  readMore: {
    en: "Read more",
    nl: "Lees meer",
  },
  selectYourPlan: {
    en: "Select your plan",
    nl: "Kies uw pakket",
  },
};
