import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EmailsOpened = ({ auth: { activeWorkspace }, heading, labelsAndData, bgColors, borderColors, cbLabel }) => {
  const data = {
    labels: labelsAndData.labels,
    datasets: [
      {
        label: heading,
        data: labelsAndData.data,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: cbLabel,
          // label: (context) => {
          //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
          // },
          title: (context) => {
            return "";
          },
        },
      },
    },
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "200px" }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

EmailsOpened.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(EmailsOpened);
