export const aCart = {
  loadingCart: {
    en: "Loading shopping cart..",
    nl: "Winkelwagentje ophalen..",
  },
  addingSub: {
    en: "Adding new subscription(s)..",
    nl: "Toevoegen nieuw(e) abonnement(en)..",
  },
  errorAddingToCart: {
    en: "A server error occured while adding the item to your cart. Please try again.",
    nl: "Er is een serverfout opgetreden tijdens het toevoegen van het product aan uw winkelwagen. Probeer het aub opnieuw.",
  },
};
