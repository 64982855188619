import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ productTitle, iconClassname, productLinkTo, productLinkText }) => {
  const EXTERNAL_LINKS = ["phpMyAdmin", "Webmail", "Plesk"];

  return (
    <div className="col-12 col-md-6 col-lg-3 p-2">
      <div className="flexSameHeight justify-content-center align-items-center shadow-light rounded-3 p-3 trans-3">
        <h3 className="text-primary text-center toGrow">{productTitle}</h3>
        <span
          className="my-4 fontSize25 text-primary bg-light rounded-circle border border-primary d-flex justify-content-center align-items-center"
          style={{ height: "75px", width: "75px" }}
        >
          <i className={iconClassname}></i>
        </span>
        {EXTERNAL_LINKS.includes(productTitle) ? (
          <a className="btn btn-primary trans-3 w-100" href={productLinkTo} target="_blank" rel="noopener noreferrer">
            {productLinkText}
          </a>
        ) : (
          <Link className="btn btn-primary trans-3 w-100" to={productLinkTo}>
            {productLinkText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
