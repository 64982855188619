import axios from "axios";

import { GET_WS_DOMAINS } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { translate } from "../translations/translations";

export const addNewDomainNameToDns = (wsId, domainName) => async (dispatch) => {
  // NOTE: Inactive: Domains not registered with Satonda cannot be hosted DNS for
  let loadingId = dispatch(setGlobalLoading(translate("aDomains.addingDnsDomain", false, null)));
  try {
    const body = JSON.stringify({ domainName });
    const res = await axios.post(`${API_URL}/dns/addNewDomain`, body, POST_CONFIG);
    dispatch({
      type: GET_WS_DOMAINS,
      payload: { wsId, domains: res.data },
    });
    dispatch(setAlert(translate("aDomains.addedDnsDomain", false, null), "success"));
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const updateNameservers = (wsId, domainId, useDefault, ns1, ns2, ns3, ns4, ns5, ns6, ip1, ip2, ip3, ip4, ip5, ip6) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aDomains.updatingNs", false, null)));
  try {
    const body = JSON.stringify({ domainId, useDefault, ns1, ns2, ns3, ns4, ns5, ns6, ip1, ip2, ip3, ip4, ip5, ip6 });
    const res = await axios.post(`${API_URL}/dns/updateNameservers`, body, POST_CONFIG);
    dispatch({
      type: GET_WS_DOMAINS,
      payload: { wsId, domains: res.data },
    });
    dispatch(setAlert(translate("aDomains.nsUpdated", false, null), "success"));
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const createDnsRecord = (wsId, domainId, type, recordFields) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aDomains.creatingDnsRecord", false, null)));
  try {
    const body = JSON.stringify({ domainId, type, recordFields });
    const res = await axios.post(`${API_URL}/dns/createDnsRecord`, body, POST_CONFIG);
    dispatch({
      type: GET_WS_DOMAINS,
      payload: { wsId, domains: res.data },
    });
    dispatch(setAlert(translate("aDomains.dnsRecordCreated", false, null), "success"));
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const deleteDnsRecord = (wsId, domainId, recordId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aDomains.deletingDnsRecord", false, null)));
  try {
    const res = await axios.delete(`${API_URL}/dns/delete/${domainId}/${recordId}`);
    dispatch({
      type: GET_WS_DOMAINS,
      payload: { wsId, domains: res.data },
    });
    dispatch(setAlert(translate("aDomains.dnsRecordDeleted", false, null), "success"));
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const updateDnsRecord = (wsId, domainId, recordId, type, recordFields) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aDomains.updatingDnsRecord", false, null)));
  try {
    // Create new record
    const body = JSON.stringify({ domainId, type, recordFields });
    await axios.post(`${API_URL}/dns/createDnsRecord`, body, POST_CONFIG);
    // Remove old
    const res = await axios.delete(`${API_URL}/dns/delete/${domainId}/${recordId}`);
    // Update ws DNS records
    dispatch({
      type: GET_WS_DOMAINS,
      payload: { wsId, domains: res.data },
    });
    dispatch(setAlert(translate("aDomains.dnsRecordUpdated", false, null), "success"));
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};
