import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FormatDate from "../../layout/FormatDate";
import FormatNumber from "../../layout/FormatNumber";

import { getTotalAmountAtCheckoutIncVat } from "../../products/lib/ProductFunctions";

import { isUnpaid, ORIGIN_OVERDUE } from "./lib/invoiceFunctions";

import { translate } from "../../../translations/translations";

const UnpaidInvoice = ({ auth: { user, activeWorkspace } }) => {
  const [unpaidInvoice, setUnpaidInvoice] = useState([]);

  useEffect(() => {
    if (activeWorkspace !== null) {
      getUnpaidInvoiceData();
    } else {
      setUnpaidInvoice([]);
    }
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getUnpaidInvoiceData = () => {
    // Get unpaid historical invoices
    let unpaidInvoices = [];
    typeof activeWorkspace.invoices !== "undefined" && (unpaidInvoices = activeWorkspace.invoices.filter((inv) => isUnpaid(inv)));
    // Get total amount due and add to the unpaid invoice object
    unpaidInvoices = unpaidInvoices.map((inv) => ({
      ...inv,
      amountIncVat: getTotalAmountAtCheckoutIncVat(ORIGIN_OVERDUE, inv.items, inv.rateVAT),
    }));
    setUnpaidInvoice(unpaidInvoices.filter((inv) => inv.amountIncVat > 0));
  };

  return unpaidInvoice.length > 0 ? (
    <>
      <h2 className="text-secondary mt-5 mb-0">{translate("cWsBilling.overdueInvoices", false, null)}</h2>
      {unpaidInvoice.map((invoice) => {
        return (
          <div className="alert alert-danger d-flex align-items-center my-2" key={invoice._id}>
            <span>
              <span className="text-bold">{translate("cWsBilling.invoiceDate", false, null)}:</span>{" "}
              <FormatDate dateString={invoice.invoiceDate} lang={user.language} formatStr={"mmmyy"} />
            </span>
            <span className="ms-5">
              <span className="text-bold">{translate("cWsBilling.amountDue", false, { tax: "VAT" })}:</span> {invoice.currency}{" "}
              <FormatNumber number={invoice.amountIncVat} formatStr={"0.00"} />
            </span>
            <Link to={`/checkout/overdue/${invoice._id}`} className="btn btn-light ms-5 trans-3 px-5">
              {translate("cWsBilling.pay", false, null)}
            </Link>
          </div>
        );
      })}
    </>
  ) : null;
};

UnpaidInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UnpaidInvoice);
