export const productVars = {
  packageDesc_hosting1: {
    en: "Best for hobbyist websites",
    nl: "Beste voor hobby websites",
  },
  packageDesc_hosting2: {
    en: "Best for professional websites with limited number of visitors",
    nl: "Beste voor professionele websites met een beperkt aantal bezoekers",
  },
  packageDesc_hosting3: {
    en: "Best for larger websites or small to medium WordPress sites",
    nl: "Beste voor grotere websites of kleine WordPress sites",
  },
  packageDesc_hosting4: {
    en: "Best for high-traffic (Wordpress) websites",
    nl: "Beste voor (WordPress) websites met veel bezoekers",
  },
  packageDesc_sb1: {
    en: "Build a beautiful site with a few pages",
    nl: "Maak een schitterende website met een paar paginas",
  },
  packageDesc_sb2: {
    en: "Unlimited pages and user interactivity",
    nl: "Onbeperkt aantal paginas en interactie met gebruikers",
  },
  packageDesc_emailMarketing1: {
    en: "Desc package 1",
    nl: "Beschrijving 1",
  },
  packageDesc_emailMarketing2: {
    en: "Desc package 2",
    nl: "Beschrijving 2",
  },
  packageDesc_emailMarketing3: {
    en: "Desc package 3",
    nl: "Beschrijving 3",
  },
};
