export const pAliasChangeRec = {
  notValidEmail: {
    en: "is not a valid email address",
    nl: "is geen correct email adres",
  },
  includedMultipleTimes: {
    en: "is included multiple times",
    nl: "is meerdere keren opgenomen",
  },
  provideRecipients: {
    en: "Please provide one or more recipients",
    nl: "Verstrek een of meerdere ontvangers",
  },
  emailAddrNotFound: {
    en: "Email address not found.",
    nl: "Email adres niet gevonden",
  },
  updateRecipients: {
    en: "Update recipients for",
    nl: "Update ontvangers voor",
  },
  recipients: {
    en: "Recipients",
    nl: "Ontvangers",
  },
  emailAddresses: {
    en: "Email address(es)",
    nl: "Email adres(sen)",
  },
  separateEmails: {
    en: `Separate multiple recipients with a ","`,
    nl: `Scheid meerdere ontvangers met een ","`,
  },
  updateAliasRecipients: {
    en: "Update alias recipients",
    nl: "Update alias ontvangers",
  },
};
