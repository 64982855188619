import axios from "axios";

import { GET_EB_PROJECTS, GET_PENDING_CAMPAIGNS, GET_EXECUTED_CAMPAIGNS } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { translate } from "../translations/translations";

// ====================
// === Emailbuilder ===
// ====================

export const getEbProjects = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.retrievingProjects", false, null)));
  try {
    const res = await axios.get(`${API_URL}/emailbuilder/manage/all`);
    dispatch({
      type: GET_EB_PROJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const createNewEbProject = (projectName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.creatingProject", false, null)));
  try {
    const body = JSON.stringify({ projectName });
    const res = await axios.post(`${API_URL}/emailbuilder/manage/create`, body, POST_CONFIG);
    dispatch({
      type: GET_EB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aEmailMarketing.projectCreated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const editEbProjectName = (projectId, projectName) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.updatingProjectName", false, null)));
  try {
    const body = JSON.stringify({ projectId, projectName });
    const res = await axios.put(`${API_URL}/emailbuilder/manage/edit/name`, body, POST_CONFIG);
    dispatch({
      type: GET_EB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aEmailMarketing.updatedProjectName", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const deleteEbProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.deletingProject", false, null)));
  try {
    const res = await axios.delete(`${API_URL}/emailbuilder/manage/delete/${projectId}`);
    dispatch({
      type: GET_EB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aEmailMarketing.projectDeleted", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const duplicateEbProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.duplicatingProject", false, null)));
  try {
    const res = await axios.get(`${API_URL}/emailbuilder/manage/duplicate/${projectId}`);
    dispatch({
      type: GET_EB_PROJECTS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aEmailMarketing.projectDuplicated", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};

// ===================================
// === New/pending email campaigns ===
// ===================================

export const getPendingEmailCampaigns = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.retrievingPendingCampaigns", false, null)));
  try {
    const res = await axios.get(`${API_URL}/email/pendingCampaigns`);
    dispatch({
      type: GET_PENDING_CAMPAIGNS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const getExecutedEmailCampaigns = () => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.retrievingCampaigns", false, null)));
  try {
    const res = await axios.get(`${API_URL}/email/executedCampaigns`);
    dispatch({
      type: GET_EXECUTED_CAMPAIGNS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const scheduleNewEmailCampaign =
  (
    emailTemplateId,
    audienceId,
    campaignType,
    campaignName,
    senderName,
    senderEmail,
    emailSubject,
    emailContent,
    recipients,
    scheduledAt,
    dynamicContentData
  ) =>
  async (dispatch) => {
    let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.creatingCampaign", false, null)));
    try {
      const body = JSON.stringify({
        emailTemplateId,
        audienceId,
        campaignType,
        campaignName,
        senderName,
        senderEmail,
        emailSubject,
        emailContent,
        recipients,
        scheduledAt,
        dynamicContentData,
      });
      const res = await axios.post(`${API_URL}/email/newCampaign`, body, POST_CONFIG);
      dispatch({
        type: GET_PENDING_CAMPAIGNS,
        payload: res.data,
      });
      dispatch(setAlert(translate("aEmailMarketing.campaignCreated", false, null), "success"));
    } catch (error) {
      console.error(error);
      dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
    }
    dispatch(removeGlobalLoading(loadingId));
  };

export const cancelEmailCampaign = (campaignId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEmailMarketing.cancellingCampaign", false, null)));
  try {
    const res = await axios.delete(`${API_URL}/email/cancel/${campaignId}`);
    dispatch({
      type: GET_PENDING_CAMPAIGNS,
      payload: res.data,
    });
    dispatch(setAlert(translate("aEmailMarketing.campaignCancelled", false, null), "success"));
  } catch (error) {
    console.error(error);
    dispatch(setAlert(translate("aEmailMarketing.errorOccured", false, null), "danger"));
  }
  dispatch(removeGlobalLoading(loadingId));
};
