import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { editEbProjectName } from "../../../../actions/emailMarketing";
import { capitalizeFirstLetter } from "../../../../lib/formattingFunctions";

import { translate } from "../../../../translations/translations";

const EbMenuEditName = ({ editEbProjectName }) => {
  let { projectId } = useParams();

  const [projectName, setProjectName] = useState("");

  const onType = (val) => {
    setProjectName(val.replace(/\s/g, "-").replace(/[^A-Za-z0-9_-]/g, ""));
  };

  const clickEditName = () => {
    if (projectName !== "") {
      editEbProjectName(projectId, capitalizeFirstLetter(projectName));
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cEmailBuilder.editProjectName", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cEmailBuilder.projectNotFound", false, null)}</p>
      ) : (
        <div className="input-group mb-3">
          <input
            type="text"
            className={`form-control`}
            placeholder={translate("cEmailBuilder.newProjectName", false, null)}
            value={projectName}
            onChange={(e) => onType(e.target.value)}
          />
          <button className="btn btn-outline-primary" disabled={projectName === ""} onClick={clickEditName}>
            {translate("cEmailBuilder.editName", false, null)}
          </button>
        </div>
      )}
    </>
  );
};

EbMenuEditName.propTypes = {
  editEbProjectName: PropTypes.func.isRequired,
};

export default connect(null, { editEbProjectName })(EbMenuEditName);
