export const aDomains = {
  retrievingDomainsData: {
    en: "Retrieving domains data..",
    nl: "Ophalen domein data..",
  },
  updatingOwnershipDetails: {
    en: "Updating domain's ownership details..",
    nl: "Bijwerken domein's eigendomsinformatie",
  },
  ownershipDetailsUpdated: {
    en: "Domain ownership details updated!",
    nl: "Domein eigendomsinformatie bijgewerkt!",
  },
  noDomainsToBeUpdated: {
    en: "You don't own any domains for which ownership details need to be updated.",
    nl: "U heeft geen domeinnamen waarvan de eigendomsinformatie bijgewerkt dient te worden.",
  },
  updatingRedirect: {
    en: "Updating domain's redirect..",
    nl: "Bijwerken domein's omleiding..",
  },
  redirectUpdated: {
    en: "Domain redirect updated!",
    nl: "Domein's omleiding bijgewerkt!",
  },
  updatingNs: {
    en: "Updating domain's nameservers..",
    nl: "Bijwerken domein's nameservers..",
  },
  nsUpdated: {
    en: "Domain nameservers updated!",
    nl: "Domein's nameservers bijgewerkt!",
  },
  creatingDnsRecord: {
    en: "Creating new DNS record..",
    nl: "Aanmaken nieuw DNS record..",
  },
  dnsRecordCreated: {
    en: "DNS record created!",
    nl: "DNS record aangemaakt!",
  },
  deletingDnsRecord: {
    en: "Deleting DNS record..",
    nl: "Verwijderen DNS record..",
  },
  dnsRecordDeleted: {
    en: "DNS record deleted!",
    nl: "DNS record verwijderd!",
  },
  updatingDnsRecord: {
    en: "Updating DNS record..",
    nl: "Bijwerken DNS record..",
  },
  dnsRecordUpdated: {
    en: "DNS record updated!",
    nl: "DNS record bijgewerkt!",
  },
  addingDnsDomain: {
    en: "Adding domain to DNS..",
    nl: "Domein toevoegen aan DNS..",
  },
  addedDnsDomain: {
    en: "Domain added to DNS!",
    nl: "Domein toegevoegd aan DNS!",
  },
  removingDomainDns: {
    en: "Removing all domain's DNS records..",
    nl: "Verwijderen van alle DNS records van het domein..",
  },
  removedDomainDns: {
    en: "All domain's DNS records removed!",
    nl: "Alle DNS records van het domein verwijderd!",
  },
};
