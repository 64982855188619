import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  isValidFqdn,
  getDnsDomainIdFromDomainName,
  hostnameTypeCombinationAlreadyExists,
  takeoutTrailingDotInHostname,
  parseIntTtl,
} from "../lib/dnsFunctions";

import { createDnsRecord, updateDnsRecord } from "../../../../actions/dns";

import { translate } from "../../../../translations/translations";

const RecordSRV = ({ auth: { activeWorkspace }, dnsRecords, formDisabled, existingData, createDnsRecord, updateDnsRecord }) => {
  let { domainName } = useParams();
  let navigate = useNavigate();

  const [hostname, setHostname] = useState(existingData ? existingData.recordFields.hostname : "");
  const [errorMsgHostname, setErrorMsgHostname] = useState("");
  const [directsTo, setDirectsTo] = useState(existingData ? existingData.recordFields.directsTo : "");
  const [errorMsgDirectsTo, setErrorMsgDirectsTo] = useState("");
  const [port, setPort] = useState(existingData ? existingData.recordFields.port : "");
  const [errorMsgPort, setErrorMsgPort] = useState("");
  const [priority, setPriority] = useState(existingData ? existingData.recordFields.priority : "");
  const [errorMsgPriority, setErrorMsgPriority] = useState("");
  const [weight, setWeight] = useState(existingData ? existingData.recordFields.weight : "");
  const [errorMsgWeight, setErrorMsgWeight] = useState("");
  const [TTL, setTTL] = useState(existingData ? existingData.recordFields.TTL : "43200");
  const [errorMsgTTL, setErrorMsgTTL] = useState("");

  const RECORD_TYPE = "SRV";

  const clickCreateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      createDnsRecord(activeWorkspace._id, domainId, RECORD_TYPE, {
        hostname: hostnameToUse,
        directsTo,
        port: parseInt(port),
        weight: parseInt(weight),
        priority: parseInt(priority),
        TTL: parseIntTtl(TTL),
      });
      resetStateVars();
    }
  };

  const clickUpdateRecord = () => {
    let hostnameToUse = hostname === "@" ? domainName : `${takeoutTrailingDotInHostname(hostname)}.${domainName}`;
    if (checkErrors(hostnameToUse) === 0) {
      let domainId = getDnsDomainIdFromDomainName(activeWorkspace.domains, domainName);
      updateDnsRecord(activeWorkspace._id, domainId, existingData._id, RECORD_TYPE, {
        hostname: hostnameToUse,
        directsTo,
        port: parseInt(port),
        weight: parseInt(weight),
        priority: parseInt(priority),
        TTL: parseIntTtl(TTL),
      });
      resetStateVars();
      navigate(`/dns/${domainName}`);
    }
  };

  const checkErrors = (hostnameToUse) => {
    resetErrorMessages();
    let errors = 0;
    if (hostnameToUse === "" || !isValidFqdn(hostnameToUse)) {
      setErrorMsgHostname(translate("cCreateDnsRecords.enterHostname", false, null));
      errors++;
    }
    if (directsTo === "" || !isValidFqdn(directsTo)) {
      setErrorMsgDirectsTo(translate("cCreateDnsRecords.enterTarget", false, null));
      errors++;
    }
    if (priority === "") {
      setErrorMsgPriority(translate("cCreateDnsRecords.enterPriority", false, null));
      errors++;
    }
    if (port === "") {
      setErrorMsgPriority(translate("cCreateDnsRecords.enterPort", false, null));
      errors++;
    }
    if (weight === "") {
      setErrorMsgPriority(translate("cCreateDnsRecords.enterWeight", false, null));
      errors++;
    }
    if (TTL === "") {
      setErrorMsgTTL(translate("cCreateDnsRecords.enterTtl", false, null));
      errors++;
    }
    if (hostnameTypeCombinationAlreadyExists(hostnameToUse, RECORD_TYPE, dnsRecords)) {
      setErrorMsgHostname(translate("cCreateDnsRecords.recordTypeHostnameCombiAlreadyExists", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    setHostname("");
    setDirectsTo("");
    setTTL("43200");
    setPriority("");
    setPort("");
    setWeight("");
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrorMsgHostname("");
    setErrorMsgDirectsTo("");
    setErrorMsgTTL("");
    setErrorMsgPriority("");
    setErrorMsgPort("");
    setErrorMsgWeight("");
  };

  return (
    <>
      <p>{translate("cCreateDnsRecords.srvRecordExplanation", false, null)}</p>
      <div className="row">
        {/* Hostname */}
        <div className="col-12 col-lg-3 py-1">
          <label className="fontSize08" htmlFor="SRV_hostname">
            {translate("cCreateDnsRecords.hostname", false, null)}
          </label>
          <div className="input-group input-group-sm">
            <input
              type="text"
              className={`form-control form-control-sm${errorMsgHostname === "" ? "" : " is-invalid"}`}
              id="SRV_hostname"
              placeholder={translate("cCreateDnsRecords.hostname", false, null)}
              value={hostname}
              onChange={(e) => setHostname(e.target.value)}
              disabled={formDisabled}
            />
            <span className="input-group-text">.{domainName}</span>
            <div className="invalid-feedback">{errorMsgHostname}</div>
          </div>
        </div>
        {/* Directs to */}
        <div className="col-12 col-lg-3 py-1">
          <label className="fontSize08" htmlFor="SRV_DirectsTo">
            {translate("cCreateDnsRecords.directsTo", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgDirectsTo === "" ? "" : " is-invalid"}`}
            id="SRV_DirectsTo"
            placeholder={translate("cCreateDnsRecords.srvTarget", false, null)}
            value={directsTo}
            onChange={(e) => setDirectsTo(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgDirectsTo}</div>
        </div>
        {/* Port */}
        <div className="col-12 col-lg-1 py-1">
          <label className="fontSize08" htmlFor="SRV_port">
            {translate("cCreateDnsRecords.srvPort", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgPort === "" ? "" : " is-invalid"}`}
            id="SRV_port"
            placeholder={translate("cCreateDnsRecords.srvPortExample", false, null)}
            value={port}
            onChange={(e) => setPort(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgPort}</div>
        </div>
        {/* Priority */}
        <div className="col-12 col-lg-1 py-1">
          <label className="fontSize08" htmlFor="SRV_priority">
            {translate("cCreateDnsRecords.priority", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgPriority === "" ? "" : " is-invalid"}`}
            id="SRV_priority"
            placeholder={translate("cCreateDnsRecords.priorityExample", false, null)}
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgPriority}</div>
        </div>
        {/* Weight */}
        <div className="col-12 col-lg-1 py-1">
          <label className="fontSize08" htmlFor="SRV_weight">
            {translate("cCreateDnsRecords.srvWeight", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgWeight === "" ? "" : " is-invalid"}`}
            id="SRV_weight"
            placeholder={translate("cCreateDnsRecords.srvWeightExample", false, null)}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgWeight}</div>
        </div>
        {/* TTL */}
        <div className="col-12 col-lg-2 py-1">
          <label className="fontSize08" htmlFor="SRV_TTL">
            {translate("cCreateDnsRecords.ttlSeconds", false, null)}
          </label>
          <input
            type="text"
            className={`form-control form-control-sm${errorMsgTTL === "" ? "" : " is-invalid"}`}
            id="SRV_TTL"
            placeholder="TTL"
            value={TTL}
            onChange={(e) => setTTL(e.target.value)}
            disabled={formDisabled}
          />
          <div className="invalid-feedback">{errorMsgTTL}</div>
        </div>
      </div>

      <div className="row mx-0 mt-2">
        {typeof existingData === "undefined" ? (
          <div className="col-12 col-md-6 col-xl-4 px-0 py-2">
            <button className="btn btn-sm btn-success w-100" onClick={clickCreateRecord} disabled={formDisabled}>
              {translate("cCreateDnsRecords.createRecord", false, null)}
            </button>
          </div>
        ) : (
          <>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-gray w-100" onClick={() => navigate(-1)} disabled={formDisabled}>
                {translate("cCreateDnsRecords.cancelUpdateRecord", false, null)}
              </button>
            </div>
            <div className="col-12 col-md-6 col-xl-4 px-3 py-2">
              <button className="btn btn-sm btn-success w-100" onClick={clickUpdateRecord} disabled={formDisabled}>
                {translate("cCreateDnsRecords.updateRecord", false, null)}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

RecordSRV.propTypes = {
  auth: PropTypes.object.isRequired,
  dnsRecords: PropTypes.object,
  formDisabled: PropTypes.bool.isRequired,
  existingData: PropTypes.object,
  createDnsRecord: PropTypes.func.isRequired,
  updateDnsRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { createDnsRecord, updateDnsRecord })(RecordSRV);
