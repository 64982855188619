import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import Spinner from "../../layout/Spinner";
import { setAlert } from "../../../actions/alert";
import { translate } from "../../../translations/translations";
import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import { countries } from "../../../lib/Countries";
import { getCountryData } from "../../../lib/VatRates";
import { POST_CONFIG, API_URL } from "../../../lib/GeneralVars";
import { editWorkspaceCustomerInfo } from "../../../actions/auth";

const WsEditCustomerInfo = ({ auth: { activeWorkspace }, setAlert, editWorkspaceCustomerInfo }) => {
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });

  const [customerType, setCustomerType] = useState("consumer");
  const [companyName, setCompanyName] = useState("");
  const [euVatPrefix, setEuVatPrefix] = useState("");
  const [euVatNumber, setEuVatNumber] = useState("");
  const [vatNumberConfirmed, setVatNumberConfirmed] = useState(false);
  const [showEuVatNumberForm, setShowEuVatNumberForm] = useState(false);

  const [errMsg_customerType, setErrMsg_customerType] = useState("");
  const [errMsg_companyName, setErrMsg_companyName] = useState("");
  const [errMsg_euVatNumber, setErrMsg_euVatNumber] = useState("");

  useEffect(() => {
    try {
      if (activeWorkspace !== null) {
        setCustomerType(activeWorkspace.customerType);
        setCompanyName(activeWorkspace.companyName);
        if (countries.filter((country) => country.code === activeWorkspace.location.countryCode && country.inEU).length > 0) {
          // If EU country: vat data is structured as follows
          setEuVatPrefix(activeWorkspace.businessVatNumber.EU.prefix);
          setEuVatNumber(activeWorkspace.businessVatNumber.EU.vatNumber);
          setVatNumberConfirmed(activeWorkspace.businessVatNumber.EU.confirmed);
        } else {
          // If not EU country: not offered yet
          setEuVatPrefix("");
          setEuVatNumber("");
          setVatNumberConfirmed(false);
        }
      }
    } catch (error) {
      setCustomerType("");
      setCompanyName("");
      setEuVatPrefix("");
      setEuVatNumber("");
      setVatNumberConfirmed(false);
    }
  }, [activeWorkspace]);

  useEffect(() => {
    if (
      activeWorkspace !== null &&
      customerType === "business" &&
      countries.filter((country) => country.code === activeWorkspace.location.countryCode && country.inEU).length > 0
    ) {
      setShowEuVatNumberForm(true);
      setEuVatPrefix(getCountryData(activeWorkspace.location.countryCode).euVatNumberCode);
    } else {
      setShowEuVatNumberForm(false);
    }
  }, [activeWorkspace, customerType]);

  useEffect(() => {
    try {
      if (
        activeWorkspace.customerType === "business" &&
        euVatNumber === activeWorkspace.businessVatNumber.EU.vatNumber &&
        activeWorkspace.businessVatNumber.EU.confirmed
      ) {
        setVatNumberConfirmed(true);
      } else {
        setVatNumberConfirmed(false);
      }
    } catch (error) {
      setVatNumberConfirmed(false);
    }
  }, [activeWorkspace, euVatNumber]);

  const checkVatNumber = async () => {
    // Check field is not empty
    if (euVatNumber === "") {
      setErrMsg_euVatNumber(translate("cWsEditCustomerInfo.provideValidVatNumber", false, null));
    } else {
      setLocalLoading({ isLoading: true, msg: translate("cWsEditCustomerInfo.confirmingVatNumber", false, null) });
      // Sanitize provided VAT number: 1) take out country prefix if provided and 2) remove any chars that are not letters/digits
      let re = new RegExp(`^${euVatPrefix}`, "i");
      let vatNumber = euVatNumber.replace(re, "").replace(/[^A-Za-z0-9]/g, "");
      try {
        const body = JSON.stringify({ countryCode: euVatPrefix, vatNumber });
        const res = await axios.post(`${API_URL}/misc/checkEuVatNumber`, body, POST_CONFIG);
        if (res.data.valid) {
          setVatNumberConfirmed(true);
        } else if (!res.data.actionSucceed) {
          setVatNumberConfirmed(false);
          setAlert(translate("cWsEditCustomerInfo.euVatCheckerUnavailable", false, null), "danger");
        } else {
          setVatNumberConfirmed(false);
          setErrMsg_euVatNumber(translate("cWsEditCustomerInfo.provideValidVatNumber", false, null));
        }
      } catch (error) {
        console.error(error);
        setAlert(translate("cWsEditCustomerInfo.errorWhileConfirmingVatNumber", false, null), "danger");
      }
      setLocalLoading({ isLoading: false, msg: "" });
    }
  };

  const clickEditInfo = async () => {
    setLocalLoading({ isLoading: true, msg: translate("aAuth.updatingWsCustomerInfo", false, null) });
    if (checkErrors() === 0) {
      let businessVatNumber = getBusinessVatNumberObject();
      editWorkspaceCustomerInfo(activeWorkspace._id, customerType, capitalizeFirstLetter(companyName), businessVatNumber);
      resetStateVars();
    }
    setLocalLoading({ isLoading: false, msg: "" });
  };

  const checkErrors = () => {
    resetErrorMessages();
    let errors = 0;
    if (customerType !== "consumer" && customerType !== "business") {
      setErrMsg_customerType(translate("cWsEditCustomerInfo.specifyCustomerType", false, null));
      errors++;
    }
    return errors;
  };

  const resetStateVars = () => {
    // setCustomerType("consumer");
    // setCompanyName("");
    // setEuVatPrefix("");
    // setEuVatNumber("");
    // setVatNumberConfirmed(false);
    // setShowEuVatNumberForm(false);
    resetErrorMessages();
  };

  const resetErrorMessages = () => {
    setErrMsg_customerType("");
    setErrMsg_companyName("");
    setErrMsg_euVatNumber("");
  };

  const getBusinessVatNumberObject = () => {
    // For EU companies:
    //   EU: {
    //     prefix: vatPrefix,
    //     vatNumber,
    //     confirmed: typeof vatNumberConfirmed === "boolean" && vatNumberConfirmed ? true : false,
    //   }
    if (
      customerType === "business" &&
      countries.filter((country) => country.code === activeWorkspace.location.countryCode && country.inEU).length > 0
    ) {
      return {
        EU: {
          prefix: euVatPrefix,
          vatNumber: euVatNumber,
          confirmed: vatNumberConfirmed,
        },
      };
    } else {
      // If not EU: Don't do anything yet. To be added
      return null;
    }
  };

  return (
    <>
      <h2 className="text-secondary mt-5">{translate("cWsEditCustomerInfo.editCustomerInfo", false, null)}</h2>
      {localLoading.isLoading ? (
        <div className="mt-5">
          <Spinner msg={localLoading.msg} />
        </div>
      ) : (
        <div className="row mt-4">
          <div className="col-12 col-lg-9 col-xl-6">
            {/* Customer type */}
            <div className="mb-3">
              <label htmlFor="customerType" className="form-label">
                {translate("cWsEditCustomerInfo.customerType", false, null)}
              </label>
              <select
                className={`form-select ${errMsg_customerType === "" ? "" : "is-invalid"}`}
                name="customerType"
                value={customerType}
                onChange={(e) => setCustomerType(e.target.value)}
                required
              >
                <option value="consumer">{translate("cWsEditCustomerInfo.consumer", false, null)}</option>
                <option value="business">{translate("cWsEditCustomerInfo.business", false, null)}</option>
              </select>
              <div className="invalid-feedback">{errMsg_customerType}</div>
            </div>
            {/* If a business: get company name  */}
            {customerType === "business" && (
              <>
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                    {translate("cWsEditCustomerInfo.companyName", false, null)}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errMsg_companyName === "" ? "" : "is-invalid"}`}
                    name="companyName"
                    placeholder={translate("cWsEditCustomerInfo.companyName", false, null)}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <div className="invalid-feedback">{errMsg_companyName}</div>
                </div>
              </>
            )}
            {/* If a EU business: get EU VAT number */}
            {showEuVatNumberForm && (
              <>
                <div className="mb-3">
                  <label htmlFor="euVatNumber" className="form-label">
                    {translate("cWsEditCustomerInfo.euVatNumber", false, null)}
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">{euVatPrefix}</span>
                    <input
                      type="text"
                      className={`form-control ${vatNumberConfirmed ? "is-valid" : ""} ${errMsg_euVatNumber === "" ? "" : "is-invalid"}`}
                      name="euVatNumber"
                      value={euVatNumber}
                      onChange={(e) => setEuVatNumber(e.target.value)}
                      placeholder={translate("cWsEditCustomerInfo.vatNumber", false, null)}
                    />
                    <button className="btn btn-outline-success" onClick={checkVatNumber}>
                      {translate("cWsEditCustomerInfo.confirm", false, null)}
                    </button>
                    <div className="invalid-feedback">{errMsg_euVatNumber}</div>
                  </div>
                </div>
              </>
            )}
            <div>
              <button className="btn btn-success w-100" onClick={clickEditInfo}>
                {translate("cWsEditCustomerInfo.editCustomerInfo", false, null)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WsEditCustomerInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  editWorkspaceCustomerInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, editWorkspaceCustomerInfo })(WsEditCustomerInfo);
