// Bring in action types
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  READ_NOTIFICATIONS_USER,
  READ_NOTIFICATIONS_WORKSPACE,
  UPDATE_USER_CONTACT_DETAILS,
  SUBMIT_PULSE,
  SET_LANG_CODE,
  // Workspace
  SET_ACTIVE_WORKSPACE,
  CREATE_NEW_WORKSPACE,
  WORKSPACE_ADD_NEW_USER,
  CHANGE_USER_ROLE_WORKSPACE,
  REMOVE_USER_FROM_WORKSPACE,
  LEAVE_WORKSPACE,
  CHANGE_WORKSPACE_NAME,
  DELETE_WORKSPACE,
  ADD_NEW_CONTACTFORM_TO_WORKSPACE,
  UPDATE_WS_VALIDATED_EMAILS,
  ADD_HOSTED_DOMAIN_WEB,
  GET_WS_DOMAINS,
  EDIT_WS_LOCATION_DATA,
  EDIT_WS_CUSTOMER_DATA,
  UPDATE_WS_SUBS,
  // Fx rates
  SET_FX_RATES,
} from "../actions/types";
import setWorkspace from "../utils/setWorkspace";
import { WS_MEMBER_TYPE_ACTIVATED, WS_MEMBER_TYPE_PENDING } from "../components/workspace/lib/wsFunctions";

// Set initialState (object of various values)
const initialState = {
  // Get token out of localstorage
  token: localStorage.getItem("token"),
  // Only set isAuth to true when logged in
  isAuthenticated: null,
  // Set loading to true as the default, so we only need to set it to false once a request is finished, rather than first set it to true and then to false afterwards
  loading: true,
  // Similar as isAuth, the initial user is null
  user: null,
  langCode: "en",
  countryCode: "nl",
  brandId: "",
  workspaces: [],
  activeWorkspace: null,
  fxRates: [],
};

// Reducer functionality
export default function auth(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  switch (type) {
    // Login functions
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
        langCode: payload.langCode,
        countryCode: payload.countryCode,
        brandId: payload.brandId,
        workspaces: payload.workspaces,
      };
    case LOGIN_SUCCESS:
      // If successfully registered a new user, set the token (passed through as part of the payload) to localstorage
      localStorage.setItem("token", payload.token);
      // Update the state object
      return {
        // Copy whatever was in the state before
        ...state,
        // Add the full payload
        ...payload,
        // Set isAuth to true
        isAuthenticated: true,
        // Set loading to false
        loading: false,
      };
    case SET_LANG_CODE:
      return {
        ...state,
        langCode: payload,
      };
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
    case ACCOUNT_DELETED:
      // Remove the token from localstorage to ensure there's no token in localstorage that is not valid
      localStorage.removeItem("token");
      localStorage.removeItem("workspace");
      return {
        // Copy whatever was in the state before
        ...state,
        // Set token to null
        token: null,
        // Set isAuth to false
        isAuthenticated: false,
        // Set loading to false
        loading: false,
        // Clear user object
        user: null,
      };
    // User functions
    case UPDATE_USER_CONTACT_DETAILS:
      // Payload = { email, firstName, lastName, phoneNumber, companyName, street, postal, city, country }
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case READ_NOTIFICATIONS_USER:
      // payload = [ "id", "id", ... ] of read notifications
      return {
        ...state,
        user: { ...state.user, notifications: state.user.notifications.map((n) => (payload.includes(n._id) ? { ...n, read: true } : n)) },
      };
    case SUBMIT_PULSE:
      // Payload = { date, value }
      return {
        ...state,
        user: { ...state.user, pulse: [payload, ...state.user.pulse].slice(0, 5) },
      };
    // Workspace functions
    case SET_ACTIVE_WORKSPACE:
      // Payload = workspace object
      return {
        ...state,
        activeWorkspace: payload,
      };
    case CREATE_NEW_WORKSPACE:
      // Payload = {workspaces, activeWsId}
      return {
        ...state,
        workspaces: payload.workspaces,
        activeWorkspace: payload.workspaces.filter((ws) => ws._id === payload.activeWsId)[0],
      };
    case WORKSPACE_ADD_NEW_USER:
      // payload = { members, pendingInvites }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === state.activeWorkspace._id ? { ...ws, members: payload.members, pendingInvites: payload.pendingInvites } : ws
        ),
        activeWorkspace: {
          ...state.activeWorkspace,
          members: payload.members,
          pendingInvites: payload.pendingInvites,
        },
      };
    case CHANGE_USER_ROLE_WORKSPACE:
      // payload = { userId, email, newRole, type }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === state.activeWorkspace._id
            ? {
                ...ws,
                members:
                  payload.type === WS_MEMBER_TYPE_ACTIVATED
                    ? ws.members.map((member) => (member.userId === payload.userId ? { ...member, role: payload.newRole } : member))
                    : ws.members,
                pendingInvites:
                  payload.type === WS_MEMBER_TYPE_PENDING
                    ? ws.pendingInvites.map((pendingInvite) =>
                        pendingInvite.email === payload.email ? { ...pendingInvite, role: payload.newRole } : pendingInvite
                      )
                    : ws.pendingInvites,
              }
            : ws
        ),
        activeWorkspace: {
          ...state.activeWorkspace,
          members:
            payload.type === WS_MEMBER_TYPE_ACTIVATED
              ? state.activeWorkspace.members.map((member) => (member.userId === payload.userId ? { ...member, role: payload.newRole } : member))
              : state.activeWorkspace.members,
          pendingInvites:
            payload.type === WS_MEMBER_TYPE_PENDING
              ? state.activeWorkspace.pendingInvites.map((pendingInvite) =>
                  pendingInvite.email === payload.email ? { ...pendingInvite, role: payload.newRole } : pendingInvite
                )
              : state.activeWorkspace.pendingInvites,
        },
      };
    case REMOVE_USER_FROM_WORKSPACE:
      // payload = { userId, email, type }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === state.activeWorkspace._id
            ? {
                ...ws,
                members: payload.type === WS_MEMBER_TYPE_ACTIVATED ? ws.members.filter((member) => member.userId !== payload.userId) : ws.members,
                pendingInvites:
                  payload.type === WS_MEMBER_TYPE_PENDING
                    ? ws.pendingInvites.filter((pendingInvite) => pendingInvite.email !== payload.email)
                    : ws.pendingInvites,
              }
            : ws
        ),
        activeWorkspace: {
          ...state.activeWorkspace,
          members:
            payload.type === WS_MEMBER_TYPE_ACTIVATED
              ? state.activeWorkspace.members.filter((member) => member.userId !== payload.userId)
              : state.activeWorkspace.members,
          pendingInvites:
            payload.type === WS_MEMBER_TYPE_PENDING
              ? state.activeWorkspace.pendingInvites.filter((pendingInvite) => pendingInvite.email !== payload.email)
              : state.activeWorkspace.pendingInvites,
        },
      };
    case LEAVE_WORKSPACE:
    case DELETE_WORKSPACE:
      // payload = wsId
      const delLeaveWs_newActiveWs = () => {
        if (state.activeWorkspace._id === payload) {
          let newActiveWs = state.workspaces.filter((ws) => ws._id !== payload)[0];
          if (typeof newActiveWs === "undefined") {
            localStorage.setItem("workspace", "");
            setWorkspace("");
            return null;
          }
          localStorage.setItem("workspace", newActiveWs._id);
          setWorkspace(newActiveWs._id);
          return newActiveWs;
        } else {
          return state.activeWorkspace;
        }
      };
      return {
        ...state,
        workspaces: state.workspaces.filter((ws) => ws._id !== payload),
        activeWorkspace: delLeaveWs_newActiveWs(),
      };
    case CHANGE_WORKSPACE_NAME:
      // payload = { wsId, newWsName }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) => (ws._id === payload.wsId ? { ...ws, name: payload.newWsName } : ws)),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                name: payload.newWsName,
              }
            : state.activeWorkspace,
      };
    case EDIT_WS_LOCATION_DATA:
      // payload = { wsId, locationObj }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) => (ws._id === payload.wsId ? { ...ws, location: { ...ws.location, ...payload.locationObj } } : ws)),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                location: { ...state.activeWorkspace.location, ...payload.locationObj },
              }
            : state.activeWorkspace,
      };
    case EDIT_WS_CUSTOMER_DATA:
      // payload = { wsId, customerType, companyName, businessVatNumber }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === payload.wsId
            ? { ...ws, customerType: payload.customerType, companyName: payload.companyName, businessVatNumber: payload.businessVatNumber }
            : ws
        ),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                customerType: payload.customerType,
                companyName: payload.companyName,
                businessVatNumber: payload.businessVatNumber,
              }
            : state.activeWorkspace,
      };
    case READ_NOTIFICATIONS_WORKSPACE:
      // payload = { wsId, notificationIds }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === payload.wsId
            ? { ...ws, notifications: ws.notifications.map((n) => (payload.notificationIds.includes(n._id) ? { ...n, read: true } : n)) }
            : ws
        ),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                notifications: state.activeWorkspace.notifications.map((n) => (payload.notificationIds.includes(n._id) ? { ...n, read: true } : n)),
              }
            : state.activeWorkspace,
      };
    case ADD_NEW_CONTACTFORM_TO_WORKSPACE:
      // payload = { wsId, contactForms }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) => (ws._id === payload.wsId ? { ...ws, contactForms: payload.contactForms } : ws)),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                contactForms: payload.contactForms,
              }
            : state.activeWorkspace,
      };
    case UPDATE_WS_VALIDATED_EMAILS:
      // payload = { wsId, arrValidatedEmails }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === payload.wsId ? { ...ws, emailMarketing: { ...ws.emailMarketing, validatedEmails: payload.arrValidatedEmails } } : ws
        ),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                emailMarketing: { ...state.activeWorkspace, validatedEmails: payload.arrValidatedEmails },
              }
            : state.activeWorkspace,
      };
    case ADD_HOSTED_DOMAIN_WEB:
      // payload = { wsId, domainName }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) =>
          ws._id === payload.wsId ? { ...ws, hosting: { ...ws.hosting, hostedDomains: [...ws.hosting.hostedDomains, payload.domainName] } } : ws
        ),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                hosting: { ...state.activeWorkspace.hosting, hostedDomains: [...state.activeWorkspace.hosting.hostedDomains, payload.domainName] },
              }
            : state.activeWorkspace,
      };
    case GET_WS_DOMAINS:
      // payload = { wsId, domains }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) => (ws._id === payload.wsId ? { ...ws, domains: payload.domains } : ws)),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                domains: payload.domains,
              }
            : state.activeWorkspace,
      };
    case UPDATE_WS_SUBS:
      // payload = { wsId, subscriptions }
      return {
        ...state,
        workspaces: state.workspaces.map((ws) => (ws._id === payload.wsId ? { ...ws, subscriptions: payload.subscriptions } : ws)),
        activeWorkspace:
          state.activeWorkspace._id === payload.wsId
            ? {
                ...state.activeWorkspace,
                subscriptions: payload.subscriptions,
              }
            : state.activeWorkspace,
      };
    // FX rates
    case SET_FX_RATES: {
      // payload = [ {curr, fxRate }, ... ]
      return {
        ...state,
        fxRates: payload,
      };
    }
    default:
      return state;
  }
}
