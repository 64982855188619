import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getStorageUsageData, workspaceExceedsStoragePlan } from "../lib/dashboardFunctions";

import { translate } from "../../../translations/translations";
import DashboardErrorItem from "../DashboardErrorItem";

ChartJS.register(ArcElement, Tooltip, Legend);

// NOTE: Would be great if this can be broken down in files, emails, DBs and available, however not easily available via Plesk

const StorageUsage = ({ auth: { activeWorkspace } }) => {
  const [storageData, setStorageData] = useState([]);

  useEffect(() => {
    getStorageData();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const getStorageData = () => {
    try {
      if (activeWorkspace !== null) {
        const data = getStorageUsageData(activeWorkspace);
        setStorageData(data);
      }
    } catch (error) {
      setStorageData([]);
    }
  };

  const data = {
    labels: [translate("cUserDashboard.storageUsed", false, null), translate("cUserDashboard.storageAvailable", false, null)],
    datasets: [
      {
        label: translate("cUserDashboard.hostingStorageUsage", false, null),
        data: getStorageUsageData(activeWorkspace),
        backgroundColor: ["rgba(229, 42, 5, 0.2)", "rgba(206, 212, 218, 0.2)"],
        borderColor: ["rgba(229, 42, 5, 1)", "rgba(206, 212, 218, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    plugins: {
      legend: {
        display: true,
      },
      // tooltip: {
      //   callbacks: {
      //     label: cbLabel,
      //     // label: (context) => {
      //     //   return `${context.formattedValue} recipients have opened the email ${context.label} times`;
      //     // },
      //     title: (context) => {
      //       return "";
      //     },
      //   },
      // },
    },
  };

  return storageData.length === 0 ? (
    <DashboardErrorItem msg={translate("cUserDashboard.noStorageDataFound", false, null)} />
  ) : workspaceExceedsStoragePlan(activeWorkspace) ? (
    <DashboardErrorItem msg={translate("cUserDashboard.hostingOverusingPlan", false, null)} />
  ) : (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

StorageUsage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(StorageUsage);
