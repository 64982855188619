export const pAudienceEntries = {
  audienceNotFound: {
    en: "Audience not found.",
    nl: "Audience niet gevonden.",
  },
  audienceEntries: {
    en: "Entries for audience",
    nl: "Gegevens voor audience",
  },
};
