import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { userIsWsAdmin } from "../../workspace/lib/wsFunctions";
import { translate } from "../../../translations/translations";

const Workspace = ({ auth: { user, activeWorkspace } }) => {
  return userIsWsAdmin(activeWorkspace, user) ? (
    <li className="mb-1">
      <button
        className="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#Workspace-collapse"
        aria-expanded="false"
      >
        {translate("cNavigation.manageActiveWs", false, null)}
      </button>
      <div className="collapse" id="Workspace-collapse">
        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <>
            <li data-bs-dismiss="offcanvas">
              <Link to={`/workspace/admin-info`} className="link-dark rounded">
                {translate("cNavigation.adminInfo", false, null)}
              </Link>
            </li>
            <li data-bs-dismiss="offcanvas">
              <Link to="/workspace/members" className="link-dark rounded">
                {translate("cNavigation.members", false, null)}
              </Link>
            </li>
            <li data-bs-dismiss="offcanvas">
              <Link to="/workspace/subscriptions" className="link-dark rounded">
                {translate("cNavigation.subscriptions", false, null)}
              </Link>
            </li>
            <li data-bs-dismiss="offcanvas">
              <Link to="/workspace/billing" className="link-dark rounded">
                {translate("cNavigation.billingOverview", false, null)}
              </Link>
            </li>
            <li data-bs-dismiss="offcanvas">
              <Link to="/workspace/referrals" className="link-dark rounded">
                {translate("cNavigation.referrals", false, null)}
              </Link>
            </li>
            <li data-bs-dismiss="offcanvas">
              <Link to={`/workspace/notifications/${activeWorkspace._id}`} className="link-dark rounded">
                {translate("cNavigation.notifications", false, null)}
              </Link>
            </li>
          </>
        </ul>
      </div>
    </li>
  ) : null;
};

Workspace.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Workspace);
