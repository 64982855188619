import { PRODUCT_NAMES } from "../../../products/lib/ProductVars";

export const subIsCancelled = (sub) => {
  return typeof sub.cancelDate !== "undefined" && !sub.isSuspended;
};

export const subIsSuspended = (sub) => {
  return typeof sub.cancelDate !== "undefined" && sub.isSuspended;
};

export const subIsSuspendedOrCancelled = (sub) => {
  return typeof sub.cancelDate !== "undefined";
};

export const sortByRenewDate = (a, b) => {
  if (a.renewDate > b.renewDate) {
    return -1;
  }
  if (a.renewDate < b.renewDate) {
    return 1;
  }
  return 0;
};

export const getPackageDetailUsageMetric = (packageDetails, metricName) => {
  return packageDetails.filter((packageDetail) => packageDetail.name === metricName)[0] || null;
};

export const canRefund = (ws, sub) => {
  try {
    // Requirements to be eligible for refund:
    // 1. Customer is a consumer
    const isConsumer = ws.customerType === "consumer";
    // 2. 14 days from original order date (= sub.startDate for sub.origin === "new" || "newUser")
    const startDate = new Date(sub.startDate);
    startDate.setDate(startDate.getDate() + 14);
    const isWithinRefundPeriod = startDate >= new Date();
    // 3. Domain names are not eligible
    const isEligibleProduct = ![PRODUCT_NAMES.domains].includes(sub.productName);
    // 4. Renewals/upgrades are not eligible
    const isOriginalBuy = ["newUser", "new"].includes(sub.origin);

    if (isConsumer && isWithinRefundPeriod && isEligibleProduct && isOriginalBuy) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
