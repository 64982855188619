import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FormatDate from "../layout/FormatDate";
import FormatNumber from "../layout/FormatNumber";

import { translate } from "../../translations/translations";

const ListWsAudiences = ({ auth: { user }, audiences: { audiences } }) => {
  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cListWsAudiences.yourAudiences", false, null)}</h2>
      {audiences.length === 0 ? (
        <p className="text-italic m-0">{translate("cListWsAudiences.noAudiencesFound", false, null)}</p>
      ) : (
        <div className="row">
          {audiences.map((audience) => (
            <div className="col-12 col-md-6 col-lg-3 p-2" key={audience._id}>
              <div className="flexSameHeight justify-content-center align-items-center shadow-light rounded-3 p-3 trans-3 posRelative">
                <Link
                  className="text-primary textHover-primary cursorPointer trans-3"
                  style={{ position: "absolute", right: "1rem", top: "1rem", fontSize: "1.25rem", zIndex: "99" }}
                  title={translate("cListWsAudiences.openMenu", false, null)}
                  to={`menu/${audience._id}`}
                >
                  <i className="fa-solid fa-bars"></i>
                </Link>
                <h3 className="text-primary text-center toGrow">{audience.audienceName}</h3>
                <span
                  className="my-4 fontSize25 text-primary bg-light rounded-circle border border-primary d-flex justify-content-center align-items-center"
                  style={{ height: "75px", width: "75px" }}
                >
                  <i className="fa-solid fa-users"></i>
                </span>
                <div className="text-center text-gray fontSize08 mb-4">
                  <p className="my-0">
                    <span className="text-bold">{translate("cListWsAudiences.entries", false, null)}:</span>{" "}
                    <FormatNumber number={audience.data.length} formatStr={"0"} />
                  </p>
                  <p className="my-0">
                    <span className="text-bold">{translate("cListWsAudiences.created", false, null)}:</span>{" "}
                    <FormatDate dateString={audience.createdAt} lang={user.language} formatStr={"dmyy"} />
                  </p>
                  <p className="my-0">
                    <span className="text-bold">{translate("cListWsAudiences.lastChange", false, null)}:</span>{" "}
                    <FormatDate dateString={audience.updatedAt} lang={user.language} formatStr={"dmyy"} />
                  </p>
                </div>
                <Link className="btn btn-primary trans-3 w-100" to={`entries/${audience._id}`}>
                  {translate("cListWsAudiences.seeEntries", false, null)}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

ListWsAudiences.propTypes = {
  auth: PropTypes.object.isRequired,
  audiences: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  audiences: state.audiences,
});

export default connect(mapStateToProps, null)(ListWsAudiences);
