export const pContactFormMessages = {
  yourContactforms: {
    en: "Your contact forms",
    nl: "Uw contact forms",
  },
  yourMessages: {
    en: "Your messages",
    nl: "Uw berichten",
  },
  noFormsYet: {
    en: "You don't have any contact forms on published sites yet.",
    nl: "U heeft nog geen contact forms op gepubliceerde sites.",
  },
  noMsgsYet: {
    en: "You don't have any messages yet.",
    nl: "U heeft nog geen berichten.",
  },
  createNewCf: {
    en: "Create new contact form",
    nl: "Creer nieuw contact form",
  },
  cfName: {
    en: "Contact form name",
    nl: "Contact form naam",
  },
  create: {
    en: "Create",
    nl: "Creer",
  },
  creatingNewCf: {
    en: "Creating new contact form..",
    nl: "Nieuw contact form aan het creeren..",
  },
  serverErrorCreatingCf: {
    en: "Server error while creating new contact form. Please try again.",
    nl: "Sever fout tijdens het creeren van het contact form. Probeer het aub opnieuw.",
  },
  latestMsgs: {
    en: "These are your latest messages",
    nl: "Dit zijn uw meest recente berichten",
  },
  newerMsg: {
    en: "See newer messages",
    nl: "Bekijk recentere berichten",
  },
  showingMsgs: {
    en: "Showing messages",
    nl: "Berichten",
  },
  showingMsgsOf: {
    en: "of",
    nl: "van",
  },
  oldestMsgs: {
    en: "These are your oldest messages",
    nl: "Dit zijn uw oudste berichten",
  },
  olderMsgs: {
    en: "See older messages",
    nl: "Bekijk oudere berichten",
  },
  deselectMsg: {
    en: "Deselect message",
    nl: "Deselecteer bericht",
  },
  selectMsg: {
    en: "Select message",
    nl: "Selecteer bericht",
  },
  markAsUnread: {
    en: "Mark as unread",
    nl: "Markeer als ongelezen",
  },
  markAsRead: {
    en: "Mark as read",
    nl: "Markeer als gelezen",
  },
  date: {
    en: "Date",
    nl: "Datum",
  },
  yourMsgOnSite: {
    en: "Your message on",
    nl: "Je bericht op",
  },
  reply: {
    en: "Reply",
    nl: "Antwoord",
  },
  delete: {
    en: "Delete",
    nl: "Verwijder",
  },
  noMsgsYetForCf: {
    en: "No messages yet for",
    nl: "Nog geen berichten voor",
  },
  msgsForCf: {
    en: "Messages for",
    nl: "Berichten voor",
  },
  from: {
    en: "from",
    nl: "van",
  },
  exportAll: {
    en: "Export all email addresses",
    nl: "Export alle email addressen",
  },
  selectAll: {
    en: "Select all",
    nl: "Selecteer alle",
  },
  deselectAll: {
    en: "Deselect all",
    nl: "Onselecteer alle",
  },
  deleteSelected: {
    en: "Delete selected",
    nl: "Verwijder geselecteerde",
  },
  retrievingYourMsgs: {
    en: "Retrieving your messages..",
    nl: "Ophalen van uw berichten..",
  },
  serverErrorRetrievingMsgs: {
    en: "Server error while retrieving your messages. Please try again.",
    nl: "Server fout tijdens het ophalen van uw berichten. Probeer het aub opnieuw.",
  },
};
