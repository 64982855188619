// Bring in action types
import { GET_AUDIENCES } from "../actions/types";

// Set initialState to an empty array
const initialState = {
  audiences: [],
};

// Reducer functionality
export default function audiences(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;
  switch (type) {
    case GET_AUDIENCES:
      // Payload is [ { audience }, ... ]
      return { ...state, audiences: payload };
    default:
      return state;
  }
}
