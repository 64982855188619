import axios from "axios";
// import { setAlert } from "./alert";
import { SET_BRAND_DATA, SET_PRODUCT_DATA, SET_TLD_DATA, BRAND_ERROR } from "./types";
import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";

import { API_URL } from "../lib/GeneralVars";

export const getBrandData = (brandId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading("Loading product information.."));
  try {
    const res = await axios.get(`${API_URL}/admin/brandsAndProducts/brands/${brandId}`);
    const { offers, ...rest } = res.data;
    dispatch({
      type: SET_BRAND_DATA,
      payload: { brandOffers: offers, brandData: rest },
    });
    let productIds = offers.map((offer) => offer.productId);
    await dispatch(getProductData(productIds));
    await dispatch(getDomainPrices(brandId));
    dispatch(removeGlobalLoading(loadingId));
  } catch (error) {
    dispatch({ type: BRAND_ERROR });
    dispatch(removeGlobalLoading(loadingId));
  }
};

export const getProductData = (productIds) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/admin/brandsAndProducts/products/all`);
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: res.data.filter((product) => productIds.includes(product._id)),
    });
  } catch (error) {
    dispatch({ type: BRAND_ERROR });
  }
};

export const getDomainPrices = (brandId) => async (dispatch) => {
  try {
    // res.data = { categories, TLDs }
    const res = await axios.get(`${API_URL}/domains/${brandId}/all`);
    dispatch({
      type: SET_TLD_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: BRAND_ERROR });
  }
};
