import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { capitalizeFirstLetter } from "../../lib/formattingFunctions";
import { PRODUCT_NAMES, PRODUCT_NAME_CREDIT, PERIOD_ANNUALLY, PERIOD_MONTHLY } from "../products/lib/ProductVars";

import FormatNumber from "../layout/FormatNumber";

import { removeFromCart } from "../../actions/cart";
import { translate } from "../../translations/translations";
import { getCurrency } from "../../lib/Currencies";

const CartSubscription = ({ auth: { activeWorkspace }, cart: { sessionId }, cartItems, removeFromCart }) => {
  let curr = activeWorkspace === null ? "" : getCurrency(activeWorkspace.location.priceCountry).currencySign;

  const getDomainPriceAction = (item) => {
    let domainPriceAction = item.additionalData?.domainPriceAction || "";
    if (domainPriceAction === "registerDiscount") {
      domainPriceAction = "register";
    }
    return capitalizeFirstLetter(domainPriceAction);
  };
  return (
    <>
      {/* Header */}
      <div className="row mx-0 bg-primary text-white text-bold py-2">
        <div className="col-3 d-flex align-items-center">{translate("cCartSubscription.subscription", false, null)}</div>
        <div className="col-2 d-flex align-items-center">{translate("cCartSubscription.price", false, null)}</div>
        <div className="col-2 d-flex align-items-center">{translate("cCartSubscription.billing", false, null)}</div>
        <div className="col-4 d-flex align-items-center">{translate("cCartOverdueInvoice.payableAtCheckout", false, null)}</div>
        <div className="col-1 d-flex align-items-center"></div>
      </div>
      {/* List of subscriptions */}
      {cartItems.map((item) => (
        <div className="row align-items-center mx-0 py-2" key={item._id}>
          <div className="col-3 d-flex flex-column">
            {item.productName === PRODUCT_NAMES.domains ? (
              <>
                {translate("cCartSubscription.domain", false, null)} - {getDomainPriceAction(item)}
              </>
            ) : (
              capitalizeFirstLetter(item.productName)
            )}{" "}
            - {capitalizeFirstLetter(item.packageName)}
          </div>
          <div className="col-2">
            {item.productName === PRODUCT_NAME_CREDIT ? (
              ""
            ) : (
              <>
                {curr} <FormatNumber number={item.selectedPeriod === PERIOD_ANNUALLY ? item.payableAtCheckout : item.price} formatStr={"0.00"} />
              </>
            )}
          </div>
          <div className="col-2 d-flex flex-column">
            <p className="m-0">
              {item.productName === PRODUCT_NAME_CREDIT
                ? ""
                : item.selectedPeriod === PERIOD_ANNUALLY
                ? translate("cCartSubscription.upfront", false, null)
                : translate("cCartSubscription.monthly", false, null)}
            </p>
          </div>
          <div className="col-4">
            {item.selectedPeriod === PERIOD_MONTHLY ? (
              <>
                <p className="m-0">--</p>
                <p className="m-0 text-italic fontSize08">({translate("cCartSubscription.changeReflectedNextMonthlyInvoice", false, null)})</p>
              </>
            ) : (
              <p className="m-0">
                {curr} <FormatNumber number={item.payableAtCheckout} formatStr={"0.00"} />
              </p>
            )}
          </div>
          <div className="col-1">
            {item.productName !== PRODUCT_NAME_CREDIT && (
              <span
                className="text-danger cursorPointer"
                title={translate("cCartOverdueInvoice.removeItemFromCart", false, null)}
                onClick={() => removeFromCart(sessionId, item._id)}
              >
                <i className="fa-solid fa-xmark" />
              </span>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

CartSubscription.propTypes = {
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  cartItems: PropTypes.array.isRequired,
  removeFromCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, { removeFromCart })(CartSubscription);
