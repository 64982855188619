import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { duplicateSbProject } from "../../../../actions/sitebuilder";

import { translate } from "../../../../translations/translations";

const SbMenuDuplicate = ({ auth: { user }, sitebuilder: { sbProjects }, duplicateSbProject }) => {
  let { projectId } = useParams();

  const clickDuplicateProject = async () => {
    await duplicateSbProject(projectId);
  };

  return (
    <>
      <h2 className="text-secondary mt-5 mb-3">{translate("cSitebuilder.duplicateProject", false, null)}</h2>
      {projectId === "" ? (
        <p className="text-italic m-0">{translate("cSitebuilder.projectNotFound", false, null)}</p>
      ) : (
        <>
          <p className="mb-2">{translate("cSitebuilder.duplicatingIsEasyStart", false, null)}</p>
          <button className="btn btn-success" onClick={clickDuplicateProject}>
            {translate("cSitebuilder.duplicateProject", false, null)}
          </button>
        </>
      )}
    </>
  );
};

SbMenuDuplicate.propTypes = {
  auth: PropTypes.object.isRequired,
  sitebuilder: PropTypes.object.isRequired,
  duplicateSbProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sitebuilder: state.sitebuilder,
});

export default connect(mapStateToProps, { duplicateSbProject })(SbMenuDuplicate);
