export const getDataMsgStats = (dataToUse) => {
  // dataToUse = array of recipients from email campaign object

  // output needed: ["Not delivered", "Not opened", "Not interacted", "Interacted", "Unsubscribed"]
  let data = [[], [], [], [], []];
  for (const recipient of dataToUse) {
    const { received, opened, clickedOn, unsubscribed } = recipient;
    if (!received) {
      data[0].push(1);
      continue;
    }
    if (!opened) {
      data[1].push(1);
      continue;
    }
    if (clickedOn.length === 0 && !unsubscribed) {
      data[2].push(1);
      continue;
    }
    if (clickedOn.length > 0 && !unsubscribed) {
      data[3].push(1);
      continue;
    }
    if (unsubscribed) {
      data[4].push(1);
      continue;
    }
  }
  return data.map((arr) => arr.length);
};
